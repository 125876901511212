import AuthService from "../AuthService";
import Service from "../Service";

const customers = {
  index(payload) {
    return AuthService.get(
      `/api/customers?date_from=${payload.date_from}&date_to=${payload.date_to}`
    ).then((res) => res);
  },
  show(id) {
    return AuthService.get(`/api/customers/${id}`).then((res) => res);
  },
  save(payload) {
    return AuthService.post("/api/customers", payload).then((res) => res);
  },
  update(id, payload) {
    return AuthService.put(`/api/customers/${id}`, payload).then((res) => res);
  },
  delete(id) {
    return AuthService.delete(`/api/customers/${id}`).then((res) => res);
  },
  deleteCustomerLog(id) {
    return AuthService.post(`/api/delete-customer-log/${id}`).then(
      (res) => res
    );
  },
};

export default customers;
