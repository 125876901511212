import Service from "../../services";

const state = {
  access_token: null,
  user: null,
};

const getters = {};

const mutations = {
  SET_TOKEN(state, data) {
    console.log(data);
    state.access_token = data.token;
    state.user = data.user;
  },
  CLEAR_TOKEN(state, data) {
    console.log(data);
    state.access_token = null;
    state.user = null;
    // window.localStorage.removeItem("vuex");
  },
};

const actions = {
  signin({ commit }, data) {
    return Service.auth
      .signin(data)
      .then((res) => {
        if (res.status === 200) {
          commit("SET_TOKEN", res);
        }
        return res;
      })
      .catch((err) => {
        return err.response;
      });
  },
  signout({ commit }) {
    return Service.auth.signout().then((res) => {
      commit("CLEAR_TOKEN");
      return res;
    });
  },
  changePassword({ commit }, data) {
    return Service.auth.changePassword(data).then((res) => {
      return res;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
