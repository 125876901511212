<template>
  <div class="input__setting">
    <v-text-field
      class="pa-0"
      :value="model"
      dense
      solo
      type="text"
      color="#000"
      :placeholder="placeholder"
      :rules="required ? [(v) => !!v] : []"
      required
      @input="$emit('update-data', $event)"
      :oninput="returnInputRule"
      hide-details
    />
  </div>
</template>

<script>
export default {
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    errorMessage: String,
    model: null,
    placeholder: String,
    inputType: {
      typr: String,
      default: "text",
    },
    color: {
      typr: String,
      default: "#a3a3a3",
    },
  },
  created() {},
  computed: {
    returnInputRule: function () {
      switch (this.inputType) {
        case "number":
          return "value=value.replace(/[^\\d]/g,'')";

        case "decimal":
          // 0-9, -, . และ ตัวเลขที่มีหลังจุดไม่เกิน 2 ตัว
          return "value=value.replace(/[^\\d\\-\\.]/g,'').replace(/(\\.\\d\\d).+/, '$1')";
        default:
          return null;
      }
    },
  },
};
</script>

<style lang="scss">
.input__setting {
  .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
    font-weight: 500;
    font-size: 16px;
    min-height: 25px;
    border-radius: 10px;
  }
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
    > .v-input__control
    > .v-input__slot {
    box-shadow: unset !important;
    border: 1px solid !important;
  }
  .v-text-field fieldset,
  .v-text-field .v-input__control,
  .v-text-field .v-input__slot {
    border-radius: 10px;
  }
  .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot,
  .v-text-field.v-text-field--enclosed .v-text-field__details {
    padding: 0 5px;
  }
  .v-text-field.v-text-field--solo .v-input__control input {
    text-align: center;
  }
}
</style>
