// local
// export const domain = "http://127.0.0.1:8000/";
// export const url_path = "http://127.0.0.1:8001/storage/";

// dev
export const domain = "https://powerpodfitness-backend.ksta.co/";
export const url_path = "https://powerpodfitness-backend.ksta.co/storage/";

// server
// export const domain = "https://systemapi.powerpodfitness.com/";
// export const url_path = "https://systemapi.powerpodfitness.com/storage/";

//questionnaire
export const url_questionnaire = "https://question.powerpodfitness.com/";

export const measurements = [
  // อายุของร่างกาย
  "อายุของปอด (ความจุปอด)",
  "อายุผิว",

  // ความสมดุลทางกายภาพ
  "ดัชนีมวลกาย",
  // "ความดันโลหิตต่ำสุด",
  // "ความดันโลหิตสูงสุด",
  "ระดับไขมันในช่องท้อง",
  "ระดับไขมันในร่างกาย",
  "ระดับน้ำในร่างกาย",
  "อัตราการเผาผลาญ",

  // การเคลื่อนไหว
  "มวลกล้ามเนื้อ",
  "แรงบีบมือข้างซ้าย",
  "แรงบีบมือข้างขวา",
  "ความแข็งแรงกล้ามเนื้อหลัง",
  "การทรงตัวยืนขาเดียว",
  "Stand Up Test",
  "Functional Test",
  "2 Step Test",

  // ความยืดหยุ่น
  "ความอ่อนตัวหัวไหล่",
  "ความอ่อนตัวหัวเข่า",
  "ความอ่อนตัวของหลัง",
  "คะแนนท่าทาง",
  "ปัญหาท่าทาง",
];

export const measurementList = [
  { name: "ความดันโลหิตต่ำสุด", no: 1 },
  { name: "ความดันโลหิตสูงสุด", no: 1 },
  { name: "ดัชนีมวลกาย", no: 2 },
  { name: "อัตราการเผาผลาญ", no: 3 },
  { name: "ระดับไขมันในร่างกาย", no: 4 },
  { name: "ระดับไขมันในช่องท้อง", no: 5 },
  { name: "แรงบีบมือข้างซ้าย", no: 6 },
  { name: "แรงบีบมือข้างขวา", no: 6 },
  { name: "มวลกล้ามเนื้อ", no: 7 },
  { name: "2 Step Test", no: 8 },
  { name: "ความอ่อนตัวหัวไหล่", no: 9 },
  { name: "ความอ่อนตัวขาข้างซ้าย", no: 10 },
  { name: "ความอ่อนตัวขาข้างขวา", no: 10 },
  // { name: "อายุของปอด (ความจุปอด)", no: 13 },
  // { name: "อายุผิว", no: 14 },
  // { name: "ความแข็งแรงกล้ามเนื้อหลัง", no: 15 },
  { name: "การทรงตัวยืนขาเดียว", no: 11 },
  { name: "Stand Up Test", no: 12 },
  { name: "Functional Test", no: 13 },
  { name: "ระดับน้ำในร่างกาย", no: 14 },
  { name: "ความอ่อนตัวของหลัง", no: 15 },
  { name: "คะแนนท่าทาง", no: 16 },
  { name: "ปัญหาท่าทาง", no: 17 },
  { name: "ทดสอบลุกจากเก้าอี้", no: 18 },
  { name: "ทดสอบก้าวขึ้น-ลง", no: 19 },
];

export const body_parts = [
  { name: "ศรีษะ", image: "head" },
  { name: "สะบัก", image: "shoulder" },
  { name: "ไหล่", image: "shoulder-2" },
  { name: "หลังส่วนบน", image: "upper-back" },
  { name: "หลังส่วนล่าง", image: "lower-back" },
  { name: "เอว", image: "waist" },
  { name: "สะโพก", image: "hip" },
  { name: "แขน", image: "arm" },
  { name: "นิ้วมือ", image: "finger" },
  { name: "ข้อพับ", image: "joint" },
  { name: "ข้อมือ", image: "wrist" },
  { name: "หน้าท้อง", image: "abdomen" },
  { name: "หน้าอก", image: "chest" },
  { name: "หลังขา", image: "behind-leg" },
  { name: "ต้นขา", image: "thigh" },
  { name: "หัวเข่า", image: "knee" },
  { name: "หน้าแข้ง", image: "shin" },
  { name: "น่อง", image: "calf" },
  { name: "ข้อเท้า", image: "ankle" },
  { name: "นิ้วเท้า", image: "toenail" },
];

export const body_positions = [
  { no: 1, name: "ศรีษะ", image: "head" },
  { no: 2, name: "สะบัก", image: "shoulder" },
  { no: 3, name: "ไหล่", image: "shoulder-2" },
  { no: 4, name: "หลังส่วนบน", image: "upper-back" },
  { no: 5, name: "หลังส่วนล่าง", image: "lower-back" },
  { no: 6, name: "เอว", image: "waist" },
  { no: 7, name: "สะโพก", image: "hip" },
  { no: 8, name: "แขน", image: "arm" },
  { no: 9, name: "นิ้วมือ", image: "finger" },
  { no: 10, name: "ข้อพับ", image: "joint" },
  { no: 11, name: "ข้อมือ", image: "wrist" },
  { no: 12, name: "หน้าท้อง", image: "abdomen" },
  { no: 13, name: "หน้าอก", image: "chest" },
  { no: 14, name: "หลังขา", image: "behind-leg" },
  { no: 15, name: "ต้นขา", image: "thigh" },
  { no: 16, name: "หัวเข่า", image: "knee" },
  { no: 17, name: "หน้าแข้ง", image: "shin" },
  { no: 18, name: "น่อง", image: "calf" },
  { no: 19, name: "ข้อเท้า", image: "ankle" },
  { no: 20, name: "นิ้วเท้า", image: "toenail" },
];

export const problematic_parts = ["หัวไหล่(ขวา)", "สะโพก(ซ้าย)"];
