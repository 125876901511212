<template>
  <v-app style="background-color: #f3f3f3">
    <div class="app">
      <transition
        name="router-animation"
        mode="out-in"
        enter-active-class="animated fadeIn fast"
        leave-active-class="animated fadeOut faster"
      >
        <router-view></router-view>
      </transition>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({}),
};
</script>

<style lang="scss">
.app {
  // max-width: 1200px;
  // margin: 0 auto;
  overflow: hidden;
  background-color: #f3f3f3;
  min-height: 100vh;
  height: 100%;
}
</style>
