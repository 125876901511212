// create func BloodPressure
export const bloodPressure = (setting, min, max) => {
  let result = "";
  let status = {
    min: "",
    max: "",
  };
  let bp = setting;
  min = parseInt(min);
  max = parseInt(max);

  // Case Min
  // Standard => ex. 0-79
  if (min >= +bp.standard_min_from && min <= +bp.standard_min_to) {
    status.min = "standard";
  }
  // Warn => ex. 80-90
  else if (min >= +bp.warn_min_from && min <= +bp.warn_min_to) {
    status.min = "warn";
  }
  // Danger => ex. 91-150
  else if (min >= +bp.danger_min_from && min <= +bp.danger_min_to) {
    status.min = "danger";
  }
  // Case of mismatch
  else {
    status.min = null;
  }

  // Case Max
  // Standard => ex. 0-119
  if (max >= +bp.standard_max_from && max <= +bp.standard_max_to) {
    status.max = "standard";
  }
  // Warn => ex. 120-140
  else if (max >= +bp.warn_max_from && max <= +bp.warn_max_to) {
    status.max = "warn";
  }
  // Danger => ex. 140-200
  else if (max >= +bp.danger_max_from && max <= +bp.danger_max_to) {
    status.max = "danger";
  }
  // Case of mismatch
  else {
    status.max = null;
  }

  // Check Color status
  if (
    (status.min === "danger" && status.max === "danger") ||
    (status.min === "danger" && status.max === "warn") ||
    (status.min === "danger" && status.max === "standard") ||
    (status.min === "warn" && status.max === "danger") ||
    (status.min === "standard" && status.max === "danger")
  ) {
    result = "danger";
  } else if (
    (status.min === "warn" && status.max === "warn") ||
    (status.min === "warn" && status.max === "standard") ||
    (status.min === "standard" && status.max === "warn")
  ) {
    result = "warn";
  } else if (status.min === "standard" && status.max === "standard") {
    result = "standard";
  } else {
    result = "";
  }

  return result;
};

export const bloodPressureMin = (setting, min) => {
  let result = "";
  let bp = setting;
  min = parseInt(min);

  // Case Min
  // Standard => ex. 0-79
  if (min >= +bp.standard_min_from && min <= +bp.standard_min_to) {
    result = "standard";
  }
  // Warn => ex. 80-90
  else if (min >= +bp.warn_min_from && min <= +bp.warn_min_to) {
    result = "warn";
  }
  // Danger => ex. 91-150
  else if (min >= +bp.danger_min_from && min <= +bp.danger_min_to) {
    result = "danger";
  }
  // Case of mismatch
  else {
    result = null;
  }

  return result;
};

export const bloodPressureMax = (setting, max) => {
  let result = "";
  let bp = setting;
  max = parseInt(max);

  console.log("max", max);
  console.log("standard_max", bp.standard_max_from, bp.standard_max_to);
  console.log("warn_max", bp.warn_max_from, bp.warn_max_to);
  console.log("danger_max", bp.danger_max_from, bp.danger_max_to);

  // Case Max
  // Standard => ex. 0-119
  if (max >= +bp.standard_max_from && max <= +bp.standard_max_to) {
    result = "standard";
  }
  // Warn => ex. 120-140
  else if (max >= +bp.warn_max_from && max <= +bp.warn_max_to) {
    result = "warn";
  }
  // Danger => ex. 140-200
  else if (max >= +bp.danger_max_from && max <= +bp.danger_max_to) {
    result = "danger";
  }
  // Case of mismatch
  else {
    result = null;
  }

  return result;
};

export const bmiSetting = (setting, bmi) => {
  let result = "";
  let bmi_setting = setting;
  bmi = parseFloat(bmi);

  // Check BIM rate
  // 18.5-25.0
  if (bmi >= +bmi_setting.standard_from && bmi <= +bmi_setting.standard_to) {
    result = "standard";
  }
  // 0-18.5 OR 25.1-35.0
  else if (
    (bmi >= +bmi_setting.warn_less_from && bmi <= +bmi_setting.warn_less_to) ||
    (bmi >= +bmi_setting.warn_than_from && bmi <= +bmi_setting.warn_than_to)
  ) {
    result = "warn";
  }
  // 35.1-50
  else if (bmi >= +bmi_setting.danger_from && bmi <= +bmi_setting.danger_to) {
    result = "danger";
  }
  // Case of mismatch
  else {
    result = "";
  }
  return result;
};

export const basalMetabolicRateSetting = (setting, age, gender, val) => {
  let result = "";
  // Get setting by Gender (Male or Female)
  let bmrs = setting.filter((item) => item.gender === gender);
  if (bmrs == null) return "";

  bmrs.forEach((item) => {
    // Check age
    if (age >= item.age_from && age <= item.age_to) {
      // get case by id in bmrs
      let bmr = bmrs.find((bmr) => bmr.id === item.id);

      if (bmr) {
        // Check result
        if (+val >= +bmr.standard_from && +val <= +bmr.standard_to) {
          console.log("standard");
          result = "standard";
        } else if (+val >= +bmr.warn_from && +val <= +bmr.warn_to) {
          console.log("warn");
          result = "warn";
        } else if (+val >= +bmr.danger_from && +val <= +bmr.danger_to) {
          console.log("danger");
          result = "danger";
        } else {
          result = "";
        }
      }
    }
  });

  return result;
};

export const bodyFatRateSetting = (setting, age, gender, val) => {
  let result = "";
  val = parseFloat(val);

  // Find BodyFatRateSetting by Gender
  let bfrs = setting.filter((item) => item.gender === gender);
  if (bfrs == null) return "";

  bfrs.forEach((item) => {
    // Check age
    if (age >= item.age_from && age <= item.age_to) {
      // Find Case BodyFatRateSetting by id
      let bmr = bfrs.find((bmr) => bmr.id === item.id);
      if (bmr) {
        // Check result
        if (val >= +bmr.standard_from && val <= +bmr.standard_to) {
          result = "standard";
        } else if (val >= +bmr.warn_from && val <= +bmr.warn_to) {
          result = "warn";
        } else if (
          (val >= +bmr.danger_less_from && val <= +bmr.danger_less_to) ||
          (val >= +bmr.danger_than_from && val <= +bmr.danger_than_to)
        ) {
          result = "danger";
        } else {
          result = "";
        }
      }
    }
  });
  return result;
};

export const visceralFatLevelSetting = (setting, val) => {
  let result = "";
  let vfl = setting;
  val = parseFloat(val);

  // 0-9.5
  if (val >= +vfl.standard_from && val <= +vfl.standard_to) {
    result = "standard";
  }
  // 10-14.5
  else if (val >= +vfl.warn_from && val <= +vfl.warn_to) {
    result = "warn";
  }
  // 15-30
  else if (val >= +vfl.danger_from && val <= +vfl.danger_to) {
    result = "danger";
  }
  // Case of mismatch
  else {
    result = "";
  }

  return result;
};

export const gripStrengthSetting = (setting, gender, left, right) => {
  let result = "";
  let status = {
    left: "",
    right: "",
  };
  left = parseFloat(left);
  right = parseFloat(right);

  // Find gt by gender
  let gt = setting.find((item) => item.gender === gender);
  if (gt == null) return "";

  // Left hand
  if (left >= +gt.standard_from && left <= +gt.standard_to) {
    status.left = "standard";
  } else if (left >= +gt.warn_from && left <= +gt.warn_to) {
    status.left = "warn";
  } else if (left >= +gt.danger_from && left <= +gt.danger_to) {
    status.left = "danger";
  }
  // Case of mismatch
  else {
    status.left = null;
  }

  // Right hand
  if (right >= +gt.standard_from && right <= +gt.standard_to) {
    status.right = "standard";
  } else if (right >= +gt.warn_from && right <= +gt.warn_to) {
    status.right = "warn";
  } else if (right >= +gt.danger_from && right <= +gt.danger_to) {
    status.right = "danger";
  }
  // Case of mismatch
  else {
    status.right = null;
  }

  // Check Color status
  if (
    (status.left === "standard" && status.right === "standard") ||
    (status.left === "standard" && status.right === "warn") ||
    (status.left === "standard" && status.right === "danger") ||
    (status.left === "warn" && status.right === "standard") ||
    (status.left === "danger" && status.right === "standard")
  ) {
    result = "standard";
  } else if (
    (status.left === "warn" && status.right === "warn") ||
    (status.left === "warn" && status.right === "danger") ||
    (status.left === "danger" && status.right === "warn")
  ) {
    result = "warn";
  } else if (status.left === "danger" && status.right === "danger") {
    result = "danger";
  } else {
    result = "";
  }

  return result;
};

export const gripStrengthRightSetting = (setting, gender, right) => {
  let result = "";
  right = parseFloat(right);

  // Find gt by gender
  let gt = setting.find((item) => item.gender === gender);
  if (gt == null) return "";

  // Right hand
  if (right >= +gt.standard_from && right <= +gt.standard_to) {
    result = "standard";
  } else if (right >= +gt.warn_from && right <= +gt.warn_to) {
    result = "warn";
  } else if (right >= +gt.danger_from && right <= +gt.danger_to) {
    result = "danger";
  }
  // Case of mismatch
  else {
    result = null;
  }

  return result;
};

export const gripStrengthLeftSetting = (setting, gender, left) => {
  let result = "";
  left = parseFloat(left);

  // Find gt by gender
  let gt = setting.find((item) => item.gender === gender);
  if (gt == null) return "";

  // left hand
  if (left >= +gt.standard_from && left <= +gt.standard_to) {
    result = "standard";
  } else if (left >= +gt.warn_from && left <= +gt.warn_to) {
    result = "warn";
  } else if (left >= +gt.danger_from && left <= +gt.danger_to) {
    result = "danger";
  }
  // Case of mismatch
  else {
    result = null;
  }

  return result;
};

export const muscleMassPointsSetting = (setting, age, gender, val) => {
  let result = "";
  val = parseFloat(val);

  // Find BodyFatRateSetting by Gender
  let mmps = setting.filter((item) => item.gender === gender);
  if (mmps == null) return "";

  mmps.forEach((item) => {
    // Check age
    if (age >= item.age_from && age <= item.age_to) {
      // Find Case BodyFatRateSetting by id
      let mmp = mmps.find((mmp) => mmp.id === item.id);
      if (mmp) {
        // Check result
        if (val >= +mmp.standard_from && val <= +mmp.standard_to) {
          result = "standard";
        } else if (val >= +mmp.warn_from && val <= +mmp.warn_to) {
          result = "warn";
        } else if (val >= +mmp.danger_from && val <= +mmp.danger_to) {
          result = "danger";
        } else {
          result = "";
        }
      }
    }
  });

  return result;
};

export const vascularAge = (age, vascular_age) => {
  let result = "";
  vascular_age = parseFloat(vascular_age);
  age = parseFloat(age);

  if (vascular_age <= age) {
    result = "standard";
  } else if (vascular_age > age) {
    if (vascular_age - age > 5) {
      result = "danger";
    } else {
      result = "warn";
    }
  } else {
    result = "";
  }
  return result;
};

export const bodyAge = (age, body_age) => {
  let result = "";
  body_age = parseFloat(body_age);
  age = parseFloat(age);

  if (body_age <= age) {
    result = "standard";
  } else if (body_age > age) {
    if (body_age - age > 5) {
      result = "danger";
    } else {
      result = "warn";
    }
  } else {
    result = "";
  }
  return result;
};

export const stability = (setting, age, gender, val) => {
  let result = "";
  val = parseFloat(val);

  // Find stability_criteria_setting Setting by Gender
  let stability_criteria_settings = setting.filter(
    (item) => item.gender === gender
  );
  if (stability_criteria_settings == null) return "";

  stability_criteria_settings.forEach((item) => {
    // Check duration age
    if (age >= item.age_from && age <= item.age_to) {
      // Find Case stability_criteria_setting by id
      // if >= 1.3 is standard and 1.1 - 1.2 is warn and < 1.1 is danger
      let stability = stability_criteria_settings.find(
        (stability) => stability.id === item.id
      );

      if (stability) {
        if (val >= +stability.ideal_from && val <= +stability.ideal_to) {
          // Check result
          result = "standard";
        } else if (val >= +stability.warn_from && val <= +stability.warn_to) {
          result = "warn";
        } else if (val < +stability.danger_from) {
          result = "danger";
        } else {
          result = "";
        }
      }
    }
  });

  return result;
};

export const FlexibilityUpper = (flexibility_upper) => {
  let result = "";
  flexibility_upper = parseFloat(flexibility_upper);

  // if -10 to 0 is standard and 0.1 to 10.0 is warn and more than 10.1 is danger
  if (flexibility_upper >= -10 && flexibility_upper <= 0) {
    result = "standard";
  } else if (flexibility_upper >= 0.1 && flexibility_upper <= 10.0) {
    result = "warn";
  } else if (flexibility_upper >= 10.1) {
    result = "danger";
  } else {
    result = "";
  }

  return result;
};

export const FlexibilityLower = (setting, age, gender, val) => {
  let result = "";
  val = parseFloat(val);

  // Find FlexibilityLower Setting by Gender
  let flexibility_lowers = setting.filter((item) => item.gender === gender);
  if (flexibility_lowers == null) return "";

  flexibility_lowers.forEach((item) => {
    // Check duration age
    if (age >= item.age_from && age <= item.age_to) {
      // Find Case FlexibilityLower by id
      let flexibility_lower = flexibility_lowers.find(
        (flexibility_lower) => flexibility_lower.id == item.id
      );
      if (flexibility_lower) {
        // Check result
        if (
          val >= +flexibility_lower.ideal_from &&
          val <= +flexibility_lower.ideal_to
        ) {
          result = "standard";
        } else if (
          val >= +flexibility_lower.warn_from &&
          val <= +flexibility_lower.warn_to
        ) {
          result = "warn";
        } else if (
          val >= +flexibility_lower.danger_from &&
          val <= +flexibility_lower.danger_to
        ) {
          result = "danger";
        } else {
          result = "";
        }
      }
    }
  });

  return result;
};

export const lungAge = (setting, age, lung_age) => {
  let result = "";
  age = parseFloat(age);
  lung_age = parseFloat(lung_age);

  // if อายุเท่ากันหรือน้อยกว่าอายุจริง return standard
  if (lung_age <= age) {
    result = "standard";
  }
  // if อายุ lung_age มากกว่าอายุจริง แต่ไม่เกิน 5 ปี return warn แต่ถ้ามากกว่า 5 ปี return danger
  else if (lung_age > age) {
    if (lung_age - age > setting.warn) {
      result = "danger";
    } else {
      result = "warn";
    }
  } else {
    result = "";
  }
  return result;
};

export const skinAge = (setting, age, skin_age) => {
  let result = "";
  age = parseFloat(age);
  skin_age = parseFloat(skin_age);

  setting.warn = setting.warn || 5;
  // if อายุเท่ากันหรือน้อยกว่าอายุจริง return standard
  if (skin_age <= age) {
    result = "standard";
  }
  // if อายุ skin_age มากกว่าอายุจริง แต่ไม่เกิน 5 ปี return warn แต่ถ้ามากกว่า 5 ปี return danger
  else if (skin_age > age) {
    if (skin_age - age > setting.warn) {
      result = "danger";
    } else {
      result = "warn";
    }
  } else {
    result = "";
  }
  return result;
};

export const backStrength = (setting, age, gender, val) => {
  let result = "";
  val = parseFloat(val);

  // Get setting by Gender (Male or Female)
  let backStrengths = setting.filter((item) => item.gender === gender);
  if (backStrengths == null) return "";

  backStrengths.forEach((item) => {
    // Check age
    if (age >= item.age_from && age <= item.age_to) {
      // get case by id in backStrengths
      let backStrength = backStrengths.find(
        (backStrength) => backStrength.id === item.id
      );
      if (backStrength) {
        // Check result
        if (
          val >= +backStrength.standard_from &&
          val <= +backStrength.standard_to
        ) {
          result = "standard";
        } else if (
          val >= +backStrength.warn_from &&
          val <= +backStrength.warn_to
        ) {
          result = "warn";
        } else if (
          val >= +backStrength.danger_from &&
          val <= +backStrength.danger_to
        ) {
          result = "danger";
        } else {
          result = "";
        }
      }
    }
  });

  return result;
};

export const singleLegBalance = (setting, val) => {
  let result = "";
  val = parseFloat(val);
  if (val >= +setting.ideal_from && val <= +setting.ideal_to) {
    result = "standard";
  } else if (val >= +setting.warn_from && val <= +setting.warn_to) {
    result = "warn";
  } else if (val >= +setting.danger_from && val <= +setting.danger_to) {
    result = "danger";
  } else {
    result = "";
  }
  return result;
};

export const standUpTest = (setting, val) => {
  let result = "";
  val = parseFloat(val);
  if (val == +setting.ideal) {
    result = "standard";
  } else if (val == +setting.warn) {
    result = "warn";
  } else if (val == +setting.danger) {
    result = "danger";
  } else {
    result = "";
  }
  return result;
};

export const functionalTest = (setting, val) => {
  let result = "";
  val = parseFloat(val);

  if (val >= +setting.ideal_from && val <= +setting.ideal_to) {
    result = "standard";
  } else if (val >= +setting.warn_from && val <= +setting.warn_to) {
    result = "warn";
  } else if (val >= +setting.danger_from && val <= +setting.danger_to) {
    result = "danger";
  } else {
    result = "";
  }
  return result;
};

export const bodyWaterContent = (setting, age, gender, val) => {
  let result = "";
  val = parseFloat(val);

  // Find setting by Gender
  let bodyWaterContents = setting.filter((item) => item.gender === gender);
  if (bodyWaterContents == null) return "";

  bodyWaterContents.forEach((item) => {
    // Check age
    if (age >= item.age_from && age <= item.age_to) {
      // Find Case by id
      let bwc = bodyWaterContents.find((bwc) => bwc.id === item.id);
      if (bwc) {
        // Check result
        if (val >= +bwc.ideal_from && val <= +bwc.ideal_to) {
          result = "standard";
        } else if (
          (val >= +bwc.warn_less_from && val <= +bwc.warn_less_to) ||
          (val >= +bwc.warn_than_from && val <= +bwc.warn_than_to)
        ) {
          result = "warn";
        } else if (
          (val >= +bwc.danger_less_from && val <= +bwc.danger_less_to) ||
          (val >= +bwc.danger_than_from && val <= +bwc.danger_than_to)
        ) {
          result = "danger";
        } else {
          result = "";
        }
      }
    }
  });
  return result;
};

export const flexibilityWaist = (setting, val) => {
  let result = "";
  val = parseFloat(val);

  if (val >= +setting.ideal_from && val <= +setting.ideal_to) {
    result = "standard";
  } else if (val >= +setting.warn_from && val <= +setting.warn_to) {
    result = "warn";
  } else if (val >= +setting.danger_from && val <= +setting.danger_to) {
    result = "danger";
  } else {
    result = "";
  }
  return result;
};

export const postureScore = (setting, val) => {
  let result = "";
  val = parseFloat(val);

  if (val >= +setting.ideal_from && val <= +setting.ideal_to) {
    result = "standard";
  } else if (val >= +setting.warn_from && val <= +setting.warn_to) {
    result = "warn";
  } else if (val >= +setting.danger_from && val <= +setting.danger_to) {
    result = "danger";
  } else {
    result = "";
  }
  return result;
};

export const postureType = (setting, val) => {
  let result = "";
  val = parseFloat(val);

  if (val == +setting.ideal) {
    result = "standard";
  } else if (val == +setting.warn) {
    result = "warn";
  } else if (val >= +setting.danger_from && val <= +setting.danger_to) {
    result = "danger";
  } else {
    result = "";
  }
  return result;
};

export const chairStandTest = (setting, age, gender, val) => {
  let result = "";
  val = parseFloat(val);

  // Find BodyFatRateSetting by Gender
  let mmps = setting.filter((item) => item.gender === gender);
  if (mmps == null) return "";

  mmps.forEach((item) => {
    // Check age
    if (age >= item.age_from && age <= item.age_to) {
      // Find Case BodyFatRateSetting by id
      let mmp = mmps.find((mmp) => mmp.id === item.id);
      if (mmp) {
        // Check result
        if (val >= +mmp.standard_from && val <= +mmp.standard_to) {
          result = "standard";
        } else if (val >= +mmp.warn_from && val <= +mmp.warn_to) {
          result = "warn";
        } else if (val >= +mmp.danger_from && val <= +mmp.danger_to) {
          result = "danger";
        } else {
          result = "";
        }
      }
    }
  });

  return result;
};

export const stepBoxTest = (setting, age, gender, val) => {
  let result = "";
  val = parseFloat(val);

  // Find BodyFatRateSetting by Gender
  let mmps = setting.filter((item) => item.gender === gender);
  if (mmps == null) return "";

  mmps.forEach((item) => {
    // Check age
    if (age >= item.age_from && age <= item.age_to) {
      // Find Case BodyFatRateSetting by id
      let mmp = mmps.find((mmp) => mmp.id === item.id);
      if (mmp) {
        // Check result
        if (val >= +mmp.standard_from && val <= +mmp.standard_to) {
          result = "standard";
        } else if (val >= +mmp.warn_from && val <= +mmp.warn_to) {
          result = "warn";
        } else if (val >= +mmp.danger_from && val <= +mmp.danger_to) {
          result = "danger";
        } else {
          result = "";
        }
      }
    }
  });

  return result;
};

export const getMeasurementMessage = (settings, key, grade, type) => {
  // console.log(key, settings);

  let result = {
    msg: "",
    checked: [],
  };

  if (settings) {
    switch (key) {
      case "ความดันโลหิตต่ำสุด":
        // check grade
        if (grade == "A") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.BloodPressure.min_risk_standard_text
              ,
              msgEN: "None",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            return {
              msg: settings.BloodPressure.min_improve_standard_text,
              msgEN: "Cardiovascular endurance training",
              checked: settings.BloodPressure.min_improve_standard_checked,
            };
          }
        } else if (grade == "B") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.BloodPressure.min_risk_warn_text,
              msgEN: "Heart disease, Ischemic Stroke",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.BloodPressure.min_improve_warn_text,
              msgEN: "Cardiovascular endurance training",
              checked: settings.BloodPressure.min_improve_warn_checked,
            };
          }
        } else if (grade == "C") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.BloodPressure.min_risk_danger_text,
              msgEN: "Heart disease, Ischemic Stroke",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.BloodPressure.min_improve_danger_text,
              msgEN: "Cardiovascular endurance training",
              checked: settings.BloodPressure.min_improve_danger_checked,
            };
          }
        }
        break;

      case "ความดันโลหิตสูงสุด":
        // check grade
        if (grade == "A") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.BloodPressure.max_risk_standard_text,
              msgEN: "None",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            return {
              msg: settings.BloodPressure.max_improve_standard_text,
              msgEN: "Cardiovascular endurance training",
              checked: settings.BloodPressure.max_improve_standard_checked,
            };
          }
        } else if (grade == "B") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.BloodPressure.max_risk_warn_text,
              msgEN: "Heart disease, Ischemic Stroke",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.BloodPressure.max_improve_warn_text,
              msgEN: "Cardiovascular endurance training",
              checked: settings.BloodPressure.max_improve_warn_checked,
            };
          }
        } else if (grade == "C") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.BloodPressure.max_risk_danger_text,
              msgEN: "Heart disease, Ischemic Stroke",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.BloodPressure.max_improve_danger_text,
              msgEN: "Cardiovascular endurance training",
              checked: settings.BloodPressure.max_improve_danger_checked,
            };
          }
        }
        break;

      case "ดัชนีมวลกาย":
        // check grade
        if (grade == "A") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.bmi.risk_standard_text,
              msgEN: "None",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            return {
              msg: settings.bmi.improve_standard_text,
              msgEN: "Increasing muscle mass to boost metabolism",
              checked: settings.bmi.improve_standard_checked,
            };
          }
        } else if (grade == "B") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.bmi.risk_warn_text,
              msgEN: "Diabetes, Emaciated",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.bmi.improve_warn_text,
              msgEN: "Increasing muscle mass to boost metabolism",
              checked: settings.bmi.improve_warn_checked,
            };
          }
        } else if (grade == "C") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.bmi.risk_danger_text,
              msgEN: "Diabetes, Emaciated",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.bmi.improve_danger_text,
              msgEN: "Increasing muscle mass to boost metabolism",
              checked: settings.bmi.improve_danger_checked,
            };
          }
        }
        break;

      case "อัตราการเผาผลาญ":
        // check grade
        if (grade == "A") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msgEN: "None",
              msg: settings.BasalMetabolicRateText.risk_standard_text,
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            return {
              msg: settings.BasalMetabolicRateText.improve_standard_text,
              checked: settings.BasalMetabolicRateText.improve_standard_checked,
              msgEN: "Strengthening muscles",
            };
          }
        } else if (grade == "B") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msgEN: "Immunodeficiency",
              msg: settings.BasalMetabolicRateText.risk_warn_text,
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.BasalMetabolicRateText.improve_warn_text,
              checked: settings.BasalMetabolicRateText.improve_warn_checked,
              msgEN: "Strengthening muscles",
            };
          }
        } else if (grade == "C") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msgEN: "Immunodeficiency",
              msg: settings.BasalMetabolicRateText.risk_danger_text,
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.BasalMetabolicRateText.improve_danger_text,
              checked: settings.BasalMetabolicRateText.improve_danger_checked,
              msgEN: "Strengthening muscles",
            };
          }
        }
        break;

      case "ระดับไขมันในร่างกาย":
        // check grade
        if (grade == "A") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msgEN: "None",
              msg: settings.BodyFatRateText.risk_standard_text,
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            return {
              msg: settings.BodyFatRateText.improve_standard_text,
              checked: settings.BodyFatRateText.improve_standard_checked,
              msgEN: "Building muscle, drink enough water",
            };
          }
        } else if (grade == "B") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.BodyFatRateText.risk_warn_text,
              msgEN: "Hyperlipidemia, Diabetes",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.BodyFatRateText.improve_warn_text,
              checked: settings.BodyFatRateText.improve_warn_checked,
              msgEN: "Building muscle, drink enough water",
            };
          }
        } else if (grade == "C") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.BodyFatRateText.risk_danger_text,
              msgEN: "Hyperlipidemia, Diabetes",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.BodyFatRateText.improve_danger_text,
              checked: settings.BodyFatRateText.improve_danger_checked,
              msgEN: "Building muscle, drink enough water",
            };
          }
        }
        break;

      case "ระดับไขมันในช่องท้อง":
        // check grade
        if (grade == "A") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msgEN: "None",
              msg: settings.VisceralFatLevel.risk_standard_text,
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            return {
              msg: settings.VisceralFatLevel.improve_standard_text,
              msgEN: "Increasing muscle mass to stimulate fat burning rate",
              checked: settings.VisceralFatLevel.improve_standard_checked,
            };
          }
        } else if (grade == "B") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.VisceralFatLevel.risk_warn_text,
              msgEN: "Heart disease, Diabetes, Fatty liver disease",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.VisceralFatLevel.improve_warn_text,
              msgEN: "Increasing muscle mass to stimulate fat burning rate",
              checked: settings.VisceralFatLevel.improve_warn_checked,
            };
          }
        } else if (grade == "C") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.VisceralFatLevel.risk_danger_text,
              msgEN: "Heart disease, Diabetes, Fatty liver disease",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.VisceralFatLevel.improve_danger_text,
              msgEN: "Increasing muscle mass to stimulate fat burning rate",
              checked: settings.VisceralFatLevel.improve_danger_checked,
            };
          }
        }
        break;

      case "แรงบีบมือข้างซ้าย":
        // check grade
        if (grade == "A") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msgEN: "None",
              msg: settings.GripStrengthText.risk_standard_text,
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            return {
              msg: settings.GripStrengthText.improve_standard_text,
              msgEN: "Wrist grip strength Training",
              checked: settings.GripStrengthText.improve_standard_checked,
            };
          }
        } else if (grade == "B") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.GripStrengthText.risk_warn_text,
              msgEN: "Heart disease, Ischemic Stroke",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.GripStrengthText.improve_warn_text,
              msgEN: "Wrist grip strength Training",
              checked: settings.GripStrengthText.improve_warn_checked,
            };
          }
        } else if (grade == "C") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.GripStrengthText.risk_danger_text,
              msgEN: "Heart disease, Ischemic Stroke",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.GripStrengthText.improve_danger_text,
              msgEN: "Wrist grip strength Training",
              checked: settings.GripStrengthText.improve_danger_checked,
            };
          }
        }
        break;

      case "แรงบีบมือข้างขวา":
        // check grade
        if (grade == "A") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msgEN: "None",
              msg: settings.GripStrengthText.risk_standard_text,
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            return {
              msg: settings.GripStrengthText.improve_standard_text,
              msgEN: "Wrist grip strength Training",
              checked: settings.GripStrengthText.improve_standard_checked,
            };
          }
        } else if (grade == "B") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.GripStrengthText.risk_warn_text,
              msgEN: "Heart disease, Ischemic Stroke",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.GripStrengthText.improve_warn_text,
              msgEN: "Wrist grip strength Training",
              checked: settings.GripStrengthText.improve_warn_checked,
            };
          }
        } else if (grade == "C") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.GripStrengthText.risk_danger_text,
              msgEN: "Heart disease, Ischemic Stroke",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.GripStrengthText.improve_danger_text,
              msgEN: "Wrist grip strength Training",
              checked: settings.GripStrengthText.improve_danger_checked,
            };
          }
        }
        break;

      case "มวลกล้ามเนื้อ":
        // check grade
        if (grade == "A") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msgEN: "None",
              msg: settings.MuscleMassPointsText.risk_standard_text,
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            return {
              msg: settings.MuscleMassPointsText.improve_standard_text,
              msgEN: "Increase muscle strength",
              checked: settings.MuscleMassPointsText.improve_standard_checked,
            };
          }
        } else if (grade == "B") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.MuscleMassPointsText.risk_warn_text,
              msgEN: "Unable to stand or sit up, Bedridden",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.MuscleMassPointsText.improve_warn_text,
              msgEN: "Increase muscle strength",
              checked: settings.MuscleMassPointsText.improve_warn_checked,
            };
          }
        } else if (grade == "C") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.MuscleMassPointsText.risk_danger_text,
              msgEN: "Unable to stand or sit up, Bedridden",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.MuscleMassPointsText.improve_danger_text,
              msgEN: "Increase muscle strength",
              checked: settings.MuscleMassPointsText.improve_danger_checked,
            };
          }
        }
        break;

      case "2 Step Test":
        // check grade
        if (grade == "A") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msgEN: "None",
              msg: settings.StabilityCriteriaSettingText.risk_standard_text,
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            return {
              msg: settings.StabilityCriteriaSettingText.improve_standard_text,
              msgEN: "Strength training flexibility of the hips",
              checked:
                settings.StabilityCriteriaSettingText.improve_standard_checked,
            };
          }
        } else if (grade == "B") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.StabilityCriteriaSettingText.risk_warn_text,
              msgEN: "Unable to walk, Hip and Knee Osteoarthritis",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.StabilityCriteriaSettingText.improve_warn_text,
              msgEN: "Strength training flexibility of the hips",
              checked:
                settings.StabilityCriteriaSettingText.improve_warn_checked,
            };
          }
        } else if (grade == "C") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.StabilityCriteriaSettingText.risk_danger_text,
              msgEN: "Unable to walk, Hip and Knee Osteoarthritis",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.StabilityCriteriaSettingText.improve_danger_text,
              msgEN: "Strength training flexibility of the hips",
              checked:
                settings.StabilityCriteriaSettingText.improve_danger_checked,
            };
          }
        }
        break;

      case "ความอ่อนตัวหัวไหล่":
        // check grade
        if (grade == "A") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msgEN: "None",
              msg: settings.FlexibilityUpperCriteriaSetting.risk_standard_text,
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            return {
              msg: settings.FlexibilityUpperCriteriaSetting
                .improve_standard_text,
              msgEN: "Flexibility and Shoulder strength training",
              checked:
                settings.FlexibilityUpperCriteriaSetting
                  .improve_standard_checked,
            };
          }
        } else if (grade == "B") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.FlexibilityUpperCriteriaSetting.risk_warn_text,
              msgEN: "Twisted bones, unable to stand upright",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.FlexibilityUpperCriteriaSetting.improve_warn_text,
              msgEN: "Flexibility and Shoulder strength training",
              checked:
                settings.FlexibilityUpperCriteriaSetting.improve_warn_checked,
            };
          }
        } else if (grade == "C") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.FlexibilityUpperCriteriaSetting.risk_danger_text,
              msgEN: "Twisted bones, unable to stand upright",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.FlexibilityUpperCriteriaSetting.improve_danger_text,
              msgEN: "Flexibility and Shoulder strength training",
              checked:
                settings.FlexibilityUpperCriteriaSetting.improve_danger_checked,
            };
          }
        }
        break;

      case "ความอ่อนตัวขาข้างซ้าย":
        // check grade
        if (grade == "A") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msgEN: "None",
              msg: settings.FlexibilityLowerCriteriaSettingText
                .risk_standard_text,
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            return {
              msg: settings.FlexibilityLowerCriteriaSettingText
                .improve_standard_text,
              msgEN: "Flexibility and Knee strength training",
              checked:
                settings.FlexibilityLowerCriteriaSettingText
                  .improve_standard_checked,
            };
          }
        } else if (grade == "B") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.FlexibilityLowerCriteriaSettingText.risk_warn_text,
              msgEN: "Unable to stand, sit up and and walk",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.FlexibilityLowerCriteriaSettingText
                .improve_warn_text,
              msgEN: "Flexibility and Knee strength training",
              checked:
                settings.FlexibilityLowerCriteriaSettingText
                  .improve_warn_checked,
            };
          }
        } else if (grade == "C") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.FlexibilityLowerCriteriaSettingText
                .risk_danger_text,
              msgEN: "Unable to stand, sit up and and walk",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.FlexibilityLowerCriteriaSettingText
                .improve_danger_text,
              msgEN: "Flexibility and Knee strength training",
              checked:
                settings.FlexibilityLowerCriteriaSettingText
                  .improve_danger_checked,
            };
          }
        }
        break;

      case "ความอ่อนตัวขาข้างขวา":
        // check grade
        if (grade == "A") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msgEN: "None",
              msg: settings.FlexibilityLowerCriteriaSettingText
                .risk_standard_text,
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            return {
              msg: settings.FlexibilityLowerCriteriaSettingText
                .improve_standard_text,
              msgEN: "Flexibility and Knee strength training",
              checked:
                settings.FlexibilityLowerCriteriaSettingText
                  .improve_standard_checked,
            };
          }
        } else if (grade == "B") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.FlexibilityLowerCriteriaSettingText.risk_warn_text,
              msgEN: "Unable to stand, sit up and and walk",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.FlexibilityLowerCriteriaSettingText
                .improve_warn_text,
              msgEN: "Flexibility and Knee strength training",
              checked:
                settings.FlexibilityLowerCriteriaSettingText
                  .improve_warn_checked,
            };
          }
        } else if (grade == "C") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.FlexibilityLowerCriteriaSettingText
                .risk_danger_text,
              msgEN: "Unable to stand, sit up and and walk", 
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.FlexibilityLowerCriteriaSettingText
                .improve_danger_text,
              msgEN: "Flexibility and Knee strength training",
              checked:
                settings.FlexibilityLowerCriteriaSettingText
                  .improve_danger_checked,
            };
          }
        }
        break;

      case "อายุของปอด (ความจุปอด)":
        // check grade
        if (grade == "A") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.lung_age.risk_standard_text,
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            return {
              msg: settings.lung_age.improve_standard_text,
              checked: settings.lung_age.improve_standard_checked,
            };
          }
        } else if (grade == "B") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.lung_age.risk_warn_text,
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.lung_age.improve_warn_text,
              checked: settings.lung_age.improve_warn_checked,
            };
          }
        } else if (grade == "C") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.lung_age.risk_danger_text,
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.lung_age.improve_danger_text,
              checked: settings.lung_age.improve_danger_checked,
            };
          }
        }
        break;

      case "อายุผิว":
        // check grade
        if (grade == "A") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.skin_age.risk_standard_text,
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            return {
              msg: settings.skin_age.improve_standard_text,
              checked: settings.skin_age.improve_standard_checked,
            };
          }
        } else if (grade == "B") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.skin_age.risk_warn_text,
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.skin_age.improve_warn_text,
              checked: settings.skin_age.improve_warn_checked,
            };
          }
        } else if (grade == "C") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.skin_age.risk_danger_text,
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.skin_age.improve_danger_text,
              checked: settings.skin_age.improve_danger_checked,
            };
          }
        }
        break;

      case "ความแข็งแรงกล้ามเนื้อหลัง":
        // check grade
        if (grade == "A") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msgEN: "None",
              msg: settings.back_strength_text.risk_standard_text,
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            return {
              msg: settings.back_strength_text.improve_standard_text,
              checked: settings.back_strength_text.improve_standard_checked,
            };
          }
        } else if (grade == "B") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.back_strength_text.risk_warn_text,
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.back_strength_text.improve_warn_text,
              checked: settings.back_strength_text.improve_warn_checked,
            };
          }
        } else if (grade == "C") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.back_strength_text.risk_danger_text,
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.back_strength_text.improve_danger_text,
              checked: settings.back_strength_text.improve_danger_checked,
            };
          }
        }
        break;

      case "การทรงตัวยืนขาเดียว":
        // check grade
        if (grade == "A") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msgEN: "None",
              msg: settings.single_leg_balance.risk_standard_text,
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            return {
              msg: settings.single_leg_balance.improve_standard_text,
              msgEN: "Core strength, leg and ankle training",
              checked: settings.single_leg_balance.improve_standard_checked,
            };
          }
        } else if (grade == "B") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.single_leg_balance.risk_warn_text,
              msgEN: "Unable to walk, Knee and Ankle Osteoarthritis",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.single_leg_balance.improve_warn_text,
              msgEN: "Core strength, leg and ankle training",
              checked: settings.single_leg_balance.improve_warn_checked,
            };
          }
        } else if (grade == "C") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.single_leg_balance.risk_danger_text,
              msgEN: "Unable to walk, Knee and Ankle Osteoarthritis",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.single_leg_balance.improve_danger_text,
              msgEN: "Core strength, leg and ankle training",
              checked: settings.single_leg_balance.improve_danger_checked,
            };
          }
        }
        break;

      case "Stand Up Test":
        // check grade
        if (grade == "A") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msgEN: "None",
              msg: settings.stand_up_test.risk_standard_text,
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            return {
              msg: settings.stand_up_test.improve_standard_text,
              msgEN: "Lower body muscle strength training",
              checked: settings.stand_up_test.improve_standard_checked,
            };
          }
        } else if (grade == "B") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.stand_up_test.risk_warn_text,
              msgEN: "Unable to stand up and walk, permanently in a wheelchair",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.stand_up_test.improve_warn_text,
              msgEN: "Lower body muscle strength training",
              checked: settings.stand_up_test.improve_warn_checked,
            };
          }
        } else if (grade == "C") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.stand_up_test.risk_danger_text,
              msgEN: "Unable to stand up and walk, permanently in a wheelchair",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.stand_up_test.improve_danger_text,
              msgEN: "Lower body muscle strength training",
              checked: settings.stand_up_test.improve_danger_checked,
            };
          }
        }
        break;

      case "Functional Test":
        // check grade
        if (grade == "A") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msgEN: "None",
              msg: settings.functional_test.risk_standard_text,
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            return {
              msg: settings.functional_test.improve_standard_text,
              msgEN: "Core muscle training",
              checked: settings.functional_test.improve_standard_checked,
            };
          }
        } else if (grade == "B") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.functional_test.risk_warn_text,
              msgEN: "Herniated disc",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.functional_test.improve_warn_text,
              msgEN: "Herniated disc",
              checked: settings.functional_test.improve_warn_checked,
            };
          }
        } else if (grade == "C") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.functional_test.risk_danger_text,
              msgEN: "Herniated disc",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.functional_test.improve_danger_text,
              msgEN: "Herniated disc",
              checked: settings.functional_test.improve_danger_checked,
            };
          }
        }
        break;

      case "Functiona":
        // check grade
        if (grade == "A") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.functional_test.risk_standard_text,
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            return {
              msg: settings.functional_test.improve_standard_text,
              checked: settings.functional_test.improve_standard_checked,
            };
          }
        } else if (grade == "B") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.functional_test.risk_warn_text,
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.functional_test.improve_warn_text,
              checked: settings.functional_test.improve_warn_checked,
            };
          }
        } else if (grade == "C") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.functional_test.risk_danger_text,
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.functional_test.improve_danger_text,
              checked: settings.functional_test.improve_danger_checked,
            };
          }
        }
        break;

      case "ระดับน้ำในร่างกาย":
        // check grade
        if (grade == "A") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msgEN: "None",
              msg: settings.body_water_content_text.risk_standard_text,
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            return {
              msg: settings.body_water_content_text.improve_standard_text,
              msgEN: "Building muscle, drink enough water",
              checked:
                settings.body_water_content_text.improve_standard_checked,
            };
          }
        } else if (grade == "B") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.body_water_content_text.risk_warn_text,
              msgEN: "Experiencing a Seizure, Stroke",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.body_water_content_text.improve_warn_text,
              msgEN: "Building muscle, drink enough water",
              checked: settings.body_water_content_text.improve_warn_checked,
            };
          }
        } else if (grade == "C") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.body_water_content_text.risk_danger_text,
              msgEN: "Experiencing a Seizure, Stroke",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.body_water_content_text.improve_danger_text,
              msgEN: "Building muscle, drink enough water",
              checked: settings.body_water_content_text.improve_danger_checked,
            };
          }
        }
        break;

      case "ความอ่อนตัวของหลัง":
        // check grade
        if (grade == "A") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msgEN: "None",
              msg: settings.flexibility_waist.risk_standard_text,
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            return {
              msg: settings.flexibility_waist.improve_standard_text,
              msgEN: "Flexibility and  Lower back strength  training",
              checked: settings.flexibility_waist.improve_standard_checked,
            };
          }
        } else if (grade == "B") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.flexibility_waist.risk_warn_text,
              msgEN: "Spinal deformity",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.flexibility_waist.improve_warn_text,
              msgEN: "Flexibility and  Lower back strength  training",
              checked: settings.flexibility_waist.improve_warn_checked,
            };
          }
        } else if (grade == "C") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.flexibility_waist.risk_danger_text,
              msgEN: "Spinal deformity",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.flexibility_waist.improve_danger_text,
              msgEN: "Flexibility and  Lower back strength  training",
              checked: settings.flexibility_waist.improve_danger_checked,
            };
          }
        }
        break;

      case "คะแนนท่าทาง":
        // check grade
        if (grade == "A") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msgEN: "None",
              msg: settings.posture_score.risk_standard_text,
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            return {
              msg: settings.posture_score.improve_standard_text,
              msgEN: "Create muscle balance",
              checked: settings.posture_score.improve_standard_checked,
            };
          }
        } else if (grade == "B") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.posture_score.risk_warn_text,
              msgEN: "Bone and Joint abnormalities",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.posture_score.improve_warn_text,
              msgEN: "Create muscle balance",
              checked: settings.posture_score.improve_warn_checked,
            };
          }
        } else if (grade == "C") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.posture_score.risk_danger_text,
              msgEN: "Bone and Joint abnormalities",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.posture_score.improve_danger_text,
              msgEN: "Create muscle balance",
              checked: settings.posture_score.improve_danger_checked,
            };
          }
        }
        break;

      case "ปัญหาท่าทาง":
        // check grade
        if (grade == "A") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msgEN: "None",
              msg: settings.posture_type.risk_standard_text,
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            return {
              msg: settings.posture_type.improve_standard_text,
              msgEN: "Create muscle balance",
              checked: settings.posture_type.improve_standard_checked,
            };
          }
        } else if (grade == "B") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.posture_type.risk_warn_text,
              msgEN: "Bone and Joint abnormalities",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.posture_type.improve_warn_text,
              msgEN: "Create muscle balance",
              checked: settings.posture_type.improve_warn_checked,
            };
          }
        } else if (grade == "C") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.posture_type.risk_danger_text,
              msgEN: "Bone and Joint abnormalities",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.posture_type.improve_danger_text,
              msgEN: "Create muscle balance",
              checked: settings.posture_type.improve_danger_checked,
            };
          }
        }
        break;
      case "ทดสอบลุกจากเก้าอี้":
        // check grade
        if (grade == "A") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msgEN: "None",
              msg: settings.cardio_vascular_endurance_settings_text
                .risk_standard_text,
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            return {
              msg: settings.cardio_vascular_endurance_settings_text
                .improve_standard_text,
              msgEN: "Lower body muscle strength training",
              checked:
                settings.cardio_vascular_endurance_settings_text
                  .improve_standard_checked,
            };
          }
        } else if (grade == "B") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.cardio_vascular_endurance_settings_text
                .risk_warn_text,
              msgEN: "Unable to stand up and walk, Permanently in a wheelchair",  
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.cardio_vascular_endurance_settings_text
                .improve_warn_text,
              msgEN: "Lower body muscle strength training",
              checked:
                settings.cardio_vascular_endurance_settings_text
                  .improve_warn_checked,
            };
          }
        } else if (grade == "C") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.cardio_vascular_endurance_settings_text
                .risk_danger_text,
              msgEN: "Unable to stand up and walk, Permanently in a wheelchair",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.cardio_vascular_endurance_settings_text
                .improve_danger_text,
              msgEN: "Lower body muscle strength training",
              checked:
                settings.cardio_vascular_endurance_settings_text
                  .improve_danger_checked,
            };
          }
        }
        break;
      case "ทดสอบก้าวขึ้น-ลง":
        // check grade
        if (grade == "A") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msgEN: "None",
              msg: settings.step_box_test_setting_text.risk_standard_text,
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            return {
              msg: settings.step_box_test_setting_text.improve_standard_text,
              msgEN: "Lower body muscle strength training",
              checked:
                settings.step_box_test_setting_text.improve_standard_checked,
            };
          }
        } else if (grade == "B") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.step_box_test_setting_text.risk_warn_text,
              msgEN: "Unable to stand up and walk, Permanently in a wheelchair",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.step_box_test_setting_text.improve_warn_text,
              msgEN: "Lower body muscle strength training",
              checked: settings.step_box_test_setting_text.improve_warn_checked,
            };
          }
        } else if (grade == "C") {
          // ถ้าเป็น ผลการวัดวันนี้
          if (type == "today") {
            result = {
              msg: settings.step_box_test_setting_text.risk_danger_text,
              msgEN: "Unable to stand up and walk, Permanently in a wheelchair",
              checked: [],
            };
          }
          // ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
          else {
            result = {
              msg: settings.cardio_vascular_endurance_settings_text
                .improve_danger_text,
              msgEN: "Lower body muscle strength training",
              checked:
                settings.cardio_vascular_endurance_settings_text
                  .improve_danger_checked,
            };
          }
        }
        break;
    }
  }

  // console.log("result", result);
  return result;
};
