<template>
  <v-row no-gutters class="text_input_form">
    <v-col :cols="cols1">
      <div class="txt_th">{{ labelTh }}</div>
      <div class="txt_en">{{ labelEn }}</div>
    </v-col>
    <v-col :cols="cols2">
      <v-text-field
        class="pa-0"
        :value="model"
        dense
        solo
        color="#C71010"
        :placeholder="placeholder"
        :rules="required ? [(v) => !!v || errorMessage + ' is required'] : []"
        required
        :type="type"
        @input="$emit('update-data', $event)"
        :oninput="returnInputRule"
        :hide-details="hideDetails"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    errorMessage: String,
    model: null,
    labelTh: String,
    labelEn: String,
    placeholder: String,
    inputType: {
      typr: String,
      default: "text",
    },
    type: {
      typr: String,
      default: "number",
    },
    color: {
      typr: String,
      default: "#a3a3a3",
    },
    hideDetails: {
      typr: Boolean,
      default: false,
    },
    cols1: {
      typr: Number,
      default: 4,
    },
    cols2: {
      typr: Number,
      default: 8,
    },
  },
  computed: {
    returnInputRule: function () {
      switch (this.inputType) {
        case "number":
          return "value=value.replace(/[^\\d]/g,'')";

        // case "decimal":
        // return "value=value.replace(/[^\\d.]/g,'')";

        default:
          return null;
      }
    },
  },
};
</script>

<style lang="scss">
.text_input_form {
  .txt_th {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    color: #000000;
  }
  .txt_en {
    margin-top: -8px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    color: #000000;
  }
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
    > .v-input__control
    > .v-input__slot {
    box-shadow: unset !important;
    border: 1px solid !important;
  }
  .v-text-field fieldset,
  .v-text-field .v-input__control,
  .v-text-field .v-input__slot {
    border-radius: 10px;
  }
  .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
    font-weight: 500;
    font-size: 18px;
    min-height: 50px;
    border-radius: 10px;
  }
  input[type="number"] {
    -moz-appearance: textfield !important;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
  }
}
</style>
