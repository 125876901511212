<template>
  <div class="mb-4">
    <div id="printScreen" ref="printMe" class="">
      <v-layout align-center justify-space-between>
        <v-flex>
          <v-layout>
            <v-flex shrink v-if="isShow">
              <v-btn icon class="mr-6" to="/">
                <v-icon large color="#2E2528">mdi-arrow-left</v-icon>
              </v-btn>
            </v-flex>
            <v-flex :class="!isShow ? 'pl-6' : ''">
              <group-title
                onPage="compare"
                txtEn="Compare"
                txtTh="เปรียบเทียบ"
              />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-divider />

      <!-- Name -->
      <v-layout align-center justify-space-between mt-3 v-if="customer">
        <v-flex style="margin-left: -6.5rem">
          <v-card class="card__name" block dark color="#000">
            <div class="text-center">
              {{ customer.first_name }} {{ customer.family_name }}
            </div>
          </v-card>
        </v-flex>
        <v-flex xs8>
          <div class="d-flex justify-end" v-if="isShow">
            <v-btn
              height="44"
              style="
                border-radius: 29px;
                box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
              "
              dark
              color="#c71010"
              class="text-capitalize"
              @click="screenshot"
            >
              <span class="font-20 font-w-600 white--text">Save Image</span>
            </v-btn>
          </div>
        </v-flex>
      </v-layout>

      <v-row class="pb-4 mt-6" no-gutters>
        <v-col cols="6" v-if="customer">
          <box-card height="55" class="py-0 d-flex align-center mb-6">
            <v-layout
              style="padding: 6px 0px"
              align-center
              justify-space-between
            >
              <v-flex shrink>
                <span class="txt__title_th">อายุ</span>
                <span class="txt__title_en mx-2">Age</span>
                <span class="txt_val">{{ customer.age }}</span>
              </v-flex>
              <v-flex shrink>
                <span class="txt__title_th">เพศ</span>
                <span class="txt__title_en mx-2">gender</span>
                <span class="txt_val">
                  {{ customer.gender === "M" ? "ชาย (Male)" : "หญิง (Female)" }}
                </span>
              </v-flex>
              <v-flex shrink>
                <span class="txt__title_th">ส่วนสูง</span>
                <span class="txt__title_en mx-2">Height</span>
                <span class="txt_val">{{ customer.height }}</span>
              </v-flex>
              <v-flex shrink>
                <span class="txt__title_th">น้ำหนัก</span>
                <span class="txt__title_en mx-2">Weight</span>
                <span class="txt_val">{{ customer.weight }}</span>
              </v-flex>
            </v-layout>
          </box-card>

          <box-card class="pa-3" color="#fff" v-if="customer_before">
            <v-layout class="pb-">
              <v-flex xs8 class="pl-3">
                <div class="flex__box d-flex flex-column justify-center">
                  <div class="font-25 font-w-600" style="color: #c71010">
                    Compare Detail
                  </div>
                  <div class="font-14 mt-n1">Date</div>
                </div>
              </v-flex>
              <v-flex xs4>
                <v-row>
                  <v-col class="line-left" cols="6">
                    <div
                      v-if="customer_before"
                      class="d-flex flex-column justify-center align-center"
                    >
                      <div class="font-25 font-w-600" style="color: #c71010">
                        Before
                      </div>
                      <div class="font-14 mt-n1">
                        {{ customer_before.date | moment("D-MMM-YYYY") }}
                      </div>
                      <v-hover v-slot="{ hover }" open-delay="200">
                        <v-img
                          class="mt-3"
                          cover
                          position="top"
                          style="border: 1px solid #000; border-radius: 10px"
                          height="62"
                          width="62"
                          :src="
                            customer_before.image_path
                              ? url_path + customer_before.image_path
                              : require('../assets/images/Default_Picture.png')
                          "
                          @click="
                            onPreviewImage(
                              url_path + customer_before.image_path
                            )
                          "
                        >
                          <v-expand-transition>
                            <div
                              v-if="hover"
                              class="d-flex justify-center align-center transition-fast-in-fast-out darken-2 v-card--reveal text-h2 white--text"
                              style="
                                height: 100%;
                                background-color: rgba(0, 0, 0, 0.5);
                              "
                            >
                              <v-btn icon>
                                <v-icon
                                  v-if="hover"
                                  class="display-1"
                                  color="white"
                                >
                                  mdi-arrow-expand
                                </v-icon>
                              </v-btn>
                            </div>
                          </v-expand-transition>
                        </v-img>
                      </v-hover>
                    </div>
                  </v-col>
                  <v-col class="line-left" cols="6">
                    <div
                      v-if="customer_after"
                      class="d-flex flex-column justify-center align-center"
                    >
                      <div class="font-25 font-w-600" style="color: #c71010">
                        After
                      </div>
                      <div class="font-14 mt-n1" v-if="customer_after">
                        {{ customer_after.date | moment("D-MMM-YYYY") }}
                      </div>
                      <v-hover v-slot="{ hover }" open-delay="200">
                        <v-img
                          class="mt-3 mb-5"
                          cover
                          position="top"
                          style="border: 1px solid #000; border-radius: 10px"
                          height="62"
                          width="62"
                          :src="
                            customer_after.image_path
                              ? url_path + customer_after.image_path
                              : require('../assets/images/Default_Picture.png')
                          "
                          @click="
                            onPreviewImage(url_path + customer_after.image_path)
                          "
                        >
                          <v-expand-transition>
                            <div
                              v-if="hover"
                              class="d-flex justify-center align-center transition-fast-in-fast-out darken-2 v-card--reveal text-h2 white--text"
                              style="
                                height: 100%;
                                background-color: rgba(0, 0, 0, 0.5);
                              "
                            >
                              <v-btn icon>
                                <v-icon
                                  v-if="hover"
                                  class="display-1"
                                  color="white"
                                >
                                  mdi-arrow-expand
                                </v-icon>
                              </v-btn>
                            </div>
                          </v-expand-transition>
                        </v-img>
                      </v-hover>
                    </div>
                  </v-col>
                </v-row>

                <v-dialog v-model="dialog" width="500">
                  <v-card flat>
                    <v-img
                      contain
                      position="center"
                      :src="previewImage"
                    ></v-img>
                  </v-card>
                </v-dialog>
              </v-flex>
            </v-layout>

            <v-divider />

            <!-- Rank -->
            <v-layout align-center class="font-14">
              <v-flex xs8>
                <v-layout>
                  <v-flex xs6 class="pl-3">Rank</v-flex>
                  <v-flex xs6>จัดอันดับ</v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-row>
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-25 font-weight-bold">
                      {{ customer_before ? customer_before.rank : "" }}
                    </span>
                  </v-col>
                  <v-col class="line-left text-center text-center" cols="6">
                    <span class="font-25 font-weight-bold">
                      {{ customer_after ? customer_after.rank : "" }}
                    </span>
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout>

            <v-divider />

            <!-- Weight -->
            <v-layout style="padding: 6px 0px" align-center class="font-14">
              <v-flex xs8>
                <v-layout>
                  <v-flex xs6 class="pl-3">Weight</v-flex>
                  <v-flex xs6>น้ำหนัก</v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-row align="center">
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_before.weight }}
                    </span>
                  </v-col>
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_after.weight }}
                    </span>
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout>

            <v-divider />

            <!-- Blood pressure -->
            <v-layout style="padding: 6px 0px" align-center class="font-14">
              <v-flex xs8>
                <v-layout>
                  <v-flex xs6 class="pl-3">Blood pressure</v-flex>
                  <v-flex xs6>ความดันโลหิต</v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-row align="center">
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_before.blood_pressure_min }}/
                      {{ customer_before.blood_pressure_max }}
                    </span>
                  </v-col>
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_after.blood_pressure_min }}/
                      {{ customer_after.blood_pressure_max }}
                    </span>
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout>

            <v-divider />

            <!-- BMI -->
            <v-layout style="padding: 6px 0px" align-center class="font-14">
              <v-flex xs8>
                <v-layout>
                  <v-flex xs6 class="pl-3">BMI</v-flex>
                  <v-flex xs6>ค่าดัชนีมวลกาย</v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-row align="center">
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_before.bmi }}
                    </span>
                  </v-col>
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_after.bmi }}
                    </span>
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout>

            <v-divider />

            <!-- Basal metabolic rate -->
            <v-layout style="padding: 6px 0px" align-center class="font-14">
              <v-flex xs8>
                <v-layout>
                  <v-flex xs6 class="pl-3">Basal metabolic rate</v-flex>
                  <v-flex xs6>อัตราการเผาผลาญพื้นฐาน</v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-row align="center">
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_before.basal_metabolic_rate }}
                    </span>
                  </v-col>
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_after.basal_metabolic_rate }}
                    </span>
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout>

            <v-divider />

            <!-- Body Fat rate -->
            <v-layout style="padding: 6px 0px" align-center class="font-14">
              <v-flex xs8>
                <v-layout>
                  <v-flex xs6 class="pl-3">Body Fat rate</v-flex>
                  <v-flex xs6>อัตราไขมันในร่างกาย</v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-row align="center">
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_before.body_fat_rate }}
                    </span>
                  </v-col>
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_after.body_fat_rate }}
                    </span>
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout>

            <v-divider />

            <!-- Visceral fat level -->
            <v-layout style="padding: 6px 0px" align-center class="font-14">
              <v-flex xs8>
                <v-layout>
                  <v-flex xs6 class="pl-3">Visceral fat level</v-flex>
                  <v-flex xs6>ระดับไขมันในช่องท้อง</v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-row align="center">
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_before.visceral_fat_level }}
                    </span>
                  </v-col>
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_after.visceral_fat_level }}
                    </span>
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout>

            <v-divider />

            <!-- Grip strength(Left/Right) -->
            <v-layout style="padding: 6px 0px" align-center class="font-14">
              <v-flex xs8>
                <v-layout>
                  <v-flex xs6 class="pl-3">Grip strength(Left/Right)</v-flex>
                  <v-flex xs6>แรงยึดเกาะ (ซ้าย/ขวา)</v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-row align="center">
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_before.grip_strength_left }}/{{
                        customer_before.grip_strength_right
                      }}
                    </span>
                  </v-col>
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_after.grip_strength_left }}/{{
                        customer_after.grip_strength_right
                      }}
                    </span>
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout>

            <v-divider />

            <!-- Muscle mass points -->
            <v-layout style="padding: 6px 0px" align-center class="font-14">
              <v-flex xs8>
                <v-layout>
                  <v-flex xs6 class="pl-3">Muscle mass points</v-flex>
                  <v-flex xs6>จุดมวลกล้ามเนื้อ</v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-row align="center">
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_before.muscle_mass_points }}
                    </span>
                  </v-col>
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_after.muscle_mass_points }}
                    </span>
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout>

            <v-divider />

            <!-- Muscle mass -->
            <v-layout style="padding: 6px 0px" align-center class="font-14">
              <v-flex xs8>
                <v-layout>
                  <v-flex xs6 class="pl-3">Muscle mass</v-flex>
                  <v-flex xs6>มวลกล้ามเนื้อ</v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-row align="center">
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_before.muscle_mass }}
                    </span>
                  </v-col>
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_after.muscle_mass }}
                    </span>
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout>

            <v-divider />

            <!-- Vascular age -->
            <v-layout style="padding: 6px 0px" align-center class="font-14">
              <v-flex xs8>
                <v-layout>
                  <v-flex xs6 class="pl-3">Vascular age</v-flex>
                  <v-flex xs6>อายุของหลอดเลือด</v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-row align="center">
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_before.vascular_age }}
                    </span>
                  </v-col>
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_after.vascular_age }}
                    </span>
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout>

            <v-divider />

            <!-- Body age -->
            <v-layout style="padding: 6px 0px" align-center class="font-14">
              <v-flex xs8>
                <v-layout>
                  <v-flex xs6 class="pl-3">Body age</v-flex>
                  <v-flex xs6>อายุของร่างกาย</v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-row align="center">
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_before.body_age }}
                    </span>
                  </v-col>
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_after.body_age }}
                    </span>
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout>

            <v-divider />

            <!-- Stability -->
            <v-layout style="padding: 6px 0px" align-center class="font-14">
              <v-flex xs8>
                <v-layout>
                  <v-flex xs6 class="pl-3">2 Step Test</v-flex>
                  <v-flex xs6>2 Step Test</v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-row align="center">
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_before.stability }}
                    </span>
                  </v-col>
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_after.stability }}
                    </span>
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout>

            <v-divider />

            <!-- Flexibility-Lower -->
            <v-layout style="padding: 6px 0px" align-center class="font-14">
              <v-flex xs8>
                <v-layout>
                  <v-flex xs6 class="pl-3">Flexibility-Lower(Left/Right)</v-flex>
                  <v-flex xs6>ความอ่อนตัวขา(ซ้าย/ขวา)</v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-row align="center">
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_before.flexibility_lower_left }}/{{
                        customer_before.flexibility_lower_right
                      }}
                    </span>
                  </v-col>
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_after.flexibility_lower_left }}/{{
                        customer_after.flexibility_lower_right
                      }}
                    </span>
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout>

            <v-divider />

            <!-- Flexibility-Upper -->
            <v-layout style="padding: 6px 0px" align-center class="font-14">
              <v-flex xs8>
                <v-layout>
                  <v-flex xs6 class="pl-3">Flexibility-Upper</v-flex>
                  <v-flex xs6>ความยืดหยุ่น-Upper</v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-row align="center">
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_before.flexibility_upper }}
                    </span>
                  </v-col>
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_after.flexibility_upper }}
                    </span>
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout>

            <v-divider />

            <!-- Lung Age(Breathing Capacity) -->
            <!-- <v-layout style="padding: 6px 0px" align-center class="font-14">
              <v-flex xs8>
                <v-layout>
                  <v-flex xs6 class="pl-3">Lung Age(Breathing Capacity)</v-flex>
                  <v-flex xs6>อายุของปอด (ความจุปอด)</v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-row align="center">
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_before.lung_age }}
                    </span>
                  </v-col>
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_after.lung_age }}
                    </span>
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout> -->

            <!-- <v-divider /> -->

            <!-- Skin Age -->
            <!-- <v-layout style="padding: 6px 0px" align-center class="font-14">
              <v-flex xs8>
                <v-layout>
                  <v-flex xs6 class="pl-3">Skin Age</v-flex>
                  <v-flex xs6>อายุผิว</v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-row align="center">
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_before.skin_age }}
                    </span>
                  </v-col>
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_after.skin_age }}
                    </span>
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout> -->

            <!-- <v-divider /> -->

            <!-- Back Strength -->
            <!-- <v-layout style="padding: 6px 0px" align-center class="font-14">
              <v-flex xs8>
                <v-layout>
                  <v-flex xs6 class="pl-3">Back Strength</v-flex>
                  <v-flex xs6>ความแข็งแรงกล้ามเนื้อหลัง</v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-row align="center">
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_before.back_strength }}
                    </span>
                  </v-col>
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_after.back_strength }}
                    </span>
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout> -->

            <!-- <v-divider /> -->

            <!-- Single Leg Balance -->
            <v-layout style="padding: 6px 0px" align-center class="font-14">
              <v-flex xs8>
                <v-layout>
                  <v-flex xs6 class="pl-3">Single Leg Balance</v-flex>
                  <v-flex xs6>การทรงตัวยืนขาเดียว</v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-row align="center">
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_before.single_leg_balance }}
                    </span>
                  </v-col>
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_after.single_leg_balance }}
                    </span>
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout>

            <v-divider />

            <!-- Stand Up Test -->
            <v-layout style="padding: 6px 0px" align-center class="font-14">
              <v-flex xs8>
                <v-layout>
                  <v-flex xs6 class="pl-3">Stand Up Test</v-flex>
                  <v-flex xs6>Stand Up Test</v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-row align="center">
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_before.stand_up_test }}
                    </span>
                  </v-col>
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_after.stand_up_test }}
                    </span>
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout>

            <v-divider />

            <!-- Functional Test -->
            <v-layout style="padding: 6px 0px" align-center class="font-14">
              <v-flex xs8>
                <v-layout>
                  <v-flex xs6 class="pl-3">Functional Test</v-flex>
                  <v-flex xs6>Functional Test</v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-row align="center">
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_before.functional_test }}
                    </span>
                  </v-col>
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_after.functional_test }}
                    </span>
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout>

            <v-divider />

            <!-- Body water content -->
            <v-layout style="padding: 6px 0px" align-center class="font-14">
              <v-flex xs8>
                <v-layout>
                  <v-flex xs6 class="pl-3">Body water content</v-flex>
                  <v-flex xs6>ระดับน้ำในร่างกาย</v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-row align="center">
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_before.body_water_content }}
                    </span>
                  </v-col>
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_after.body_water_content }}
                    </span>
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout>

            <v-divider />

            <!-- Flexibility of Waist -->
            <v-layout style="padding: 6px 0px" align-center class="font-14">
              <v-flex xs8>
                <v-layout>
                  <v-flex xs6 class="pl-3">Flexibility of Back</v-flex>
                  <v-flex xs6>ความอ่อนตัวของหลัง</v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-row align="center">
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_before.flexibility_waist }}
                    </span>
                  </v-col>
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_after.flexibility_waist }}
                    </span>
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout>

            <v-divider />

            <!-- Posture Score -->
            <v-layout style="padding: 6px 0px" align-center class="font-14">
              <v-flex xs8>
                <v-layout>
                  <v-flex xs6 class="pl-3">Posture Score</v-flex>
                  <v-flex xs6>คะแนนท่าทาง</v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-row align="center">
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_before.posture_score }}
                    </span>
                  </v-col>
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_after.posture_score }}
                    </span>
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout>

            <v-divider />

             <!-- Posture Problem -->
             <v-layout style="padding: 6px 0px" align-center class="font-14">
              <v-flex xs8>
                <v-layout>
                  <v-flex xs6 class="pl-3">Posture Problem</v-flex>
                  <v-flex xs6>ปัญหาท่าทาง</v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-row align="center">
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_before.posture_type }}
                    </span>
                  </v-col>
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_after.posture_type }}
                    </span>
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout>

            <v-divider />

            <!-- Chair Stand Test -->
            <v-layout style="padding: 6px 0px" align-center class="font-14">
              <v-flex xs8>
                <v-layout>
                  <v-flex xs6 class="pl-3">Chair Stand Test</v-flex>
                  <v-flex xs6>ทดสอบลุกจากเก้าอี้</v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-row align="center">
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_before.chair_stand_test }}
                    </span>
                  </v-col>
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_after.chair_stand_test }}
                    </span>
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout>

            <v-divider />

            <!-- Step Box Test -->
            <v-layout style="padding: 6px 0px" align-center class="font-14">
              <v-flex xs8>
                <v-layout>
                  <v-flex xs6 class="pl-3">Step Box Test</v-flex>
                  <v-flex xs6>ทดสอบก้าวขึ้น-ลง</v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-row align="center">
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_before.step_box_test }}
                    </span>
                  </v-col>
                  <v-col class="line-left text-center" cols="6">
                    <span class="font-16">
                      {{ customer_after.step_box_test }}
                    </span>
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout>
          </box-card>
        </v-col>

        <v-col cols="6">
          <v-row>
            <v-col cols="12" v-if="series.length > 0">
              <box-card class="pa-3" height="575">
                <v-layout align-start class="px-3 mb-n2">
                  <v-flex>
                    <div class="font-20 font-weight-bold">
                      การเปลี่ยนแปลงของอายุร่างกาย
                    </div>
                    <div>Body Age Transition</div>
                  </v-flex>
                  <v-flex shrink class="mr-n2">
                    <v-btn fab icon small @click="previewGraph('line')">
                      <v-img
                        max-width="24"
                        max-height="24"
                        aspect-ratio="1"
                        :src="require('../assets/images/maximize-4.png')"
                      ></v-img>
                    </v-btn>
                  </v-flex>
                </v-layout>
                <apexchart
                  type="area"
                  width="100%"
                  height="80%"
                  :options="chartOptions"
                  :series="series"
                >
                </apexchart>

                <v-layout justify-center align-center>
                  <v-flex shrink class="d-flex mx-2 align-center">
                    <div class="legend-1"></div>
                    <span class="font-12 ml-1">Actual Age</span>
                  </v-flex>
                  <v-flex shrink class="d-flex mx-2 align-center">
                    <div class="legend-2"></div>
                    <span class="font-12 ml-1">Vascular Age</span>
                  </v-flex>
                  <v-flex shrink class="d-flex mx-2 align-center">
                    <div class="legend-3"></div>
                    <span class="font-12 ml-1">Body Age</span>
                  </v-flex>
                </v-layout>
              </box-card>
            </v-col>

            <v-col cols="12" v-if="seriesRadar.length > 0">
              <box-card class="pa-3" height="575">
                <v-layout align-start class="px-3 mb-n6">
                  <v-flex>
                    <div class="font-20 font-weight-bold">
                      ความสมดุลความคล่องตัว
                    </div>
                    <div>Mobility Balance</div>
                  </v-flex>
                  <v-flex shrink class="mr-n2">
                    <v-btn fab icon small @click="previewGraph('radar')">
                      <v-img
                        max-width="24"
                        max-height="24"
                        aspect-ratio="1"
                        :src="require('../assets/images/maximize-4.png')"
                      ></v-img>
                    </v-btn>
                  </v-flex>
                </v-layout>

                <apexchart
                  width="100%"
                  height="89%"
                  :options="optionsRadar"
                  :series="seriesRadar"
                ></apexchart>

                <v-layout justify-center align-center mt-n7>
                  <v-flex shrink class="d-flex mx-2 align-center">
                    <div class="radar-legend-1"></div>
                    <span class="font-12 ml-1">Before</span>
                  </v-flex>
                  <v-flex shrink class="d-flex mx-2 align-center">
                    <div class="radar-legend-2"></div>
                    <span class="font-12 ml-1">After</span>
                  </v-flex>
                </v-layout>

                <v-row justify="center">
                  <v-dialog
                    class="graph-dialog"
                    v-model="graph_dialog"
                    width="1000"
                  >
                    <v-card flat width="1000" height="700">
                      <apexchart
                        width="100%"
                        height="95%"
                        :options="optionsRadar"
                        :series="seriesRadar"
                      ></apexchart>

                      <v-layout justify-center align-center mt-n2>
                        <v-flex shrink class="d-flex mx-2 align-center">
                          <div class="radar-legend-1"></div>
                          <span class="font-12 ml-1">Before</span>
                        </v-flex>
                        <v-flex shrink class="d-flex mx-2 align-center">
                          <div class="radar-legend-2"></div>
                          <span class="font-12 ml-1">After</span>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-dialog>

                  <v-dialog
                    class="graph-dialog"
                    v-model="line_graph_dialog"
                    width="1000"
                  >
                    <v-card flat width="1000" height="600" class="pa-8">
                      <apexchart
                        type="area"
                        width="100%"
                        height="90%"
                        :options="chartOptions"
                        :series="series"
                      >
                      </apexchart>

                      <v-layout justify-center align-center mt-6 mb-n6>
                        <v-flex shrink class="d-flex mx-2 align-center">
                          <div class="legend-1"></div>
                          <span class="font-12 ml-1">Actual Age</span>
                        </v-flex>
                        <v-flex shrink class="d-flex mx-2 align-center">
                          <div class="legend-2"></div>
                          <span class="font-12 ml-1">Vascular Age</span>
                        </v-flex>
                        <v-flex shrink class="d-flex mx-2 align-center">
                          <div class="legend-3"></div>
                          <span class="font-12 ml-1">Body Age</span>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-dialog>
                </v-row>
              </box-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <div id="previewCapture" style="display: none"></div>

    <Loading color="#C71010" :active.sync="isLoading" :is-full-page="true" />
  </div>
</template>

<script>
import BoxCard from "@/components/BoxCard.vue";
import moment from "moment";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import SERVICES from "../services";
import { url_path } from "../services/Constants";
import html2canvas from "html2canvas";
import GroupTitle from "@/components/GroupTitle.vue";
export default {
  components: {
    Loading,
    BoxCard,
    GroupTitle,
  },
  data() {
    return {
      url_path,
      graph_dialog: false,
      line_graph_dialog: false,
      isShow: true,
      optionsRadar: {
        chart: {
          type: "radar",
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: [
            "Muscle mass point",
            "Muscle mass",
            "Grip Strength",
            "Stability",
            "Flexibility (Lower)",
            "Flexibility (Upper)",
          ],
          labels: {
            style: {
              fontSize: "11px",
              colors: ["#000", "#000", "#000", "#000", "#000", "#000"],
            },
          },
        },
        fill: {
          colors: ["#D4EDEE", "#FFB876"],
          opacity: 0.5,
          type: "solid",
        },
        colors: ["#55C5C8", "#FF7A2F"],
      },
      seriesRadar: [],
      series: [],
      chartOptions: {
        chart: {
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "40%",
            endingShape: "rounded",
          },
        },
        stroke: {
          width: [2, 0, 0],
        },
        colors: ["#C71010", "#0075FF", "#FF00E5"],
        // set start 0 for y axis
        yaxis: {
          min: 0,
          max: 100,
          tickAmount: 10,
          labels: {
            formatter: function (val) {
              return val;
            },
          },
        },
        grid: {
          row: {
            colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
            opacity: 0,
          },
        },
        xaxis: {
          categories: ["", "Before", "After", ""],
        },
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            // get the name of the series
            let name = w.globals.seriesNames;
            // get the xaxis label
            let categoryLabels = w.globals.categoryLabels;
            //set html content
            let html =
              `<div class="tooltip-custom">
              <div class="font-16 font-w-500 pa-2">${categoryLabels[seriesIndex]}</div>
              <hr />` +
              (dataPointIndex == 1
                ? `
                <div class="tooltip-custom__title pa-2">
                  <span class="label-aa"></span>
                  ${name[0]} : ${series[0][0]}
                </div>
                <div class="tooltip-custom__title pa-2">
                   <span class="label-va"></span>
                  ${name[1]} : ${series[1][1]}
                </div>
                <div class="tooltip-custom__title pa-2">
                  <span class="label-ba"></span>
                   ${name[2]} : ${series[2][1]}
                </div>
                `
                : "") +
              (dataPointIndex == 2
                ? `
                <div class="tooltip-custom__title pa-2">
                  <span class="label-aa"></span>
                  ${name[0]} : ${series[0][0]}
                </div>
                <div class="tooltip-custom__title pa-2">
                   <span class="label-va"></span>
                  ${name[1]} : ${series[1][2]}
                </div>
                <div class="tooltip-custom__title pa-2">
                  <span class="label-ba"></span>
                   ${name[2]} : ${series[2][2]}
                </div>
                `
                : "") +
              `</div>`;
            return html;
          },
        },
        fill: {
          colors: ["#C71010", "#0075FF", "#FF00E5"],
          opacity: 1,
          type: "gradient",
          gradient: {
            // shade: "dark",
            type: "vertical",
            shadeIntensity: 0.5,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 100],
            colorStops: [
              [
                {
                  offset: 100,
                  color: "#C71010",
                  opacity: 1,
                },
              ],
              [
                {
                  offset: 0,
                  color: "#41E5FC",
                  opacity: 1,
                },
                {
                  offset: 100,
                  color: "#0075FF",
                  opacity: 1,
                },
              ],
              [
                {
                  offset: 0,
                  color: "#FFBB02",
                  opacity: 1,
                },
                {
                  offset: 100,
                  color: "#FF5C00",
                  opacity: 1,
                },
              ],
            ],
          },
        },
      },
      isLoading: false,
      customer: null,
      customer_before: null,
      customer_after: null,
      previewImage: null,
      dialog: false,
      date: moment().format("DD/MM/YYYY"),
    };
  },
  created() {
    this.getCampare();
  },
  methods: {
    getCampare() {
      this.isLoading = true;
      SERVICES.compare.getCompare(this.$route.params.id).then(async (res) => {
        this.customer = res.customer;
        this.customer_before = res.customer_before;
        this.customer_after = res.customer_after;
        this.seriesRadar = res.radar_series;
        this.series = res.line_series;
        this.isLoading = false;
      });
    },
    onPreviewImage(image) {
      if (image) {
        this.dialog = true;
        this.previewImage = image;
      }
    },
    screenshot() {
      this.isLoading = true;
      this.isShow = false;
      setTimeout(() => {
        html2canvas(document.querySelector("#printScreen"), {
          allowTaint: true,
          useCORS: true,
          logging: true,
        })
          .then((canvas) => {
            document.getElementById("previewCapture").appendChild(canvas);
            var dataURL = canvas
              .toDataURL("image/jpeg", 1.0)
              .replace("image/jpeg", "image/octet-stream");

            let date = moment(
              new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10)
            ).format("YYYYMMDD");

            this.downloadImage(
              dataURL,
              `${this.customer.first_name.replaceAll(
                " ",
                ""
              )}_${this.customer.family_name.replaceAll(
                " ",
                ""
              )}_compare_${date}.jpeg`
            );
            this.isLoading = false;
            this.isShow = true;
          })
          .catch((err) => {
            this.isLoading = false;
            this.isShow = true;
          });
      }, 1000);
    },

    // Save | Download image
    downloadImage(data, filename = "untitled.jpeg") {
      var a = document.createElement("a");
      a.href = data;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
    },

    previewGraph(type) {
      if (type === "radar") {
        this.graph_dialog = true;
      } else {
        this.line_graph_dialog = true;
      }
      this.optionsRadar.xaxis.labels.style.fontSize = "16px";
    },
  },
};
</script>

<style lang="scss">
.line-left {
  border-left: 1px solid #d3d3d3;
}
.line-top {
  border-top: 1px solid #d3d3d3;
}
.line-right {
  border-right: 1px solid #d3d3d3;
}
.legend-1 {
  background: #c71010;
  width: 18px;
  height: 18px;
  border-radius: 100%;
}
.legend-2 {
  background: linear-gradient(360deg, #0075ff 0%, #41e5fc 98.43%);
  width: 18px;
  height: 18px;
  border-radius: 100%;
}
.legend-3 {
  background: linear-gradient(360deg, #ff00e5 0%, #fea914 75.36%);
  width: 18px;
  height: 18px;
  border-radius: 100%;
}
.radar-legend-1 {
  background: #55c5c8;
  width: 18px;
  height: 18px;
  border-radius: 100%;
}
.radar-legend-2 {
  background: #ff7a2f;
  width: 18px;
  height: 18px;
  border-radius: 100%;
}
.label-aa {
  background: #c71010;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  display: inline-block;
  margin-right: 10px;
}
.label-ba {
  background: linear-gradient(360deg, #ff00e5 0%, #fea914 75.36%);
  width: 18px;
  height: 18px;
  border-radius: 100%;
  display: inline-block;
  margin-right: 10px;
}
.label-va {
  background: linear-gradient(360deg, #0075ff 0%, #41e5fc 98.43%);
  width: 18px;
  height: 18px;
  border-radius: 100%;
  display: inline-block;
  margin-right: 10px;
}
</style>
