<template>
  <div class="">
    <div v-if="!isShow" class="pb-12">
      <v-layout align-center justify-space-between>
        <v-flex xs4>
          <v-layout>
            <v-flex shrink>
              <v-btn icon class="mr-6" to="/">
                <v-icon large color="#2E2528">mdi-arrow-left</v-icon>
              </v-btn>
            </v-flex>
            <v-flex>
              <group-title onPage="report" txtEn="REPORT" txtTh="รายงาน" />
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs4>
          <v-img width="260" src="../assets/images/banner-report.png"></v-img>
        </v-flex>
        <v-flex shrink>
          <v-layout align-center class="date_picker_report">
            <v-flex shrink class="font-18 font-w-600">Date</v-flex>
            <v-flex pl-3>
              <v-text-field
                v-model="date"
                readonly
                solo
                dense
                hint="MM/DD/YYYY format"
                color="var(--color-2)"
                hide-details
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex shrink class="px-4">
          <v-btn
            height="44"
            block
            style="
              border-radius: 29px;
              box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
            "
            dark
            color="#000000"
            class="text-capitalize"
            :to="'/customer/edit/' + $route.params.slug"
          >
            <v-icon class="mr-2">mdi-pencil-outline</v-icon>
            <span class="font-20 font-w-600 white--text">Edit</span>
          </v-btn>
        </v-flex>
        <v-flex shrink>
          <v-btn
            height="44"
            block
            style="
              border-radius: 29px;
              box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
            "
            dark
            color="#c71010"
            class="text-capitalize"
            @click="screenshot"
          >
            <span class="font-20 font-w-600 white--text">Save Image</span>
          </v-btn>
        </v-flex>
      </v-layout>

      <v-divider />

      <v-layout justify-space-between mt-4>
        <v-flex xs5>
          <v-layout align-center style="width: 710px">
            <v-flex style="margin-left: -6.5rem">
              <v-card class="card__name" block dark color="#000">
                <div style="padding-left: 7rem">
                  {{ form.first_name }} {{ form.family_name }}
                </div>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs7>
          <box-card height="57" class="py-0 d-flex align-center">
            <v-layout align-center>
              <v-flex>
                <span class="txt__title_th">อายุ</span>
                <span class="txt__title_en mx-2">Age</span>
                <span class="txt_val">{{ form.age }}</span>
              </v-flex>
              <v-flex>
                <span class="txt__title_th">เพศ</span>
                <span class="txt__title_en mx-2">gender</span>
                <span class="txt_val">
                  {{ form.gender === "M" ? "ชาย (Male)" : "หญิง (Female)" }}
                </span>
              </v-flex>
              <v-flex>
                <span class="txt__title_th">ส่วนสูง</span>
                <span class="txt__title_en mx-2">Height</span>
                <span class="txt_val">{{ form.height }}</span>
              </v-flex>
              <v-flex>
                <span class="txt__title_th">น้ำหนัก</span>
                <span class="txt__title_en mx-2">Weight</span>
                <span class="txt_val">{{ form.weight }}</span>
              </v-flex>
            </v-layout>
          </box-card>
        </v-flex>
      </v-layout>

      <v-layout justify-space-between mt-3>
        <v-flex xs5 class="pt-3 pr-2">
          <div>
            <v-row no-gutters class="mb-5">
              <v-col cols="4" class="mb-6">
                <div class="text-center font-20 font-weight-bold">
                  จัดอันดับ
                </div>
                <div class="text-center font-15 mb-2">Rank</div>
                <div class="d-flex align-center justify-center mb-5">
                  <div class="rank-report">{{ form.rank }}</div>
                </div>
                <v-layout justify-center>
                  <v-flex
                    shrink
                    class="text-center font-24 mx-1"
                    :class="form.rank == 'S' ? 'font-weight-bold' : ''"
                  >
                    S
                  </v-flex>
                  <v-flex
                    shrink
                    class="text-center font-24 mx-1"
                    :class="form.rank == 'A' ? 'font-weight-bold' : ''"
                  >
                    A
                  </v-flex>
                  <v-flex
                    shrink
                    class="text-center font-24 mx-1"
                    :class="form.rank == 'B' ? 'font-weight-bold' : ''"
                  >
                    B
                  </v-flex>
                  <v-flex
                    shrink
                    class="text-center font-24 mx-1"
                    :class="form.rank == 'C' ? 'font-weight-bold' : ''"
                  >
                    C
                  </v-flex>
                  <v-flex
                    shrink
                    class="text-center font-24 mx-1"
                    :class="form.rank == 'D' ? 'font-weight-bold' : ''"
                  >
                    D
                  </v-flex>
                </v-layout>
              </v-col>

              <v-col cols="8" class="mb-6">
                <box-card height="295" class="pa-0">
                  <v-card flat height="198" class="pa-0">
                    <v-layout align-start class="px-3 pt-4">
                      <v-flex>
                        <div class="font-16 font-w-600 mt-n2">
                          ความสมดุลความคล่องตัว
                        </div>
                        <div class="font-15 mt-n1">Mobility Balance</div>
                      </v-flex>
                      <v-flex shrink class="mr-n2">
                        <v-btn fab icon small @click="previewGraph()">
                          <v-icon>mdi-arrow-expand</v-icon>
                        </v-btn>
                      </v-flex>
                    </v-layout>

                    <div>
                      <v-card
                        style="border-radius: 20px"
                        class="pa-0"
                        height="230"
                        flat
                        color="#fff"
                      >
                        <apexchart
                          width="100%"
                          height="100%"
                          :options="optionRadar"
                          :series="seriesRadar"
                        ></apexchart>
                      </v-card>

                      <v-row justify="center">
                        <v-dialog
                          class="graph-dialog"
                          v-model="graph_dialog"
                          width="1000"
                        >
                          <v-card flat width="1000" height="700">
                            <apexchart
                              width="100%"
                              height="100%"
                              :options="optionRadar"
                              :series="seriesRadar"
                            ></apexchart>
                          </v-card>
                        </v-dialog>
                      </v-row>
                    </div>
                  </v-card>
                </box-card>
              </v-col>

              <v-col cols="6" class="pr-1">
                <box-card
                  height="295"
                  :theme="checkStatusCard(form.body_age_criterion)"
                >
                  <div class="font-24 font-w-600 mt-n2">อายุร่างกาย</div>
                  <div class="font-15 mt-n1">Body Age</div>
                  <div class="d-flex justify-center mt-2">
                    <div :class="scoreAge(form.body_age_criterion)">
                      {{ form.body_age }}
                    </div>
                  </div>
                </box-card>
              </v-col>

              <v-col cols="6" class="pl-1">
                <box-card
                  height="295"
                  :theme="checkStatusCard(form.vascular_age_criterion)"
                >
                  <div class="font-24 font-w-600 mt-n2">อายุหลอดเลือด</div>
                  <div class="font-15 mt-n1">Vascular Age</div>
                  <div class="d-flex justify-center mt-2">
                    <div :class="scoreAge(form.vascular_age_criterion)">
                      {{ form.vascular_age }}
                    </div>
                  </div>
                </box-card>
              </v-col>
            </v-row>
            <div class="pl-4 mt-3 font-13">
              <span class="font-weight-bold">
                ข้อกังวล (Point of concern):
              </span>
              {{ form.point_concern }}
            </div>
          </div>
        </v-flex>

        <v-flex xs7 class="pt-3">
          <box-card>
            <div class="font-40 font-w-600" style="color: #c71010">
              การวัดผลทางกายภาพ
            </div>
            <div class="font-20 font-w-400 mt-n3 mb-2">
              Physical Measurement
            </div>
            <v-row>
              <v-col cols="6">
                <CardResult
                  titleTH="1.ความดันโลหิตต่ำสุด/สูงสุด"
                  titleEN="Blood Pressure Min/Max"
                  :result="
                    form.blood_pressure_min + '/' + form.blood_pressure_max
                  "
                  :type="form.blood_pressure_criterion"
                  exe="mmHg"
                  class="mb-2"
                />
                <CardResult
                  titleTH="2.ดัชนีมวลกาย"
                  titleEN="BMI"
                  :result="form.bmi"
                  :type="form.bmi_criterion"
                  class="mb-2"
                />
                <CardResult
                  titleTH="3.อัตราการเผาผลาญ"
                  titleEN="Basal Metabolic Rate"
                  :result="form.basal_metabolic_rate"
                  :type="form.basal_metabolic_rate_criterion"
                  exe="kcal"
                  class="mb-2"
                />
                <CardResult
                  titleTH="4.ระดับไขมันในร่างกาย"
                  titleEN="Body Fat Rate"
                  :result="form.body_fat_rate"
                  :type="form.body_fat_rate_criterion"
                  exe="%"
                  class="mb-2"
                />
                <CardResult
                  titleTH="5.ระดับไขมันในช่องท้อง"
                  titleEN="Visceral Fat Level"
                  :result="form.visceral_fat_level"
                  :type="form.visceral_fat_level_criterion"
                  exe="Lv."
                  class="mb-2"
                />
                <CardResult
                  titleTH="6.แรงบีบมือข้างซ้าย/ขวา"
                  titleEN="Grip Strength Left/Right"
                  :result="
                    form.grip_strength_left + '/' + form.grip_strength_right
                  "
                  :type="form.grip_strength_criterion"
                  exe="Kwg."
                  class="mb-2"
                />
                <CardResult
                  titleTH="7.มวลกล้ามเนื้อ"
                  titleEN="Muscle Mass Point"
                  :result="form.muscle_mass_points"
                  :type="form.muscle_mass_points_criterion"
                  exe="Pt."
                  class="mb-2"
                />
                <CardResult
                  titleTH="8. 2 Step Test"
                  titleEN="2 Step Test"
                  :result="form.stability"
                  :type="form.stability_criteria"
                  exe="Pt."
                  class="mb-2"
                />
                <CardResult
                  titleTH="9.ความอ่อนตัวหัวไหล่"
                  titleEN="Flexibility of Shoulder"
                  :result="form.flexibility_upper"
                  :type="form.flexibility_upper_criteria"
                  exe="Cm."
                  class="mb-2"
                />
                <CardResult
                  titleTH="10.ความอ่อนตัวหัวเข่า"
                  titleEN="Flexibility of Knee"
                  :result="form.flexibility_lower"
                  :type="form.flexibility_lower_criteria"
                  exe="Cm."
                  class="mb-2"
                />
              </v-col>
              <v-col cols="6">
                <CardResult
                  titleTH="11.อายุของปอด (ความจุปอด)"
                  titleEN="Lung Age(Breathing Capacity)"
                  :result="form.lung_age"
                  :type="form.lung_age_criteria"
                  exe="Age"
                  class="mb-2"
                />
                <CardResult
                  titleTH="12.อายุผิว"
                  titleEN="Skin Age"
                  :result="form.skin_age"
                  :type="form.skin_age_criteria"
                  exe="Age"
                  class="mb-2"
                />
                <CardResult
                  titleTH="13.ความแข็งแรงกล้ามเนื้อหลัง"
                  titleEN="Back Strength"
                  :result="form.back_strength"
                  :type="form.back_strength_criteria"
                  exe="kg."
                  class="mb-2"
                />
                <CardResult
                  titleTH="14.การทรงตัวยืนขาเดียว"
                  titleEN="Single Leg Balance"
                  :result="form.single_leg_balance"
                  :type="form.single_leg_balance_criteria"
                  exe="Sec."
                  class="mb-2"
                />
                <CardResult
                  titleTH="15.Stand Up Test"
                  titleEN="Stand Up Test"
                  :result="form.stand_up_test"
                  :type="form.stand_up_test_criteria"
                  exe="Level"
                  class="mb-2"
                />
                <CardResult
                  titleTH="16.Functional Test"
                  titleEN="Functional Test"
                  :result="form.functional_test"
                  :type="form.functional_test_criteria"
                  exe="cm."
                  class="mb-2"
                />
                <CardResult
                  titleTH="17.ระดับน้ำในร่างกาย"
                  titleEN="Body water content"
                  :result="form.flexibility_lower"
                  :type="form.flexibility_lower_criteria"
                  exe="%"
                  class="mb-2"
                />
                <CardResult
                  titleTH="18.ความอ่อนตัวเอว"
                  titleEN="Flexibility of Back"
                  :result="form.flexibility_waist"
                  :type="form.flexibility_waist_criteria"
                  exe="°"
                  class="mb-2"
                />
                <CardResult
                  titleTH="19.คะแนนท่าทาง"
                  titleEN="Posture Score"
                  :result="form.posture_score"
                  :type="form.posture_score_criteria"
                  exe=""
                  class="mb-2"
                />
                <CardResult
                  titleTH="20.ปัญหาท่าทาง"
                  titleEN="Posture Problem"
                  :result="form.posture_type"
                  :type="form.posture_type_criteria"
                  exe=""
                  class="mb-2"
                />
              </v-col>
            </v-row>
          </box-card>
        </v-flex>
      </v-layout>
    </div>

    <!-- For Export to Image -->
    <div v-else id="capture" ref="printMe" class="">
      <v-layout align-center justify-space-between>
        <v-flex xs4>
          <v-layout>
            <v-flex shrink>
              <v-btn icon class="mr-6" to="/">
                <v-icon large color="#2E2528">mdi-arrow-left</v-icon>
              </v-btn>
            </v-flex>
            <v-flex>
              <group-title onPage="report" txtEn="REPORT" txtTh="รายงาน" />
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs4>
          <v-img width="260" src="../assets/images/banner-report.png"></v-img>
        </v-flex>
        <v-flex shrink class="pr-3">
          <v-layout align-center class="date_picker_report">
            <v-flex shrink class="font-18 font-w-600">Date</v-flex>
            <v-flex pl-3>
              <v-text-field
                v-model="date"
                readonly
                solo
                dense
                hint="MM/DD/YYYY format"
                color="var(--color-2)"
                hide-details
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-divider />

      <v-layout justify-space-between mt-4>
        <v-flex xs5>
          <v-layout align-center style="width: 710px">
            <v-flex style="margin-left: -6.5rem">
              <v-card class="card__name" block dark color="#000">
                <div style="padding-left: 7rem">
                  {{ form.first_name }} {{ form.family_name }}
                </div>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs7>
          <box-card height="57" class="py-0 d-flex align-center">
            <v-layout align-center>
              <v-flex>
                <span class="txt__title_th">อายุ</span>
                <span class="txt__title_en mx-2">Age</span>
                <span class="txt_val">{{ form.age }}</span>
              </v-flex>
              <v-flex>
                <span class="txt__title_th">เพศ</span>
                <span class="txt__title_en mx-2">gender</span>
                <span class="txt_val">
                  {{ form.gender === "M" ? "ชาย (Male)" : "หญิง (Female)" }}
                </span>
              </v-flex>
              <v-flex>
                <span class="txt__title_th">ส่วนสูง</span>
                <span class="txt__title_en mx-2">Height</span>
                <span class="txt_val">{{ form.height }}</span>
              </v-flex>
              <v-flex>
                <span class="txt__title_th">น้ำหนัก</span>
                <span class="txt__title_en mx-2">Weight</span>
                <span class="txt_val">{{ form.weight }}</span>
              </v-flex>
            </v-layout>
          </box-card>
        </v-flex>
      </v-layout>

      <v-layout justify-space-between mt-3>
        <v-flex xs5 class="pt-3 pr-2">
          <div>
            <v-row no-gutters class="mb-5">
              <v-col cols="4" class="mb-6">
                <div class="text-center font-20 font-weight-bold">
                  จัดอันดับ
                </div>
                <div class="text-center font-15 mb-2">Rank</div>
                <div class="d-flex align-center justify-center mb-5">
                  <div class="rank-report">{{ form.rank }}</div>
                </div>
                <v-layout justify-center>
                  <v-flex
                    shrink
                    class="text-center font-24 mx-1"
                    :class="form.rank == 'S' ? 'font-weight-bold' : ''"
                  >
                    S
                  </v-flex>
                  <v-flex
                    shrink
                    class="text-center font-24 mx-1"
                    :class="form.rank == 'A' ? 'font-weight-bold' : ''"
                  >
                    A
                  </v-flex>
                  <v-flex
                    shrink
                    class="text-center font-24 mx-1"
                    :class="form.rank == 'B' ? 'font-weight-bold' : ''"
                  >
                    B
                  </v-flex>
                  <v-flex
                    shrink
                    class="text-center font-24 mx-1"
                    :class="form.rank == 'C' ? 'font-weight-bold' : ''"
                  >
                    C
                  </v-flex>
                  <v-flex
                    shrink
                    class="text-center font-24 mx-1"
                    :class="form.rank == 'D' ? 'font-weight-bold' : ''"
                  >
                    D
                  </v-flex>
                </v-layout>
              </v-col>

              <v-col cols="8" class="mb-6">
                <box-card height="295" class="pa-0">
                  <v-card flat height="198" class="pa-0">
                    <v-layout align-start class="px-3 pt-4">
                      <v-flex>
                        <div class="font-16 font-w-600 mt-n2">
                          ความสมดุลความคล่องตัว
                        </div>
                        <div class="font-15 mt-n1">Mobility Balance</div>
                      </v-flex>
                      <v-flex shrink class="mr-n2">
                        <v-btn fab icon small @click="previewGraph()">
                          <v-icon>mdi-arrow-expand</v-icon>
                        </v-btn>
                      </v-flex>
                    </v-layout>

                    <div>
                      <v-card
                        style="border-radius: 20px"
                        class="pa-0"
                        height="230"
                        flat
                        color="#fff"
                      >
                        <apexchart
                          width="100%"
                          height="100%"
                          :options="optionRadar"
                          :series="seriesRadar"
                        ></apexchart>
                      </v-card>
                      <v-row justify="center">
                        <v-dialog
                          class="graph-dialog"
                          v-model="graph_dialog"
                          width="1000"
                        >
                          <v-card flat width="1000" height="700">
                            <apexchart
                              width="100%"
                              height="100%"
                              :options="optionRadar"
                              :series="seriesRadar"
                            ></apexchart>
                          </v-card>
                        </v-dialog>
                      </v-row>
                    </div>
                  </v-card>
                </box-card>
              </v-col>

              <v-col cols="6" class="pr-1">
                <box-card
                  height="295"
                  :theme="checkStatusCard(form.body_age_criterion)"
                >
                  <div class="font-24 font-w-600 mt-n2">อายุร่างกาย</div>
                  <div class="font-15 mt-n1">Body Age</div>
                  <div class="d-flex justify-center mt-2">
                    <div :class="scoreAge(form.body_age_criterion)">
                      {{ form.body_age }}
                    </div>
                  </div>
                </box-card>
              </v-col>

              <v-col cols="6" class="pl-1">
                <box-card
                  height="295"
                  :theme="checkStatusCard(form.vascular_age_criterion)"
                >
                  <div class="font-24 font-w-600 mt-n2">อายุหลอดเลือด</div>
                  <div class="font-15 mt-n1">Vascular Age</div>
                  <div class="d-flex justify-center mt-2">
                    <div :class="scoreAge(form.vascular_age_criterion)">
                      {{ form.vascular_age }}
                    </div>
                  </div>
                </box-card>
              </v-col>
            </v-row>
            <div class="pl-4 mt-3 font-13">
              <span class="font-weight-bold">
                ข้อกังวล (Point of concern):
              </span>
              {{ form.point_concern }}
            </div>
          </div>
        </v-flex>

        <v-flex xs7 class="pt-3">
          <box-card>
            <div class="font-40 font-w-600" style="color: #c71010">
              การวัดผลทางกายภาพ
            </div>
            <div class="font-20 font-w-400 mt-n3 mb-2">
              Physical Measurement
            </div>
            <v-row>
              <v-col cols="6">
                <CardResult
                  titleTH="1.ความดันโลหิตต่ำสุด/สูงสุด"
                  titleEN="Blood Pressure Min/Max"
                  :result="
                    form.blood_pressure_min + '/' + form.blood_pressure_max
                  "
                  :type="form.blood_pressure_criterion"
                  exe="mmHg"
                  class="mb-2"
                />
                <CardResult
                  titleTH="2.ดัชนีมวลกาย"
                  titleEN="BMI"
                  :result="form.bmi"
                  :type="form.bmi_criterion"
                  class="mb-2"
                />
                <CardResult
                  titleTH="3.อัตราการเผาผลาญ"
                  titleEN="Basal Metabolic Rate"
                  :result="form.basal_metabolic_rate"
                  :type="form.basal_metabolic_rate_criterion"
                  exe="kcal"
                  class="mb-2"
                />
                <CardResult
                  titleTH="4.ระดับไขมันในร่างกาย"
                  titleEN="Body Fat Rate"
                  :result="form.body_fat_rate"
                  :type="form.body_fat_rate_criterion"
                  exe="%"
                  class="mb-2"
                />
                <CardResult
                  titleTH="5.ระดับไขมันในช่องท้อง"
                  titleEN="Visceral Fat Level"
                  :result="form.visceral_fat_level"
                  :type="form.visceral_fat_level_criterion"
                  exe="Lv."
                  class="mb-2"
                />
              </v-col>
              <v-col cols="6">
                <CardResult
                  titleTH="6.แรงบีบมือข้างซ้าย/ขวา"
                  titleEN="Grip Strength Left/Right"
                  :result="
                    form.grip_strength_left + '/' + form.grip_strength_right
                  "
                  :type="form.grip_strength_criterion"
                  exe="Kwg."
                  class="mb-2"
                />
                <CardResult
                  titleTH="7.มวลกล้ามเนื้อ"
                  titleEN="Muscle Mass Point"
                  :result="form.muscle_mass_points"
                  :type="form.muscle_mass_points_criterion"
                  exe="Pt."
                  class="mb-2"
                />
                <CardResult
                  titleTH="8. 2 Step Test"
                  titleEN="2 Step Test"
                  :result="form.stability"
                  :type="form.stability_criteria"
                  exe="Pt."
                  class="mb-2"
                />
                <CardResult
                  titleTH="9.ความอ่อนตัวหัวไหล่"
                  titleEN="Flexibility of Shoulder"
                  :result="form.flexibility_upper"
                  :type="form.flexibility_upper_criteria"
                  exe="Cm."
                  class="mb-2"
                />
                <CardResult
                  titleTH="10.ความอ่อนตัวหัวเข่า"
                  titleEN="Flexibility of Knee"
                  :result="form.flexibility_lower"
                  :type="form.flexibility_lower_criteria"
                  exe="Cm."
                  class="mb-2"
                />
              </v-col>
            </v-row>
          </box-card>
        </v-flex>
      </v-layout>
    </div>

    <div id="previewCapture" style="display: none"></div>

    <Loading color="#C71010" :active.sync="isLoading" :is-full-page="true" />
  </div>
</template>

<script>
import GroupTitle from "@/components/GroupTitle.vue";
import BoxCard from "@/components/BoxCard.vue";
import CardResult from "@/components/CardResult.vue";
import SERVICE from "../services/index";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import html2canvas from "html2canvas";

export default {
  components: { GroupTitle, BoxCard, CardResult, Loading },
  name: "ReportView",
  metaInfo: {
    title: "Report",
    titleTemplate: "%s | Powerpod Fitness Club",
  },
  data() {
    return {
      graph_dialog: false,
      isShow: false,
      output: null,
      dialog: false,
      isLoading: false,
      form: {
        first_name: "",
        family_name: "",
        age: "",
        gender: "",
        phone: "",
        height: "",
        weight: "",
        body_age: "",
        vascular_age: "",
        point_concern: "",
        blood_pressure_min: "",
        blood_pressure_max: "",
        bmi: "",
        basal_metabolic_rate: "",
        body_fat_rate: "",
        visceral_fat_level: "",
        grip_strength_left: "",
        grip_strength_right: "",
        muscle_mass: "",
        muscle_mass_point: "",
        viscural_age: "",
        date: null,
      },
      // optionRadar: {
      //   chart: {
      //     type: "radar",
      //     toolbar: {
      //       show: false,
      //     },
      //   },
      //   legend: {
      //     show: false,
      //   },
      //   xaxis: {
      //     categories: [
      //       "Muscle mass point",
      //       "Muscle mass",
      //       "Grip Strength",
      //       "Stability",
      //       "Flexibility (Lower)",
      //       "Flexibility (Upper)",
      //     ],
      //     labels: {
      //       style: {
      //         fontSize: "11px",
      //         colors: ["#000", "#000", "#000", "#000", "#000", "#000"],
      //       },
      //     },
      //   },
      //   fill: {
      //     colors: ["#D4EDEE"],
      //     opacity: 0.5,
      //     type: "solid",
      //   },
      //   colors: ["#55C5C8"],
      // },
      optionRadar: {
        chart: {
          type: "radar",
          toolbar: {
            show: false,
          },
          sparkline: {
            enabled: false,
          },
          parentHeightOffset: 0,
        },
        legend: {
          show: true,
        },
        xaxis: {
          categories: [
            "Muscle mass point",
            "Muscle mass",
            "Grip Strength",
            "Stability",
            "Flexibility (Lower)",
            "Flexibility (Upper)",
          ],
          labels: {
            style: {
              fontSize: "11px",
              colors: ["#000", "#000", "#000", "#000", "#000", "#000"],
            },
          },
        },
        fill: {
          colors: ["#C8E5FE", "#FFCFCF"],
          opacity: 0.5,
          type: "solid",
        },
        colors: ["#428FF3", "#FF7A2F"],
      },
      seriesRadar: [],
    };
  },
  created() {
    this.fetchData(this.$route.params.slug);
  },
  computed: {
    date() {
      return this.form.date ? moment(this.form.date).format("DD/MM/YYYY") : "";
    },
  },
  methods: {
    screenshot() {
      this.isShow = true;
      this.isLoading = true;
      setTimeout(() => {
        html2canvas(document.querySelector("#capture")).then((canvas) => {
          document.getElementById("previewCapture").appendChild(canvas);
          var dataURL = canvas
            .toDataURL("image/jpeg", 1.0)
            .replace("image/jpeg", "image/octet-stream");

          let date = moment(
            new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substr(0, 10)
          ).format("YYYYMMDD");

          this.downloadImage(
            dataURL,
            `${this.form.full_name.replaceAll(" ", "")}_Report_${date}.jpeg`
          );
          this.isLoading = false;
          this.isShow = false;
        });
      }, 1000);
    },

    // Save | Download image
    downloadImage(data, filename = "untitled.jpeg") {
      var a = document.createElement("a");
      a.href = data;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
    },

    scoreAge(type) {
      switch (type) {
        case "warn":
          return "score__circle__warn";
        case "danger":
          return "score__circle__danger";
        default:
          return "score__circle__standard";
      }
    },

    checkStatusCard(type) {
      switch (type) {
        case "warn":
          return "yellow-dark";
        case "danger":
          return "red-dark";
        default:
          return "green-dark";
      }
    },

    async fetchData(id) {
      this.isLoading = true;
      const res = await SERVICE.customer.show(id);
      this.isLoading = false;
      this.form = res.history.length > 0 ? res.history[0] : res.data;
      // this.form = res.data;
      this.seriesRadar = res.radar_series;
    },
    previewGraph() {
      this.graph_dialog = true;
      this.optionRadar.xaxis.labels.style.fontSize = "16px";
    },
  },
};
</script>

<style lang="scss">
.card__name {
  position: relative;
  background: #000 !important;
  box-shadow: 8px 2px 0px 0px #ffffff, 8px 2px 0px 1px #000000,
    17px 6px 0px 0px #ffffff, 17px 6px 0px 1px #000000 !important;
  border: 1px solid !important;
  border-radius: 0px 20px 20px 0px !important;
  padding: 0.5rem 1.5rem 0.5rem 4rem;
  margin-left: -3rem;
  font-weight: 600;
  font-size: 28px;
}
.txt__title_th {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 38px;
}
.txt__title_en {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
}
.txt_val {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 36px;
  color: #2e4785;
}
.score__circle__danger {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 80px;
  font-weight: 600;
  width: 200px;
  height: 200px;
  background: #ffdbdb;
  color: #b21212;
  border-radius: 100% !important;
  border: 25px solid #c71010;
}
.score__circle__warn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 80px;
  font-weight: 600;
  color: #ffbb02;
  width: 200px;
  height: 200px;
  background: #fdf6bf;
  border-radius: 100% !important;
  border: 25px solid #ffbb02;
}
.score__circle__standard {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 80px;
  font-weight: 600;
  color: #44a04c;
  width: 200px;
  height: 200px;
  background: #ddffdb;
  border-radius: 100% !important;
  border: 25px solid #44a04c;
}
.box__concern {
  border: 1px solid;
  border-radius: 30px;
  padding: 15px;
  background: #fff;
}
.date_picker_report {
  .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
    min-height: 63px;
    width: 150px;
    min-height: 44px;
    font-size: 18px;
    border: 1px solid #000000;
    box-sizing: border-box;
  }
  .v-text-field fieldset,
  .v-text-field .v-input__control,
  .v-text-field .v-input__slot {
    border-radius: 30px;
  }
}
.v-dialog.v-dialog--active {
  overflow: hidden !important;
}
.point_concern {
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 10px;
  height: 220px;
  overflow: auto;
}
.rank-report {
  background: #000 !important;
  -webkit-box-shadow: 7px 7px 0px 0px #fff, 7px 7px 0px 1px black !important;
  box-shadow: 7px 7px 0px 0px #fff, 7px 7px 0px 1px black !important;
  border: 1px solid !important;
  border-radius: 100% !important;
  // padding: 0.7rem 1.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 146px;
  height: 146px;
  font-weight: 600;
  font-size: 96px;
  color: #fff;
}
</style>
