import AuthService from "../AuthService";
import Service from "../Service";

const setting = {
  index() {
    return AuthService.get(`/api/settings`).then((res) => res);
  },
  save(payload) {
    return AuthService.post("/api/settings", payload).then((res) => res);
  },
  pointConcern() {
    return AuthService.get("/api/pointconcern").then((res) => res);
  },
};

export default setting;
