<template>
  <div class="mb-12">
    <v-layout align-center>
      <v-flex shrink class="mt-n12">
        <v-btn icon class="mr-6" to="/">
          <v-icon large color="#2E2528">mdi-arrow-left</v-icon>
        </v-btn>
      </v-flex>
      <v-flex shrink>
        <group-title
          onPage="password"
          txtEn="Change Password"
          txtTh="เปลี่ยนรหัสผ่าน"
        />
      </v-flex>
      <v-spacer />
    </v-layout>

    <box-card class="my-6">
      <v-row justify="center">
        <v-col cols="10">
          <v-form ref="form" v-model="valid" lazy-validation class="px-6 py-12">
            <v-row no-gutters class="text_input_form my-3">
              <v-col cols="12">
                <div class="font-24 font-w-600">รหัสผ่านใหม่</div>
                <div class="txt_en">New Password</div>
              </v-col>
              <v-col cols="12" class="mt-2">
                <v-text-field
                  @click:append="() => (value = !value)"
                  :type="value ? 'password' : 'text'"
                  :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                  class="pa-0"
                  v-model="form.password"
                  dense
                  solo
                  color="#C71010"
                  :rules="passwordRule"
                  required
                />
              </v-col>
            </v-row>

            <v-row no-gutters class="text_input_form my-3">
              <v-col cols="12">
                <div class="font-24 font-w-600">ยืนยันรหัสผ่านใหม่</div>
                <div class="txt_en">Confirm New Password</div>
              </v-col>
              <v-col cols="12" class="mt-2">
                <v-text-field
                  class="pa-0"
                  v-model="form.password_confirmation"
                  dense
                  solo
                  color="#C71010"
                  :rules="confirmPasswordRule"
                  @click:append="() => (value = !value)"
                  :type="value ? 'password' : 'text'"
                  :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                  required
                />
              </v-col>
            </v-row>

            <div class="d-flex justify-center px-12 mt-12">
              <v-btn
                :loading="isBtnLoading"
                width="221"
                height="60"
                style="
                  border-radius: 29px;
                  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
                "
                :dark="valid"
                :disabled="!valid"
                color="#c71010"
                class="text-capitalize"
                @click="validate"
              >
                <span class="font-28 font-w-600 white--text">Submit</span>
              </v-btn>
            </div>
          </v-form>
        </v-col>
      </v-row>
    </box-card>
  </div>
</template>

<script>
import GroupTitle from "@/components/GroupTitle.vue";
import BoxCard from "@/components/BoxCard.vue";
import SERVICE from "../services/index";
import { mapState } from "vuex";

export default {
  metaInfo: {
    title: "Change Password",
    titleTemplate: "%s | Powerpod Fitness Club",
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content: "Change password page",
      },
    ],
  },
  components: { GroupTitle, BoxCard },
  data() {
    return {
      value: String,
      valid: true,
      isBtnLoading: false,
      form: {
        user_id: null,
        password: "",
        password_confirmation: "",
      },
      passwordRule: [
        (v) => !!v || "Password is required.",
        (v) =>
          (v && v.length >= 6) || "Password must be more than 6 characters.",
      ],
      confirmPasswordRule: [
        (v) => !!v || "Password is required.",
        (v) =>
          v === this.form.password || "Password Confirmation does not match.",
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    async save() {
      this.isBtnLoading = true;
      this.form.user_id = this.user.id;
      const res = await SERVICE.auth.changePassword(this.form);
      if (res.status === 200) {
        this.isBtnLoading = false;
        this.$swal({
          title: res.message,
          icon: "success",
          confirmButtonColor: "#C71010",
        });
        this.$refs.form.reset();
      } else {
        this.$swal({
          title: "Change Password Failed!",
          text: res.message,
          icon: "error",
          confirmButtonColor: "#C71010",
        });
        this.isBtnLoading = false;
      }
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.save();
      }
    },
  },
};
</script>

<style></style>
