<template>
  <div>
    <Loading color="#C71010" :active.sync="isLoading" :is-full-page="true" />
    <v-container class="pa-0">
      <v-layout class="login" justify-center align-center>
        <v-flex xs12 sm11 md10>
          <v-layout align-center>
            <v-flex xs12 sm6 class="d-flex justify-center align-center pa-12">
              <v-img
                width="100%"
                src="../assets/images/image-heroes-login.png"
              ></v-img>
            </v-flex>
            <v-flex xs12 sm6>
              <div class="d-flex justify-center mb-4">
                <v-img
                  width="336px"
                  contain
                  height="200"
                  src="../assets/images/logo-login.png"
                ></v-img>
              </div>

              <v-form
                class="customer__form mt-6"
                ref="formLogin"
                v-model="valid"
                lazy-validation
              >
                <v-card class="card__login mb-16">
                  <div class="pt-6 px-8">
                    <TextInput
                      required
                      errorMessage="Email"
                      label="Email"
                      placeholder="Email"
                      type="email"
                      :model="form.email"
                      @update-data="form.email = $event"
                    />
                  </div>

                  <v-divider class="mb-4" />

                  <div class="pb-6 px-8">
                    <TextPasswordInput
                      type="password"
                      label="Password"
                      placeholder="**********"
                      :model="form.password"
                      required
                      errorMessage="Password"
                      @update-data="form.password = $event"
                    />
                  </div>
                </v-card>

                <div class="d-flex justify-center">
                  <v-btn
                    :disabled="!valid"
                    width="281"
                    height="70"
                    @click="signin()"
                    class="btn_login"
                    large
                    color="#000"
                  >
                    <span class="font-32 white--text font-w-600">Login</span>
                  </v-btn>
                </div>
              </v-form>
            </v-flex>
            <v-spacer />
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import TextInput from "@/components/TextInput.vue";
import TextPasswordInput from "@/components/TextPasswordInput.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
    metaInfo: {
    title: "Login",
    titleTemplate: "%s | Powerpod Fitness Club",
  },
  components: {
    TextInput,
    TextPasswordInput,
    Loading,
  },
  name: "LoginPage",
  data() {
    return {
      valid: true,
      isLoading: false,
      form: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    signin() {
      if (this.$refs.formLogin.validate()) {
        this.isLoading = true;
        this.$store.dispatch("auth/signin", this.form).then((res) => {
          if (res.status !== 200) {
            this.isLoading = false;
            this.$swal({
              title: "Warning",
              text: res.response.data.message,
              icon: "warning",
              confirmButtonColor: "#C71010",
            });
          } else {
            this.isLoading = false;
            this.$router.push("/");
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
.card__login {
  background: #ffffff;
  border: 1px solid #000000 !important;
  box-sizing: border-box !important;
  border-radius: 30px !important;
  -webkit-box-shadow: 7px 7px 0px 1px #000000 !important;
  box-shadow: 7px 7px 0px 1px #000000 !important;
}
.login_welcome {
  font-weight: 300;
  font-size: 36px;
  line-height: 54px;
  color: var(--color-3);
}
.login_title {
  font-weight: 400;
  font-size: 36px;
  line-height: 54px;
  color: var(--color-2);
}
.login {
  min-height: 100vh;
}
.btn_login {
  border-radius: 29px !important;
}
</style>
