<template>
  <div class="mb-12">
    <!-- Search *************************************************************************** -->
    <v-layout align-center mb-4 mt-2>
      <v-flex>
        <GroupTitle
          onPage="Questionnaire"
          txtTh="แบบสอบถาม"
          txtEn="Questionnaire"
        />
      </v-flex>
      <v-spacer />
      <v-flex shrink>
        <!-- Search -->
        <v-layout align-center wrap class="form-search">
          <v-flex shrink>
            <v-layout align-center>
              <v-flex shrink mr-3 class="font-18 font-w-400">Date</v-flex>
              <v-flex>
                <v-menu
                  v-model="menu_date_from"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  nudge-bottom="10px"
                  nudge-left="150px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateFrom"
                      :placeholder="hover_date"
                      readonly
                      color="var(--color-2)"
                      v-bind="attrs"
                      v-on="on"
                      solo
                      dense
                      hide-details
                      clearable
                      @click:clear="clearFilter('date_from')"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    color="var(--color-2)"
                    v-model="filter.date_from"
                    @change="setFilter('date_from')"
                    @input="menu_date_from = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex shrink pl-3>
            <v-layout align-center>
              <v-flex shrink class="font-18 font-w-400">To</v-flex>
              <v-flex pl-3>
                <v-menu
                  v-model="menu_date_to"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  nudge-bottom="10px"
                  nudge-left="150px"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateTo"
                      :placeholder="hover_date"
                      readonly
                      solo
                      height="35"
                      dense
                      color="var(--color-2)"
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                      clearable
                      :min="filter.date_from"
                      @click:clear="clearFilter('date_to')"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :min="filter.date_from"
                    v-model="filter.date_to"
                    @change="setFilter('date_to')"
                    color="var(--color-2)"
                    @input="menu_date_to = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex class="ml-3">
            <v-btn color="#FFCFCF" small fab depressed @click="fetchApi()">
              <v-icon color="#C71010">mdi-magnify</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <div class="questionnaire__table">
      <v-card class="px-6 pb-6">
        <div class="mb-3">
          <v-layout justify-space-between align-center mt-6>
            <v-flex xs5 pr-5>
              <v-layout align-center class="input__search">
                <v-flex>
                  <v-text-field
                    v-model="search"
                    label="Search"
                    single-line
                    solo
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex shrink>
                  <v-btn
                    color="#FFCFCF"
                    fab
                    width="45"
                    height="45"
                    depressed
                    class="mr-n4"
                  >
                    <v-icon large color="#C71010">mdi-magnify</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>

            <v-spacer></v-spacer>

            <v-flex shrink mr-4>
              <v-btn
                height="50"
                style="border-radius: 30px"
                dark
                color="#55C5C8"
                depressed
                class="text-capitalize"
                @click="onAddQuestionnaire"
              >
                <v-icon>mdi-plus-circle-outline</v-icon>
                <span class="font-18"> Questionnaire</span>
              </v-btn>
            </v-flex>

            <v-flex shrink>
              <vue-excel-xlsx
                :data="questionnaires"
                :columns="columns"
                :file-name="excelName"
                :file-type="'xlsx'"
                :sheet-name="'sheetname'"
              >
                <v-btn
                  height="50"
                  style="border-radius: 30px"
                  dark
                  depressed
                  color="#44A04C"
                  class="text-capitalize"
                >
                  <v-icon>mdi-file-excel</v-icon>
                  <span class="font-18">Export to Excel</span>
                </v-btn>
              </vue-excel-xlsx>
            </v-flex>
          </v-layout>
        </div>

        <!-- Data Table *************************************************************************** -->
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          hide-default-header
        >
          <!-- Custom header -->
          <template v-slot:header="{ props }">
            <th
              v-for="(head, h) in props.headers"
              :key="h"
              :align="head.align"
              :width="head.width"
              style="border-bottom: 1px solid #e0e0e0"
              class="px-4 pb-1"
            >
              <div class="font-18 font-w-600 black--text">
                {{ head.text }}
              </div>
              <div class="font-11 font-w-500 black--text">
                {{ head.sub }}
              </div>
            </th>
          </template>

          <!-- Custom column -->
          <template v-slot:[`item.questionnaire.first_name`]="{ item }">
            <div v-if="item.questionnaire">
              {{ item.questionnaire.first_name }}
              {{ item.questionnaire.last_name }}
            </div>
            <div v-else>-</div>
          </template>
          <template v-slot:[`item.questionnaire.nickname`]="{ item }">
            <div v-if="item.questionnaire">
              {{ item.questionnaire.nickname }}
            </div>
            <div v-else>-</div>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ item.created_at | moment("DD/MM/YYYY") }}
          </template>
          <template v-slot:[`item.questionnaire.created_at`]="{ item }">
            <div v-if="item.questionnaire">
              {{ item.questionnaire.created_at | moment("DD/MM/YYYY") }}
            </div>
            <div v-else>-</div>
          </template>
          <template v-slot:[`item.customer`]="{ item }">
            <div class="d-flex justify-center">
              <v-btn
                v-if="item.customer_id && item.questionnaire"
                :to="'/customer/edit/' + item.customer_id"
                outlined
                dark
                fab
                color="#55C5C8"
                width="37"
                height="37"
                class="text-capitalize"
              >
                <v-icon color="#55C5C8">mdi-eye-outline</v-icon>
              </v-btn>
              <v-btn
                v-else
                dark
                color="#55C5C8"
                depressed
                height="32"
                style="border-radius: 30px"
                class="text-capitalize btn-view-questionnaire"
                @click="createCustomer(item)"
              >
                <span class="font-14 font-w-600">Create Customer</span>
              </v-btn>
            </div>
          </template>

          <!-- <template v-slot:[`item.print`]="{ item }">
            <v-btn
              color="#C71010"
              fab
              depressed
              width="35"
              height="35"
              @click="onPrint(item)"
              :disabled="!item.questionnaire"
            >
              <v-img
                contain
                src="../assets/images/pdf.png"
                width="16"
                height="20"
              ></v-img>
            </v-btn>
          </template> -->

          <template v-slot:[`item.action`]="{ item }">
            <v-layout wrap>
              <v-flex shrink py-1 mr-3>
                <v-btn
                  outlined
                  :dark="item.questionnaire ? true : false"
                  fab
                  color="#44A04C"
                  width="35"
                  height="35"
                  class="text-capitalize btn-view"
                  @click="onCopyLink(item.token)"
                >
                  <v-icon>mdi-link-variant</v-icon>
                </v-btn>
              </v-flex>
              <v-flex shrink py-1 mr-3>
                <v-btn
                  outlined
                  :dark="item.questionnaire ? true : false"
                  fab
                  color="#C71010"
                  width="35"
                  height="35"
                  class="text-capitalize btn-vieww"
                  :to="'/questionnaire/' + item.id"
                  :disabled="!item.questionnaire"
                >
                  <v-icon>mdi-eye-outline</v-icon>
                </v-btn>
              </v-flex>
              <v-flex shrink py-1>
                <v-btn
                  @click="onDelete(item)"
                  outlined
                  fab
                  dark
                  color="#A3A3A3"
                  width="35"
                  height="35"
                  class="text-capitalize"
                >
                  <v-icon color="#A3A3A3">mdi-delete-outline</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </template>
        </v-data-table>
      </v-card>
    </div>

    <!-- modal create questionnaire link *************************************************************************** -->
    <questionnaire-form-modal
      :dialog="dialog"
      @close="dialog = false"
      @fetch:data="fetchApi()"
    />

    <Loading color="#C71010" :active.sync="isLoading" :is-full-page="true" />
  </div>
</template>

<script>
import GroupTitle from "@/components/GroupTitle.vue";
import SERVICE from "../services/index";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import BoxCard from "@/components/BoxCard.vue";
import { url_questionnaire } from "../services/Constants";
import QuestionnaireFormModal from "@/components/QuestionnaireFormModal.vue";

export default {
  name: "QuestionnaireList",
  components: {
    GroupTitle,
    Loading,
    QuestionnaireFormModal,
  },
  data() {
    return {
      valid: true,
      dialog: false,
      isLoading: false,
      menu_date_from: false,
      menu_date_to: false,
      hover_date: "Select Date",
      dateTo: "",
      dateFrom: "",
      search: "",
      filter: {
        date_from: "",
        date_to: "",
      },

      //   table
      headers: [
        {
          text: "ลำดับที่",
          sub: "No.",
          value: "no",
          align: "start",
          sortable: false,
          width: "5%",
        },
        {
          text: "ชื่อ",
          sub: "Name",
          value: "questionnaire.first_name",
          align: "start",
          width: "10%",
          sortable: false,
        },
        {
          text: "ชื่อเล่น",
          sub: "Nickname",
          value: "questionnaire.nickname",
          align: "start",
          width: "5%",
          sortable: false,
        },
        {
          text: "เบอร์โทร",
          sub: "Tel",
          value: "tel",
          align: "start",
          width: "10%",
          sortable: false,
        },
        {
          text: "วันที่สร้าง",
          sub: "Create Date",
          value: "created_at",
          align: "start",
          width: "10%",
          sortable: false,
        },
        {
          text: "วันที่ส่ง",
          sub: "Complete Date",
          value: "questionnaire.created_at",
          align: "start",
          width: "10%",
          sortable: false,
        },
        {
          text: "รหัสผ่าน",
          sub: "Password",
          value: "password",
          align: "start",
          width: "10%",
          sortable: false,
        },
        {
          text: "ลูกค้า",
          sub: "Customer",
          value: "customer",
          align: "center",
          width: "10%",
          sortable: false,
        },
        {
          text: "ตัวเลือกการทำงาน",
          sub: "Action",
          value: "action",
          align: "start",
          width: "15%",
          sortable: false,
        },
      ],
      items: [],

      form: {
        type: "visitor",
        tel: "",
        password: "",
        token: "",
      },
      link: "",
      showPassword: false,
      alert: false,
      telRules: [
        (v) => !!v || "กรุณากรอกเบอร์โทร",
        (v) => (v && v.length == 10) || "เบอร์โทรต้องมี 10 หลัก",
      ],
      passwordRules: [
        (v) => !!v || "กรุณากรอกรหัสผ่าน",
        (v) => (v && v.length >= 6) || "รหัสผ่านต้องมี 6 หลัก",
      ],

      //   Export
      excelName: `questionnaires_${moment(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      ).format("YYYYMMDD")}`,
      questionnaires: [],
      columns: [
        { label: "ชื่อ", field: "first_name" },
        { label: "นามสกุล", field: "last_name" },
        { label: "เบอร์โทร", field: "tel" },
        { label: "Create Date", field: "create_date" },
        { label: "Complete Date", field: "complete_date" },
        { label: "Link", field: "link" },
        { label: "Password", field: "password" },
        { label: "Nickname", field: "nickname" },
        { label: "Birthdate", field: "birthdate" },
        { label: "Tel", field: "tel" },
        { label: "Gender", field: "gender" },
        { label: "Postcode", field: "postcode" },
        { label: "Province", field: "province_name" },
        { label: "District", field: "district_name" },
        { label: "District", field: "district_name" },

        {
          label: "สาเหตุที่คิดจะตรวจอายุร่างกาย",
          field: "question_5_select_name",
        },
        {
          label: "สาเหตุที่คิดจะตรวจอายุร่างกายอื่นๆ",
          field: "question_5_other",
        },

        {
          label: "คุณมักจะออกกำลังกาย หรือไม่",
          field: "question_12_often_exercise",
        },
        {
          label: "ทำประจำประมาณกี่วัน/สัปดาห์",
          field: "question_12_often_exercise_week",
        },
        {
          label: "นานๆครั้ง ประมาณกี่วัน/เดือน",
          field: "question_12_often_exercise_month",
        },

        { label: "คุณสูบบุหรี่ หรือไม่", field: "question_13_smoke" },
        { label: "คุณสูบบุหรี่กี่มวน/วัน", field: "question_13_smoke_day" },
        {
          label: "เคยสูบ แต่ตอนนี้เลิกแล้ว เมื่อกี่ปี",
          field: "question_13_smoke_year",
        },

        {
          label: "คุณดื่มเครื่องดื่มผสม แอลกอฮอล์หรือไม่",
          field: "question_14_alcohol",
        },
        {
          label: "ดื่มประมาณกี่วัน/สัปดาห์",
          field: "question_14_alcohol_week",
        },
        {
          label: "นานๆครั้ง ประมาณกี่วัน/เดือน",
          field: "question_14_alcohol_month",
        },

        {
          label: "คุณรู้จัก Powerpod จากช่องทางใด",
          field: "question_15_know_powerpod",
        },
        {
          label: "รู้จัก Powerpod จากสมาชิก",
          field: "question_15_member_recommend",
        },
        {
          label: "รู้จัก Powerpod จากช่องทางอื่นๆ",
          field: "question_15_other",
        },
      ],
    };
  },
  created() {
    this.fetchApi();
  },
  methods: {
    async fetchApi() {
      this.isLoading = true;
      const res = await SERVICE.questionnaire.index(this.filter);
      if (res.status === 200) {
        this.items = res.data;
        // this.questionnaires = res.data;
        this.setDateExcel(res.data);
        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
    },

    setDateExcel(items) {
      items.forEach((e) => {
        this.questionnaires.push({
          // data link
          tel: e.tel,
          type: e.type,
          link: `${url_questionnaire}?token=${e.token}`,
          password: e.password,
          create_date: e.created_at
            ? moment(e.created_at).format("DD/MM/YYYY")
            : "",
          complete_date: e.questionnaire
            ? moment(e.questionnaire.created_at).format("DD/MM/YYYY")
            : "",

          // data questionnaire
          first_name: e.questionnaire ? e.questionnaire.first_name : "",
          last_name: e.questionnaire ? e.questionnaire.last_name : "",
          nickname: e.questionnaire ? e.questionnaire.nickname : "",
          birthdate: e.questionnaire
            ? moment(e.questionnaire.birthdate).format("DD/MM/YYYY")
            : "",
          //   body_problem_concerns: e.questionnaire.body_problem_concerns,

          gender: e.questionnaire ? e.questionnaire.gender : "",

          postcode: e.questionnaire ? e.questionnaire.postcode : "",
          province_name: e.questionnaire ? e.questionnaire.province_name : "",
          district_name: e.questionnaire ? e.questionnaire.district_name : "",

          customer_id: e.questionnaire ? e.questionnaire.customer_id : "",

          question_5_other: e.questionnaire
            ? e.questionnaire.question_5_other
            : "",
          question_5_select_name: e.questionnaire
            ? JSON.stringify(e.questionnaire.question_5_select_name)
            : "",

          question_12_often_exercise: e.questionnaire
            ? e.questionnaire.question_12_often_exercise
            : "",
          question_12_often_exercise_month: e.questionnaire
            ? e.questionnaire.question_12_often_exercise_month
            : "",
          question_12_often_exercise_week: e.questionnaire
            ? e.questionnaire.question_12_often_exercise_week
            : "",

          question_13_smoke: e.questionnaire
            ? e.questionnaire.question_13_smoke
            : "",
          question_13_smoke_day: e.questionnaire
            ? e.questionnaire.question_13_smoke_day
            : "",
          question_13_smoke_year: e.questionnaire
            ? e.questionnaire.question_13_smoke_year
            : "",

          question_14_alcohol: e.questionnaire
            ? e.questionnaire.question_14_alcohol
            : "",
          question_14_alcohol_month: e.questionnaire
            ? e.questionnaire.question_14_alcohol_month
            : "",
          question_14_alcohol_week: e.questionnaire
            ? e.questionnaire.question_14_alcohol_week
            : "",

          question_15_know_powerpod: e.questionnaire
            ? JSON.stringify(e.questionnaire.question_15_know_powerpod)
            : "",
          question_15_member_recommend: e.questionnaire
            ? e.questionnaire.question_15_member_recommend
            : "",
          question_15_other: e.questionnaire
            ? e.questionnaire.question_15_other
            : "",
        });
      });
    },
    onFilter() {
      if (this.filter.date_from && this.filter.date_to) {
        // todo something
        this.fetchApi();
      } else {
        this.$swal({
          title: "Waring!",
          text: "Please, Selected Date From and Date To !",
          icon: "warning",
          confirmButtonColor: "#C71010",
        });
      }
    },
    setFilter(key) {
      if (key === "date_from") {
        this.dateFrom = moment(this.filter.date_from).format("DD/MM/YYYY");
        this.dateTo = "";
        this.filter.date_to = "";
      } else if (key === "date_to") {
        this.dateTo = moment(this.filter.date_to).format("DD/MM/YYYY");
      }
    },
    clearFilter(type) {
      if (type === "date_from") {
        this.filter.date_from = "";
      } else if (type === "date_to") {
        this.filter.date_to = "";
      }
    },
    onAddQuestionnaire() {
      this.dialog = true;
    },
    generateToken(length) {
      //edit the token allowed characters
      var a =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split(
          ""
        );
      var b = [];
      for (var i = 0; i < length; i++) {
        var j = (Math.random() * (a.length - 1)).toFixed(0);
        b[i] = a[j];
      }
      return b.join("");
    },
    onPrint(item) {
      console.log("onPrint", item);
    },
    onDelete(item) {
      console.log("onDelete", item);
      this.$swal({
        title: "Are you sure?",
        text: "You want to delete this?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#C71010",
        cancelButtonColor: "#000",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.isLoading = true;
          SERVICE.questionnaire
            .delete({
              linkId: item.id,
              questionnaireId: item.questionnaire
                ? item.questionnaire.id
                : null,
            })
            .then((res) => {
              this.isLoading = false;
              console.log("res", res);
              setTimeout(() => {
                if (res.status === 200) {
                  this.$swal({
                    title: "Delete Success",
                    icon: "success",
                    timer: 1000,
                    showConfirmButton: false,
                  });
                } else {
                  this.$swal({
                    title: "Delete Fail",
                    icon: "error",
                    timer: 1000,
                    showConfirmButton: false,
                  });
                }
              }, 1000);
            })
            .then(() => {
              this.fetchApi();
            });
        }
      });
    },

    onCopyLink(token) {
      console.log("onCopyLink", token);
      if (token) {
        navigator.clipboard.writeText(url_questionnaire + "?token=" + token);

        this.$swal({
          title: "Copy Link Success",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    },
    createCustomer(item) {
      console.log("createCustomer", item);
      this.$router.push({
        path: "/customer/create",
        query: {
          first_name: item?.questionnaire?.first_name,
          family_name: item?.questionnaire?.last_name,
          gender: item?.questionnaire?.gender,
          phone: item.tel,
          link_questionnaire_id: item.id,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.questionnaire__table {
  .v-sheet.v-card:not(.v-sheet--outlined) {
    border: 1px solid;
    -webkit-box-shadow: 6px 6px 0px 1px #000000 !important;
    box-shadow: 6px 6px 0px 1px #000000 !important;
  }
  .v-sheet.v-card {
    border-radius: 30px !important;
  }
}

.questionnaire-form {
  .v-text-field fieldset,
  .v-text-field .v-input__control,
  .v-text-field .v-input__slot {
    border-radius: 35px;
  }
  .v-text-field--outlined fieldset {
    border-collapse: collapse;
    border-color: #000;
    border-style: solid;
    border-width: 1px !important;
  }
  .link-token {
    .v-text-field--outlined fieldset {
      border-color: #55c5c8 !important;
      border-style: solid;
      border-width: 1px !important;
    }
  }
  .txt-center {
    input {
      text-align: center !important;
      // font-size: 24px;
    }
    label {
      text-align: center !important;
      position: absolute;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
