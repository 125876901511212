<template>
  <div>
    <v-dialog :value="dialog" max-width="650px" persistent>
      <div class="pa-3">
        <box-card width="600">
          <v-layout align-center mb-2>
            <v-flex shrink mr-2>
              <v-img
                width="90"
                src="../assets/images/icon-create-questionnaire.png"
              ></v-img>
            </v-flex>
            <v-flex style="color: #55c5c8" class="font-40 font-w-600">
              Create Link
            </v-flex>
          </v-layout>

          <v-form
            ref="formQuestionnaire"
            v-model="valid"
            lazy-validation
            class="questionnaire-form"
          >
            <div class="txt-center">
              <div>เบอร์โทร</div>
              <v-text-field
                :readonly="item ? true : false"
                v-model="form.tel"
                :rules="telRules"
                label="เบอร์โทร"
                single-line
                outlined
                color="#000"
                :oninput="returnInputRule('number')"
              ></v-text-field>
            </div>

            <v-divider />

            <div class="text-center mt-4">
              <v-layout justify-center>
                <v-flex xs6>
                  <div class="txt-center">
                    <div class="mb-1">สร้างรหัสผ่าน (6 หลัก)</div>
                    <v-layout>
                      <v-flex>
                        <v-text-field
                          :readonly="item ? true : false"
                          v-model="form.password"
                          :rules="passwordRules"
                          label="รหัสผ่าน"
                          :type="showPassword ? 'text' : 'password'"
                          single-line
                          outlined
                          dense
                          color="#000"
                        ></v-text-field>
                      </v-flex>
                      <v-flex shrink pt-1 ml-2>
                        <v-btn
                          fab
                          depressed
                          width="35"
                          height="35"
                          @click="showPassword = !showPassword"
                          :color="!showPassword ? '#F3F3F3' : '#F3F3F3'"
                        >
                          <v-icon color="#55C5C8">
                            {{ !showPassword ? "mdi-eye" : "mdi-eye-off" }}
                          </v-icon>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </div>
                </v-flex>
              </v-layout>
            </div>

            <v-layout class="link-token mt-6" v-if="isSave">
              <v-flex xs8>
                <v-text-field
                  v-model="link"
                  single-line
                  outlined
                  dense
                  readonly
                  color="#55C5C8"
                ></v-text-field>
              </v-flex>
              <v-flex xs4 pl-3 pt-1>
                <v-btn
                  depressed
                  block
                  style="border-radius: 35px"
                  @click="copyLink"
                >
                  <span
                    style="color: #55c5c8"
                    class="text-capitalize font-20 font-w-600"
                  >
                    CopyLink
                  </span>
                </v-btn>
              </v-flex>
            </v-layout>
            <v-alert
              v-model="alert"
              dense
              text
              type="success"
              close-text="Close Alert"
              dismissible
            >
              Link has been copied to clipboard
            </v-alert>

            <v-layout class="mt-12 mb-4" justify-center>
              <v-flex shrink>
                <v-btn
                  depressed
                  block
                  width="150"
                  height="50"
                  style="border-radius: 35px"
                  color="#A3A3A3"
                  dark
                  @click="onCancelDialog()"
                >
                  <span class="text-capitalize font-24 font-w-600">
                    {{ item ? "ปิด" : "ยกเลิก" }}
                  </span>
                </v-btn>
              </v-flex>
              <v-spacer v-if="!item"></v-spacer>
              <v-flex shrink v-if="!item">
                <v-btn
                  v-if="isSave"
                  depressed
                  block
                  width="180"
                  height="50"
                  style="border-radius: 35px"
                  color="#55C5C8"
                  dark
                  @click="submit()"
                >
                  <span class="text-capitalize font-24 font-w-600"> Save </span>
                </v-btn>
                <v-btn
                  v-else
                  depressed
                  block
                  width="180"
                  height="50"
                  style="border-radius: 35px"
                  color="#55C5C8"
                  dark
                  @click="onCreate()"
                >
                  <span class="text-capitalize font-24 font-w-600">
                    Create
                  </span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-form>
        </box-card>
      </div>
    </v-dialog>
    <Loading color="#C71010" :active.sync="isLoading" :is-full-page="true" />
  </div>
</template>

<script>
import { url_questionnaire } from "../services/Constants";
import SERVICE from "../services/index";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import BoxCard from "./BoxCard.vue";

export default {
  props: {
    item: null,
    dialog: {
      type: Boolean,
      default: false,
    },
    customerId: null,
    tel: null,
  },
  components: {
    Loading,
    BoxCard,
  },
  data() {
    return {
      data: this.item,
      isSave: false,
      isLoading: false,
      valid: true,
      showPassword: false,
      alert: false,
      link: "",
      form: {
        customer_id: null,
        tel: "",
        password: "",
      },
      telRules: [
        (v) => !!v || "กรุณากรอกเบอร์โทร",
        (v) => (v && v.length === 10) || "กรุณากรอกเบอร์โทรให้ครบ 10 หลัก",
      ],
      passwordRules: [
        (v) => !!v || "กรุณากรอกรหัสผ่าน",
        (v) => (v && v.length === 6) || "กรุณากรอกรหัสผ่านให้ครบ 6 หลัก",
      ],
    };
  },
  watch: {
    dialog: function (val) {
      if (val) {
        this.form.customer_id = this.customerId;
        this.form.tel = this.tel;
      }
    },
    item: function (val) {
      if (val) {
        this.form = {
          customer_id: val.customer_id,
          tel: val.tel,
          password: val.password,
        };
        this.isSave = true;
        this.link = url_questionnaire + "?token=" + val.token;
      }
      // force update
      this.$forceUpdate();
    },
  },
  methods: {
    generateToken(length) {
      var a =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split(
          ""
        );
      var b = [];
      for (var i = 0; i < length; i++) {
        var j = (Math.random() * (a.length - 1)).toFixed(0);
        b[i] = a[j];
      }
      return b.join("");
    },
    onCancelDialog() {
      this.$refs.formQuestionnaire.reset();
      this.alert = false;
      this.link = null;
      this.isSave = false;
      this.$emit("close");
    },
    onCreate() {
      if (this.$refs.formQuestionnaire.validate()) {
        this.form.token = this.generateToken(32);
        this.link = url_questionnaire + "?token=" + this.form.token;
        this.isSave = true;
      }
    },
    submit() {
      if (this.$refs.formQuestionnaire.validate()) {
        console.log(this.form);
        this.isLoading = true;
        SERVICE.questionnaire.createLink(this.form).then((res) => {
          if (res.status === 200) {
            this.isLoading = false;
            this.$swal({
              title: "Success!",
              text: "Create Link Success",
              icon: "success",
              confirmButtonColor: "#C71010",
            }).then(() => {
              this.isSave = false;
              this.$emit("close");
              this.$emit("fetch:data");
            });
          } else {
            this.isLoading = false;
            this.$swal({
              title: "Save failed",
              text: res.message,
              icon: "error",
              confirmButtonColor: "#C71010",
            });
          }
        });
      }
    },
    closeModal() {
      this.$emit("close");
    },
    returnInputRule(inputType) {
      switch (inputType) {
        case "number":
          return "value=value.replace(/[^\\d]/g,'')";

        case "decimal":
          return "value=value.replace(/[^\\d.]/g,'')";

        default:
          return null;
      }
    },
    onCopyLink(token) {
      console.log("onCopyLink", token);
      if (token) {
        navigator.clipboard.writeText(url_questionnaire + "?token=" + token);

        this.$swal({
          title: "Copy Link Success",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    },
    copyLink() {
      navigator.clipboard.writeText(this.link);
      this.alert = true;
    },
  },
};
</script>

<style></style>
