import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./assets/scss/variables.scss";
import "./assets/scss/custom.scss";
import "./assets/css/font.css";
import "./assets/css/main.css";
import axios from "axios";
import VueAxios from "vue-axios";
import VueExcelXlsx from "vue-excel-xlsx";
import VueMeta from "vue-meta";
// import VueHtml2Canvas from "vue-html2canvas";

import VueSweetalert2 from "vue-sweetalert2";
// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

// eslint-disable-next-line vue/multi-word-component-names
Vue.component("apexchart", VueApexCharts);

// //VueQuillEditor
// import VueQuillEditor from "vue-quill-editor";
// // require styles
// import "quill/dist/quill.core.css";
// import "quill/dist/quill.snow.css";
// import "quill/dist/quill.bubble.css";
// Vue.use(VueQuillEditor /* { default global options } */);

Vue.use(VueSweetalert2);
Vue.config.productionTip = false;
Vue.use(require("vue-moment"));
Vue.use(VueAxios, axios);
Vue.use(VueExcelXlsx);
Vue.use(VueMeta);
// Vue.use(VueHtml2Canvas);

Vue.mixin({
  methods: {},
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
