import AuthService from "../AuthService";

const questionnaire = {
  index(payload) {
    return AuthService.get(
      `/api/questionnaire/index?date_from=${payload.date_from}&date_to=${payload.date_to}`
    ).then((res) => res);
  },
  show(id) {
    return AuthService.get(`/api/questionnaire/show/${id}`).then((res) => res);
  },
  delete(payload) {
    return AuthService.post(`/api/questionnaire/delete`, payload).then(
      (res) => res
    );
  },
  createLink(payload) {
    return AuthService.post(`/api/questionnaire/create-link`, payload).then(
      (res) => res
    );
  },
};

export default questionnaire;
