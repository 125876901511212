import AuthService from "../AuthService";
import Service from "../Service";

const auth = {
  signin(data) {
    return Service.post("/api/signin", data).then((res) => res);
  },
  signout() {
    return AuthService.post(`api/sign-out`).then((res) => res);
  },
  changePassword(data) {
    return AuthService.post(`api/change-password`, data).then(
      (res) => res
    );
  },
};

export default auth;
