<template>
  <div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :filename="filename"
      :paginate-elements-by-height="1500"
      :pdf-quality="2"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="790px"
      :manual-pagination="false"
      ref="html2Pdf"
    >
      <section slot="pdf-content" v-if="form">
        <!-- information -->
        <div v-if="form" style="height: 1120px; position: relative">
          <div class="page_number">1/{{ pageTotal }}</div>
          <div class="pdf-content">
            <div class="box-header">
              <div class="shadow_border"></div>
              <div class="card-box-header">
                <div class="font-24">ส่งแบบสอบถาม</div>
                <div class="font-36 font-w-600">เรียบร้อย!</div>
              </div>
            </div>

            <div class="section_card">
              <div class="shadow_border"></div>

              <div class="main_card">
                <!-- info -->
                <div style="padding: 1.5rem 4rem">
                  <v-layout justify-space-between>
                    <v-flex xs9>
                      <v-layout wrap>
                        <v-flex xs4 class="mb7">
                          <div class="font-20 font-w-600">1.ชื่อ</div>
                        </v-flex>
                        <v-flex xs8 class="mb7">
                          <div class="font-20 font-w-400">
                            {{ form.first_name }}
                          </div>
                        </v-flex>

                        <v-flex xs4 class="mb7">
                          <div class="font-20 font-w-600">นามสกุล</div>
                        </v-flex>
                        <v-flex xs8 class="mb7">
                          <div class="font-20 font-w-400">
                            {{ form.last_name }}
                          </div>
                        </v-flex>

                        <v-flex xs4 class="mb7">
                          <div class="font-20 font-w-600">ชื่อเล่น</div>
                        </v-flex>
                        <v-flex xs8 class="mb7">
                          <div class="font-20 font-w-400">
                            {{ form.nickname }}
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs3 style="padding-left: 2rem">
                      <img
                        class="image"
                        src="../assets/images/step1-info.png"
                        width="60"
                      />
                    </v-flex>
                  </v-layout>
                </div>

                <v-divider />

                <!-- วันเกิด -->
                <div style="padding: 1.5rem 4rem">
                  <v-layout>
                    <v-flex xs9>
                      <div class="font-20 font-w-600 mb7">2.วันเกิด</div>
                      <div class="font-20 font-w-400">
                        {{ formatDate(form.birthdate) }}
                      </div>
                    </v-flex>
                    <v-flex xs3 style="padding-left: 2rem">
                      <img
                        src="../assets/images/step2-birthdate.png"
                        width="60"
                      />
                    </v-flex>
                  </v-layout>
                </div>

                <v-divider />

                <!-- เพศ -->
                <div style="padding: 1.5rem 4rem">
                  <v-layout>
                    <v-flex xs9>
                      <div class="font-20 font-w-600 mb7">3.เพศ</div>
                      <div class="font-20 font-w-400">
                        {{ formatGender(form.gender) }}
                      </div>
                    </v-flex>
                    <v-flex xs3 style="padding-left: 2rem">
                      <Icon
                        v-if="form.gender == 'F'"
                        style="font-size: 3rem"
                        icon="ph:gender-female-bold"
                      />
                      <Icon
                        v-else
                        style="font-size: 3rem"
                        icon="ph:gender-male-bold"
                      />
                    </v-flex>
                  </v-layout>
                </div>
                <v-divider />

                <!-- พื้นที่อยู่อาศัย -->
                <div style="padding: 1.5rem 4rem">
                  <v-layout>
                    <v-flex xs9>
                      <div class="font-20 font-w-600 mb7">
                        4.พื้นที่อยู่อาศัย
                      </div>
                      <div class="font-20 font-w-400">
                        {{ form.postcode }} {{ form.province_name }},
                        {{ form.district_name }}
                      </div>
                    </v-flex>
                    <v-flex xs3 style="padding-left: 2rem">
                      <img src="../assets/images/step4-home.png" width="60" />
                    </v-flex>
                  </v-layout>
                </div>
                <v-divider />

                <!-- สาเหตุที่คิดจะ "ตรวจอายุ ร่างกาย" ในครั้งนี้คืออะไร  -->
                <div
                  style="padding: 1.5rem 4rem; position: relative; z-index: 999"
                >
                  <v-layout justify-center>
                    <v-flex xs9>
                      <div class="font-20 font-w-600 mb7">
                        5. สาเหตุที่คิดจะ "ตรวจอายุ ร่างกาย" ในครั้งนี้คืออะไร?
                      </div>
                      <ul>
                        <li
                          v-for="(item, i) in form.question_5_select_name"
                          :key="i"
                          class="font-20 font-w-400"
                        >
                          {{ item }}
                        </li>
                      </ul>
                    </v-flex>
                    <v-flex xs3 style="padding-left: 2rem">
                      <img src="../assets/images/step5.png" width="60" />
                    </v-flex>
                  </v-layout>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- สาเหตุที่คิดจะ "ตรวจอายุ ร่างกาย" ในครั้งนี้คืออะไร? -->
        <div v-if="form">
          <div
            v-for="(item, i) in form.body_problem_concerns"
            :key="i"
            style="height: 1120px; position: relative"
          >
            <div class="page_number">
              {{ pageTotal + i + 2 - pageTotal }}/{{ pageTotal }}
            </div>
            <div class="pdf-content">
              <div class="section_card">
                <div class="shadow_border"></div>

                <div class="main_card">
                  <!-- ปัญหาร่างกาย/ ความกังวล"ที่ต้องการ แก้ไขคืออะไรบ้าง? -->
                  <div style="padding: 15px 4rem">
                    <v-layout>
                      <v-flex xs9>
                        <div class="font-20 font-w-600">
                          6.ปัญหาร่างกาย/ ความกังวล"ที่ต้องการ แก้ไขคืออะไรบ้าง?
                        </div>

                        <!-- หัวข้อปัญหา -->
                        <div style="margin: 1rem 0px">
                          <v-btn
                            color="#C71010"
                            style="border-radius: 35px"
                            height="40px"
                            depressed
                            dark
                          >
                            <div class="font-20 font-w-400">
                              {{ item.question_6_problem_name }}
                              {{
                                item.question_6_problem_name == "อื่นๆ"
                                  ? `(${item.question_6_problem_other})`
                                  : ""
                              }}
                            </div>
                          </v-btn>
                        </div>

                        <!-- ถ้าเลือกปัญหาข้อ 1-4 ให้แสดง problem position (ตำแหน่งที่มีอาการ/ปัญหาคือบริเวณใด?)  -->
                        <v-layout>
                          <v-flex xs10>
                            <div class="font-20 font-w-600 mb7">
                              7.ตำแหน่งที่มีอาการ /ปัญหาคือบริเวณใด?
                            </div>
                            <ul>
                              <li
                                v-for="(
                                  text, d
                                ) in item.body_problem_concern_position"
                                :key="d"
                                class="font-20 font-w-400"
                              >
                                {{ text.question_7_position }}
                              </li>
                            </ul>
                          </v-flex>
                          <v-flex xs2 style="position: relative">
                            <div class="body_model">
                              <img
                                style="padding-left: 2rem"
                                src="../assets/images/report-pdf/body.png"
                                widht="152.45px"
                                height="143.94px"
                                contain
                              />

                              <div
                                class="part_position"
                                style="padding-left: 2rem"
                                v-for="(body_parts, i) in body_parts"
                                :key="i"
                              >
                                <img
                                  v-if="
                                    checkShowImage(
                                      item.body_problem_concern_position,
                                      body_parts.name
                                    )
                                  "
                                  :src="
                                    require(`../assets/images/report-pdf/body-position/${body_parts.image}.png`)
                                  "
                                  widht="152.45px"
                                  height="143.94px"
                                  alt=""
                                />
                              </div>
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs3 style="padding-left: 2rem">
                        <img
                          src="../assets/images/step6-problem.png"
                          width="60"
                        />
                      </v-flex>
                    </v-layout>
                  </div>
                  <v-divider />

                  <!-- คุณพอจะทราบสาเหตุที่ทำให้เกิดอาการ/ปัญหาออกหรือไม่? -->
                  <div style="padding: 15px 4rem">
                    <div class="font-20 font-w-600 mb7">
                      8.คุณพอจะทราบสาเหตุที่ทำให้เกิดอาการ /ปัญหาออกหรือไม่?
                    </div>
                    <div class="font-20 font-w-400">
                      {{
                        item.body_problem_concern_questionnaire
                          ? item.body_problem_concern_questionnaire
                              .question_8_is_know
                          : ""
                      }}
                    </div>
                    <div
                      class="font-20 font-w-400"
                      v-if="
                        item.body_problem_concern_questionnaire &&
                        item.body_problem_concern_questionnaire
                          .question_8_is_know === 'ทราบ'
                      "
                    >
                      {{
                        item.body_problem_concern_questionnaire
                          .question_8_case_question
                      }}
                    </div>
                  </div>
                  <v-divider />

                  <!-- คุณเริ่มรู้สึกถึงอาการ/ปัญหา ตั้งแต่เมื่อใด? -->
                  <div style="padding: 15px 4rem">
                    <v-layout justify-center>
                      <v-flex xs9>
                        <div class="font-20 font-w-600 mb7">
                          9.คุณเริ่มรู้สึกถึงอาการ /ปัญหา ตั้งแต่เมื่อใด?
                        </div>
                        <div class="font-20 font-w-400">
                          {{
                            item.body_problem_concern_questionnaire
                              ? checkSymptom(
                                  item.body_problem_concern_questionnaire
                                )
                              : ""
                          }}
                        </div>
                      </v-flex>
                      <v-flex xs3 style="padding-left: 2rem">
                        <img src="../assets/images/calendar.png" width="60" />
                      </v-flex>
                    </v-layout>
                  </div>
                  <v-divider />

                  <!-- อาการ/ปัญหาเกิดขึ้นในเวลาใด? -->
                  <div style="padding: 15px 4rem">
                    <div class="font-20 font-w-600 mb7">
                      10.อาการ/ปัญหาเกิดขึ้นในเวลาใด?
                    </div>
                    <ul
                      class="font-18 font-w-400"
                      v-if="item.body_problem_concern_questionnaire"
                    >
                      <li>
                        ตอนที่ตื่นนอน ({{
                          item.body_problem_concern_questionnaire
                            .question_10_wakeup
                        }})
                      </li>
                      <li>
                        ตอนที่ยืนอยู่({{
                          item.body_problem_concern_questionnaire
                            .question_10_standing
                        }})
                      </li>
                      <li>
                        ตอนที่เดินอยู่({{
                          item.body_problem_concern_questionnaire
                            .question_10_walking
                        }})
                      </li>
                      <li>
                        ตอนที่นั่งอยู่({{
                          item.body_problem_concern_questionnaire
                            .question_10_sitting
                        }})
                      </li>
                      <li>
                        ตอนที่เคลื่อนไหวบางอิริยาบท({{
                          item.body_problem_concern_questionnaire
                            .question_10_moving
                        }})
                      </li>
                      <li>
                        ตอนที่นอนอยู่({{
                          item.body_problem_concern_questionnaire
                            .question_10_sleeping
                        }})
                      </li>
                    </ul>
                  </div>
                  <v-divider />

                  <!-- ที่ผ่านมาคุณทำอะไรไปบ้าง เพื่อแก้ไขอาการ/ปัญหา?  -->
                  <div style="padding: 15px 4rem">
                    <div class="font-20 font-w-600 mb7">
                      11.สาเหตุที่คิดจะ "ตรวจอายุ ร่างกาย" ในครั้งนี้คืออะไร?
                    </div>
                    <ul
                      class="font-18 font-w-400"
                      v-if="item.body_problem_concern_questionnaire"
                    >
                      <li>
                        ตรวจที่โรงพยาบาล/คลินิก({{
                          item.body_problem_concern_questionnaire
                            .question_11_hospital
                        }})
                      </li>
                      <li>
                        นวด({{
                          item.body_problem_concern_questionnaire
                            .question_11_massage
                        }})
                      </li>
                      <li>
                        เข้าคลาสโยคะ/พิลาทิส({{
                          item.body_problem_concern_questionnaire
                            .question_11_yoga
                        }})
                      </li>
                      <li>
                        ไปฟิตเนส({{
                          item.body_problem_concern_questionnaire
                            .question_11_fitness
                        }})
                      </li>
                      <li>
                        ออกกำลังกายด้วยตัวเอง({{
                          item.body_problem_concern_questionnaire
                            .question_11_exercise
                        }})
                      </li>
                      <li
                        v-if="
                          item.body_problem_concern_questionnaire
                            .question_11_exercise_other
                        "
                      >
                        อื่นๆ({{
                          item.body_problem_concern_questionnaire
                            .question_11_exercise_other
                        }})
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="form">
          <div class="pdf-content">
            <!-- card 3 -->
            <div class="section_card">
              <div class="shadow_border"></div>

              <div class="main_card">
                <!-- คุณมักจะออกกำลังกายหรือไม่?  -->
                <div style="padding: 15px 4rem">
                  <v-layout>
                    <v-flex xs9>
                      <div class="font-20 font-w-600 mb7">
                        12.คุณมักจะออกกำลังกายหรือไม่?
                      </div>
                      <div class="font-20">
                        {{ form ? form.question_12_often_exercise : "" }}
                      </div>
                      <div
                        class="font-20"
                        v-if="
                          form.question_12_often_exercise !==
                          'ไม่ได้ออกกำลังกาย'
                        "
                      >
                        {{ checkExercise(form) }}
                      </div>
                    </v-flex>
                    <v-flex xs3 style="padding-left: 2rem">
                      <img src="../assets/images/step-12.png" width="60" />
                    </v-flex>
                  </v-layout>
                </div>
                <v-divider />

                <!-- คุณสูบบุหรี่หรือไม่??  -->
                <div style="padding: 15px 4rem">
                  <v-layout>
                    <v-flex xs9>
                      <div class="font-20 font-w-600">
                        13.คุณสูบบุหรี่หรือไม่??
                      </div>
                      <div class="font-20">
                        {{ form.question_13_smoke }}
                      </div>
                      <div
                        class="font-20"
                        v-if="form.question_13_smoke !== 'ไม่สูบ'"
                      >
                        {{ checkSmoke(form) }}
                      </div>
                    </v-flex>
                    <v-flex xs3 style="padding-left: 2rem">
                      <img src="../assets/images/step-13.png" width="60" />
                    </v-flex>
                  </v-layout>
                </div>
                <v-divider />

                <!-- คุณดื่มเครื่องดื่มผสมแอลกอฮอล์หรือไม่?  -->
                <div style="padding: 15px 4rem">
                  <v-layout>
                    <v-flex xs9>
                      <div class="font-20 font-w-600 mb7">
                        14.คุณดื่มเครื่องดื่มผสมแอลกอฮอล์หรือไม่?
                      </div>
                      <div class="font-20">
                        {{ form ? form.question_14_alcohol : "" }}
                      </div>
                      <div
                        class="font-20"
                        v-if="form.question_14_alcohol !== 'ไม่ดื่ม'"
                      >
                        {{ checkDrink(form) }}
                      </div>
                    </v-flex>
                    <v-flex xs3 style="padding-left: 2rem">
                      <img src="../assets/images/step-14.png" width="60" />
                    </v-flex>
                  </v-layout>
                </div>
                <v-divider />

                <!-- คุณรู้จัก Powerpod จากช่องทางใด?  -->
                <div style="padding: 15px 4rem">
                  <div class="font-20 font-w-600 mb7">
                    15.คุณรู้จัก Powerpod จากช่องทางใด?
                  </div>
                  <div
                    class="font-20"
                    v-for="(
                      question_15_know_powerpod, q
                    ) in form.question_15_know_powerpod"
                    :key="q"
                  >
                    {{ question_15_know_powerpod }}
                    <div
                      v-if="
                        question_15_know_powerpod == 'สมาชิก Powerpod แนะนำ'
                      "
                    >
                      {{ form.question_15_member_recommend }}
                    </div>
                    <div v-if="question_15_know_powerpod == 'อื่นๆ'">
                      {{ form.question_15_other }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </vue-html2pdf>

    <v-layout justify-space-between align-center my-6>
      <v-flex shrink>
        <v-btn icon @click="$router.push('/questionnaire')">
          <v-icon large> mdi-arrow-left </v-icon>
        </v-btn>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex shrink>
        <v-btn
          dark
          depressed
          color="#C71010"
          style="border-radius: 35px"
          @click="downloadPdf()"
        >
          <v-img src="../assets/images/pdf.png" width="22" height="28"></v-img>
          <span class="text-capitalize font-w-600 font-600 ml-3">
            Export to PDF
          </span>
        </v-btn>
      </v-flex>
    </v-layout>

    <v-container>
      <dynamic-box-card backgroundColor="#55C5C8" class="mb-6 pa-1">
        <div class="text-center" style="color: #fff">
          <div class="font-24">ส่งแบบสอบถาม</div>
          <div class="font-36 font-w-600">เรียบร้อย!</div>
        </div>
      </dynamic-box-card>

      <!-- card info -->
      <dynamic-box-card class="mb-5 py-4">
        <!-- info -->
        <v-layout justify-center>
          <v-flex xs8>
            <div class="py-3 px-4">
              <v-layout justify-space-between>
                <v-flex xs8>
                  <v-layout wrap>
                    <v-flex xs4 class="py-2">
                      <div class="font-20 font-w-600">1.ชื่อ</div>
                    </v-flex>
                    <v-flex xs8 class="py-2">
                      <div class="font-20 font-w-400">
                        {{ form.first_name }}
                      </div>
                    </v-flex>

                    <v-flex xs4 class="py-2">
                      <div class="font-20 font-w-600">นามสกุล</div>
                    </v-flex>
                    <v-flex xs8 class="py-2">
                      <div class="font-20 font-w-400">
                        {{ form.last_name }}
                      </div>
                    </v-flex>

                    <v-flex xs4 class="py-2">
                      <div class="font-20 font-w-600">ชื่อเล่น</div>
                    </v-flex>
                    <v-flex xs8 class="py-2">
                      <div class="font-20 font-w-400">
                        {{ form.nickname }}
                      </div>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex shrink>
                  <v-img
                    src="../assets/images/step1-info.png"
                    width="60"
                    contain
                  ></v-img>
                </v-flex>
              </v-layout>
            </div>
          </v-flex>
        </v-layout>
        <v-divider />

        <!-- วันเกิด -->
        <v-layout justify-center>
          <v-flex xs8>
            <div class="py-3 px-4">
              <v-layout wrap justify-space-between align-center>
                <v-flex shrink class="py-2">
                  <div class="font-20 font-w-600">2.วันเกิด</div>
                  <div class="font-20 font-w-400">
                    {{ formatDate(form.birthdate) }}
                  </div>
                </v-flex>
                <v-flex shrink class="py-2">
                  <v-img
                    src="../assets/images/step2-birthdate.png"
                    width="60"
                    contain
                  ></v-img>
                </v-flex>
              </v-layout>
            </div>
          </v-flex>
        </v-layout>
        <v-divider />

        <!-- เพศ -->
        <v-layout justify-center>
          <v-flex xs8>
            <div class="py-3 px-4">
              <v-layout wrap justify-space-between align-center>
                <v-flex shrink class="py-2">
                  <div class="font-20 font-w-600">3.เพศ</div>
                  <div class="font-20 font-w-400">
                    {{ formatGender(form.gender) }}
                  </div>
                </v-flex>
                <v-flex shrink class="py-2">
                  <Icon
                    v-if="form.gender == 'F'"
                    style="font-size: 3rem"
                    icon="ph:gender-female-bold"
                  />
                  <Icon
                    v-else
                    style="font-size: 3rem"
                    icon="ph:gender-male-bold"
                  />
                </v-flex>
              </v-layout>
            </div>
          </v-flex>
        </v-layout>
        <v-divider />

        <!-- พื้นที่อยู่อาศัย -->
        <v-layout justify-center>
          <v-flex xs8>
            <div class="py-3 px-4">
              <v-layout justify-space-between align-center>
                <v-flex shrink class="py-2">
                  <div class="font-20 font-w-600">4.พื้นที่อยู่อาศัย</div>
                  <div class="font-20 font-w-400">
                    {{ form.postcode }} {{ form.province_name }},
                    {{ form.district_name }}
                  </div>
                </v-flex>
                <v-flex shrink class="py-2">
                  <v-img
                    src="../assets/images/step4-home.png"
                    width="60"
                    contain
                  ></v-img>
                </v-flex>
              </v-layout>
            </div>
          </v-flex>
        </v-layout>
        <v-divider />

        <!-- สาเหตุที่คิดจะ "ตรวจอายุ ร่างกาย" ในครั้งนี้คืออะไร  -->
        <v-layout justify-center>
          <v-flex xs8>
            <div class="py-3 px-4">
              <v-layout justify-space-between align-center>
                <v-flex shrink class="py-2">
                  <div class="font-20 font-w-600">
                    5.สาเหตุที่คิดจะ "ตรวจอายุ ร่างกาย" ในครั้งนี้คืออะไร?
                  </div>
                </v-flex>
                <v-flex shrink class="py-2">
                  <v-img
                    src="../assets/images/step5.png"
                    width="60"
                    contain
                  ></v-img>
                </v-flex>
              </v-layout>
              <ul>
                <li
                  v-for="(item, i) in form.question_5_select_name"
                  :key="i"
                  class="font-20 font-w-400"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
          </v-flex>
        </v-layout>
      </dynamic-box-card>

      <!-- card 2 -->
      <dynamic-box-card
        class="mb-5 py-4"
        v-for="(item, i) in form.body_problem_concerns"
        :key="i"
      >
        <!-- ปัญหาร่างกาย/ ความกังวล"ที่ต้องการ แก้ไขคืออะไรบ้าง? -->
        <v-layout justify-center>
          <v-flex xs8>
            <div class="py-3 px-4">
              <v-layout justify-space-between align-center>
                <v-flex shrink>
                  <div class="font-20 font-w-600">
                    6.ปัญหาร่างกาย/ ความกังวล"ที่ต้องการ แก้ไขคืออะไรบ้าง?
                  </div>
                </v-flex>
                <v-flex shrink class="py-2">
                  <v-img
                    src="../assets/images/step6-problem.png"
                    width="60"
                    contain
                  ></v-img>
                </v-flex>
              </v-layout>

              <!-- หัวข้อปัญหา -->
              <div class="mb-3">
                <v-btn
                  color="#C71010"
                  style="border-radius: 35px"
                  height="40px"
                  depressed
                  dark
                >
                  <div class="font-20 font-w-400">
                    {{ item.question_6_problem_name }}
                    {{
                      item.question_6_problem_name == "อื่นๆ"
                        ? `(${item.question_6_problem_other})`
                        : ""
                    }}
                  </div>
                </v-btn>
              </div>

              <!-- ถ้าเลือกปัญหาข้อ 1-4 ให้แสดง problem position (ตำแหน่งที่มีอาการ/ปัญหาคือบริเวณใด?)  -->
              <v-layout>
                <v-flex xs8>
                  <div class="font-20 font-w-600">
                    7.ตำแหน่งที่มีอาการ /ปัญหาคือบริเวณใด?
                  </div>
                  <ul>
                    <li
                      v-for="(text, d) in item.body_problem_concern_position"
                      :key="d"
                      class="font-20 font-w-400"
                    >
                      {{ text.question_7_position }}
                    </li>
                  </ul>
                </v-flex>
                <v-flex xs4 style="position: relative">
                  <div class="body_model">
                    <img
                      src="../assets/images/report-pdf/body.png"
                      widht="152.45px"
                      height="143.94px"
                      contain
                    />

                    <div
                      class="part_position"
                      v-for="(body_parts, i) in body_parts"
                      :key="i"
                    >
                      <img
                        v-if="
                          checkShowImage(
                            item.body_problem_concern_position,
                            body_parts.name
                          )
                        "
                        :src="
                          require(`../assets/images/report-pdf/body-position/${body_parts.image}.png`)
                        "
                        widht="152.45px"
                        height="143.94px"
                        alt=""
                      />
                    </div>
                  </div>
                </v-flex>
              </v-layout>
            </div>
          </v-flex>
        </v-layout>
        <v-divider />

        <!-- คุณพอจะทราบสาเหตุที่ทำให้เกิดอาการ/ปัญหาออกหรือไม่? -->
        <v-layout justify-center>
          <v-flex xs8>
            <div class="py-3 px-4">
              <v-layout wrap justify-space-between align-center>
                <v-flex shrink class="py-2">
                  <div class="font-20 font-w-600">
                    8.คุณพอจะทราบสาเหตุที่ทำให้เกิดอาการ /ปัญหาออกหรือไม่?
                  </div>
                  <div class="font-20 font-w-400">
                    {{
                      item.body_problem_concern_questionnaire
                        ? item.body_problem_concern_questionnaire
                            .question_8_is_know
                        : ""
                    }}
                  </div>
                  <div
                    class="font-20 font-w-400"
                    v-if="
                      item.body_problem_concern_questionnaire &&
                      item.body_problem_concern_questionnaire
                        .question_8_is_know === 'ทราบ'
                    "
                  >
                    {{
                      item.body_problem_concern_questionnaire
                        .question_8_case_question
                    }}
                  </div>
                </v-flex>
              </v-layout>
            </div>
          </v-flex>
        </v-layout>
        <v-divider />

        <!-- คุณเริ่มรู้สึกถึงอาการ/ปัญหา ตั้งแต่เมื่อใด? -->
        <v-layout justify-center>
          <v-flex xs8>
            <div class="py-3 px-4">
              <v-layout justify-space-between align-center>
                <v-flex shrink class="py-2">
                  <div class="font-20 font-w-600">
                    9.คุณเริ่มรู้สึกถึงอาการ /ปัญหา ตั้งแต่เมื่อใด?
                  </div>
                  <div class="font-20 font-w-400">
                    {{
                      item.body_problem_concern_questionnaire
                        ? checkSymptom(item.body_problem_concern_questionnaire)
                        : ""
                    }}
                  </div>
                </v-flex>
                <v-flex shrink class="py-2">
                  <v-img
                    src="../assets/images/calendar.png"
                    width="60"
                    contain
                  ></v-img>
                </v-flex>
              </v-layout>
            </div>
          </v-flex>
        </v-layout>
        <v-divider />

        <!-- อาการ/ปัญหาเกิดขึ้นในเวลาใด? -->
        <v-layout justify-center>
          <v-flex xs8>
            <div class="py-3 px-4">
              <v-layout justify-space-between align-center>
                <v-flex shrink class="py-2">
                  <div class="font-20 font-w-600">
                    10.อาการ/ปัญหาเกิดขึ้นในเวลาใด?
                  </div>
                  <ul
                    class="font-18 font-w-400"
                    v-if="item.body_problem_concern_questionnaire"
                  >
                    <li>
                      ตอนที่ตื่นนอน ({{
                        item.body_problem_concern_questionnaire
                          .question_10_wakeup
                      }})
                    </li>
                    <li>
                      ตอนที่ยืนอยู่({{
                        item.body_problem_concern_questionnaire
                          .question_10_standing
                      }})
                    </li>
                    <li>
                      ตอนที่เดินอยู่({{
                        item.body_problem_concern_questionnaire
                          .question_10_walking
                      }})
                    </li>
                    <li>
                      ตอนที่นั่งอยู่({{
                        item.body_problem_concern_questionnaire
                          .question_10_sitting
                      }})
                    </li>
                    <li>
                      ตอนที่เคลื่อนไหวบางอิริยาบท({{
                        item.body_problem_concern_questionnaire
                          .question_10_moving
                      }})
                    </li>
                    <li>
                      ตอนที่นอนอยู่({{
                        item.body_problem_concern_questionnaire
                          .question_10_sleeping
                      }})
                    </li>
                  </ul>
                </v-flex>
              </v-layout>
            </div>
          </v-flex>
        </v-layout>
        <v-divider />

        <!-- ที่ผ่านมาคุณทำอะไรไปบ้าง เพื่อแก้ไขอาการ/ปัญหา?  -->
        <v-layout justify-center>
          <v-flex xs8>
            <div class="py-3 px-4">
              <v-layout justify-space-between align-center>
                <v-flex shrink class="py-2">
                  <div class="font-20 font-w-600">
                    11.สาเหตุที่คิดจะ "ตรวจอายุ ร่างกาย" ในครั้งนี้คืออะไร?
                  </div>
                  <ul
                    class="font-18 font-w-400"
                    v-if="item.body_problem_concern_questionnaire"
                  >
                    <li>
                      ตรวจที่โรงพยาบาล/คลินิก({{
                        item.body_problem_concern_questionnaire
                          .question_11_hospital
                      }})
                    </li>
                    <li>
                      นวด({{
                        item.body_problem_concern_questionnaire
                          .question_11_massage
                      }})
                    </li>
                    <li>
                      เข้าคลาสโยคะ/พิลาทิส({{
                        item.body_problem_concern_questionnaire
                          .question_11_yoga
                      }})
                    </li>
                    <li>
                      ไปฟิตเนส({{
                        item.body_problem_concern_questionnaire
                          .question_11_fitness
                      }})
                    </li>
                    <li>
                      ออกกำลังกายด้วยตัวเอง({{
                        item.body_problem_concern_questionnaire
                          .question_11_exercise
                      }})
                    </li>
                    <li
                      v-if="
                        item.body_problem_concern_questionnaire
                          .question_11_exercise_other
                      "
                    >
                      อื่นๆ({{
                        item.body_problem_concern_questionnaire
                          .question_11_exercise_other
                      }})
                    </li>
                  </ul>
                </v-flex>
              </v-layout>
            </div>
          </v-flex>
        </v-layout>
      </dynamic-box-card>

      <!-- card 3 -->
      <dynamic-box-card class="mb-16 py-4">
        <!-- คุณมักจะออกกำลังกายหรือไม่?  -->
        <v-layout justify-center>
          <v-flex xs8>
            <div class="py-3 px-4">
              <v-layout justify-space-between align-start>
                <v-flex shrink class="py-2">
                  <div class="font-20 font-w-600">
                    12.คุณมักจะออกกำลังกายหรือไม่?
                  </div>
                  <div class="font-20">
                    {{ form ? form.question_12_often_exercise : "" }}
                  </div>
                  <div
                    class="font-20"
                    v-if="
                      form.question_12_often_exercise !== 'ไม่ได้ออกกำลังกาย'
                    "
                  >
                    {{ checkExercise(form) }}
                  </div>
                </v-flex>
                <v-flex shrink class="py-2">
                  <v-img
                    src="../assets/images/step-12.png"
                    width="60"
                    contain
                  ></v-img>
                </v-flex>
              </v-layout>
            </div>
          </v-flex>
        </v-layout>
        <v-divider />

        <!-- คุณสูบบุหรี่หรือไม่??  -->
        <v-layout justify-center>
          <v-flex xs8>
            <div class="py-3 px-4">
              <v-layout justify-space-between align-start>
                <v-flex shrink class="py-2">
                  <div class="font-20 font-w-600">13.คุณสูบบุหรี่หรือไม่??</div>
                  <div class="font-20">
                    {{ form.question_13_smoke }}
                  </div>
                  <div
                    class="font-20"
                    v-if="form.question_13_smoke !== 'ไม่สูบ'"
                  >
                    {{ checkSmoke(form) }}
                  </div>
                </v-flex>
                <v-flex shrink class="py-2">
                  <v-img
                    src="../assets/images/step-13.png"
                    width="60"
                    contain
                  ></v-img>
                </v-flex>
              </v-layout>
            </div>
          </v-flex>
        </v-layout>
        <v-divider />

        <!-- คุณดื่มเครื่องดื่มผสมแอลกอฮอล์หรือไม่?  -->
        <v-layout justify-center>
          <v-flex xs8>
            <div class="py-3 px-4">
              <v-layout justify-space-between align-start>
                <v-flex shrink class="py-2">
                  <div class="font-20 font-w-600">
                    14.คุณดื่มเครื่องดื่มผสมแอลกอฮอล์หรือไม่?
                  </div>
                  <div class="font-20">
                    {{ form ? form.question_14_alcohol : "" }}
                  </div>
                  <div
                    class="font-20"
                    v-if="form.question_14_alcohol !== 'ไม่ดื่ม'"
                  >
                    {{ checkDrink(form) }}
                  </div>
                </v-flex>
                <v-flex shrink class="py-2">
                  <v-img
                    src="../assets/images/step-14.png"
                    width="60"
                    contain
                  ></v-img>
                </v-flex>
              </v-layout>
            </div>
          </v-flex>
        </v-layout>
        <v-divider />

        <!-- คุณรู้จัก Powerpod จากช่องทางใด?  -->
        <v-layout justify-center>
          <v-flex xs8>
            <div class="py-3 px-4">
              <div class="font-20 font-w-600">
                15.คุณรู้จัก Powerpod จากช่องทางใด?
              </div>
              <div
                class="font-20"
                v-for="(
                  question_15_know_powerpod, q
                ) in form.question_15_know_powerpod"
                :key="q"
              >
                {{ question_15_know_powerpod }}
                <div
                  v-if="question_15_know_powerpod == 'สมาชิก Powerpod แนะนำ'"
                >
                  {{ form.question_15_member_recommend }}
                </div>
                <div v-if="question_15_know_powerpod == 'อื่นๆ'">
                  {{ form.question_15_other }}
                </div>
              </div>
            </div>
          </v-flex>
        </v-layout>
      </dynamic-box-card>
    </v-container>

    <Loading color="#C71010" :active.sync="isLoading" :is-full-page="true" />
  </div>
</template>

<script>
import { Icon } from "@iconify/vue2";
import DynamicBoxCard from "@/components/DynamicBoxCard.vue";
import SERVICE from "../services/index";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import VueHtml2pdf from "vue-html2pdf";
import { body_parts } from "../services/Constants";

// import QuestionnairePdf from "./QuestionnairePdf.vue";

export default {
  components: {
    DynamicBoxCard,
    Icon,
    Loading,
    VueHtml2pdf,
  },

  created() {
    this.getQuestionnaireDetail();
  },

  data() {
    return {
      form: {},
      isLoading: false,
      isDownload: false,
      filename: "",
      pageTotal: 2,
      body_parts,
      body_problem_concerns: [],
    };
  },

  methods: {
    async downloadPdf() {
      //   if (!(await this.validateControlValue())) return;
      const date = moment().format("YYYYMMDDHHmmss");
      this.filename = `Questionnaire_${date}`;
      this.$refs.html2Pdf.generatePdf();
    },
    getQuestionnaireDetail() {
      this.isLoading = true;
      SERVICE.questionnaire.show(this.$route.params.id).then((res) => {
        this.isLoading = false;
        if (res.status == 200) {
          this.form = res.data.questionnaire;
          this.body_problem_concerns = this.form.body_problem_concerns;
          this.pageTotal =
            this.pageTotal + this.form.body_problem_concerns.length;
        } else {
          this.$swal({
            title: "Error!",
            text: "ไม่พบข้อมูลแบบสอบถาม",
            icon: "error",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push("/questionnaire");
            }
          });
        }
      });
    },

    formatDate(date) {
      return date
        ? new Date(date).toLocaleDateString("th-TH", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })
        : "";
    },

    formatGender(gender) {
      if (gender) {
        if (gender === "M") {
          return "ชาย";
        } else {
          return "หญิง";
        }
      }
    },

    checkShowImage(positions, key) {
      let isShow = false;
      positions.forEach((e) => {
        if (e.question_7_position == key) {
          isShow = true;
        }
      });
      return isShow;
    },

    checkSymptom(item) {
      if (item) {
        switch (item.question_9_select_name) {
          case "เดือนที่แล้ว":
            return item.question_9_last_month + " เดือนที่แล้ว";
          case "สัปดาห์ที่ผ่านมา":
            return item.question_9_last_week + " สัปดาห์ที่ผ่านมา";
          default:
            return item.question_9_select_name;
        }
      }
    },

    checkExercise(item) {
      console.log(item);
      if (item) {
        if (item.question_12_often_exercise === "ทำประจำ ประมาณ") {
          return item.question_12_often_exercise_week + " วัน/สัปดาห์";
        } else {
          return item.question_12_often_exercise_month + " วัน/เดือน";
        }
      }
    },

    checkSmoke(item) {
      if (item) {
        if (item.question_13_smoke === "สูบ ประมาณ") {
          return item.question_13_smoke_day + " มวน/วัน";
        } else {
          return item.question_13_smoke_year + " ปีที่แล้ว";
        }
      }
    },

    checkDrink(item) {
      if (item) {
        if (item.question_14_alcohol === "ดื่ม ประมาณ") {
          return item.question_14_alcohol_week + " วัน/สัปดาห์";
        } else {
          return item.question_14_alcohol_month + " วัน/เดือน";
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page_number {
  position: absolute;
  bottom: 3px;
  right: 5px;
  color: #000;
  z-index: 2;
  font-size: 12px;
}
.pdf-content {
  // width: 100%;
  background: #fff;
  // height: 100vh;

  .box-header {
    width: 100%;
    position: relative;
    .card-box-header {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: #55c5c8;
      border: 1px solid #000000;
      border-radius: 20px;
      color: #fff;
      margin-bottom: 1rem;
      z-index: 1;
    }
    .shadow_border {
      position: absolute;
      background: #000000;
      border: 1px solid #000000;
      border-radius: 20px;
      z-index: 0;
      width: 100%;
      height: 100%;
      top: 5px;
      left: 5px;
    }
  }
  .card-info {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .box-info {
      width: 60%;
      display: flex;
      justify-content: space-between;
    }
  }
  .section_card {
    margin-bottom: 1rem;
    position: relative;
    .main_card {
      position: relative;
      z-index: 2;
      border: 1px solid #000;
      border-radius: 30px;
      padding: 1rem 0px;
      background-color: #fff;
    }
    .shadow_border {
      position: absolute;
      background: #000;
      border: 1px solid #000000;
      border-radius: 30px;
      z-index: 1;
      width: 100%;
      height: 100%;
      top: 5px;
      left: 5px;
    }
  }
}

.width-100 {
  width: 100%;
}

.d-flex {
  display: flex;
}
.justify-content-center {
  justify-content: center;
}
.align-items-center {
  align-items: center;
}
.flex-column {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
.flex-grow-1 {
  flex-grow: 1;
}
.flex-grow-0 {
  flex-grow: 0;
}
.flex-fill {
  flex: 1 1 auto;
}
.mb7 {
  margin-bottom: 7px;
}

.problem-image {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  z-index: 1;
}

.body_model {
  position: relative;
  margin-top: 20px;
  padding-left: 10px;
  .part_position {
    position: absolute;
    top: 0;
    left: 10px;
    z-index: 1;
  }
}
</style>
