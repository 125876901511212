<template>
  <v-card
    outlined
    class="card__result pa-0"
    :style="{ border: `2px solid ${color} !important` }"
  >
    <v-layout>
      <v-flex
        xs3
        class="d-flex justify-center align-center"
        :style="{ background: color }"
      >
        <v-icon x-large dark style="font-size: 55px">
          {{ icon }}
        </v-icon>
      </v-flex>
      <v-flex xs9 class="pa-3">
        <div class="font-18 font-w-600">{{ titleTH }}</div>
        <div class="font-13 mt-n1">{{ titleEN }}</div>
        <div>
          <span :style="{ color: color }" class="font-24 font-w-600">
            {{ result }}
          </span>
          <span class="font-13 font-w-500 ml-1">{{ exe }}</span>
        </div>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "standard",
    },
    titleTH: String,
    titleEN: String,
    exe: String,
    result: null,
  },
  computed: {
    color() {
      switch (this.type) {
        case "warn":
          return "#FFBB02";
        case "danger":
          return "#C71010";
        case "standard":
          return "#44A04C";
        default:
          return "#A3A3A3";
      }
    },
    icon(type) {
      switch (this.type) {
        case "warn":
          return "mdi-emoticon-neutral-outline";
        case "danger":
          return "mdi-emoticon-sad-outline";
        case "standard":
          return "mdi-emoticon-happy-outline";
        default:
          return "";
      }
    },
  },
  methods: {},
};
</script>

<style lang="scss">
.card__result {
  border-radius: 30px !important;
  overflow: hidden;
}
</style>
