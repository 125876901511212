<template>
  <div>
    <!-- Form -->
    <v-form
      class="customer__form mt-6"
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-layout align-center>
        <v-flex shrink class="mt-n12">
          <v-btn icon class="mr-6" to="/">
            <v-icon large color="#2E2528">mdi-arrow-left</v-icon>
          </v-btn>
        </v-flex>
        <v-flex shrink>
          <group-title
            onPage="customerForm"
            txtEn="CREATE CUSTOMER"
            txtTh="สร้างข้อมูลลูกค้า"
          />
        </v-flex>
        <v-spacer />
        <v-flex shrink v-if="!this.$route.params.slug">
          <v-row align="center" class="text_input_form" no-gutters>
            <v-col cols="auto" class="mr-2">
              <div class="font-16 font-weight-bold">Date</div>
            </v-col>
            <v-col>
              <v-menu
                offset-y
                nudge-bottom="10px"
                nudge-left="100px"
                v-model="menu_date"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    readonly
                    solo
                    dense
                    hint="MM/DD/YYYY format"
                    color="var(--color-2)"
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date_picker"
                  color="var(--color-2)"
                  @input="menu_date = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-flex>
      </v-layout>

      <v-divider class="mt-2 mb-4" />

      <v-layout v-if="this.$route.params.slug" align-center my-6>
        <v-flex xs8>
          <v-layout align-center>
            <v-flex xs6>
              <v-row class="switch">
                <v-col cols="6">
                  <v-btn
                    @click="onSwitch(false)"
                    :color="switchEdit ? '#fff' : '#C71010'"
                    :dark="!switchEdit"
                    depressed
                    class="btn-switch text-capitalize"
                    height="54"
                    block
                  >
                    <span class="font-24 font-weight-bold"> Add Record</span>
                  </v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn
                    @click="onSwitch(true)"
                    :color="switchEdit ? '#C71010' : '#fff'"
                    :dark="switchEdit"
                    depressed
                    class="btn-switch text-capitalize"
                    height="54"
                    block
                  >
                    <span class="font-24 font-weight-bold">Edit Record</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-flex>
            <v-flex xs6 px-6>
              <box-card v-if="switchEdit">
                <v-row align="center" class="text_input_form" no-gutters>
                  <v-col cols="4">
                    <div class="txt_th">ประวัติข้อมูล</div>
                    <div class="txt_en">History</div>
                  </v-col>
                  <v-col cols="8">
                    <v-select
                      menu-props="offsetY"
                      v-model="history"
                      :items="historyList"
                      item-text="date"
                      item-value="id"
                      color="#C71010"
                      hide-details
                      solo
                      @change="onHistoryChange"
                      dense
                    >
                      <template v-slot:selection="{ item }">
                        <span>
                          {{
                            historyList.length -
                            historyList
                              .map(function (x) {
                                return x.id;
                              })
                              .indexOf(item.id)
                          }}
                          -
                          {{ item.date | moment("D/MM/YYYY") }}
                        </span>
                      </template>

                      <template v-slot:item="{ item }">
                        <span class="font-16">
                          {{
                            historyList.length -
                            historyList
                              .map(function (x) {
                                return x.id;
                              })
                              .indexOf(item.id)
                          }}
                          -
                          {{ item.date | moment("D/MM/YYYY") }}
                        </span>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </box-card>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs4>
          <v-row>
            <v-col cols="6">
              <v-row align="center" class="text_input_form" no-gutters>
                <v-col cols="12">
                  <div class="font-16 font-weight-bold">Set to compare</div>
                </v-col>
                <v-col cols="12">
                  <v-select
                    menu-props="offsetY"
                    v-model="form.is_compare"
                    :items="compareList"
                    item-text="text"
                    item-value="value"
                    color="#C71010"
                    hide-details
                    solo
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row align="center" class="text_input_form" no-gutters>
                <v-col cols="12">
                  <div class="font-16 font-weight-bold">Date</div>
                </v-col>
                <v-col cols="12">
                  <v-menu
                    offset-y
                    nudge-bottom="10px"
                    nudge-left="100px"
                    v-model="menu_date"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        readonly
                        solo
                        dense
                        hint="MM/DD/YYYY format"
                        color="var(--color-2)"
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date_picker"
                      color="var(--color-2)"
                      @input="menu_date = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-flex>
      </v-layout>

      <v-divider v-if="this.$route.params.slug" class="mb-4 mt-2" />

      <div class="mb-12">
        <!-- Name/Lastname -->
        <v-row no-gutters>
          <v-col xs6>
            <box-card class="pt-5 pb-0">
              <TextInputForm
                required
                errorMessage="First Name"
                labelTh="ชื่อจริง"
                labelEn="First Name"
                inputType="text"
                type="text"
                :model="form.first_name"
                @update-data="form.first_name = $event"
              />
              <!-- :hideDetails="form.first_name ? true : false" -->
            </box-card>
          </v-col>
          <v-col xs6>
            <box-card class="pt-5 pb-0">
              <TextInputForm
                required
                errorMessage="Family Name"
                labelTh="นามสกุล"
                labelEn="Family Name"
                inputType="text"
                type="text"
                :model="form.family_name"
                @update-data="form.family_name = $event"
              />
              <!-- :hideDetails="form.family_name ? true : false" -->
            </box-card>
          </v-col>
        </v-row>

        <div class="mt-6">
          <v-layout>
            <v-flex xs4>
              <box-card class="pt-5 pb-0">
                <TextInputForm
                  required
                  errorMessage="Trainer Name"
                  labelTh="ชื่อเทรนเนอร์"
                  labelEn="Trainer Name"
                  inputType="text"
                  type="text"
                  :model="form.trainer_name"
                  @update-data="form.trainer_name = $event"
                />
              </box-card>

              <box-card class="pt-6 mt-6">
                <TextInputForm
                  required
                  errorMessage="Age"
                  labelTh="อายุ"
                  labelEn="Age"
                  inputType="number"
                  type="number"
                  :model="form.age.toString()"
                  @update-data="form.age = $event"
                />

                <v-row align="center" class="text_input_form" no-gutters>
                  <v-col cols="4" class="mt-n6">
                    <div class="txt_th">เพศ</div>
                    <div class="txt_en">Gender</div>
                  </v-col>
                  <v-col cols="8">
                    <v-select
                      menu-props="offsetY"
                      v-model="form.gender"
                      :items="gender"
                      item-text="text"
                      item-value="value"
                      color="#C71010"
                      :rules="[(v) => !!v || 'Gender is required']"
                      required
                      solo
                      dense
                    ></v-select>
                  </v-col>
                </v-row>

                <TextInputForm
                  required
                  errorMessage="Phone"
                  labelTh="เบอร์โทรศัพท์"
                  labelEn="Phone"
                  inputType="number"
                  :model="form.phone"
                  @update-data="form.phone = $event"
                />

                <TextInputForm
                  required
                  errorMessage="Height"
                  labelTh="ส่วนสูง"
                  labelEn="Height"
                  inputType="decimal"
                  :model="form.height"
                  @update-data="form.height = $event"
                />

                <TextInputForm
                  required
                  errorMessage="Weight"
                  labelTh="น้ำหนัก"
                  labelEn="Weight"
                  inputType="decimal"
                  :model="form.weight"
                  @update-data="form.weight = $event"
                />
              </box-card>

              <box-card class="pt-6 mt-6">
                <TextInputForm
                  required
                  errorMessage="Body Age"
                  labelTh="อายุร่างกาย"
                  labelEn="Body Age"
                  inputType="decimal"
                  :model="form.body_age"
                  @update-data="form.body_age = $event"
                />

                <TextInputForm
                  required
                  errorMessage="Vascular Age"
                  labelTh="อายุหลอดเลือด"
                  labelEn="Vascular Age"
                  inputType="decimal"
                  :model="form.vascular_age"
                  @update-data="form.vascular_age = $event"
                />
              </box-card>

              <box-card class="pt-6 pb-6 mt-6 text_input_form">
                <div class="font-18 font-w-600">ความคาดหวังของคุณ</div>
                <div class="font-12 font-w-400">Expectations</div>
                <v-textarea
                  class="mt-2"
                  color="#C71010"
                  rows="11"
                  solo
                  v-model="form.comment"
                />
              </box-card>

              <box-card class="pt-6 pb-6 mt-6 text_input_form">
                <div class="font-18 font-w-600">ข้อความจาก PWP</div>
                <div class="font-12 font-w-400">Message from PWP</div>
                <v-textarea
                  class="mt-2"
                  color="#C71010"
                  rows="11"
                  solo
                  v-model="form.message_pwp"
                />
              </box-card>
            </v-flex>

            <!-- ********** Physical Measurement ********** -->
            <v-flex xs8>
              <box-card class="area_input mx-0 mb-6 text_input_form pb-6">
                <div class="font-40 font-w-600" style="color: #c71010">
                  การวัดผลทางกายภาพ
                </div>
                <div class="font-20 font-w-400 mt-n3">Physical Measurement</div>

                <!--  1.ความดันโลหิตต่ำสุด/สูงสุด -->
                <v-layout mt-3>
                  <v-flex xs4 class="txt__label__form">
                    <div class="font-18 font-w-600">
                      1.ความดันโลหิตต่ำสุด/สูงสุด
                    </div>
                    <div class="font-12 mt-n1 pl-3">Blood Pressure Min/Max</div>
                  </v-flex>
                  <v-flex xs6>
                    <v-layout>
                      <v-flex>
                        <v-text-field
                          solo
                          dense
                          v-model="form.blood_pressure_min"
                          :rules="[
                            (v) => !!v || 'Blood Pressure Min is required',
                          ]"
                          color="#C71010"
                          required
                          type="number"
                          :oninput="returnInputRule('number')"
                        />
                      </v-flex>
                      <v-flex shrink class="mr-3 ml-4 pt-3 font-w-600"
                        >/</v-flex
                      >
                      <v-flex>
                        <v-text-field
                          type="number"
                          solo
                          dense
                          v-model="form.blood_pressure_max"
                          :rules="[
                            (v) => !!v || 'Blood Pressure Max is required',
                          ]"
                          required
                          :oninput="returnInputRule('number')"
                          color="#C71010"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs2 class="text-left pl-3 font-w-600 pt-3">
                    mmHg
                  </v-flex>
                </v-layout>

                <!-- 2.ดัชนีมวลกาย -->
                <v-layout>
                  <v-flex xs4 class="txt__label__form">
                    <div class="font-18 font-w-600">2.ดัชนีมวลกาย</div>
                    <div class="font-12 pl-3 mt-n1">BMI</div>
                  </v-flex>
                  <v-flex xs6>
                    <v-text-field
                      type="number"
                      solo
                      dense
                      v-model="form.bmi"
                      :rules="[(v) => !!v || 'BMI is required']"
                      required
                      color="#C71010"
                    />
                  </v-flex>
                  <v-flex xs2 class="text-left pl-3 font-w-600 pt-3"> </v-flex>
                </v-layout>

                <!-- 3.อัตราการเผาผลาญ -->
                <v-layout>
                  <v-flex xs4 class="txt__label__form">
                    <div class="font-18 font-w-600">3.อัตราการเผาผลาญ</div>
                    <div class="font-12 pl-3 mt-n1">Basal Metabolic Rate</div>
                  </v-flex>
                  <v-flex xs6>
                    <v-text-field
                      solo
                      dense
                      v-model="form.basal_metabolic_rate"
                      :rules="[
                        (v) => !!v || 'Basal Metabolic Rate is required',
                      ]"
                      required
                      color="#C71010"
                      :oninput="returnInputRule('number')"
                      type="number"
                    />
                  </v-flex>
                  <v-flex xs2 class="text-left pl-3 font-w-600 pt-3"
                    >kcal</v-flex
                  >
                </v-layout>

                <!-- 4.ระดับไขมันในร่างกาย -->
                <v-layout>
                  <v-flex xs4 class="txt__label__form">
                    <div class="font-18 font-w-600">4.ระดับไขมันในร่างกาย</div>
                    <div class="font-12 pl-3 mt-n1">Body Fat Rate</div>
                  </v-flex>
                  <v-flex xs6>
                    <v-text-field
                      solo
                      dense
                      v-model="form.body_fat_rate"
                      :rules="[(v) => !!v || 'Body Fat Rate is required']"
                      required
                      color="#C71010"
                      type="number"
                    />
                  </v-flex>
                  <v-flex xs2 class="text-left pl-3 font-w-600 pt-3">%</v-flex>
                </v-layout>

                <!-- 5.ระดับไขมันในช่องท้อง -->
                <v-layout>
                  <v-flex xs4 class="txt__label__form">
                    <div class="font-18 font-w-600">5.ระดับไขมันในช่องท้อง</div>
                    <div class="font-12 pl-3 mt-n1">Visceral Fat Level</div>
                  </v-flex>
                  <v-flex xs6>
                    <v-text-field
                      solo
                      dense
                      v-model="form.visceral_fat_level"
                      :rules="[(v) => !!v || 'Visceral Fat Level is required']"
                      required
                      color="#C71010"
                      type="number"
                    />
                  </v-flex>
                  <v-flex xs2 class="text-left pl-3 font-w-600 pt-3"
                    >Lv.</v-flex
                  >
                </v-layout>

                <!-- 6.แรงบีบมือข้างซ้าย/ขวา -->
                <v-layout>
                  <v-flex xs4 class="txt__label__form">
                    <div class="font-18 font-w-600">
                      6.แรงบีบมือข้างซ้าย/ขวา
                    </div>
                    <div class="font-12 pl-3 mt-n1">
                      Grip Strength Left/Right
                    </div>
                  </v-flex>
                  <v-flex xs6>
                    <v-layout>
                      <v-flex>
                        <v-text-field
                          solo
                          dense
                          v-model="form.grip_strength_left"
                          :rules="[
                            (v) => !!v || 'Grip Strength Left is required',
                          ]"
                          required
                          color="#C71010"
                          type="number"
                        />
                      </v-flex>
                      <v-flex shrink class="mr-3 ml-4 pt-3 font-w-600"
                        >/</v-flex
                      >
                      <v-flex>
                        <v-text-field
                          solo
                          dense
                          v-model="form.grip_strength_right"
                          :rules="[
                            (v) => !!v || 'Grip Strength Right is required',
                          ]"
                          required
                          color="#C71010"
                          type="number"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs2 class="text-left pl-3 font-w-600 pt-3"
                    >Kgw.
                  </v-flex>
                </v-layout>

                <!-- 7.คะแนนมวลกล้ามเนื้อ -->
                <!-- <v-layout>
                  <v-flex xs4 class="txt__label__form">
                    <div class="font-18 font-w-600">7.คะแนนมวลกล้ามเนื้อ</div>
                    <div class="font-12 pl-3 mt-n1">Muscle Mass Point</div>
                  </v-flex>
                  <v-flex xs6>
                    <v-text-field
                      solo
                      dense
                      v-model="form.muscle_mass_points"
                      :rules="[(v) => !!v || 'Muscle Mass Point is required']"
                      required
                      color="#C71010"
                      :oninput="returnInputRule('number')"
                    />
                  </v-flex>
                  <v-flex xs2 class="text-left pl-3 font-w-600 pt-3"
                    >Pt.
                  </v-flex>
                </v-layout> -->

                <!-- 8.มวลกล้ามเนื้อ -->
                <v-layout>
                  <v-flex xs4 class="txt__label__form">
                    <div class="font-18 font-w-600">7.มวลกล้ามเนื้อ</div>
                    <div class="font-12 pl-3 mt-n1">Muscle Mass</div>
                  </v-flex>
                  <v-flex xs6>
                    <v-text-field
                      solo
                      dense
                      v-model="form.muscle_mass"
                      :rules="[(v) => !!v || 'Viscural Age is required']"
                      required
                      color="#C71010"
                      type="number"
                    />
                  </v-flex>
                  <v-flex xs2 class="text-left pl-3 font-w-600 pt-3"
                    >Kg.</v-flex
                  >
                </v-layout>

                <!-- 9.2 Step Test -->
                <v-layout>
                  <v-flex xs4 class="txt__label__form">
                    <div class="font-18 font-w-600">8. 2 Step Test</div>
                    <div class="font-12 pl-3 mt-n1">2 Step Test</div>
                  </v-flex>
                  <v-flex xs6>
                    <v-text-field
                      solo
                      dense
                      v-model="form.stability"
                      :rules="[(v) => !!v || 'Stability is required']"
                      required
                      color="#C71010"
                      type="number"
                    />
                  </v-flex>
                  <v-flex xs2 class="text-left pl-3 font-w-600 pt-3"
                    >Pt.</v-flex
                  >
                </v-layout>

                <!-- 10.ความอ่อนตัวหัวไหล่ -->
                <v-layout>
                  <v-flex xs4 class="txt__label__form">
                    <div class="font-18 font-w-600">9.ความอ่อนตัวหัวไหล่</div>
                    <div class="font-12 pl-3 mt-n1">
                      Flexibility of Shoulder
                    </div>
                  </v-flex>
                  <v-flex xs6>
                    <v-text-field
                      solo
                      dense
                      v-model="form.flexibility_upper"
                      :rules="[
                        (v) => !!v || 'Flexibility of Shoulder is required',
                      ]"
                      required
                      color="#C71010"
                      type="number"
                    />
                  </v-flex>
                  <v-flex xs2 class="text-left pl-3 font-w-600 pt-3"
                    >Cm.</v-flex
                  >
                </v-layout>

                <!-- 11.ความอ่อนตัวหัวเข่า -->
                <v-layout>
                  <v-flex xs4 class="txt__label__form">
                    <div class="font-18 font-w-600">
                      10.ความอ่อนตัวขาข้างซ้าย/ขวา
                    </div>
                    <div class="font-12 pl-3 mt-n1">
                      Flexibility of Leg Left/Right
                    </div>
                  </v-flex>
                  <v-flex xs6>
                    <v-layout>
                      <v-flex>
                        <v-text-field
                          solo
                          dense
                          v-model="form.flexibility_lower_left"
                          :rules="[
                            (v) => !!v || 'Flexibility of Leg Left is required',
                          ]"
                          required
                          color="#C71010"
                          type="number"
                        />
                      </v-flex>
                      <v-flex shrink class="mr-3 ml-4 pt-3 font-w-600"
                        >/</v-flex
                      >
                      <v-flex>
                        <v-text-field
                          solo
                          dense
                          v-model="form.flexibility_lower_right"
                          :rules="[
                            (v) =>
                              !!v || 'Flexibility of Leg Right is required',
                          ]"
                          required
                          color="#C71010"
                          type="number"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs2 class="text-left pl-3 font-w-600 pt-3"
                    >Cm.</v-flex
                  >
                </v-layout>

                <!-- 12.น้ำหนักที่เหมาะสม -->
                <!-- <v-layout>
                  <v-flex xs4 class="txt__label__form">
                    <div class="font-18 font-w-600">12.น้ำหนักที่เหมาะสม</div>
                    <div class="font-12 pl-3 mt-n1">
                      Training Machine Load Weight
                    </div>
                  </v-flex>
                  <v-flex xs6>
                    <v-layout>
                      <v-flex class="d-flex">
                        <label class="pt-3 font-18 font-w-600">S</label>
                        <v-text-field
                          solo
                          dense
                          v-model="form.training_machine_road_weight_s"
                          :rules="[
                            (v) =>
                              !!v ||
                              'Training Machine Load Weight S is required',
                          ]"
                          required
                          color="#C71010"
                          type="number"
                          class="pr-3 pl-2"
                        />
                      </v-flex>
                      <v-flex class="d-flex">
                        <label class="pt-3 font-18 font-w-600">L</label>
                        <v-text-field
                          solo
                          dense
                          v-model="form.training_machine_road_weight_l"
                          :rules="[
                            (v) =>
                              !!v ||
                              'Training Machine Load Weight L is required',
                          ]"
                          required
                          color="#C71010"
                          type="number"
                          class="pr-3 pl-2"
                        />
                      </v-flex>
                      <v-flex class="d-flex">
                        <label class="pt-3 font-18 font-w-600">H</label>
                        <v-text-field
                          solo
                          dense
                          v-model="form.training_machine_road_weight_h"
                          :rules="[
                            (v) =>
                              !!v ||
                              'Training Machine Load Weight H is required',
                          ]"
                          required
                          color="#C71010"
                          type="number"
                          class="pl-2"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs2 class="text-left pl-3 font-w-600 pt-3"
                    >Kg.</v-flex
                  >
                </v-layout> -->

                <!-- 13.อายุของปอด (ความจุปอด) -->
                <!-- <v-layout>
                  <v-flex xs4 class="txt__label__form">
                    <div class="font-18 font-w-600">
                      13.อายุของปอด (ความจุปอด)
                    </div>
                    <div class="font-12 pl-3 mt-n1">
                      Lung Age(Breathing Capacity)
                    </div>
                  </v-flex>
                  <v-flex xs6>
                    <v-text-field
                      solo
                      dense
                      v-model="form.lung_age"
                      :rules="[(v) => !!v || 'Lung Age is required']"
                      required
                      color="#C71010"
                      type="number"
                    />
                  </v-flex>
                  <v-flex xs2 class="text-left pl-3 font-w-600 pt-3"
                    >Age</v-flex
                  >
                </v-layout> -->

                <!-- 14.อายุผิว -->
                <!-- <v-layout>
                  <v-flex xs4 class="txt__label__form">
                    <div class="font-18 font-w-600">14.อายุผิว</div>
                    <div class="font-12 pl-3 mt-n1">Skin Age</div>
                  </v-flex>
                  <v-flex xs6>
                    <v-text-field
                      solo
                      dense
                      v-model="form.skin_age"
                      :rules="[(v) => !!v || 'Skin Age is required']"
                      required
                      color="#C71010"
                      type="number"
                    />
                  </v-flex>
                  <v-flex xs2 class="text-left pl-3 font-w-600 pt-3"
                    >Age</v-flex
                  >
                </v-layout> -->

                <!-- 15.ความแข็งแรงกล้ามเนื้อหลัง -->
                <!-- <v-layout>
                  <v-flex xs4 class="txt__label__form">
                    <div class="font-18 font-w-600">
                      15.ความแข็งแรงกล้ามเนื้อหลัง
                    </div>
                    <div class="font-12 pl-3 mt-n1">Back Strength</div>
                  </v-flex>
                  <v-flex xs6>
                    <v-text-field
                      solo
                      dense
                      v-model="form.back_strength"
                      :rules="[(v) => !!v || 'Back Strength is required']"
                      required
                      color="#C71010"
                      type="number"
                    />
                  </v-flex>
                  <v-flex xs2 class="text-left pl-3 font-w-600 pt-3">
                    kg
                  </v-flex>
                </v-layout> -->

                <!-- 16.การทรงตัวยืนขาเดียว -->
                <v-layout>
                  <v-flex xs4 class="txt__label__form">
                    <div class="font-18 font-w-600">11.การทรงตัวยืนขาเดียว</div>
                    <div class="font-12 pl-3 mt-n1">Single Leg Balance</div>
                  </v-flex>
                  <v-flex xs6>
                    <v-text-field
                      solo
                      dense
                      v-model="form.single_leg_balance"
                      :rules="[(v) => !!v || 'Single Leg Balance is required']"
                      required
                      color="#C71010"
                      type="number"
                    />
                  </v-flex>
                  <v-flex xs2 class="text-left pl-3 font-w-600 pt-3">
                    Sec
                  </v-flex>
                </v-layout>

                <!-- 17.Stand Up Test -->
                <v-layout>
                  <v-flex xs4 class="txt__label__form">
                    <div class="font-18 font-w-600">12.Stand Up Test</div>
                    <div class="font-12 pl-3 mt-n1">Stand Up Test</div>
                  </v-flex>
                  <v-flex xs6>
                    <v-text-field
                      solo
                      dense
                      v-model="form.stand_up_test"
                      :rules="[(v) => !!v || 'Stand Up Test is required']"
                      required
                      color="#C71010"
                      type="number"
                    />
                  </v-flex>
                  <v-flex xs2 class="text-left pl-3 font-w-600 pt-3">
                    Level
                  </v-flex>
                </v-layout>

                <!-- 18.Functional Test -->
                <v-layout>
                  <v-flex xs4 class="txt__label__form">
                    <div class="font-18 font-w-600">13.Functional Test</div>
                    <div class="font-12 pl-3 mt-n1">Functional Test</div>
                  </v-flex>
                  <v-flex xs6>
                    <v-text-field
                      solo
                      dense
                      v-model="form.functional_test"
                      :rules="[(v) => !!v || 'Functional Test is required']"
                      required
                      color="#C71010"
                      type="number"
                    />
                  </v-flex>
                  <v-flex xs2 class="text-left pl-3 font-w-600 pt-3">
                    cm
                  </v-flex>
                </v-layout>

                <!-- 19.ระดับน้ำในร่างกาย -->
                <v-layout>
                  <v-flex xs4 class="txt__label__form">
                    <div class="font-18 font-w-600">14.ระดับน้ำในร่างกาย</div>
                    <div class="font-12 pl-3 mt-n1">Body water content</div>
                  </v-flex>
                  <v-flex xs6>
                    <v-text-field
                      solo
                      dense
                      v-model="form.body_water_content"
                      :rules="[(v) => !!v || 'Body water content is required']"
                      required
                      color="#C71010"
                      type="number"
                    />
                  </v-flex>
                  <v-flex xs2 class="text-left pl-3 font-w-600 pt-3">
                    %
                  </v-flex>
                </v-layout>

                <!-- 20.ความอ่อนตัวของหลัง -->
                <v-layout>
                  <v-flex xs4 class="txt__label__form">
                    <div class="font-18 font-w-600">15.ความอ่อนตัวของหลัง</div>
                    <div class="font-12 pl-3 mt-n1">Flexibility of Back</div>
                  </v-flex>
                  <v-flex xs6>
                    <v-text-field
                      solo
                      dense
                      v-model="form.flexibility_waist"
                      :rules="[(v) => !!v || 'Flexibility of Back is required']"
                      required
                      color="#C71010"
                      type="number"
                    />
                  </v-flex>
                  <v-flex xs2 class="text-left pl-3 font-w-600 pt-3">
                    °
                  </v-flex>
                </v-layout>

                <!-- 21.คะแนนท่าทาง -->
                <v-layout>
                  <v-flex xs4 class="txt__label__form">
                    <div class="font-18 font-w-600">16.คะแนนท่าทาง</div>
                    <div class="font-12 pl-3 mt-n1">Posture Score</div>
                  </v-flex>
                  <v-flex xs6>
                    <v-text-field
                      solo
                      dense
                      v-model="form.posture_score"
                      :rules="[(v) => !!v || 'Posture Score is required']"
                      required
                      color="#C71010"
                      type="number"
                    />
                  </v-flex>
                  <v-flex xs2 class="text-left pl-3 font-w-600 pt-3"
                    >pt.</v-flex
                  >
                </v-layout>

                <!-- 22.ปัญหาท่าทาง -->
                <v-layout>
                  <v-flex xs4 class="txt__label__form">
                    <div class="font-18 font-w-600">17.ปัญหาท่าทาง</div>
                    <div class="font-12 pl-3 mt-n1">Posture Problem</div>
                  </v-flex>
                  <v-flex xs6>
                    <v-text-field
                      solo
                      dense
                      v-model="form.posture_type"
                      :rules="[(v) => !!v || 'Posture Problem is required']"
                      required
                      color="#C71010"
                      type="number"
                    />
                  </v-flex>
                  <v-flex xs2 class="text-left pl-3 font-w-600 pt-3"> </v-flex>
                </v-layout>

                <!-- 23.ทดสอบลุกจากเก้าอี้ -->
                <v-layout>
                  <v-flex xs4 class="txt__label__form">
                    <div class="font-18 font-w-600">18.ทดสอบลุกจากเก้าอี้</div>
                    <div class="font-12 pl-3 mt-n1">Chair Stand Test</div>
                  </v-flex>
                  <v-flex xs6>
                    <v-text-field
                      solo
                      dense
                      v-model="form.chair_stand_test"
                      :rules="[(v) => !!v || 'Chair Stand Test is required']"
                      required
                      color="#C71010"
                      type="number"
                    />
                  </v-flex>
                  <v-flex xs2 class="text-left pl-3 font-w-600 pt-3"
                    >rep</v-flex
                  >
                </v-layout>

                <!-- 24.ทดสอบก้าวขึ้น-ลง -->
                <v-layout>
                  <v-flex xs4 class="txt__label__form">
                    <div class="font-18 font-w-600">19.ทดสอบก้าวขึ้น-ลง</div>
                    <div class="font-12 pl-3 mt-n1">Step Box Test</div>
                  </v-flex>
                  <v-flex xs6>
                    <v-text-field
                      solo
                      dense
                      v-model="form.step_box_test"
                      :rules="[(v) => !!v || 'Step Box Test is required']"
                      required
                      color="#C71010"
                      type="number"
                    />
                  </v-flex>
                  <v-flex xs2 class="text-left pl-3 font-w-600 pt-3">HR</v-flex>
                </v-layout>
              </box-card>
            </v-flex>
          </v-layout>
        </div>

        <!-- หน่วยการวัดผล -->
        <div class="mt-6">
          <v-layout>
            <v-flex xs4>
              <box-card class="area_input mb-6 text_input_form">
                <div class="font-30 font-w-600">หน่วยการวัดผล</div>
                <div class="font-12 font-w-400 mt-n1">Measurements</div>

                <div class="mt-4">
                  <v-layout
                    align-center
                    v-for="(item, i) in form.measurements"
                    :key="i"
                    class="mb-4"
                  >
                    <v-flex>
                      <v-autocomplete
                        menu-props="offsetY"
                        v-model="item.measurement_name"
                        :items="measurementList"
                        item-text="name"
                        item-value="name"
                        color="#C71010"
                        solo
                        dense
                        clearable
                        hide-details
                        @change="onMeasurementChange(i, $event)"
                      >
                        <template v-slot:selection="data">
                          <div>{{ data.item.no }}. {{ data.item.name }}</div>
                        </template>
                        <template v-slot:item="data">
                          <div>{{ data.item.no }}. {{ data.item.name }}</div>
                        </template>
                      </v-autocomplete>
                    </v-flex>
                  </v-layout>
                </div>
              </box-card>
            </v-flex>
            <v-flex xs8>
              <v-layout>
                <v-flex xs6>
                  <box-card class="area_input mb-6 text_input_form">
                    <div class="font-30 font-w-600">ผลการวัดวันนี้</div>
                    <div class="font-12 font-w-400 mt-n1">Today</div>

                    <div class="mt-4">
                      <v-layout
                        align-center
                        justify-space-between
                        v-for="(item, i) in form.measurements"
                        :key="i"
                        style="border-top: 1px solid #d3d3d3; min-height: 66px"
                      >
                        <v-flex xs7 class="font-20 d-flex justify-center">
                          {{ item.result_today ? item.result_today : "" }}
                        </v-flex>
                        <v-flex
                          shrink
                          pr-12
                          class="font-30 font-w-600"
                          :class="checkClassOfGrade(item.grade_result_today)"
                        >
                          {{
                            item.grade_result_today
                              ? item.grade_result_today
                              : ""
                          }}
                        </v-flex>
                      </v-layout>
                    </div>
                  </box-card>
                </v-flex>
                <v-flex xs6>
                  <box-card class="area_input mb-6 text_input_form">
                    <div class="font-30 font-w-600">ค่าเป้าหมายการปรับปรุง</div>
                    <div class="font-12 font-w-400 mt-n1">(3 เดือนต่อมา)</div>

                    <div class="mt-4">
                      <v-layout
                        align-center
                        justify-space-between
                        v-for="(item, i) in form.measurements"
                        :key="i"
                        style="border-top: 1px solid #d3d3d3; min-height: 66px"
                      >
                        <v-flex
                          xs7
                          class="font-20 d-flex justify-center pl-6 text-field-center"
                        >
                          <v-text-field
                            v-if="item.measurement_name"
                            solo
                            style="text-align: center"
                            dense
                            v-model="item.improve_target"
                            :rules="
                              item.measurement_name
                                ? [(v) => !!v || 'Field is required']
                                : []
                            "
                            hide-details
                            required
                            color="#C71010"
                            type="number"
                            @input="onInputImproveTarget(i, $event)"
                          />
                        </v-flex>
                        <v-flex
                          shrink
                          pr-12
                          class="font-30 font-w-600"
                          :class="checkClassOfGrade(item.grade_improve_target)"
                        >
                          {{
                            item.grade_improve_target
                              ? item.grade_improve_target
                              : ""
                          }}
                        </v-flex>
                      </v-layout>
                    </div>
                  </box-card>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </div>

        <!-- ตำแหน่งที่มีอาการ/ปัญหาในปัจจุบัน -->
        <div>
          <box-card class="area_input mb-6 text_input_form">
            <v-layout align-center py-4>
              <v-flex xs4 style="position: relative">
                <div>
                  <v-img
                    src="../assets/images/report-pdf/body.png"
                    widht="328px"
                    height="306px"
                    contain
                  ></v-img>
                </div>
                <div v-for="(item, i) in form.problematic_part" :key="i">
                  <div
                    class="problem-image"
                    v-for="(body_position, i) in body_positions"
                    :key="i"
                  >
                    <v-img
                      :src="
                        require(`../assets/images/report-pdf/body-position/${body_position.image}.png`)
                      "
                      v-if="item.position_problem == body_position.name"
                      widht="328px"
                      height="306px"
                      contain
                    ></v-img>
                  </div>
                </div>
              </v-flex>
              <v-flex xs8 class="px-6">
                <div class="font-18 font-w-600 mt-3">
                  ตำแหน่งที่มีอาการ/ปัญหาในปัจจุบัน
                </div>
                <div class="font-12 font-w-400 mb-3">Problematic part</div>

                <v-layout
                  align-center
                  mb-3
                  v-for="(item, i) in form.problematic_part"
                  :key="i"
                >
                  <v-flex pr-3 xs6>
                    <v-autocomplete
                      menu-props="offsetY"
                      v-model="item.position_problem"
                      :items="body_positions"
                      item-text="name"
                      item-value="name"
                      color="#C71010"
                      solo
                      dense
                      clearable
                      hide-details
                      @click:clear="item.problem = ''"
                    >
                      <template v-slot:selection="data">
                        <div>{{ data.item.no }}. {{ data.item.name }}</div>
                      </template>
                      <template v-slot:item="data">
                        <div>{{ data.item.no }}. {{ data.item.name }}</div>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                  <v-flex pl-3 xs6>
                    <v-text-field
                      v-model="item.problem"
                      color="#C71010"
                      solo
                      dense
                      hide-details
                    >
                    </v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </box-card>
        </div>

        <!-- ********** อัปโหลดรูปภาพ ************* -->
        <div>
          <box-card class="area_input mb-6 text_input_form">
            <div class="font-40 font-w-600" style="color: #c71010">
              อัปโหลดรูปภาพ
            </div>
            <div class="font-20 font-w-400 mt-n3">Upload Picture</div>

            <v-row>
              <v-col cols="6">
                <v-row class="text_input_form" no-gutters>
                  <v-col cols="auto" class="mr-4">
                    <div class="txt_th">คำบรรยาย</div>
                    <div class="txt_en">Description</div>
                  </v-col>
                  <v-col cols>
                    <v-textarea
                      class="mt-2"
                      color="#C71010"
                      rows="7"
                      solo
                      v-model="formImage.desc"
                      placeholder="Description"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-layout align-center>
                  <v-flex xs2 class="txt__label__form mr-2">
                    <div class="font-18 font-w-600">รูปภาพ</div>
                    <div class="font-12 pl-3 mt-n1">Picture</div>
                  </v-flex>
                  <v-flex>
                    <input
                      hidden
                      type="file"
                      accept="image/*"
                      ref="uploadImage"
                      @change="onFileChange($event, 'pickup_container_image')"
                    />
                    <v-text-field
                      solo
                      clearable
                      @click:clear="clearImage"
                      dense
                      v-model="formImage.imageName"
                      readonly
                      color="#A3A3A3"
                      hide-details
                    />
                  </v-flex>
                  <v-flex shrink class="text-left pl-3 font-w-600">
                    <v-btn
                      height="35"
                      block
                      style="
                        border-radius: 29px;
                        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
                      "
                      dark
                      color="#000000"
                      class="text-capitalize"
                      @click="$refs.uploadImage.click()"
                    >
                      Browse
                    </v-btn>
                  </v-flex>
                </v-layout>

                <v-layout mt-3>
                  <v-flex xs2 class="txt__label__form mr-2">
                    <div class="font-18 font-w-600">ตัวอย่าง</div>
                    <div class="font-12 pl-3 mt-n1">Preview</div>
                  </v-flex>
                  <v-flex>
                    <div v-if="formImage.imageFile">
                      <v-img
                        :src="checkPreviewImage(formImage.imageFile)"
                        width="150"
                        height="150"
                        cover
                        style="border-radius: 10px"
                      >
                      </v-img>
                    </div>
                    <div v-else>
                      <v-card
                        style="border-radius: 10px; border: 1px solid black"
                        outlined
                        width="150"
                        height="150"
                        class="d-flex align-center justify-center"
                      >
                        <v-icon large color="#A3A3A3"> mdi-image </v-icon>
                      </v-card>
                    </div>
                  </v-flex>
                </v-layout>
              </v-col>
            </v-row>
          </box-card>
        </div>

        <!-- *********** อัลบั้ม *********** -->
        <div v-if="$route.params.slug">
          <box-card class="area_input mb-6 text_input_form">
            <div class="font-40 font-w-600" style="color: #c71010">อัลบั้ม</div>
            <div class="font-20 font-w-400 mt-n3">Album</div>

            <v-row v-if="albums.length > 0" class="mt-2">
              <v-col cols="2" v-for="image in albums" :key="image.id">
                <v-img
                  :src="checkPreviewImage(image.path)"
                  cover
                  position="top"
                  style="border-radius: 10px; border: 1px solid #000"
                  aspect-ratio="1"
                >
                  <div class="d-flex justify-end align-start fill-height">
                    <v-btn
                      class="ma-2"
                      color="#A3A3A3"
                      fab
                      width="20"
                      height="20"
                      @click="deleteImage(image.id)"
                      dark
                      outlined
                    >
                      <v-icon small>mdi-close</v-icon>
                    </v-btn>
                  </div>
                </v-img>
                <p class="font-14 mt-1">{{ image.description }}</p>
              </v-col>
            </v-row>
          </box-card>
        </div>

        <div class="d-flex justify-end align-center mt-3">
          <v-btn
            v-if="history"
            class="mr-6"
            color="#A3A3A3"
            dark
            icon
            width="70"
            height="70"
            outlined
            @click="removeHistory"
          >
            <v-icon large>mdi-delete-outline</v-icon>
          </v-btn>

          <v-btn
            :loading="isBtnLoading"
            width="221"
            height="70"
            style="
              border-radius: 29px;
              box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
            "
            dark
            :disabled="!valid"
            color="#c71010"
            class="text-capitalize"
            @click="validate"
          >
            <span class="font-28 font-w-600 white--text">Save</span>
          </v-btn>
        </div>
      </div>
    </v-form>

    <Loading color="#C71010" :active.sync="isLoading" :is-full-page="true" />
  </div>
</template>

<script>
import moment from "moment";
import GroupTitle from "@/components/GroupTitle.vue";
import BoxCard from "@/components/BoxCard.vue";
import TextInputForm from "@/components/TextInputForm.vue";
import SERVICE from "../services/index";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import {
  bloodPressureMin,
  bloodPressureMax,
  bloodPressure,
  bmiSetting,
  basalMetabolicRateSetting,
  bodyFatRateSetting,
  visceralFatLevelSetting,
  gripStrengthLeftSetting,
  gripStrengthRightSetting,
  muscleMassPointsSetting,
  vascularAge,
  bodyAge,
  stability,
  FlexibilityUpper,
  FlexibilityLower,
  lungAge,
  skinAge,
  backStrength,
  singleLegBalance,
  standUpTest,
  functionalTest,
  bodyWaterContent,
  flexibilityWaist,
  postureScore,
  postureType,
  stepBoxTest,
  chairStandTest,
} from "../plugins/global-func.js";
import {
  url_path,
  measurements,
  body_positions,
  measurementList,
} from "../services/Constants";
export default {
  metaInfo: {
    title: "Customer Form",
    titleTemplate: "%s | Powerpod Fitness Club",
  },
  components: { GroupTitle, BoxCard, TextInputForm, Loading },
  data() {
    return {
      switchEdit: true,
      compareList: [
        {
          text: "None",
          value: "NONE",
        },
        {
          text: "Before",
          value: "BEFORE",
        },
        {
          text: "After",
          value: "AFTER",
        },
      ],
      isCompare: "",
      isBtnLoading: false,
      isLoading: false,
      menu_date: false,
      history: null,
      historyList: [],
      measurements: measurements,
      measurementList,
      body_positions: body_positions,
      settings: null,
      form: {
        first_name: "",
        family_name: "",
        age: "",
        gender: "",
        phone: "",
        height: "",
        weight: "",
        body_age: "",
        vascular_age: "",
        point_concern: "",
        blood_pressure_min: "",
        blood_pressure_max: "",
        bmi: "",
        basal_metabolic_rate: "",
        body_fat_rate: "",
        visceral_fat_level: "",
        grip_strength_left: "",
        grip_strength_right: "",
        muscle_mass: "",
        muscle_mass_points: "",
        customer_type_id: "",
        customer_type_name: "",
        customer_status: "",
        stability: "",
        flexibility_upper: "",
        flexibility_lower: "",
        training_machine_road_weight_s: "",
        training_machine_road_weight_l: "",
        training_machine_road_weight_h: "",
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        is_compare: "NONE",
        images: [],
        remove_images: [],

        // new update 2023-03-22
        trainer_name: "",
        comment: "",
        message_pwp: "",
        lung_age: "",
        skin_age: "",
        back_strength: "",
        single_leg_balance: "",
        stand_up_test: "",
        functional_test: "",
        body_water_content: "",
        flexibility_waist: "",
        posture_score: "",
        posture_type: "",
        // new update 2023-05-02
        chair_stand_test: "",
        step_box_test: "",
        flexibility_lower_left: "",
        flexibility_lower_right: "",
        measurements: [
          {
            measurement_name: "",
            result_today: "",
            grade_result_today: "",
            improve_target: "",
            grade_improve_target: "",
          },
          {
            measurement_name: "",
            result_today: "",
            grade_result_today: "",
            improve_target: "",
            grade_improve_target: "",
          },
          {
            measurement_name: "",
            result_today: "",
            grade_result_today: "",
            improve_target: "",
            grade_improve_target: "",
          },
          {
            measurement_name: "",
            result_today: "",
            grade_result_today: "",
            improve_target: "",
            grade_improve_target: "",
          },
        ],
        problematic_part: [
          { position_problem: "", problem: "" },
          { position_problem: "", problem: "" },
          { position_problem: "", problem: "" },
        ],
      },
      backupForm: {},
      formImage: {
        imageFile: "",
        imageName: "",
        desc: "",
      },
      albums: [],
      remove_images: [],
      date_picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      date: moment(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      ).format("DD/MM/YYYY"),
      gender: [
        { id: 1, text: "ชาย (Male)", value: "M" },
        { id: 2, text: "หญิง (Female)", value: "F" },
      ],
      valid: true,
      fieldRules: [(v) => !!v || "Field is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
    };
  },
  created() {
    if (this.$route.params.slug) {
      // promise all
      this.isLoading = true;
      Promise.all([this.getSetting(), this.fetchData(this.$route.params.slug)])
        .then((res) => {
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
        });
    } else {
      this.isLoading = true;
      Promise.all([this.getSetting(), this.getPointConcern()])
        .then((res) => {
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
        });

      this.form.first_name = this.$route.query.first_name;
      this.form.family_name = this.$route.query.family_name;
      this.form.gender = this.$route.query.gender;
      this.form.phone = this.$route.query.phone;
      this.form.link_questionnaire_id = this.$route.query.link_questionnaire_id;
    }
  },
  watch: {
    date_picker(val) {
      this.form.date = val;
      this.date = moment(val).format("DD/MM/YYYY");
    },
  },

  methods: {
    async getPointConcern() {
      const res = await SERVICE.setting.pointConcern();
      this.form.point_concern = await res.point_concern;
    },
    async getSetting() {
      const { data } = await SERVICE.setting.index();
      console.log(data);
      this.settings = data;
      return data;
    },
    returnInputRule(type) {
      switch (type) {
        case "number":
          return "value=value.replace(/[^\\d]/g,'')";

        case "decimal":
          return "value=value.replace(/[^0-9\\.]/g,'')";

        default:
          return null;
      }
    },
    async fetchData(id) {
      // this.isLoading = true;
      const res = await SERVICE.customer.show(id);
      // this.form = await res.data;
      this.form = res.history.length > 0 ? res.history[0] : res.data;
      // set measurement data
      if (
        this.form.measurements == null ||
        this.form.measurements.length == 0
      ) {
        this.form.measurements = [
          {
            measurement_name: "",
            result_today: "",
            grade_result_today: "",
            improve_target: "",
            grade_improve_target: "",
          },
          {
            measurement_name: "",
            result_today: "",
            grade_result_today: "",
            improve_target: "",
            grade_improve_target: "",
          },
          {
            measurement_name: "",
            result_today: "",
            grade_result_today: "",
            improve_target: "",
            grade_improve_target: "",
          },
          {
            measurement_name: "",
            result_today: "",
            grade_result_today: "",
            improve_target: "",
            grade_improve_target: "",
          },
        ];
      }
      // set problematic part data
      if (
        this.form.problematic_part == null ||
        this.form.problematic_part.length == 0
      ) {
        this.form.problematic_part = [
          { position_problem: "", problem: "" },
          { position_problem: "", problem: "" },
          { position_problem: "", problem: "" },
        ];
      }
      this.history = (await res.history.length) > 0 ? res.history[0].id : "";
      this.backupForm = (await res.history.length) > 0 ? res.history[0] : "";
      this.albums = await res.data.images;
      this.date_picker = await this.form.date;
      this.date = await moment(this.form.date).format("DD/MM/YYYY");
      this.historyList = await res.history;
      return res;
      // this.isLoading = false;
    },
    async save() {
      this.isBtnLoading = true;
      this.isLoading = true;

      this.form.images = this.formImage;
      this.form.remove_images = this.remove_images;

      // Update
      if (this.$route.params.slug) {
        if (!this.form.id) {
          this.form.id = this.$route.params.slug;
        }
        const res = await SERVICE.customer
          .update(this.$route.params.slug, this.form)
          .catch((err) => {
            this.isBtnLoading = false;
          });
        this.isLoading = await false;
        if (res.status === 200) {
          this.isBtnLoading = false;
          this.$swal({
            title: "Update Successfully",
            text: res.message,
            icon: "success",
            confirmButtonColor: "#C71010",
          }).then(() => {
            this.$router.push("/");
          });
        } else {
          this.$swal({
            title: "Update Failed!",
            text: res.message,
            icon: "error",
            confirmButtonColor: "#C71010",
          });
          this.isBtnLoading = false;
        }
      }
      // Save
      else {
        const res = await SERVICE.customer.save(this.form).catch((err) => {
          this.isBtnLoading = false;
          this.$toast.error(err.response.data.message);
        });
        this.isLoading = await false;
        if (res.status === 200) {
          this.isBtnLoading = false;
          this.$swal({
            title: "Save Successfully",
            text: res.message,
            icon: "success",
            confirmButtonColor: "#C71010",
          }).then(() => {
            this.$router.push("/");
          });
        } else {
          this.isBtnLoading = false;
          this.$swal({
            title: "Save Failed!",
            text: res.message,
            icon: "error",
            confirmButtonColor: "#C71010",
          });
        }
      }
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.save();
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    clearImage() {
      this.formImage.imageFile = "";
      this.formImage.imageName = "";
      // clear input file ref uploadImage
      this.$refs.uploadImage.value = "";
    },
    onFileChange(e) {
      let reader = new FileReader();
      this.formImage.imageName = e.target.files[0].name;
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (e) => {
        this.formImage.imageFile = e.target.result;
      };
    },
    checkPreviewImage(url) {
      // check url is base64 or not
      if (url && url.indexOf("data:image") > -1) {
        return url;
      } else {
        return url_path + url;
      }
    },
    deleteImage(id) {
      this.remove_images.push(id);
      // slice image from array
      this.albums = this.albums.filter((item) => item.id !== id);
    },
    onSwitch(val) {
      this.switchEdit = val;
      if (!val) {
        this.history = null;

        this.form = {
          id: this.form.customer_id,
          first_name: this.form.first_name,
          family_name: this.form.family_name,
          age: this.form.age,
          gender: this.form.gender,
          phone: this.form.phone,
          height: this.form.height,
          weight: "",
          body_age: "",
          vascular_age: "",
          point_concern: this.form.point_concern,
          blood_pressure_min: "",
          blood_pressure_max: "",
          bmi: "",
          basal_metabolic_rate: "",
          body_fat_rate: "",
          visceral_fat_level: "",
          grip_strength_left: "",
          grip_strength_right: "",
          muscle_mass: "",
          muscle_mass_points: "",
          customer_type_id: "",
          customer_type_name: "",
          customer_status: "",
          stability: "",
          flexibility_upper: "",
          flexibility_lower: "",
          training_machine_road_weight_s: "",
          training_machine_road_weight_l: "",
          training_machine_road_weight_h: "",
          flexibility_lower_left: "",
          flexibility_lower_right: "",
          step_box_test: "",
          chair_stand_test: "",
          date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
          is_compare: "NONE",
          images: [],
          remove_images: [],
          // new update 2023-03-22
          trainer_name: "",
          comment: "",
          message_pwp: "",
          lung_age: "",
          skin_age: "",
          back_strength: "",
          single_leg_balance: "",
          stand_up_test: "",
          functional_test: "",
          body_water_content: "",
          flexibility_waist: "",
          posture_score: "",
          posture_type: "",
          measurements: [
            {
              measurement_name: "",
              result_today: "",
              grade_result_today: "",
              improve_target: "",
              grade_improve_target: "",
            },
            {
              measurement_name: "",
              result_today: "",
              grade_result_today: "",
              improve_target: "",
              grade_improve_target: "",
            },
            {
              measurement_name: "",
              result_today: "",
              grade_result_today: "",
              improve_target: "",
              grade_improve_target: "",
            },
            {
              measurement_name: "",
              result_today: "",
              grade_result_today: "",
              improve_target: "",
              grade_improve_target: "",
            },
          ],
          problematic_part: [
            { position_problem: "", problem: "" },
            { position_problem: "", problem: "" },
            { position_problem: "", problem: "" },
          ],
        };
        this.$refs.form.resetValidation();
      } else {
        if (this.historyList.length > 0) {
          this.form = this.historyList[0];
          this.history = this.historyList[0].id;
        }
      }
    },
    onHistoryChange(id) {
      this.form = this.historyList.find((item) => item.id === id);
    },

    removeHistory() {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#C71010",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          SERVICE.customer
            .deleteCustomerLog(this.history)
            .then(() => {
              this.$swal({
                title: "Deleted!",
                text: "Your file has been deleted.",
                icon: "success",
                confirmButtonColor: "#C71010",
              }).then(() => {
                this.fetchData(this.$route.params.slug);
                this.switchEdit = false;
                this.form = this.backupForm;
                this.history = {};
              });
            })
            .catch((err) => {
              this.isBtnLoading = false;
              this.$toast.error(err.response.data.message);
            });
        }
      });
    },

    onMeasurementChange(index, value) {
      let status = "";
      this.form.measurements[index].result_today = "";
      this.form.measurements[index].grade_result_today = "";
      this.form.measurements[index].improve_target = "";
      this.form.measurements[index].grade_improve_target = "";
      if (value) {
        switch (value) {
          case "ความดันโลหิตต่ำสุด/สูงสุด":
            status = bloodPressure(
              this.settings.BloodPressure,
              this.form.blood_pressure_min,
              this.form.blood_pressure_max
            );
            this.form.measurements[index].result_today =
              this.form.blood_pressure_min + "/" + this.form.blood_pressure_max;
            this.form.measurements[index].grade_result_today =
              this.checkGrade(status);
            break;
          case "ความดันโลหิตต่ำสุด":
            status = bloodPressureMin(
              this.settings.BloodPressure,
              this.form.blood_pressure_min
            );
            this.form.measurements[index].result_today =
              this.form.blood_pressure_min;
            this.form.measurements[index].grade_result_today =
              this.checkGrade(status);
            break;
          case "ความดันโลหิตสูงสุด":
            status = bloodPressureMax(
              this.settings.BloodPressure,
              this.form.blood_pressure_max
            );
            this.form.measurements[index].result_today =
              this.form.blood_pressure_max;
            this.form.measurements[index].grade_result_today =
              this.checkGrade(status);
            break;
          case "ดัชนีมวลกาย":
            status = bmiSetting(this.settings.bmi, this.form.bmi);
            this.form.measurements[index].result_today = this.form.bmi;
            this.form.measurements[index].grade_result_today =
              this.checkGrade(status);
            break;
          case "อัตราการเผาผลาญ":
            status = basalMetabolicRateSetting(
              this.settings.BasalMetabolicRate,
              this.form.age,
              this.form.gender,
              this.form.basal_metabolic_rate
            );
            this.form.measurements[index].result_today =
              this.form.basal_metabolic_rate;
            this.form.measurements[index].grade_result_today =
              this.checkGrade(status);
            break;
          case "ระดับไขมันในร่างกาย":
            status = bodyFatRateSetting(
              this.settings.BodyFatRate,
              this.form.age,
              this.form.gender,
              this.form.body_fat_rate
            );
            this.form.measurements[index].result_today =
              this.form.body_fat_rate;
            this.form.measurements[index].grade_result_today =
              this.checkGrade(status);
            break;
          case "ระดับไขมันในช่องท้อง":
            status = visceralFatLevelSetting(
              this.settings.VisceralFatLevel,
              this.form.visceral_fat_level
            );
            this.form.measurements[index].result_today =
              this.form.visceral_fat_level;
            this.form.measurements[index].grade_result_today =
              this.checkGrade(status);
            break;
          case "แรงบีบมือข้างซ้าย":
            status = gripStrengthLeftSetting(
              this.settings.GripStrength,
              this.form.gender,
              this.form.grip_strength_left
            );
            this.form.measurements[index].result_today =
              this.form.grip_strength_left;
            this.form.measurements[index].grade_result_today =
              this.checkGrade(status);
            break;
          case "แรงบีบมือข้างขวา":
            status = gripStrengthRightSetting(
              this.settings.GripStrength,
              this.form.gender,
              this.form.grip_strength_right
            );
            this.form.measurements[index].result_today =
              this.form.grip_strength_right;
            this.form.measurements[index].grade_result_today =
              this.checkGrade(status);
            break;
          case "มวลกล้ามเนื้อ":
            status = muscleMassPointsSetting(
              this.settings.MuscleMassPoints,
              this.form.age,
              this.form.gender,
              this.form.muscle_mass
            );
            this.form.measurements[index].result_today =
              this.form.muscle_mass;
            this.form.measurements[index].grade_result_today =
              this.checkGrade(status);
            break;
          case "2 Step Test":
            status = stability(
              this.settings.StabilityCriteriaSetting,
              this.form.age,
              this.form.gender,
              this.form.stability
            );
            this.form.measurements[index].result_today = this.form.stability;
            this.form.measurements[index].grade_result_today =
              this.checkGrade(status);
            break;
          case "ความอ่อนตัวหัวไหล่":
            status = FlexibilityUpper(this.form.flexibility_upper);
            this.form.measurements[index].result_today =
              this.form.flexibility_upper;
            this.form.measurements[index].grade_result_today =
              this.checkGrade(status);
            break;
          case "ความอ่อนตัวขาข้างซ้าย":
            status = FlexibilityLower(
              this.settings.FlexibilityLowerCriteriaSetting,
              this.form.age,
              this.form.gender,
              this.form.flexibility_lower_left
            );
            this.form.measurements[index].result_today =
              this.form.flexibility_lower_left;
            this.form.measurements[index].grade_result_today =
              this.checkGrade(status);
            break;
          case "ความอ่อนตัวขาข้างขวา":
            status = FlexibilityLower(
              this.settings.FlexibilityLowerCriteriaSetting,
              this.form.age,
              this.form.gender,
              this.form.flexibility_lower_right
            );
            this.form.measurements[index].result_today =
              this.form.flexibility_lower_right;
            this.form.measurements[index].grade_result_today =
              this.checkGrade(status);
            break;
          case "อายุของปอด (ความจุปอด)":
            status = lungAge(
              this.settings.lung_age,
              this.form.age,
              this.form.lung_age
            );
            this.form.measurements[index].result_today = this.form.lung_age;
            this.form.measurements[index].grade_result_today =
              this.checkGrade(status);
            break;
          case "อายุผิว":
            status = skinAge(
              this.settings.skin_age,
              this.form.age,
              this.form.skin_age
            );
            this.form.measurements[index].result_today = this.form.skin_age;
            this.form.measurements[index].grade_result_today =
              this.checkGrade(status);
            break;
          case "ความแข็งแรงกล้ามเนื้อหลัง":
            status = backStrength(
              this.settings.back_strength,
              this.form.age,
              this.form.gender,
              this.form.back_strength
            );
            this.form.measurements[index].result_today =
              this.form.back_strength;
            this.form.measurements[index].grade_result_today =
              this.checkGrade(status);
            break;
          case "การทรงตัวยืนขาเดียว":
            status = singleLegBalance(
              this.settings.single_leg_balance,
              this.form.single_leg_balance
            );
            this.form.measurements[index].result_today =
              this.form.single_leg_balance;
            this.form.measurements[index].grade_result_today =
              this.checkGrade(status);
            break;
          case "Stand Up Test":
            status = standUpTest(
              this.settings.stand_up_test,
              this.form.stand_up_test
            );
            this.form.measurements[index].result_today =
              this.form.stand_up_test;
            this.form.measurements[index].grade_result_today =
              this.checkGrade(status);
            break;
          case "Functional Test":
            status = functionalTest(
              this.settings.functional_test,
              this.form.functional_test
            );
            this.form.measurements[index].result_today =
              this.form.functional_test;
            this.form.measurements[index].grade_result_today =
              this.checkGrade(status);
            break;
          case "ระดับน้ำในร่างกาย":
            status = bodyWaterContent(
              this.settings.body_water_content,
              this.form.age,
              this.form.gender,
              this.form.body_water_content
            );
            this.form.measurements[index].result_today =
              this.form.body_water_content;
            this.form.measurements[index].grade_result_today =
              this.checkGrade(status);
            break;
          case "ความอ่อนตัวของหลัง":
            status = flexibilityWaist(
              this.settings.flexibility_waist,
              this.form.flexibility_waist
            );
            this.form.measurements[index].result_today =
              this.form.flexibility_waist;
            this.form.measurements[index].grade_result_today =
              this.checkGrade(status);
            break;
          case "คะแนนท่าทาง":
            status = postureScore(
              this.settings.posture_score,
              this.form.posture_score
            );
            this.form.measurements[index].result_today =
              this.form.posture_score;
            this.form.measurements[index].grade_result_today =
              this.checkGrade(status);
            break;
          case "ปัญหาท่าทาง":
            status = postureType(
              this.settings.posture_type,
              this.form.posture_type
            );
            this.form.measurements[index].result_today = this.form.posture_type;
            this.form.measurements[index].grade_result_today =
              this.checkGrade(status);
            break;
          case "ทดสอบลุกจากเก้าอี้":
            status = chairStandTest(
              this.settings.cardio_vascular_endurance_settings,
              this.form.age,
              this.form.gender,
              this.form.chair_stand_test
            );
            this.form.measurements[index].result_today =
              this.form.chair_stand_test;
            this.form.measurements[index].grade_result_today =
              this.checkGrade(status);
            break;
          case "ทดสอบก้าวขึ้น-ลง":
            status = stepBoxTest(
              this.settings.step_box_test_setting,
              this.form.age,
              this.form.gender,
              this.form.step_box_test
            );
            this.form.measurements[index].result_today =
              this.form.step_box_test;
            this.form.measurements[index].grade_result_today =
              this.checkGrade(status);
            break;
        }
      }

      console.log(status);
    },

    onInputImproveTarget(index, value) {
      let status = "";

      // get selected value from dropdown list
      let measurement_name = this.form.measurements[index].measurement_name;
      if (measurement_name) {
        switch (measurement_name) {
          case "ความดันโลหิตต่ำสุด/สูงสุด":
            status = bloodPressure(
              this.settings.BloodPressure,
              this.form.value,
              this.form.value
            );
            this.form.measurements[index].grade_improve_target =
              this.checkGrade(status);
            break;
          case "ความดันโลหิตต่ำสุด":
            status = bloodPressureMin(this.settings.BloodPressure, value);
            this.form.measurements[index].result_today =
              this.form.blood_pressure_min;
            this.form.measurements[index].grade_improve_target =
              this.checkGrade(status);
            break;
          case "ความดันโลหิตสูงสุด":
            status = bloodPressureMax(this.settings.BloodPressure, value);
            this.form.measurements[index].result_today =
              this.form.blood_pressure_max;
            this.form.measurements[index].grade_improve_target =
              this.checkGrade(status);
            break;
          case "ดัชนีมวลกาย":
            status = bmiSetting(this.settings.bmi, value);
            this.form.measurements[index].grade_improve_target =
              this.checkGrade(status);
            break;
          case "อัตราการเผาผลาญ":
            status = basalMetabolicRateSetting(
              this.settings.BasalMetabolicRate,
              this.form.age,
              this.form.gender,
              value
            );
            this.form.measurements[index].grade_improve_target =
              this.checkGrade(status);
            break;
          case "ระดับไขมันในร่างกาย":
            status = bodyFatRateSetting(
              this.settings.BodyFatRate,
              this.form.age,
              this.form.gender,
              value
            );
            this.form.measurements[index].grade_improve_target =
              this.checkGrade(status);
            break;
          case "ระดับไขมันในช่องท้อง":
            status = visceralFatLevelSetting(
              this.settings.VisceralFatLevel,
              value
            );
            this.form.measurements[index].grade_improve_target =
              this.checkGrade(status);
            break;
          case "แรงบีบมือข้างซ้าย":
            status = gripStrengthLeftSetting(
              this.settings.GripStrength,
              this.form.gender,
              value,
              value
            );
            this.form.measurements[index].grade_improve_target =
              this.checkGrade(status);
            break;
          case "แรงบีบมือข้างขวา":
            status = gripStrengthRightSetting(
              this.settings.GripStrength,
              this.form.gender,
              value,
              value
            );
            this.form.measurements[index].grade_improve_target =
              this.checkGrade(status);
            break;
          case "มวลกล้ามเนื้อ":
            status = muscleMassPointsSetting(
              this.settings.MuscleMassPoints,
              this.form.age,
              this.form.gender,
              value
            );
            this.form.measurements[index].grade_improve_target =
              this.checkGrade(status);
            break;
          case "2 Step Test":
            status = stability(
              this.settings.StabilityCriteriaSetting,
              this.form.age,
              this.form.gender,
              value
            );
            this.form.measurements[index].grade_improve_target =
              this.checkGrade(status);
            break;
          case "ความอ่อนตัวหัวไหล่":
            status = FlexibilityUpper(value);
            this.form.measurements[index].grade_improve_target =
              this.checkGrade(status);
            break;
          case "ความอ่อนตัวขาข้างซ้าย":
            status = FlexibilityLower(
              this.settings.FlexibilityLowerCriteriaSetting,
              this.form.age,
              this.form.gender,
              value
            );
            this.form.measurements[index].grade_improve_target =
              this.checkGrade(status);
            break;
          case "ความอ่อนตัวขาข้างขวา":
            status = FlexibilityLower(
              this.settings.FlexibilityLowerCriteriaSetting,
              this.form.age,
              this.form.gender,
              value
            );
            this.form.measurements[index].grade_improve_target =
              this.checkGrade(status);
            break;
          case "อายุของปอด (ความจุปอด)":
            status = lungAge(this.settings.lung_age, this.form.age, value);
            this.form.measurements[index].grade_improve_target =
              this.checkGrade(status);
            break;
          case "อายุผิว":
            status = skinAge(this.settings.skin_age, this.form.age, value);
            this.form.measurements[index].grade_improve_target =
              this.checkGrade(status);
            break;
          case "ความแข็งแรงกล้ามเนื้อหลัง":
            status = backStrength(
              this.settings.back_strength,
              this.form.age,
              this.form.gender,
              value
            );
            this.form.measurements[index].grade_improve_target =
              this.checkGrade(status);
            break;
          case "การทรงตัวยืนขาเดียว":
            status = singleLegBalance(this.settings.single_leg_balance, value);
            this.form.measurements[index].grade_improve_target =
              this.checkGrade(status);
            break;
          case "Stand Up Test":
            status = standUpTest(this.settings.stand_up_test, value);
            this.form.measurements[index].grade_improve_target =
              this.checkGrade(status);
            break;
          case "Functional Test":
            status = functionalTest(this.settings.functional_test, value);
            this.form.measurements[index].grade_improve_target =
              this.checkGrade(status);
            break;
          case "ระดับน้ำในร่างกาย":
            status = bodyWaterContent(
              this.settings.body_water_content,
              this.form.age,
              this.form.gender,
              value
            );
            this.form.measurements[index].grade_improve_target =
              this.checkGrade(status);
            break;
          case "ความอ่อนตัวของหลัง":
            status = flexibilityWaist(this.settings.flexibility_waist, value);
            this.form.measurements[index].grade_improve_target =
              this.checkGrade(status);
            break;
          case "คะแนนท่าทาง":
            status = postureScore(this.settings.posture_score, value);
            this.form.measurements[index].grade_improve_target =
              this.checkGrade(status);
            break;
          case "ปัญหาท่าทาง":
            status = postureType(this.settings.posture_type, value);
            this.form.measurements[index].grade_improve_target =
              this.checkGrade(status);
            break;
          case "ทดสอบลุกจากเก้าอี้":
            status = chairStandTest(
              this.settings.cardio_vascular_endurance_settings,
              this.form.age,
              this.form.gender,
              value
            );
            this.form.measurements[index].grade_improve_target =
              this.checkGrade(status);
            break;
          case "ทดสอบก้าวขึ้น-ลง":
            status = chairStandTest(
              this.settings.step_box_test_setting,
              this.form.age,
              this.form.gender,
              value
            );
            this.form.measurements[index].grade_improve_target =
              this.checkGrade(status);
            break;
        }
      }

      console.log(status);
    },
    checkClassOfGrade(grade) {
      switch (grade) {
        case "A":
          return "grade-standard";
        case "B":
          return "grade-warning";
        case "C":
          return "grade-danger";
        default:
          return "";
      }
    },
    checkGrade(status) {
      switch (status) {
        case "standard":
          return "A";
        case "warn":
          return "B";
        case "danger":
          return "C";
        default:
          return "N/A";
      }
    },

    setData() {
      this.form = {
        first_name: "Test",
        family_name: "Kisra",
        age: "40",
        gender: "M",
        phone: "222222222",
        height: "170",
        weight: "68",
        body_age: "45",
        vascular_age: "68",
        point_concern: "test",
        blood_pressure_min: "10",
        blood_pressure_max: "20",
        bmi: "25",
        basal_metabolic_rate: "30",
        body_fat_rate: "35",
        visceral_fat_level: "12",
        grip_strength_left: "45",
        grip_strength_right: "50",
        muscle_mass: "55",
        muscle_mass_points: "60",
        customer_type_id: "",
        customer_type_name: "",
        customer_status: "",
        stability: "1.64",
        flexibility_upper: "5",
        flexibility_lower: "41",
        training_machine_road_weight_s: "1",
        training_machine_road_weight_l: "1",
        training_machine_road_weight_h: "1",
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        trainer_name: "test",
        comment:
          "มีอาการปวดสะโพกมากเวลาเดิน หรือ วิ่งเป็นเวลานานมากกว่า 2 ชั่วโมง",
        message_pwp: "ยืดเส้นก่อนเดินหรือวิ่ง",
        lung_age: "45",
        skin_age: "46",
        back_strength: "100",
        single_leg_balance: "20",
        stand_up_test: "3",
        functional_test: "30",
        body_water_content: "77",
        flexibility_waist: "85",
        posture_score: "41",
        posture_type: "2",
        measurements: [
          {
            measurement_name: "",
            result_today: "",
            grade_result_today: "",
            improve_target: "",
            grade_improve_target: "",
          },
          {
            measurement_name: "",
            result_today: "",
            grade_result_today: "",
            improve_target: "",
            grade_improve_target: "",
          },
          {
            measurement_name: "",
            result_today: "",
            grade_result_today: "",
            improve_target: "",
            grade_improve_target: "",
          },
          {
            measurement_name: "",
            result_today: "",
            grade_result_today: "",
            improve_target: "",
            grade_improve_target: "",
          },
        ],
        problematic_part: [
          { position_problem: "", problem: "" },
          { position_problem: "", problem: "" },
          { position_problem: "", problem: "" },
        ],
      };
    },
  },
};
</script>

<style lang="scss">
.v-text-field input {
  height: 30px !important;
}

.card__customer__form {
  // border-radius: 10px !important;
  // box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2) !important;

  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
    > .v-input__control
    > .v-input__slot {
    box-shadow: unset !important;
  }
  .v-text-field fieldset,
  .v-text-field .v-input__control,
  .v-text-field .v-input__slot {
    border-radius: 10px;
  }
  .txt__label__form {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #a3a3a3;
  }
}
.area_input {
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
    > .v-input__control
    > .v-input__slot {
    box-shadow: unset !important;
    border: 1px solid;
    border-radius: 10px;
  }
}

.date_picker {
  .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
    min-height: 63px;
    width: 150px;
    min-height: 50px;
    font-size: 18px;
    border: 1px solid #000000;
    box-sizing: border-box;
  }
  .v-text-field fieldset,
  .v-text-field .v-input__control,
  .v-text-field .v-input__slot {
    border-radius: 30px;
  }
}
.v-messages__message {
  font-size: 10px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: rgba(0, 0, 0, 0.3) !important;
}
.switch {
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 30px;
  .btn-switch {
    border-radius: 30px;
  }
}

.problem-image {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  z-index: 1;
}
.grade-standard {
  color: #36a777;
}
.grade-warning {
  color: #ffbb02;
}
.grade-danger {
  color: #c71010;
}
.text-field-center {
  input {
    text-align: center !important;
  }
}
</style>
