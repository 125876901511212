<template>
  <div class="text_password">
    <label :for="label" class="font-20 font-w-500">{{ label }}</label>
    <v-text-field
      class="pa-0"
      :value="model"
      :placeholder="placeholder"
      @click:append="() => (value = !value)"
      :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
      :type="value ? 'password' : 'text'"
      :color="color"
      solo
      :rules="required ? [(v) => !!v || errorMessage + ' is required'] : []"
      required
      :hide-details="!required"
      @input="$emit('update-data', $event)"
    />
    <!-- :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'" -->
  </div>
</template>

<script>
export default {
  
  data() {
    return {
      value: String,
    };
  },
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    errorMessage: String,
    model: String,
    label: String,
    placeholder: String,
    type: {
      typr: String,
      default: "text",
    },
    color: {
      typr: String,
      default: "var(--color-8)",
    },
  },
};
</script>

<style lang="scss">
.text_password {
  .theme--light.v-input input {
    color: var(--color-8);
  }
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
    > .v-input__control
    > .v-input__slot {
    box-shadow: unset;
  }
  .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot,
  .v-text-field.v-text-field--enclosed .v-text-field__details {
    padding: 0px;
  }
  .v-input__slot {
    margin-bottom: 0px;
  }
  .v-text-field.v-text-field--enclosed .v-text-field__details {
    padding-top: 0px;
    margin-bottom: 0px;
  }
}
</style>
