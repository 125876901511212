<template>
  <div class="bg_main">
    <Loading color="#C71010" :active.sync="isLoading" :is-full-page="true" />

    <div
      class="px-12"
      :class="$route.name == 'questionnaire-detail' ? 'pt-0' : ''"
    >
      <v-layout
        :class="
          $route.name == 'questionnaire' || $route.name == 'home'
            ? 'custom-header'
            : ''
        "
        align-center
      >
        <v-flex v-if="$route.name !== 'questionnaire-detail'">
          <v-img width="186" src="../assets/images/logo.png"></v-img>
        </v-flex>
        <v-flex v-else>
          <v-layout align-center>
            <v-flex shrink>
              <v-btn icon @click="$router.go(-1)">
                <v-icon> mdi-arrow-left </v-icon>
              </v-btn>
            </v-flex>
            <v-flex shrink class="pl-3">
              <GroupTitle
                onPage="Questionnaire"
                txtTh="แบบสอบถาม"
                txtEn="Questionnaire"
              />
            </v-flex>
          </v-layout>
        </v-flex>
        <v-spacer />
        <v-flex
          shrink
          class="d-flex align-end pr-6"
          style="height: 69px"
          v-if="$route.name == 'questionnaire' || $route.name == 'home'"
        >
          <v-layout justify-space-between>
            <v-flex
              shrink
              class="tab-menu mx-2 pb-1"
              :class="{ 'active-tap': $route.path === '/' }"
              @click="$router.push('/')"
            >
              Customer List
            </v-flex>
            <v-flex
              shrink
              mx-2
              class="tab-menu pb-1"
              :class="{ 'active-tap': $route.path === '/questionnaire' }"
              @click="$router.push('/questionnaire')"
            >
              Questionnaire
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex shrink class="d-flex align-end">
          <v-layout align-center>
            <v-flex shrink mr-6>
              <v-tooltip bottom color="#6b0000">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    to="/change-password"
                    icon
                    color="transparent"
                    small
                    class="btn_hover"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-img
                      width="36"
                      src="../assets/images/Password.png"
                    ></v-img>
                  </v-btn>
                </template>
                <span class="mt-3"> Change Password </span>
              </v-tooltip>
            </v-flex>
            <v-flex shrink mr-6>
              <v-tooltip bottom color="#6b0000">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    to="/setting"
                    icon
                    class="btn_hover"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-img
                      width="36"
                      src="../assets/images/setting-icon.png"
                    ></v-img>
                  </v-btn>
                </template>
                <span class="mt-3"> Setting </span>
              </v-tooltip>
            </v-flex>
            <v-flex shrink>
              <v-tooltip bottom color="#6b0000">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="btn_hover"
                    v-bind="attrs"
                    v-on="on"
                    @click="signout()"
                  >
                    <v-img
                      width="36"
                      src="../assets/images/logout-icon.png"
                    ></v-img>
                  </v-btn>
                </template>
                <span class="mt-3"> Logout </span>
              </v-tooltip>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>

    <v-main class="px-12">
      <transition
        name="router-animation"
        mode="out-in"
        enter-active-class="animated fadeIn fast"
        leave-active-class="animated fadeOut faster"
      >
        <router-view></router-view>
      </transition>
    </v-main>
  </div>
</template>

<script>
import GroupTitle from "@/components/GroupTitle.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: { Loading, GroupTitle },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    signout() {
      this.$swal({
        title: "Are you sure?",
        text: "You want to logout now?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#000000",
        cancelButtonColor: "#C71010",
        confirmButtonText: "Yes, Logout!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.isLoading = true;
          this.$store.dispatch("auth/signout", this.form).then((res) => {
            this.isLoading = false;
            this.$router.push("/login");
          });
        }
      });
    },
  },
};
</script>

<style>
.custom-header {
  border-bottom: 1px solid #d3d3d3;
}
.tab-menu {
  cursor: pointer;
  font-weight: 500;
}
.active-tap {
  color: #c71010;
  border-bottom: 2px solid #c71010;
}
</style>
