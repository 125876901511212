<template>
  <div class="setting mb-16">
    <v-layout align-center>
      <v-flex shrink>
        <v-btn icon class="mr-6" to="/">
          <v-icon large color="#2E2528">mdi-arrow-left</v-icon>
        </v-btn>
      </v-flex>
      <v-flex shrink>
        <group-title onPage="setting" txtEn="SETTING" txtTh="ตั้งค่า" />
      </v-flex>
      <v-spacer />
    </v-layout>

    <!-- Form -->
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-divider class="mb-6" style="background-color: #d3d3d3" />

      <!-- ************** Weighted Score ************ -->
      <div>
        <div class="pl-5 mb-2">
          <div class="font-20 font-w-600" style="color: #c71010">
            ค่าประเมินระดับสุขภาพ
          </div>
          <div class="font-12 font-w-400 mt-n1">Weighted Score</div>
        </div>

        <v-row no-gutters>
          <!-- Measurements -->
          <v-col cols="6">
            <v-layout class="input__setting">
              <v-flex xs7>
                <box-card>
                  <div class="mb-2">
                    <div class="font-24 font-w-600">หน่วยการวัดผล</div>
                    <div class="font-12 mt-n1">Measurements</div>
                  </div>

                  <v-divider />

                  <div v-for="(rate, i) in form.rate" :key="i">
                    <div class="flex__box d-flex flex-column justify-center">
                      <div class="font-20 font-w-600" style="color: #c71010">
                        {{ rate.name_th }}
                      </div>
                      <div class="font-12 mt-n1">{{ rate.name_en }}</div>
                    </div>

                    <v-divider v-if="form.rate.length != i + 1" />
                  </div>
                </box-card>
              </v-flex>

              <!-- Rate -->
              <v-flex xs5>
                <box-card theme="white-bluelight" class="mx-2">
                  <div class="mb-2">
                    <div class="font-24 font-w-600 standard_color">
                      ค่าประเมิน
                    </div>
                    <div class="font-12 mt-n1">Rate</div>
                  </div>

                  <v-divider />

                  <div v-for="(rate, i) in form.rate" :key="i">
                    <v-row no-gutters align="center" class="flex__box">
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="number"
                          :model="rate.rate"
                          @update-data="rate.rate = $event"
                        />
                      </v-col>
                    </v-row>

                    <v-divider v-if="form.rate.length != i + 1" />
                  </div>
                </box-card>
              </v-flex>
            </v-layout>
          </v-col>

          <!-- Definition -->
          <v-col cols="6">
            <box-card theme="gray-black" class="area_input mb-6">
              <div class="font-24 font-w-600">ข้อกังวล</div>
              <div class="font-12 mt-n1 mb-3">Point of Concern</div>
              <v-textarea
                color="#C71010"
                rows="8"
                solo
                v-model="form.point_concern.point_concern"
                placeholder="Point of Concern"
                class="mb-6"
              />
            </box-card>

            <v-layout class="input__setting">
              <v-flex xs7>
                <box-card>
                  <div class="mb-2">
                    <div class="font-24 font-w-600">การจัดอันดับ</div>
                    <div class="font-12 mt-n1">Definition</div>
                  </div>

                  <v-divider />

                  <div v-for="(rate, i) in form.rank_setting" :key="i">
                    <div class="flex__box d-flex flex-column justify-center">
                      <div class="font-20 font-w-600" style="color: #c71010">
                        {{ rate.name }}
                      </div>
                    </div>

                    <v-divider v-if="form.rank_setting.length != i + 1" />
                  </div>
                </box-card>
              </v-flex>

              <!-- Score -->
              <v-flex xs5>
                <box-card theme="white-bluelight">
                  <div class="mb-2">
                    <div class="font-24 font-w-600 standard_color">คะแนน</div>
                    <div class="font-12 mt-n1">Score</div>
                  </div>

                  <v-divider />

                  <div v-for="(rank_setting, i) in form.rank_setting" :key="i">
                    <v-row no-gutters align="center" class="flex__box">
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="number"
                          :model="rank_setting.from"
                          @update-data="rank_setting.from = $event"
                        />
                      </v-col>
                      <v-col cols="auto" class="mx-2">To</v-col>
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="number"
                          :model="rank_setting.to"
                          @update-data="rank_setting.to = $event"
                        />
                      </v-col>
                    </v-row>

                    <v-divider v-if="form.rank_setting.length != i + 1" />
                  </div>
                </box-card>
              </v-flex>
            </v-layout>
          </v-col>
        </v-row>
      </div>

      <v-divider class="mt-8 mb-5" style="background-color: #d3d3d3" />

      <!-- *************1 Blood Pressure Minimum *************-->
      <div>
        <div class="pl-5 mb-2">
          <div class="font-20 font-w-600" style="color: #c71010">
            1.ความดันโลหิตต่ำสุด
          </div>
          <div class="font-12 font-w-400 mt-n1">Blood Pressure Minimum</div>
        </div>
        <v-layout class="input__setting">
          <!-- Standard -->
          <v-flex xs4>
            <box-card theme="standard">
              <div class="mb-2">
                <div class="font-24 font-w-600 standard_color">ปกติ</div>
                <div class="font-12 mt-n1">Standard</div>
              </div>
              <v-divider class="" style="background-color: #d3d3d3" />
              <v-row no-gutters align="center" class="flex__box">
                <v-col>
                  <TextInputSetting
                    required
                    inputType="number"
                    :model="form.blood_pressure.standard_min_from"
                    @update-data="
                      form.blood_pressure.standard_min_from = $event
                    "
                  />
                </v-col>
                <v-col cols="auto" class="mx-2">To</v-col>
                <v-col>
                  <TextInputSetting
                    required
                    inputType="number"
                    :model="form.blood_pressure.standard_min_to"
                    @update-data="form.blood_pressure.standard_min_to = $event"
                  />
                </v-col>
              </v-row>
            </box-card>
          </v-flex>

          <!-- Warning -->
          <v-flex xs4>
            <box-card theme="warn">
              <div class="mb-2">
                <div class="font-24 font-w-600 warn_color">เฝ้าระวัง</div>
                <div class="font-12 mt-n1">Warn</div>
              </div>
              <v-divider class="" style="background-color: #d3d3d3" />
              <v-row no-gutters align="center" class="flex__box">
                <v-col>
                  <TextInputSetting
                    required
                    inputType="number"
                    :model="form.blood_pressure.warn_min_from"
                    @update-data="form.blood_pressure.warn_min_from = $event"
                  />
                </v-col>
                <v-col cols="auto" class="mx-2">To</v-col>
                <v-col>
                  <TextInputSetting
                    required
                    inputType="number"
                    :model="form.blood_pressure.warn_min_to"
                    @update-data="form.blood_pressure.warn_min_to = $event"
                  />
                </v-col>
              </v-row>
            </box-card>
          </v-flex>

          <!-- Danger -->
          <v-flex xs4>
            <box-card theme="danger">
              <div class="mb-2">
                <div class="font-24 font-w-600 danger_color">อันตราย</div>
                <div class="font-12 mt-n1">Danger</div>
              </div>
              <v-divider class="" style="background-color: #d3d3d3" />
              <v-row no-gutters align="center" class="flex__box">
                <v-col>
                  <TextInputSetting
                    required
                    inputType="number"
                    :model="form.blood_pressure.danger_min_from"
                    @update-data="form.blood_pressure.danger_min_from = $event"
                  />
                </v-col>
                <v-col cols="auto" class="mx-2">To</v-col>
                <v-col>
                  <TextInputSetting
                    required
                    inputType="number"
                    :model="form.blood_pressure.danger_min_to"
                    @update-data="form.blood_pressure.danger_min_to = $event"
                  />
                </v-col>
              </v-row>
            </box-card>
          </v-flex>
        </v-layout>

        <!-- ผลการวัด -->
        <box-card class="mt-6 area_input">
          <!-- ผลการวัดวันนี้ -->
          <v-row align="start">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ผลการวัดวันนี้
              </div>
              <div class="font-24 font-w-600">ความเสี่ยง</div>
              <div class="font-18 font-w-600 mt-n1">(อาการทั่วไป)</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <div
                class="font-24 font-w-600 standard_color d-flex align-center"
              >
                A
              </div>
              <div class="font-12 mt-n1 mb-2">standard</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.blood_pressure.min_risk_standard_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <div class="font-24 font-w-600 warn_color d-flex align-center">
                B
              </div>
              <div class="font-12 mt-n1 mb-2">warn</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.blood_pressure.min_risk_warn_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <div class="font-24 font-w-600 danger_color d-flex align-center">
                C
              </div>
              <div class="font-12 mt-n1 mb-2">Danger</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.blood_pressure.min_risk_danger_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>
          </v-row>

          <v-divider class="mx-n4 mb-5" style="background-color: #d3d3d3" />

          <!--  ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา) -->
          <v-row align="start" class="mb-1">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
              </div>
              <div class="font-24 font-w-600">วิธีปรับปรุง</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.blood_pressure.min_improve_standard_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="blood_pressure_min.standard['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueBloodMinStandardCheckbox(
                      'blood_pressure',
                      item,
                      $event
                    )
                  "
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.blood_pressure.min_improve_warn_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="blood_pressure_min.warn['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueBloodMinWarnCheckbox('blood_pressure', item, $event)
                  "
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.blood_pressure.min_improve_danger_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="blood_pressure_min.danger['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueBloodMinDangerCheckbox(
                      'blood_pressure',
                      item,
                      $event
                    )
                  "
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
        </box-card>
      </div>

      <v-divider class="mt-8 mb-5" style="background-color: #d3d3d3" />

      <!-- *************1 Blood Pressure Maximum *************-->
      <div>
        <div class="pl-5 mb-2">
          <div class="font-20 font-w-600" style="color: #c71010">
            1.ความดันโลหิตสูงสุด
          </div>
          <div class="font-12 font-w-400 mt-n1">Blood Pressure Maximum</div>
        </div>

        <v-layout class="input__setting">
          <!-- Standard -->
          <v-flex xs4>
            <box-card theme="standard">
              <div class="mb-2">
                <div class="font-24 font-w-600 standard_color">ปกติ</div>
                <div class="font-12 mt-n1">Standard</div>
              </div>
              <v-divider class="" style="background-color: #d3d3d3" />
              <v-row no-gutters align="center" class="flex__box">
                <v-col>
                  <TextInputSetting
                    required
                    inputType="number"
                    :model="form.blood_pressure.standard_max_from"
                    @update-data="
                      form.blood_pressure.standard_max_from = $event
                    "
                  />
                </v-col>
                <v-col cols="auto" class="mx-2">To</v-col>
                <v-col>
                  <TextInputSetting
                    required
                    inputType="number"
                    :model="form.blood_pressure.standard_max_to"
                    @update-data="form.blood_pressure.standard_max_to = $event"
                  />
                </v-col>
              </v-row>
            </box-card>
          </v-flex>

          <!-- Warning -->
          <v-flex xs4>
            <box-card theme="warn">
              <div class="mb-2">
                <div class="font-24 font-w-600 warn_color">เฝ้าระวัง</div>
                <div class="font-12 mt-n1">Warn</div>
              </div>
              <v-divider class="" style="background-color: #d3d3d3" />
              <v-row no-gutters align="center" class="flex__box">
                <v-col>
                  <TextInputSetting
                    required
                    :model="form.blood_pressure.warn_max_from"
                    @update-data="form.blood_pressure.warn_max_from = $event"
                  />
                </v-col>
                <v-col cols="auto" class="mx-2">To</v-col>
                <v-col>
                  <TextInputSetting
                    required
                    inputType="number"
                    :model="form.blood_pressure.warn_max_to"
                    @update-data="form.blood_pressure.warn_max_to = $event"
                  />
                </v-col>
              </v-row>
            </box-card>
          </v-flex>

          <!-- Danger -->
          <v-flex xs4>
            <box-card theme="danger">
              <div class="mb-2">
                <div class="font-24 font-w-600 danger_color">อันตราย</div>
                <div class="font-12 mt-n1">Danger</div>
              </div>
              <v-divider class="" style="background-color: #d3d3d3" />
              <v-row no-gutters align="center" class="flex__box">
                <v-col>
                  <TextInputSetting
                    required
                    :model="form.blood_pressure.danger_max_from"
                    @update-data="form.blood_pressure.danger_max_from = $event"
                  />
                </v-col>
                <v-col cols="auto" class="mx-2">To</v-col>
                <v-col>
                  <TextInputSetting
                    required
                    inputType="number"
                    :model="form.blood_pressure.danger_max_to"
                    @update-data="form.blood_pressure.danger_max_to = $event"
                  />
                </v-col>
              </v-row>
            </box-card>
          </v-flex>
        </v-layout>

        <!-- ผลการวัด -->
        <box-card class="mt-6 area_input">
          <!-- ผลการวัดวันนี้ -->
          <v-row align="start">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ผลการวัดวันนี้
              </div>
              <div class="font-24 font-w-600">ความเสี่ยง</div>
              <div class="font-18 font-w-600 mt-n1">(อาการทั่วไป)</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <div
                class="font-24 font-w-600 standard_color d-flex align-center"
              >
                A
              </div>
              <div class="font-12 mt-n1 mb-2">standard</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.blood_pressure.max_risk_standard_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <div class="font-24 font-w-600 warn_color d-flex align-center">
                B
              </div>
              <div class="font-12 mt-n1 mb-2">warn</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.blood_pressure.max_risk_warn_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <div class="font-24 font-w-600 danger_color d-flex align-center">
                C
              </div>
              <div class="font-12 mt-n1 mb-2">Danger</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.blood_pressure.max_risk_danger_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>
          </v-row>

          <v-divider class="mx-n4 mb-5" style="background-color: #d3d3d3" />

          <!--  ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา) -->
          <v-row align="start" class="mb-1">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
              </div>
              <div class="font-24 font-w-600">วิธีปรับปรุง</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.blood_pressure.max_improve_standard_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="blood_pressure_max.standard['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueBloodMaxStandardCheckbox(
                      'blood_pressure',
                      item,
                      $event
                    )
                  "
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.blood_pressure.max_improve_warn_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="blood_pressure_max.warn['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueBloodMaxWarnCheckbox('blood_pressure', item, $event)
                  "
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.blood_pressure.max_improve_danger_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="blood_pressure_max.danger['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueBloodMaxDangerCheckbox(
                      'blood_pressure',
                      item,
                      $event
                    )
                  "
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
        </box-card>
      </div>

      <v-divider class="mt-8 mb-5" style="background-color: #d3d3d3" />

      <!-- *************2 BMI *************-->
      <div>
        <div class="pl-5 mb-2">
          <div class="font-20 font-w-600" style="color: #c71010">
            2.ดัชนีมวลกาย
          </div>
          <div class="font-12 font-w-400 mt-n1">BMI</div>
        </div>
        <v-layout class="input__setting">
          <!-- Standard -->
          <v-flex xs3>
            <box-card theme="standard">
              <div class="mb-2">
                <div class="font-24 font-w-600 standard_color">ปกติ</div>
                <div class="font-12 mt-n1">Standard</div>
              </div>

              <v-divider />
              <v-row no-gutters align="center" class="flex__box">
                <v-col>
                  <TextInputSetting
                    required
                    inputType="decimal"
                    :model="form.bmi.standard_from"
                    @update-data="form.bmi.standard_from = $event"
                  />
                </v-col>
                <v-col cols="auto" class="mx-2">To</v-col>
                <v-col>
                  <TextInputSetting
                    required
                    inputType="decimal"
                    :model="form.bmi.standard_to"
                    @update-data="form.bmi.standard_to = $event"
                  />
                </v-col>
              </v-row>
            </box-card>
          </v-flex>

          <!-- Warning -->
          <v-flex xs3>
            <box-card theme="warn">
              <div class="mb-2">
                <div class="font-24 font-w-600 warn_color d-flex align-center">
                  เฝ้าระวัง <span class="font-12">(ต่ำกว่า)</span>
                </div>
                <div class="font-12 mt-n1">Warn(Less than)</div>
              </div>
              <v-divider />
              <v-row no-gutters align="center" class="flex__box">
                <v-col>
                  <TextInputSetting
                    required
                    inputType="decimal"
                    :model="form.bmi.warn_less_from"
                    @update-data="form.bmi.warn_less_from = $event"
                  />
                </v-col>
                <v-col cols="auto" class="mx-2">To</v-col>
                <v-col>
                  <TextInputSetting
                    required
                    inputType="decimal"
                    :model="form.bmi.warn_less_to"
                    @update-data="form.bmi.warn_less_to = $event"
                  />
                </v-col>
              </v-row>
            </box-card>
          </v-flex>
          <v-flex xs3>
            <box-card theme="warn">
              <div class="mb-2">
                <div class="font-24 font-w-600 warn_color d-flex align-center">
                  เฝ้าระวัง <span class="font-12">(มากกว่า)</span>
                </div>
                <div class="font-12 mt-n1">Warn(More than)</div>
              </div>

              <v-divider />

              <v-row no-gutters align="center" class="flex__box">
                <v-col>
                  <TextInputSetting
                    required
                    inputType="decimal"
                    :model="form.bmi.warn_than_from"
                    @update-data="form.bmi.warn_than_from = $event"
                  />
                </v-col>
                <v-col cols="auto" class="mx-2">To</v-col>
                <v-col>
                  <TextInputSetting
                    required
                    inputType="decimal"
                    :model="form.bmi.warn_than_to"
                    @update-data="form.bmi.warn_than_to = $event"
                  />
                </v-col>
              </v-row>
            </box-card>
          </v-flex>

          <!-- Danger -->
          <v-flex xs3>
            <box-card theme="danger">
              <div class="mb-2">
                <div class="font-24 font-w-600 danger_color">อันตราย</div>
                <div class="font-12 mt-n1">Danger</div>
              </div>

              <v-divider />

              <v-row no-gutters align="center" class="flex__box">
                <v-col>
                  <TextInputSetting
                    required
                    inputType="number"
                    :model="form.bmi.danger_from"
                    @update-data="form.bmi.danger_from = $event"
                  />
                </v-col>
                <v-col cols="auto" class="mx-2">To</v-col>
                <v-col>
                  <TextInputSetting
                    required
                    inputType="number"
                    :model="form.bmi.danger_to"
                    @update-data="form.bmi.danger_to = $event"
                  />
                </v-col>
              </v-row>
            </box-card>
          </v-flex>
        </v-layout>

        <!-- ผลการวัด -->
        <box-card class="mt-6 area_input">
          <!-- ผลการวัดวันนี้ -->
          <v-row align="start">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ผลการวัดวันนี้
              </div>
              <div class="font-24 font-w-600">ความเสี่ยง</div>
              <div class="font-18 font-w-600 mt-n1">(อาการทั่วไป)</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <div
                class="font-24 font-w-600 standard_color d-flex align-center"
              >
                A
              </div>
              <div class="font-12 mt-n1 mb-2">standard</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.bmi.risk_standard_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <div class="font-24 font-w-600 warn_color d-flex align-center">
                B
              </div>
              <div class="font-12 mt-n1 mb-2">warn</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.bmi.risk_warn_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <div class="font-24 font-w-600 danger_color d-flex align-center">
                C
              </div>
              <div class="font-12 mt-n1 mb-2">Danger</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.bmi.risk_danger_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>
          </v-row>

          <v-divider class="mx-n4 mb-5" style="background-color: #d3d3d3" />

          <!--  ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา) -->
          <v-row align="start" class="mb-1">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
              </div>
              <div class="font-24 font-w-600">วิธีปรับปรุง</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.bmi.improve_standard_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="bmi.standard['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="setValueStandardCheckbox('bmi', item, $event)"
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.bmi.improve_warn_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="bmi.warn['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="setValueWarnCheckbox('bmi', item, $event)"
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.bmi.improve_danger_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="bmi.danger['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="setValueDangerCheckbox('bmi', item, $event)"
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
        </box-card>
      </div>

      <v-divider class="mt-8 mb-5" style="background-color: #d3d3d3" />

      <!-- *************3 Basal metabolic rate  *************-->
      <div>
        <div class="pl-5 mb-2">
          <div class="font-20 font-w-600" style="color: #c71010">
            3.อัตราการเผาผลาญ
          </div>
          <div class="font-12 font-w-400 mt-n1">Basal metabolic rate</div>
        </div>

        <v-row no-gutters>
          <v-col cols="4">
            <box-card>
              <v-row no-gutters class="pt-3 mb-3">
                <v-col cols="6" class="font-24 font-w-600">Gender</v-col>
                <v-col cols="6" class="font-24 font-w-600">Age</v-col>
              </v-row>

              <v-divider />

              <div
                v-for="(
                  item, basal_metabolic_rate_index
                ) in form.basal_metabolic_rate"
                :key="basal_metabolic_rate_index"
              >
                <div class="flex__box d-flex flex-column justify-center">
                  <v-row
                    no-gutters
                    align="center"
                    :class="
                      form.basal_metabolic_rate.length ==
                      basal_metabolic_rate_index + 1
                        ? 'mb-3'
                        : ''
                    "
                  >
                    <v-col cols="6" class="font-16 font-w-600">
                      {{ item.gender === "M" ? "Male" : "Female" }}
                    </v-col>
                    <v-col cols="6" class="font-16 font-w-600">
                      {{ item.age_from }}-{{ item.age_to }}
                    </v-col>
                  </v-row>
                </div>
                <v-divider
                  v-if="
                    form.basal_metabolic_rate.length !=
                    basal_metabolic_rate_index + 1
                  "
                />
              </div>
            </box-card>
          </v-col>
          <v-col cols="8">
            <v-layout class="input__setting">
              <!-- Standard -->
              <v-flex xs4>
                <box-card theme="standard">
                  <div class="mb-2">
                    <div class="font-24 font-w-600 standard_color">ปกติ</div>
                    <div class="font-12 mt-n1">Standard</div>
                  </div>

                  <v-divider />

                  <div
                    v-for="(
                      item, basal_metabolic_rate_index
                    ) in form.basal_metabolic_rate"
                    :key="basal_metabolic_rate_index"
                  >
                    <v-row no-gutters align="center" class="flex__box">
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="number"
                          :model="item.standard_from"
                          @update-data="item.standard_from = $event"
                        />
                      </v-col>
                      <v-col cols="auto" class="mx-2">To</v-col>
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="number"
                          :model="item.standard_to"
                          @update-data="item.standard_to = $event"
                        />
                      </v-col>
                    </v-row>

                    <v-divider
                      v-if="
                        form.basal_metabolic_rate.length !=
                        basal_metabolic_rate_index + 1
                      "
                    />
                  </div>
                </box-card>
              </v-flex>

              <!-- Warning -->
              <v-flex xs4>
                <box-card theme="warn">
                  <div class="mb-2">
                    <div class="font-24 font-w-600 warn_color">เฝ้าระวัง</div>
                    <div class="font-12 mt-n1">Warn</div>
                  </div>

                  <v-divider />
                  <div
                    v-for="(
                      item, basal_metabolic_rate_index
                    ) in form.basal_metabolic_rate"
                    :key="basal_metabolic_rate_index"
                  >
                    <v-row no-gutters align="center" class="flex__box">
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="number"
                          :model="item.warn_from"
                          @update-data="item.warn_from = $event"
                        />
                      </v-col>
                      <v-col cols="auto" class="mx-2">To</v-col>
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="number"
                          :model="item.warn_to"
                          @update-data="item.warn_to = $event"
                        />
                      </v-col>
                    </v-row>

                    <v-divider
                      v-if="
                        form.basal_metabolic_rate.length !=
                        basal_metabolic_rate_index + 1
                      "
                    />
                  </div>
                </box-card>
              </v-flex>

              <!-- Danger -->
              <v-flex xs4>
                <box-card theme="danger">
                  <div class="mb-2">
                    <div class="font-24 font-w-600 danger_color">อันตราย</div>
                    <div class="font-12 mt-n1">Danger</div>
                  </div>

                  <v-divider />

                  <div
                    v-for="(
                      item, basal_metabolic_rate_index
                    ) in form.basal_metabolic_rate"
                    :key="basal_metabolic_rate_index"
                  >
                    <v-row no-gutters align="center" class="flex__box">
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="number"
                          :model="item.danger_from"
                          @update-data="item.danger_from = $event"
                        />
                      </v-col>
                      <v-col cols="auto" class="mx-2">To</v-col>
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="number"
                          :model="item.danger_to"
                          @update-data="item.danger_to = $event"
                        />
                      </v-col>
                    </v-row>

                    <v-divider
                      v-if="
                        form.basal_metabolic_rate.length !=
                        basal_metabolic_rate_index + 1
                      "
                    />
                  </div>
                </box-card>
              </v-flex>
            </v-layout>
          </v-col>
        </v-row>

        <!-- ผลการวัด -->
        <box-card class="mt-6 area_input">
          <!-- ผลการวัดวันนี้ -->
          <v-row align="start">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ผลการวัดวันนี้
              </div>
              <div class="font-24 font-w-600">ความเสี่ยง</div>
              <div class="font-18 font-w-600 mt-n1">(อาการทั่วไป)</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <div
                class="font-24 font-w-600 standard_color d-flex align-center"
              >
                A
              </div>
              <div class="font-12 mt-n1 mb-2">standard</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="
                  form.basal_metabolic_rate_settings_text.risk_standard_text
                "
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <div class="font-24 font-w-600 warn_color d-flex align-center">
                B
              </div>
              <div class="font-12 mt-n1 mb-2">warn</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.basal_metabolic_rate_settings_text.risk_warn_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <div class="font-24 font-w-600 danger_color d-flex align-center">
                C
              </div>
              <div class="font-12 mt-n1 mb-2">Danger</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="
                  form.basal_metabolic_rate_settings_text.risk_danger_text
                "
                :rules="[(v) => !!v || '']"
              />
            </v-col>
          </v-row>

          <v-divider class="mx-n4 mb-5" style="background-color: #d3d3d3" />

          <!--  ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา) -->
          <v-row align="start" class="mb-1">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
              </div>
              <div class="font-24 font-w-600">วิธีปรับปรุง</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="
                  form.basal_metabolic_rate_settings_text.improve_standard_text
                "
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="
                    basal_metabolic_rate_settings_text.standard[
                      'checkbox' + (i + 1)
                    ]
                  "
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueStandardCheckbox(
                      'basal_metabolic_rate_settings_text',
                      item,
                      $event
                    )
                  "
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="
                  form.basal_metabolic_rate_settings_text.improve_warn_text
                "
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="
                    basal_metabolic_rate_settings_text.warn[
                      'checkbox' + (i + 1)
                    ]
                  "
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueWarnCheckbox(
                      'basal_metabolic_rate_settings_text',
                      item,
                      $event
                    )
                  "
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="
                  form.basal_metabolic_rate_settings_text.improve_danger_text
                "
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="
                    basal_metabolic_rate_settings_text.danger[
                      'checkbox' + (i + 1)
                    ]
                  "
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueDangerCheckbox(
                      'basal_metabolic_rate_settings_text',
                      item,
                      $event
                    )
                  "
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
        </box-card>
      </div>

      <v-divider class="mt-8 mb-5" style="background-color: #d3d3d3" />

      <!-- *************4 Body Fat Rate *************** -->
      <div>
        <div class="pl-5 mb-2">
          <div class="font-20 font-w-600" style="color: #c71010">
            4.ระดับไขมันในร่างกาย
          </div>
          <div class="font-12 font-w-400 mt-n1">Body Fat Rate</div>
        </div>

        <v-row no-gutters>
          <v-col>
            <box-card>
              <v-row no-gutters class="pt-3 mb-3">
                <v-col cols="6" class="font-24 font-w-600">Gender</v-col>
                <v-col cols="6" class="font-24 font-w-600">Age</v-col>
              </v-row>

              <v-divider />

              <div
                v-for="(item, body_fat_rate_index) in form.body_fat_rate"
                :key="body_fat_rate_index"
              >
                <div class="flex__box d-flex flex-column justify-center">
                  <v-row
                    align="center"
                    no-gutters
                    :class="
                      form.body_fat_rate.length == body_fat_rate_index + 1
                        ? 'mb-3'
                        : ''
                    "
                  >
                    <v-col cols="6" class="font-16 font-w-600">
                      {{ item.gender === "M" ? "Male" : "Female" }}
                    </v-col>
                    <v-col cols="6" class="font-16 font-w-600">
                      {{ item.age_from }}-{{ item.age_to }}
                    </v-col>
                  </v-row>
                </div>

                <v-divider
                  v-if="form.body_fat_rate.length != body_fat_rate_index + 1"
                />
              </div>
            </box-card>
          </v-col>
          <v-col cols="9">
            <v-layout class="input__setting">
              <!-- Standard -->
              <v-flex xs3>
                <box-card theme="standard">
                  <div class="mb-2">
                    <div class="font-24 font-w-600 standard_color">ปกติ</div>
                    <div class="font-12 mt-n1">Standard</div>
                  </div>

                  <v-divider />
                  <div
                    v-for="(item, body_fat_rate_index) in form.body_fat_rate"
                    :key="body_fat_rate_index"
                  >
                    <v-row no-gutters align="center" class="flex__box">
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.standard_from"
                          @update-data="item.standard_from = $event"
                        />
                      </v-col>
                      <v-col cols="auto" class="mx-2">To</v-col>
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.standard_to"
                          @update-data="item.standard_to = $event"
                        />
                      </v-col>
                    </v-row>

                    <v-divider
                      v-if="
                        form.body_fat_rate.length != body_fat_rate_index + 1
                      "
                    />
                  </div>
                </box-card>
              </v-flex>

              <!-- Warning -->
              <v-flex xs3>
                <box-card theme="warn">
                  <div class="mb-2">
                    <div class="font-24 font-w-600 warn_color">เฝ้าระวัง</div>
                    <div class="font-12 mt-n1">Warn</div>
                  </div>

                  <v-divider />
                  <div
                    v-for="(item, body_fat_rate_index) in form.body_fat_rate"
                    :key="body_fat_rate_index"
                  >
                    <v-row no-gutters align="center" class="flex__box">
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.warn_from"
                          @update-data="item.warn_from = $event"
                        />
                      </v-col>
                      <v-col cols="auto" class="mx-2">To</v-col>
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.warn_to"
                          @update-data="item.warn_to = $event"
                        />
                      </v-col>
                    </v-row>

                    <v-divider
                      v-if="
                        form.body_fat_rate.length != body_fat_rate_index + 1
                      "
                    />
                  </div>
                </box-card>
              </v-flex>

              <!-- Danger -->
              <v-flex xs3>
                <box-card theme="danger">
                  <div class="mb-2">
                    <div
                      class="font-24 font-w-600 danger_color d-flex align-center"
                    >
                      อันตราย <span class="font-12">(ต่ำกว่า)</span>
                    </div>
                    <div class="font-12 mt-n1">Danger(Less than)</div>
                  </div>
                  <v-divider />

                  <div
                    v-for="(item, body_fat_rate_index) in form.body_fat_rate"
                    :key="body_fat_rate_index"
                  >
                    <v-row no-gutters align="center" class="flex__box">
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.danger_less_from"
                          @update-data="item.danger_less_from = $event"
                        />
                      </v-col>
                      <v-col cols="auto" class="mx-2">To</v-col>
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.danger_less_to"
                          @update-data="item.danger_less_to = $event"
                        />
                      </v-col>
                    </v-row>

                    <v-divider
                      v-if="
                        form.body_fat_rate.length != body_fat_rate_index + 1
                      "
                    />
                  </div>
                </box-card>
              </v-flex>
              <v-flex xs3>
                <box-card theme="danger">
                  <div class="mb-2">
                    <div
                      class="font-24 font-w-600 danger_color d-flex align-center"
                    >
                      อันตราย <span class="font-12">(มากกว่า)</span>
                    </div>
                    <div class="font-12 mt-n1">Danger(More than)</div>
                  </div>

                  <v-divider />

                  <div
                    v-for="(item, body_fat_rate_index) in form.body_fat_rate"
                    :key="body_fat_rate_index"
                  >
                    <v-row no-gutters align="center" class="flex__box">
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.danger_than_from"
                          @update-data="item.danger_than_from = $event"
                        />
                      </v-col>
                      <v-col cols="auto" class="mx-2">To</v-col>
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.danger_than_to"
                          @update-data="item.danger_than_to = $event"
                        />
                      </v-col>
                    </v-row>

                    <v-divider
                      v-if="
                        form.body_fat_rate.length != body_fat_rate_index + 1
                      "
                    />
                  </div>
                </box-card>
              </v-flex>
            </v-layout>
          </v-col>
        </v-row>

        <!-- ผลการวัด -->
        <box-card class="mt-6 area_input">
          <!-- ผลการวัดวันนี้ -->
          <v-row align="start">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ผลการวัดวันนี้
              </div>
              <div class="font-24 font-w-600">ความเสี่ยง</div>
              <div class="font-18 font-w-600 mt-n1">(อาการทั่วไป)</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <div
                class="font-24 font-w-600 standard_color d-flex align-center"
              >
                A
              </div>
              <div class="font-12 mt-n1 mb-2">standard</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.body_fat_rate_settings_text.risk_standard_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <div class="font-24 font-w-600 warn_color d-flex align-center">
                B
              </div>
              <div class="font-12 mt-n1 mb-2">warn</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.body_fat_rate_settings_text.risk_warn_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <div class="font-24 font-w-600 danger_color d-flex align-center">
                C
              </div>
              <div class="font-12 mt-n1 mb-2">Danger</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.body_fat_rate_settings_text.risk_danger_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>
          </v-row>

          <v-divider class="mx-n4 mb-5" style="background-color: #d3d3d3" />

          <!--  ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา) -->
          <v-row align="start" class="mb-1">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
              </div>
              <div class="font-24 font-w-600">วิธีปรับปรุง</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.body_fat_rate_settings_text.improve_standard_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="
                    body_fat_rate_settings_text.standard['checkbox' + (i + 1)]
                  "
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueStandardCheckbox(
                      'body_fat_rate_settings_text',
                      item,
                      $event
                    )
                  "
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.body_fat_rate_settings_text.improve_warn_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="
                    body_fat_rate_settings_text.warn['checkbox' + (i + 1)]
                  "
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueWarnCheckbox(
                      'body_fat_rate_settings_text',
                      item,
                      $event
                    )
                  "
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.body_fat_rate_settings_text.improve_danger_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="
                    body_fat_rate_settings_text.danger['checkbox' + (i + 1)]
                  "
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueDangerCheckbox(
                      'body_fat_rate_settings_text',
                      item,
                      $event
                    )
                  "
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
        </box-card>
      </div>

      <v-divider class="mt-8 mb-5" style="background-color: #d3d3d3" />

      <!-- *************5 Visceral Fat Lavel *************-->
      <div>
        <div class="pl-5 mb-2">
          <div class="font-20 font-w-600" style="color: #c71010">
            5.ระดับไขมันในช่องท้อง
          </div>
          <div class="font-12 font-w-400 mt-n1">Visceral Fat Lavel</div>
        </div>

        <v-layout class="input__setting">
          <!-- Standard -->
          <v-flex xs4>
            <box-card theme="standard">
              <div class="mb-2">
                <div class="font-24 font-w-600 standard_color">ปกติ</div>
                <div class="font-12 mt-n1">Standard</div>
              </div>
              <v-divider class="" style="background-color: #d3d3d3" />
              <v-row no-gutters align="center" class="flex__box">
                <v-col>
                  <TextInputSetting
                    required
                    inputType="decimal"
                    :model="form.visceral_fat_level.standard_from"
                    @update-data="
                      form.visceral_fat_level.standard_from = $event
                    "
                  />
                </v-col>
                <v-col cols="auto" class="mx-2">To</v-col>
                <v-col>
                  <TextInputSetting
                    required
                    inputType="decimal"
                    :model="form.visceral_fat_level.standard_to"
                    @update-data="form.visceral_fat_level.standard_to = $event"
                  />
                </v-col>
              </v-row>
            </box-card>
          </v-flex>

          <!-- Warning -->
          <v-flex xs4>
            <box-card theme="warn">
              <div class="mb-2">
                <div class="font-24 font-w-600 warn_color">เฝ้าระวัง</div>
                <div class="font-12 mt-n1">Warn</div>
              </div>
              <v-divider class="" style="background-color: #d3d3d3" />
              <v-row no-gutters align="center" class="flex__box">
                <v-col>
                  <TextInputSetting
                    required
                    inputType="decimal"
                    :model="form.visceral_fat_level.warn_from"
                    @update-data="form.visceral_fat_level.warn_from = $event"
                  />
                </v-col>
                <v-col cols="auto" class="mx-2">To</v-col>
                <v-col>
                  <TextInputSetting
                    required
                    inputType="decimal"
                    :model="form.visceral_fat_level.warn_to"
                    @update-data="form.visceral_fat_level.warn_to = $event"
                  />
                </v-col>
              </v-row>
            </box-card>
          </v-flex>

          <!-- Danger -->
          <v-flex xs4>
            <box-card theme="danger">
              <div class="mb-2">
                <div class="font-24 font-w-600 danger_color">อันตราย</div>
                <div class="font-12 mt-n1">Danger</div>
              </div>
              <v-divider class="" style="background-color: #d3d3d3" />
              <v-row no-gutters align="center" class="flex__box">
                <v-col>
                  <TextInputSetting
                    required
                    inputType="decimal"
                    :model="form.visceral_fat_level.danger_from"
                    @update-data="form.visceral_fat_level.danger_from = $event"
                  />
                </v-col>
                <v-col cols="auto" class="mx-2">To</v-col>
                <v-col>
                  <TextInputSetting
                    required
                    inputType="decimal"
                    :model="form.visceral_fat_level.danger_to"
                    @update-data="form.visceral_fat_level.danger_to = $event"
                  />
                </v-col>
              </v-row>
            </box-card>
          </v-flex>
        </v-layout>

        <!-- ผลการวัด -->
        <box-card class="mt-6 area_input">
          <!-- ผลการวัดวันนี้ -->
          <v-row align="start">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ผลการวัดวันนี้
              </div>
              <div class="font-24 font-w-600">ความเสี่ยง</div>
              <div class="font-18 font-w-600 mt-n1">(อาการทั่วไป)</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <div
                class="font-24 font-w-600 standard_color d-flex align-center"
              >
                A
              </div>
              <div class="font-12 mt-n1 mb-2">standard</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.visceral_fat_level.risk_standard_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <div class="font-24 font-w-600 warn_color d-flex align-center">
                B
              </div>
              <div class="font-12 mt-n1 mb-2">warn</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.visceral_fat_level.risk_warn_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <div class="font-24 font-w-600 danger_color d-flex align-center">
                C
              </div>
              <div class="font-12 mt-n1 mb-2">Danger</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.visceral_fat_level.risk_danger_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>
          </v-row>

          <v-divider class="mx-n4 mb-5" style="background-color: #d3d3d3" />

          <!--  ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา) -->
          <v-row align="start" class="mb-1">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
              </div>
              <div class="font-24 font-w-600">วิธีปรับปรุง</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.visceral_fat_level.improve_standard_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="visceral_fat_level.standard['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueStandardCheckbox('visceral_fat_level', item, $event)
                  "
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.visceral_fat_level.improve_warn_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="visceral_fat_level.warn['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueWarnCheckbox('visceral_fat_level', item, $event)
                  "
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.visceral_fat_level.improve_danger_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="visceral_fat_level.danger['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueDangerCheckbox('visceral_fat_level', item, $event)
                  "
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
        </box-card>
      </div>

      <v-divider class="mt-8 mb-5" style="background-color: #d3d3d3" />

      <!-- *************6 Grip strength(Left/Right)  *************-->
      <div>
        <div class="pl-5 mb-2">
          <div class="font-20 font-w-600" style="color: #c71010">
            6.แรงบีบมือ
          </div>
          <div class="font-12 font-w-400 mt-n1">Grip strength(Left/Right)</div>
        </div>

        <v-row no-gutters>
          <v-col cols="3">
            <box-card>
              <div
                class="font-24 font-w-600 text-center mb-3"
                style="padding-top: 14px"
              >
                Gender
              </div>

              <v-divider />

              <div
                v-for="(item, grip_strength_index) in form.grip_strength"
                :key="grip_strength_index"
              >
                <div
                  class="text-center font-16 font-w-600 flex__box d-flex flex-column justify-center"
                  :class="
                    form.grip_strength.length == grip_strength_index + 1
                      ? 'mb-2'
                      : ''
                  "
                >
                  {{ item.gender === "M" ? "Male" : "Female" }}
                </div>

                <v-divider
                  v-if="form.grip_strength.length != grip_strength_index + 1"
                />
              </div>
            </box-card>
          </v-col>
          <v-col cols="9">
            <v-layout class="input__setting">
              <!-- Standard -->
              <v-flex xs4>
                <box-card theme="standard">
                  <div class="mb-2">
                    <div class="font-24 font-w-600 standard_color">ปกติ</div>
                    <div class="font-12 mt-n1">Standard</div>
                  </div>

                  <v-divider />
                  <div
                    v-for="(item, grip_strength_index) in form.grip_strength"
                    :key="grip_strength_index"
                  >
                    <v-row no-gutters align="center" class="flex__box">
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.standard_from"
                          @update-data="item.standard_from = $event"
                        />
                      </v-col>
                      <v-col cols="auto" class="mx-2">To</v-col>
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.standard_to"
                          @update-data="item.standard_to = $event"
                        />
                      </v-col>
                    </v-row>

                    <v-divider
                      v-if="
                        form.grip_strength.length != grip_strength_index + 1
                      "
                    />
                  </div>
                </box-card>
              </v-flex>

              <!-- Warning -->
              <v-flex xs4>
                <box-card theme="warn">
                  <div class="mb-2">
                    <div class="font-24 font-w-600 warn_color">เฝ้าระวัง</div>
                    <div class="font-12 mt-n1">Warn</div>
                  </div>

                  <v-divider />
                  <div
                    v-for="(item, grip_strength_index) in form.grip_strength"
                    :key="grip_strength_index"
                  >
                    <v-row no-gutters align="center" class="flex__box">
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.warn_from"
                          @update-data="item.warn_from = $event"
                        />
                      </v-col>
                      <v-col cols="auto" class="mx-2">To</v-col>
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.warn_to"
                          @update-data="item.warn_to = $event"
                        />
                      </v-col>
                    </v-row>

                    <v-divider
                      v-if="
                        form.grip_strength.length != grip_strength_index + 1
                      "
                    />
                  </div>
                </box-card>
              </v-flex>

              <!-- Danger -->
              <v-flex xs4>
                <box-card theme="danger">
                  <div class="mb-2">
                    <div class="font-24 font-w-600 danger_color">อันตราย</div>
                    <div class="font-12 mt-n1">Danger</div>
                  </div>

                  <v-divider />

                  <div
                    v-for="(item, grip_strength_index) in form.grip_strength"
                    :key="grip_strength_index"
                  >
                    <v-row no-gutters align="center" class="flex__box">
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.danger_from"
                          @update-data="item.danger_from = $event"
                        />
                      </v-col>
                      <v-col cols="auto" class="mx-2">To</v-col>
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.danger_to"
                          @update-data="item.danger_to = $event"
                        />
                      </v-col>
                    </v-row>

                    <v-divider
                      v-if="
                        form.grip_strength.length != grip_strength_index + 1
                      "
                    />
                  </div>
                </box-card>
              </v-flex>
            </v-layout>
          </v-col>
        </v-row>

        <!-- ผลการวัด -->
        <box-card class="mt-6 area_input">
          <!-- ผลการวัดวันนี้ -->
          <v-row align="start">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ผลการวัดวันนี้
              </div>
              <div class="font-24 font-w-600">ความเสี่ยง</div>
              <div class="font-18 font-w-600 mt-n1">(อาการทั่วไป)</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <div
                class="font-24 font-w-600 standard_color d-flex align-center"
              >
                A
              </div>
              <div class="font-12 mt-n1 mb-2">standard</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.grip_strength_settings_text.risk_standard_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <div class="font-24 font-w-600 warn_color d-flex align-center">
                B
              </div>
              <div class="font-12 mt-n1 mb-2">warn</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.grip_strength_settings_text.risk_warn_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <div class="font-24 font-w-600 danger_color d-flex align-center">
                C
              </div>
              <div class="font-12 mt-n1 mb-2">Danger</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.grip_strength_settings_text.risk_danger_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>
          </v-row>

          <v-divider class="mx-n4 mb-5" style="background-color: #d3d3d3" />

          <!--  ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา) -->
          <v-row align="start" class="mb-1">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
              </div>
              <div class="font-24 font-w-600">วิธีปรับปรุง</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.grip_strength_settings_text.improve_standard_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="
                    grip_strength_settings_text.standard['checkbox' + (i + 1)]
                  "
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueStandardCheckbox(
                      'grip_strength_settings_text',
                      item,
                      $event
                    )
                  "
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.grip_strength_settings_text.improve_warn_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="
                    grip_strength_settings_text.warn['checkbox' + (i + 1)]
                  "
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueWarnCheckbox(
                      'grip_strength_settings_text',
                      item,
                      $event
                    )
                  "
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.grip_strength_settings_text.improve_danger_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="
                    grip_strength_settings_text.danger['checkbox' + (i + 1)]
                  "
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueDangerCheckbox(
                      'grip_strength_settings_text',
                      item,
                      $event
                    )
                  "
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
        </box-card>
      </div>

      <v-divider class="mt-8 mb-5" style="background-color: #d3d3d3" />

      <!-- *************7 Muscle mass points  *************-->
      <div>
        <div class="pl-5 mb-2">
          <div class="font-20 font-w-600" style="color: #c71010">
            7.มวลกล้ามเนื้อ
          </div>
          <div class="font-12 font-w-400 mt-n1">Muscle mass points</div>
        </div>

        <v-row no-gutters>
          <v-col cols="4">
            <box-card>
              <v-row no-gutters class="pt-3 mb-3">
                <v-col cols="6" class="font-24 font-w-600">Gender</v-col>
                <v-col cols="6" class="font-24 font-w-600">Age</v-col>
              </v-row>

              <v-divider />

              <div
                v-for="(
                  item, muscle_mass_points_index
                ) in form.muscle_mass_points"
                :key="muscle_mass_points_index"
              >
                <div class="flex__box d-flex flex-column justify-center">
                  <v-row
                    align="center"
                    no-gutters
                    :class="
                      form.muscle_mass_points.length ==
                      muscle_mass_points_index + 1
                        ? 'mb-3'
                        : ''
                    "
                  >
                    <v-col cols="6" class="font-16 font-w-600">
                      {{ item.gender === "M" ? "Male" : "Female" }}
                    </v-col>
                    <v-col cols="6" class="font-16 font-w-600">
                      {{ item.age_from }}-{{ item.age_to }}
                    </v-col>
                  </v-row>
                </div>
                <v-divider
                  v-if="
                    form.muscle_mass_points.length !=
                    muscle_mass_points_index + 1
                  "
                />
              </div>
            </box-card>
          </v-col>
          <v-col cols="8">
            <v-layout class="input__setting">
              <!-- Standard -->
              <v-flex xs4>
                <box-card theme="standard">
                  <div class="mb-2">
                    <div class="font-24 font-w-600 standard_color">ปกติ</div>
                    <div class="font-12 mt-n1">Standard</div>
                  </div>

                  <v-divider />
                  <div
                    v-for="(
                      item, muscle_mass_points_index
                    ) in form.muscle_mass_points"
                    :key="muscle_mass_points_index"
                  >
                    <v-row no-gutters align="center" class="flex__box">
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="number"
                          :model="item.standard_from"
                          @update-data="item.standard_from = $event"
                        />
                      </v-col>
                      <v-col cols="auto" class="mx-2">To</v-col>
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="number"
                          :model="item.standard_to"
                          @update-data="item.standard_to = $event"
                        />
                      </v-col>
                    </v-row>

                    <v-divider
                      v-if="
                        form.muscle_mass_points.length !=
                        muscle_mass_points_index + 1
                      "
                    />
                  </div>
                </box-card>
              </v-flex>

              <!-- Warning -->
              <v-flex xs4>
                <box-card theme="warn">
                  <div class="mb-2">
                    <div class="font-24 font-w-600 warn_color">เฝ้าระวัง</div>
                    <div class="font-12 mt-n1">Warn</div>
                  </div>

                  <v-divider />
                  <div
                    v-for="(
                      item, muscle_mass_points_index
                    ) in form.muscle_mass_points"
                    :key="muscle_mass_points_index"
                  >
                    <v-row no-gutters align="center" class="flex__box">
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="number"
                          :model="item.warn_from"
                          @update-data="item.warn_from = $event"
                        />
                      </v-col>
                      <v-col cols="auto" class="mx-2">To</v-col>
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="number"
                          :model="item.warn_to"
                          @update-data="item.warn_to = $event"
                        />
                      </v-col>
                    </v-row>

                    <v-divider
                      v-if="
                        form.muscle_mass_points.length !=
                        muscle_mass_points_index + 1
                      "
                    />
                  </div>
                </box-card>
              </v-flex>

              <!-- Danger -->
              <v-flex xs4>
                <box-card theme="danger">
                  <div class="mb-2">
                    <div class="font-24 font-w-600 danger_color">อันตราย</div>
                    <div class="font-12 mt-n1">Danger</div>
                  </div>

                  <v-divider />

                  <div
                    v-for="(
                      item, muscle_mass_points_index
                    ) in form.muscle_mass_points"
                    :key="muscle_mass_points_index"
                  >
                    <v-row no-gutters align="center" class="flex__box">
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="number"
                          :model="item.danger_from"
                          @update-data="item.danger_from = $event"
                        />
                      </v-col>
                      <v-col cols="auto" class="mx-2">To</v-col>
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="number"
                          :model="item.danger_to"
                          @update-data="item.danger_to = $event"
                        />
                      </v-col>
                    </v-row>

                    <v-divider
                      v-if="
                        form.muscle_mass_points.length !=
                        muscle_mass_points_index + 1
                      "
                    />
                  </div>
                </box-card>
              </v-flex>
            </v-layout>
          </v-col>
        </v-row>

        <!-- ผลการวัด -->
        <box-card class="mt-6 area_input">
          <!-- ผลการวัดวันนี้ -->
          <v-row align="start">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ผลการวัดวันนี้
              </div>
              <div class="font-24 font-w-600">ความเสี่ยง</div>
              <div class="font-18 font-w-600 mt-n1">(อาการทั่วไป)</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <div
                class="font-24 font-w-600 standard_color d-flex align-center"
              >
                A
              </div>
              <div class="font-12 mt-n1 mb-2">standard</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="
                  form.muscle_mass_points_settings_text.risk_standard_text
                "
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <div class="font-24 font-w-600 warn_color d-flex align-center">
                B
              </div>
              <div class="font-12 mt-n1 mb-2">warn</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.muscle_mass_points_settings_text.risk_warn_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <div class="font-24 font-w-600 danger_color d-flex align-center">
                C
              </div>
              <div class="font-12 mt-n1 mb-2">Danger</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.muscle_mass_points_settings_text.risk_danger_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>
          </v-row>

          <v-divider class="mx-n4 mb-5" style="background-color: #d3d3d3" />

          <!--  ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา) -->
          <v-row align="start" class="mb-1">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
              </div>
              <div class="font-24 font-w-600">วิธีปรับปรุง</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="
                  form.muscle_mass_points_settings_text.improve_standard_text
                "
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="
                    muscle_mass_points_settings_text.standard[
                      'checkbox' + (i + 1)
                    ]
                  "
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueStandardCheckbox(
                      'muscle_mass_points_settings_text',
                      item,
                      $event
                    )
                  "
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="
                  form.muscle_mass_points_settings_text.improve_warn_text
                "
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="
                    muscle_mass_points_settings_text.warn['checkbox' + (i + 1)]
                  "
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueWarnCheckbox(
                      'muscle_mass_points_settings_text',
                      item,
                      $event
                    )
                  "
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="
                  form.muscle_mass_points_settings_text.improve_danger_text
                "
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="
                    muscle_mass_points_settings_text.danger[
                      'checkbox' + (i + 1)
                    ]
                  "
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueDangerCheckbox(
                      'muscle_mass_points_settings_text',
                      item,
                      $event
                    )
                  "
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
        </box-card>
      </div>

      <v-divider class="mt-8 mb-5" style="background-color: #d3d3d3" />

      <!-- *************9 2 Step Test  *************-->
      <div>
        <div class="pl-5 mb-2">
          <div class="font-20 font-w-600" style="color: #c71010">
            9. 2 Step Test
          </div>
          <div class="font-12 font-w-400 mt-n1">2 Step Test</div>
        </div>

        <v-row no-gutters>
          <v-col cols="4">
            <box-card>
              <v-row no-gutters class="pt-3 mb-3">
                <v-col cols="6" class="font-24 font-w-600">Gender</v-col>
                <v-col cols="6" class="font-24 font-w-600">Age</v-col>
              </v-row>

              <v-divider />

              <div
                v-for="(
                  item, stability_criteria_setting_index
                ) in form.stability_criteria_setting"
                :key="stability_criteria_setting_index"
              >
                <div class="flex__box d-flex flex-column justify-center">
                  <v-row
                    align="center"
                    no-gutters
                    :class="
                      form.stability_criteria_setting.length ==
                      stability_criteria_setting_index + 1
                        ? 'mb-3'
                        : ''
                    "
                  >
                    <v-col cols="6" class="font-16 font-w-600">
                      {{ item.gender === "M" ? "Male" : "Female" }}
                    </v-col>
                    <v-col cols="6" class="font-16 font-w-600">
                      {{ item.age_from }}-{{ item.age_to }}
                    </v-col>
                  </v-row>
                </div>
                <v-divider
                  v-if="
                    form.stability_criteria_setting.length !=
                    stability_criteria_setting_index + 1
                  "
                />
              </div>
            </box-card>
          </v-col>
          <v-col cols="8">
            <v-layout class="input__setting">
              <!-- Standard -->
              <v-flex xs4>
                <box-card theme="standard">
                  <div class="mb-2">
                    <div class="font-24 font-w-600 standard_color">ปกติ</div>
                    <div class="font-12 mt-n1">Standard</div>
                  </div>

                  <v-divider />
                  <div
                    v-for="(
                      item, stability_criteria_setting_index
                    ) in form.stability_criteria_setting"
                    :key="stability_criteria_setting_index"
                  >
                    <v-row no-gutters align="center" class="flex__box">
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.ideal_from"
                          @update-data="item.ideal_from = $event"
                        />
                      </v-col>
                      <v-col cols="auto" class="mx-2">To</v-col>
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.ideal_to"
                          @update-data="item.ideal_to = $event"
                        />
                      </v-col>
                    </v-row>

                    <v-divider
                      v-if="
                        form.stability_criteria_setting.length !=
                        stability_criteria_setting_index + 1
                      "
                    />
                  </div>
                </box-card>
              </v-flex>

              <!-- Warning -->
              <v-flex xs4>
                <box-card theme="warn">
                  <div class="mb-2">
                    <div class="font-24 font-w-600 warn_color">เฝ้าระวัง</div>
                    <div class="font-12 mt-n1">Warn</div>
                  </div>

                  <v-divider />
                  <div
                    v-for="(
                      item, stability_criteria_setting_index
                    ) in form.stability_criteria_setting"
                    :key="stability_criteria_setting_index"
                  >
                    <v-row no-gutters align="center" class="flex__box">
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.warn_from"
                          @update-data="item.warn_from = $event"
                        />
                      </v-col>
                      <v-col cols="auto" class="mx-2">To</v-col>
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.warn_to"
                          @update-data="item.warn_to = $event"
                        />
                      </v-col>
                    </v-row>

                    <v-divider
                      v-if="
                        form.stability_criteria_setting.length !=
                        stability_criteria_setting_index + 1
                      "
                    />
                  </div>
                </box-card>
              </v-flex>

              <!-- Danger -->
              <v-flex xs4>
                <box-card theme="danger">
                  <div class="mb-2">
                    <div class="font-24 font-w-600 danger_color">อันตราย</div>
                    <div class="font-12 mt-n1">Danger</div>
                  </div>

                  <v-divider />

                  <div
                    v-for="(
                      item, stability_criteria_setting_index
                    ) in form.stability_criteria_setting"
                    :key="stability_criteria_setting_index"
                  >
                    <v-row no-gutters align="center" class="flex__box">
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.danger_from"
                          @update-data="item.danger_from = $event"
                        />
                      </v-col>
                      <v-col cols="auto" class="mx-2">To</v-col>
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.danger_to"
                          @update-data="item.danger_to = $event"
                        />
                      </v-col>
                    </v-row>

                    <v-divider
                      v-if="
                        form.stability_criteria_setting.length !=
                        stability_criteria_setting_index + 1
                      "
                    />
                  </div>
                </box-card>
              </v-flex>
            </v-layout>
          </v-col>
        </v-row>

        <!-- ผลการวัด -->
        <box-card class="mt-6 area_input">
          <!-- ผลการวัดวันนี้ -->
          <v-row align="start">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ผลการวัดวันนี้
              </div>
              <div class="font-24 font-w-600">ความเสี่ยง</div>
              <div class="font-18 font-w-600 mt-n1">(อาการทั่วไป)</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <div
                class="font-24 font-w-600 standard_color d-flex align-center"
              >
                A
              </div>
              <div class="font-12 mt-n1 mb-2">standard</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="
                  form.stability_criteria_setting_text.risk_standard_text
                "
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <div class="font-24 font-w-600 warn_color d-flex align-center">
                B
              </div>
              <div class="font-12 mt-n1 mb-2">warn</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.stability_criteria_setting_text.risk_warn_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <div class="font-24 font-w-600 danger_color d-flex align-center">
                C
              </div>
              <div class="font-12 mt-n1 mb-2">Danger</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.stability_criteria_setting_text.risk_danger_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>
          </v-row>

          <v-divider class="mx-n4 mb-5" style="background-color: #d3d3d3" />

          <!--  ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา) -->
          <v-row align="start" class="mb-1">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
              </div>
              <div class="font-24 font-w-600">วิธีปรับปรุง</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="
                  form.stability_criteria_setting_text.improve_standard_text
                "
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="
                    stability_criteria_setting_text.standard[
                      'checkbox' + (i + 1)
                    ]
                  "
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueStandardCheckbox(
                      'stability_criteria_setting_text',
                      item,
                      $event
                    )
                  "
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.stability_criteria_setting_text.improve_warn_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="
                    stability_criteria_setting_text.warn['checkbox' + (i + 1)]
                  "
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueWarnCheckbox(
                      'stability_criteria_setting_text',
                      item,
                      $event
                    )
                  "
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="
                  form.stability_criteria_setting_text.improve_danger_text
                "
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="
                    stability_criteria_setting_text.danger['checkbox' + (i + 1)]
                  "
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueDangerCheckbox(
                      'stability_criteria_setting_text',
                      item,
                      $event
                    )
                  "
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
        </box-card>
      </div>

      <v-divider class="mt-8 mb-5" style="background-color: #d3d3d3" />

      <!-- *************10 Flexibility-Upper *************-->
      <div>
        <div class="pl-5 mb-2">
          <div class="font-20 font-w-600" style="color: #c71010">
            10.ความอ่อนตัวหัวไหล่
          </div>
          <div class="font-12 font-w-400 mt-n1">Flexibility of Shoulder</div>
        </div>

        <v-layout class="input__setting">
          <!-- Standard -->
          <v-flex xs4>
            <box-card theme="standard">
              <div class="mb-2">
                <div class="font-24 font-w-600 standard_color">ปกติ</div>
                <div class="font-12 mt-n1">Standard</div>
              </div>
              <v-divider class="" style="background-color: #d3d3d3" />
              <v-row no-gutters align="center" class="flex__box">
                <v-col>
                  <TextInputSetting
                    required
                    inputType="decimal"
                    :model="form.flexibility_upper_criteria_setting.ideal_from"
                    @update-data="
                      form.flexibility_upper_criteria_setting.ideal_from =
                        $event
                    "
                  />
                </v-col>
                <v-col cols="auto" class="mx-2">To</v-col>
                <v-col>
                  <TextInputSetting
                    required
                    inputType="decimal"
                    :model="form.flexibility_upper_criteria_setting.ideal_to"
                    @update-data="
                      form.flexibility_upper_criteria_setting.ideal_to = $event
                    "
                  />
                </v-col>
              </v-row>
            </box-card>
          </v-flex>

          <!-- Warning -->
          <v-flex xs4>
            <box-card theme="warn">
              <div class="mb-2">
                <div class="font-24 font-w-600 warn_color">เฝ้าระวัง</div>
                <div class="font-12 mt-n1">Warn</div>
              </div>
              <v-divider class="" style="background-color: #d3d3d3" />
              <v-row no-gutters align="center" class="flex__box">
                <v-col>
                  <TextInputSetting
                    required
                    inputType="decimal"
                    :model="form.flexibility_upper_criteria_setting.warn_from"
                    @update-data="
                      form.flexibility_upper_criteria_setting.warn_from = $event
                    "
                  />
                </v-col>
                <v-col cols="auto" class="mx-2">To</v-col>
                <v-col>
                  <TextInputSetting
                    required
                    inputType="decimal"
                    :model="form.flexibility_upper_criteria_setting.warn_to"
                    @update-data="
                      form.flexibility_upper_criteria_setting.warn_to = $event
                    "
                  />
                </v-col>
              </v-row>
            </box-card>
          </v-flex>

          <!-- Danger -->
          <v-flex xs4>
            <box-card theme="danger">
              <div class="mb-2">
                <div class="font-24 font-w-600 danger_color">อันตราย</div>
                <div class="font-12 mt-n1">Danger</div>
              </div>
              <v-divider class="" style="background-color: #d3d3d3" />
              <v-row no-gutters align="center" class="flex__box">
                <v-col>
                  <TextInputSetting
                    required
                    inputType="decimal"
                    :model="form.flexibility_upper_criteria_setting.danger_from"
                    @update-data="
                      form.flexibility_upper_criteria_setting.danger_from =
                        $event
                    "
                  />
                </v-col>
                <v-col cols="auto" class="mx-2">To</v-col>
                <v-col>
                  <TextInputSetting
                    required
                    inputType="decimal"
                    :model="form.flexibility_upper_criteria_setting.danger_to"
                    @update-data="
                      form.flexibility_upper_criteria_setting.danger_to = $event
                    "
                  />
                </v-col>
              </v-row>
            </box-card>
          </v-flex>
        </v-layout>

        <!-- ผลการวัด -->
        <box-card class="mt-6 area_input">
          <!-- ผลการวัดวันนี้ -->
          <v-row align="start">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ผลการวัดวันนี้
              </div>
              <div class="font-24 font-w-600">ความเสี่ยง</div>
              <div class="font-18 font-w-600 mt-n1">(อาการทั่วไป)</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <div
                class="font-24 font-w-600 standard_color d-flex align-center"
              >
                A
              </div>
              <div class="font-12 mt-n1 mb-2">standard</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="
                  form.flexibility_upper_criteria_setting.risk_standard_text
                "
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <div class="font-24 font-w-600 warn_color d-flex align-center">
                B
              </div>
              <div class="font-12 mt-n1 mb-2">warn</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.flexibility_upper_criteria_setting.risk_warn_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <div class="font-24 font-w-600 danger_color d-flex align-center">
                C
              </div>
              <div class="font-12 mt-n1 mb-2">Danger</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="
                  form.flexibility_upper_criteria_setting.risk_danger_text
                "
                :rules="[(v) => !!v || '']"
              />
            </v-col>
          </v-row>

          <v-divider class="mx-n4 mb-5" style="background-color: #d3d3d3" />

          <!--  ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา) -->
          <v-row align="start" class="mb-1">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
              </div>
              <div class="font-24 font-w-600">วิธีปรับปรุง</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="
                  form.flexibility_upper_criteria_setting.improve_standard_text
                "
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="
                    flexibility_upper_criteria_setting.standard[
                      'checkbox' + (i + 1)
                    ]
                  "
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueStandardCheckbox(
                      'flexibility_upper_criteria_setting',
                      item,
                      $event
                    )
                  "
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="
                  form.flexibility_upper_criteria_setting.improve_warn_text
                "
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="
                    flexibility_upper_criteria_setting.warn[
                      'checkbox' + (i + 1)
                    ]
                  "
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueWarnCheckbox(
                      'flexibility_upper_criteria_setting',
                      item,
                      $event
                    )
                  "
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="
                  form.flexibility_upper_criteria_setting.improve_danger_text
                "
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="
                    flexibility_upper_criteria_setting.danger[
                      'checkbox' + (i + 1)
                    ]
                  "
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueDangerCheckbox(
                      'flexibility_upper_criteria_setting',
                      item,
                      $event
                    )
                  "
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
        </box-card>
      </div>

      <v-divider class="mt-8 mb-5" style="background-color: #d3d3d3" />

      <!-- *************11 Flexibility of Leg  *************-->
      <div>
        <div class="pl-5 mb-2">
          <div class="font-20 font-w-600" style="color: #c71010">
            11.ความอ่อนตัวขา
          </div>
          <div class="font-12 font-w-400 mt-n1">Flexibility of Leg</div>
        </div>

        <v-row no-gutters>
          <v-col cols="4">
            <box-card>
              <v-row no-gutters class="pt-3 mb-3">
                <v-col cols="6" class="font-24 font-w-600">Gender</v-col>
                <v-col cols="6" class="font-24 font-w-600">Age</v-col>
              </v-row>

              <v-divider />

              <div
                v-for="(
                  item, flexibility_lower_criteria_setting_index
                ) in form.flexibility_lower_criteria_setting"
                :key="flexibility_lower_criteria_setting_index"
              >
                <div class="flex__box d-flex flex-column justify-center">
                  <v-row
                    align="center"
                    no-gutters
                    :class="
                      form.flexibility_lower_criteria_setting.length ==
                      flexibility_lower_criteria_setting_index + 1
                        ? 'mb-3'
                        : ''
                    "
                  >
                    <v-col cols="6" class="font-16 font-w-600">
                      {{ item.gender === "M" ? "Male" : "Female" }}
                    </v-col>
                    <v-col cols="6" class="font-16 font-w-600">
                      {{ item.age_from }}-{{ item.age_to }}
                    </v-col>
                  </v-row>
                </div>
                <v-divider
                  v-if="
                    form.flexibility_lower_criteria_setting.length !=
                    flexibility_lower_criteria_setting_index + 1
                  "
                />
              </div>
            </box-card>
          </v-col>
          <v-col cols="8">
            <v-layout class="input__setting">
              <!-- Standard -->
              <v-flex xs4>
                <box-card theme="standard">
                  <div class="mb-2">
                    <div class="font-24 font-w-600 standard_color">ปกติ</div>
                    <div class="font-12 mt-n1">Standard</div>
                  </div>

                  <v-divider />
                  <div
                    v-for="(
                      item, flexibility_lower_criteria_setting_index
                    ) in form.flexibility_lower_criteria_setting"
                    :key="flexibility_lower_criteria_setting_index"
                  >
                    <v-row no-gutters align="center" class="flex__box">
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.ideal_from"
                          @update-data="item.ideal_from = $event"
                        />
                      </v-col>
                      <v-col cols="auto" class="mx-2">To</v-col>
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.ideal_to"
                          @update-data="item.ideal_to = $event"
                        />
                      </v-col>
                    </v-row>

                    <v-divider
                      v-if="
                        form.flexibility_lower_criteria_setting.length !=
                        flexibility_lower_criteria_setting_index + 1
                      "
                    />
                  </div>
                </box-card>
              </v-flex>

              <!-- Warning -->
              <v-flex xs4>
                <box-card theme="warn">
                  <div class="mb-2">
                    <div class="font-24 font-w-600 warn_color">เฝ้าระวัง</div>
                    <div class="font-12 mt-n1">Warn</div>
                  </div>

                  <v-divider />
                  <div
                    v-for="(
                      item, flexibility_lower_criteria_setting_index
                    ) in form.flexibility_lower_criteria_setting"
                    :key="flexibility_lower_criteria_setting_index"
                  >
                    <v-row no-gutters align="center" class="flex__box">
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.warn_from"
                          @update-data="item.warn_from = $event"
                        />
                      </v-col>
                      <v-col cols="auto" class="mx-2">To</v-col>
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.warn_to"
                          @update-data="item.warn_to = $event"
                        />
                      </v-col>
                    </v-row>

                    <v-divider
                      v-if="
                        form.flexibility_lower_criteria_setting.length !=
                        flexibility_lower_criteria_setting_index + 1
                      "
                    />
                  </div>
                </box-card>
              </v-flex>

              <!-- Danger -->
              <v-flex xs4>
                <box-card theme="danger">
                  <div class="mb-2">
                    <div class="font-24 font-w-600 danger_color">อันตราย</div>
                    <div class="font-12 mt-n1">Danger</div>
                  </div>

                  <v-divider />

                  <div
                    v-for="(
                      item, flexibility_lower_criteria_setting_index
                    ) in form.flexibility_lower_criteria_setting"
                    :key="flexibility_lower_criteria_setting_index"
                  >
                    <v-row no-gutters align="center" class="flex__box">
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.danger_from"
                          @update-data="item.danger_from = $event"
                        />
                      </v-col>
                      <v-col cols="auto" class="mx-2">To</v-col>
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.danger_to"
                          @update-data="item.danger_to = $event"
                        />
                      </v-col>
                    </v-row>

                    <v-divider
                      v-if="
                        form.flexibility_lower_criteria_setting.length !=
                        flexibility_lower_criteria_setting_index + 1
                      "
                    />
                  </div>
                </box-card>
              </v-flex>
            </v-layout>
          </v-col>
        </v-row>

        <!-- ผลการวัด -->
        <box-card class="mt-6 area_input">
          <!-- ผลการวัดวันนี้ -->
          <v-row align="start">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ผลการวัดวันนี้
              </div>
              <div class="font-24 font-w-600">ความเสี่ยง</div>
              <div class="font-18 font-w-600 mt-n1">(อาการทั่วไป)</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <div
                class="font-24 font-w-600 standard_color d-flex align-center"
              >
                A
              </div>
              <div class="font-12 mt-n1 mb-2">standard</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="
                  form.flexibility_lower_criteria_setting_text
                    .risk_standard_text
                "
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <div class="font-24 font-w-600 warn_color d-flex align-center">
                B
              </div>
              <div class="font-12 mt-n1 mb-2">warn</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="
                  form.flexibility_lower_criteria_setting_text.risk_warn_text
                "
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <div class="font-24 font-w-600 danger_color d-flex align-center">
                C
              </div>
              <div class="font-12 mt-n1 mb-2">Danger</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="
                  form.flexibility_lower_criteria_setting_text.risk_danger_text
                "
                :rules="[(v) => !!v || '']"
              />
            </v-col>
          </v-row>

          <v-divider class="mx-n4 mb-5" style="background-color: #d3d3d3" />

          <!--  ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา) -->
          <v-row align="start" class="mb-1">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
              </div>
              <div class="font-24 font-w-600">วิธีปรับปรุง</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="
                  form.flexibility_lower_criteria_setting_text
                    .improve_standard_text
                "
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="
                    flexibility_lower_criteria_setting_text.standard[
                      'checkbox' + (i + 1)
                    ]
                  "
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueStandardCheckbox(
                      'flexibility_lower_criteria_setting_text',
                      item,
                      $event
                    )
                  "
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="
                  form.flexibility_lower_criteria_setting_text.improve_warn_text
                "
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="
                    flexibility_lower_criteria_setting_text.warn[
                      'checkbox' + (i + 1)
                    ]
                  "
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueWarnCheckbox(
                      'flexibility_lower_criteria_setting_text',
                      item,
                      $event
                    )
                  "
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="
                  form.flexibility_lower_criteria_setting_text
                    .improve_danger_text
                "
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="
                    flexibility_lower_criteria_setting_text.danger[
                      'checkbox' + (i + 1)
                    ]
                  "
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueDangerCheckbox(
                      'flexibility_lower_criteria_setting_text',
                      item,
                      $event
                    )
                  "
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
        </box-card>
      </div>

      <v-divider class="mt-8 mb-5" style="background-color: #d3d3d3" />

      <!-- *************13 อายุของปอด  *************-->
      <div>
        <div class="pl-5 mb-2">
          <div class="font-20 font-w-600" style="color: #c71010">
            13.อายุของปอด (ความจุปอด)
          </div>
          <div class="font-12 font-w-400 mt-n1">
            Lung Age (Breathing Capacity)
          </div>
        </div>

        <v-layout class="input__setting">
          <!-- Standard -->
          <v-flex xs6>
            <box-card theme="standard">
              <div class="mb-2">
                <div class="font-24 font-w-600 standard_color">ปกติ</div>
                <div class="font-12 mt-n1">Standard</div>
              </div>
              <v-divider />
              <v-row no-gutters align="center" class="flex__box">
                <v-col>
                  <TextInputSetting
                    required
                    :model="form.lung_age.standard"
                    @update-data="form.lung_age.standard = $event"
                  />
                </v-col>
              </v-row>
            </box-card>
          </v-flex>

          <!-- Warning -->
          <v-flex xs3>
            <box-card theme="warn">
              <div class="mb-2">
                <div class="font-24 font-w-600 warn_color d-flex align-center">
                  เฝ้าระวัง
                </div>
                <div class="font-12 mt-n1">Warn</div>
              </div>
              <v-divider />
              <v-row no-gutters align="center" class="flex__box">
                <v-col>
                  <TextInputSetting
                    required
                    inputType="number"
                    :model="form.lung_age.warn"
                    @update-data="form.lung_age.warn = $event"
                  />
                </v-col>
              </v-row>
            </box-card>
          </v-flex>

          <!-- Danger -->
          <v-flex xs3>
            <box-card theme="danger">
              <div class="mb-2">
                <div
                  class="font-24 font-w-600 danger_color d-flex align-center"
                >
                  อันตราย
                </div>
                <div class="font-12 mt-n1">Danger</div>
              </div>
              <v-divider />
              <v-row no-gutters align="center" class="flex__box">
                <v-col>
                  <TextInputSetting
                    required
                    inputType="number"
                    :model="form.lung_age.danger"
                    @update-data="form.lung_age.danger = $event"
                  />
                </v-col>
              </v-row>
            </box-card>
          </v-flex>
        </v-layout>

        <!-- ผลการวัด -->
        <box-card class="mt-6 area_input">
          <!-- ผลการวัดวันนี้ -->
          <v-row align="start">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ผลการวัดวันนี้
              </div>
              <div class="font-24 font-w-600">ความเสี่ยง</div>
              <div class="font-18 font-w-600 mt-n1">(อาการทั่วไป)</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <div
                class="font-24 font-w-600 standard_color d-flex align-center"
              >
                A
              </div>
              <div class="font-12 mt-n1 mb-2">standard</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.lung_age.risk_standard_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <div class="font-24 font-w-600 warn_color d-flex align-center">
                B
              </div>
              <div class="font-12 mt-n1 mb-2">warn</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.lung_age.risk_warn_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <div class="font-24 font-w-600 danger_color d-flex align-center">
                C
              </div>
              <div class="font-12 mt-n1 mb-2">Danger</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.lung_age.risk_danger_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>
          </v-row>

          <v-divider class="mx-n4 mb-5" style="background-color: #d3d3d3" />

          <!--  ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา) -->
          <v-row align="start" class="mb-1">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
              </div>
              <div class="font-24 font-w-600">วิธีปรับปรุง</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.lung_age.improve_standard_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="lung_age.standard['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="setValueStandardCheckbox('lung_age', item, $event)"
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.lung_age.improve_warn_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="lung_age.warn['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="setValueWarnCheckbox('lung_age', item, $event)"
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.lung_age.improve_danger_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="lung_age.danger['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="setValueDangerCheckbox('lung_age', item, $event)"
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
        </box-card>
      </div>

      <v-divider class="mt-8 mb-5" style="background-color: #d3d3d3" />

      <!-- *************14 อายุผิว (Skin Age)  *************-->
      <div>
        <div class="pl-5 mb-2">
          <div class="font-20 font-w-600" style="color: #c71010">
            14.อายุผิว
          </div>
          <div class="font-12 font-w-400 mt-n1">Skin Age</div>
        </div>

        <v-layout class="input__setting">
          <!-- Standard -->
          <v-flex xs6>
            <box-card theme="standard">
              <div class="mb-2">
                <div class="font-24 font-w-600 standard_color">ปกติ</div>
                <div class="font-12 mt-n1">Standard</div>
              </div>
              <v-divider />
              <v-row no-gutters align="center" class="flex__box">
                <v-col>
                  <TextInputSetting
                    required
                    :model="form.skin_age.standard"
                    @update-data="form.skin_age.standard = $event"
                  />
                </v-col>
              </v-row>
            </box-card>
          </v-flex>

          <!-- Warning -->
          <v-flex xs3>
            <box-card theme="warn">
              <div class="mb-2">
                <div class="font-24 font-w-600 warn_color d-flex align-center">
                  เฝ้าระวัง
                </div>
                <div class="font-12 mt-n1">Warn</div>
              </div>
              <v-divider />
              <v-row no-gutters align="center" class="flex__box">
                <v-col>
                  <TextInputSetting
                    required
                    inputType="number"
                    :model="form.skin_age.warn"
                    @update-data="form.skin_age.warn = $event"
                  />
                </v-col>
              </v-row>
            </box-card>
          </v-flex>

          <!-- Danger -->
          <v-flex xs3>
            <box-card theme="danger">
              <div class="mb-2">
                <div
                  class="font-24 font-w-600 danger_color d-flex align-center"
                >
                  อันตราย
                </div>
                <div class="font-12 mt-n1">Danger</div>
              </div>
              <v-divider />
              <v-row no-gutters align="center" class="flex__box">
                <v-col>
                  <TextInputSetting
                    required
                    inputType="number"
                    :model="form.skin_age.danger"
                    @update-data="form.skin_age.danger = $event"
                  />
                </v-col>
              </v-row>
            </box-card>
          </v-flex>
        </v-layout>

        <!-- ผลการวัด -->
        <box-card class="mt-6 area_input">
          <!-- ผลการวัดวันนี้ -->
          <v-row align="start">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ผลการวัดวันนี้
              </div>
              <div class="font-24 font-w-600">ความเสี่ยง</div>
              <div class="font-18 font-w-600 mt-n1">(อาการทั่วไป)</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <div
                class="font-24 font-w-600 standard_color d-flex align-center"
              >
                A
              </div>
              <div class="font-12 mt-n1 mb-2">standard</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.skin_age.risk_standard_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <div class="font-24 font-w-600 warn_color d-flex align-center">
                B
              </div>
              <div class="font-12 mt-n1 mb-2">warn</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.skin_age.risk_warn_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <div class="font-24 font-w-600 danger_color d-flex align-center">
                C
              </div>
              <div class="font-12 mt-n1 mb-2">Danger</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.skin_age.risk_danger_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>
          </v-row>

          <v-divider class="mx-n4 mb-5" style="background-color: #d3d3d3" />

          <!--  ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา) -->
          <v-row align="start" class="mb-1">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
              </div>
              <div class="font-24 font-w-600">วิธีปรับปรุง</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.skin_age.improve_standard_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="skin_age.standard['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="setValueStandardCheckbox('skin_age', item, $event)"
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.skin_age.improve_warn_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="skin_age.warn['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="setValueWarnCheckbox('skin_age', item, $event)"
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.skin_age.improve_danger_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="skin_age.danger['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="setValueDangerCheckbox('skin_age', item, $event)"
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
        </box-card>
      </div>

      <v-divider class="mt-8 mb-5" style="background-color: #d3d3d3" />

      <!-- *************15 ความแข็งแรงกล้ามเนื้อหลัง Back Strength  *************-->
      <div>
        <div class="pl-5 mb-2">
          <div class="font-20 font-w-600" style="color: #c71010">
            15.ความแข็งแรงกล้ามเนื้อหลัง
          </div>
          <div class="font-12 font-w-400 mt-n1">Back Strength</div>
        </div>

        <v-row no-gutters>
          <v-col cols="4">
            <box-card>
              <v-row no-gutters class="pt-3 mb-3">
                <v-col cols="6" class="font-24 font-w-600">Gender</v-col>
                <v-col cols="6" class="font-24 font-w-600">Age</v-col>
              </v-row>

              <v-divider />

              <div
                v-for="(item, back_strength_index) in form.back_strength"
                :key="back_strength_index"
              >
                <div class="flex__box d-flex flex-column justify-center">
                  <v-row
                    align="center"
                    no-gutters
                    :class="
                      form.back_strength.length == back_strength_index + 1
                        ? 'mb-3'
                        : ''
                    "
                  >
                    <v-col cols="6" class="font-16 font-w-600">
                      {{ item.gender === "M" ? "Male" : "Female" }}
                    </v-col>
                    <v-col cols="6" class="font-16 font-w-600">
                      {{ item.age_from }}-{{ item.age_to }}
                    </v-col>
                  </v-row>
                </div>
                <v-divider
                  v-if="form.back_strength.length != back_strength_index + 1"
                />
              </div>
            </box-card>
          </v-col>
          <v-col cols="8">
            <v-layout class="input__setting">
              <!-- Standard -->
              <v-flex xs4>
                <box-card theme="standard">
                  <div class="mb-2">
                    <div class="font-24 font-w-600 standard_color">ปกติ</div>
                    <div class="font-12 mt-n1">Standard</div>
                  </div>

                  <v-divider />
                  <div
                    v-for="(item, back_strength_index) in form.back_strength"
                    :key="back_strength_index"
                  >
                    <v-row no-gutters align="center" class="flex__box">
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.standard_from"
                          @update-data="item.standard_from = $event"
                        />
                      </v-col>
                      <v-col cols="auto" class="mx-2">To</v-col>
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.standard_to"
                          @update-data="item.standard_to = $event"
                        />
                      </v-col>
                    </v-row>

                    <v-divider
                      v-if="
                        form.back_strength.length != back_strength_index + 1
                      "
                    />
                  </div>
                </box-card>
              </v-flex>

              <!-- Warning -->
              <v-flex xs4>
                <box-card theme="warn">
                  <div class="mb-2">
                    <div class="font-24 font-w-600 warn_color">เฝ้าระวัง</div>
                    <div class="font-12 mt-n1">Warn</div>
                  </div>

                  <v-divider />
                  <div
                    v-for="(item, back_strength_index) in form.back_strength"
                    :key="back_strength_index"
                  >
                    <v-row no-gutters align="center" class="flex__box">
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.warn_from"
                          @update-data="item.warn_from = $event"
                        />
                      </v-col>
                      <v-col cols="auto" class="mx-2">To</v-col>
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.warn_to"
                          @update-data="item.warn_to = $event"
                        />
                      </v-col>
                    </v-row>

                    <v-divider
                      v-if="
                        form.back_strength.length != back_strength_index + 1
                      "
                    />
                  </div>
                </box-card>
              </v-flex>

              <!-- Danger -->
              <v-flex xs4>
                <box-card theme="danger">
                  <div class="mb-2">
                    <div class="font-24 font-w-600 danger_color">อันตราย</div>
                    <div class="font-12 mt-n1">Danger</div>
                  </div>

                  <v-divider />

                  <div
                    v-for="(item, back_strength_index) in form.back_strength"
                    :key="back_strength_index"
                  >
                    <v-row no-gutters align="center" class="flex__box">
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="number"
                          :model="item.danger_from"
                          @update-data="item.danger_from = $event"
                        />
                      </v-col>
                      <v-col cols="auto" class="mx-2">To</v-col>
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="number"
                          :model="item.danger_to"
                          @update-data="item.danger_to = $event"
                        />
                      </v-col>
                    </v-row>

                    <v-divider
                      v-if="
                        form.back_strength.length != back_strength_index + 1
                      "
                    />
                  </div>
                </box-card>
              </v-flex>
              <!-- <v-flex xs4>
                <box-card theme="danger">
                  <div class="mb-2">
                    <div class="font-24 font-w-600 danger_color">อันตราย</div>
                    <div class="font-12 mt-n1">Danger</div>
                  </div>

                  <v-divider />

                  <div
                    v-for="(item, back_strength_index) in form.back_strength"
                    :key="back_strength_index"
                  >
                    <v-row no-gutters align="center" class="flex__box">
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.danger_from"
                          @update-data="item.danger_from = $event"
                        />
                      </v-col>
                      <v-col cols="auto" class="mx-2">To</v-col>
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.danger_to"
                          @update-data="item.danger_to = $event"
                        />
                      </v-col>
                    </v-row>

                    <v-divider
                      v-if="
                        form.back_strength.length != back_strength_index + 1
                      "
                    />
                  </div>
                </box-card>
              </v-flex> -->
            </v-layout>
          </v-col>
        </v-row>

        <!-- ผลการวัด -->
        <box-card class="mt-6 area_input">
          <!-- ผลการวัดวันนี้ -->
          <v-row align="start">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ผลการวัดวันนี้
              </div>
              <div class="font-24 font-w-600">ความเสี่ยง</div>
              <div class="font-18 font-w-600 mt-n1">(อาการทั่วไป)</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <div
                class="font-24 font-w-600 standard_color d-flex align-center"
              >
                A
              </div>
              <div class="font-12 mt-n1 mb-2">standard</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.back_strength_text.risk_standard_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <div class="font-24 font-w-600 warn_color d-flex align-center">
                B
              </div>
              <div class="font-12 mt-n1 mb-2">warn</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.back_strength_text.risk_warn_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <div class="font-24 font-w-600 danger_color d-flex align-center">
                C
              </div>
              <div class="font-12 mt-n1 mb-2">Danger</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.back_strength_text.risk_danger_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>
          </v-row>

          <v-divider class="mx-n4 mb-5" style="background-color: #d3d3d3" />

          <!--  ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา) -->
          <v-row align="start" class="mb-1">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
              </div>
              <div class="font-24 font-w-600">วิธีปรับปรุง</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.back_strength_text.improve_standard_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="back_strength_text.standard['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueStandardCheckbox('back_strength_text', item, $event)
                  "
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.back_strength_text.improve_warn_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="back_strength_text.warn['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueWarnCheckbox('back_strength_text', item, $event)
                  "
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.back_strength_text.improve_danger_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="back_strength_text.danger['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueDangerCheckbox('back_strength_text', item, $event)
                  "
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
        </box-card>
      </div>

      <v-divider class="mt-8 mb-5" style="background-color: #d3d3d3" />

      <!-- *************16 การทรงตัวยืนขาเดียว Single Leg Balance *************-->
      <div>
        <div class="pl-5 mb-2">
          <div class="font-20 font-w-600" style="color: #c71010">
            16.การทรงตัวยืนขาเดียว
          </div>
          <div class="font-12 font-w-400 mt-n1">Single Leg Balance</div>
        </div>

        <v-layout class="input__setting">
          <!-- Standard -->
          <v-flex xs4>
            <box-card theme="standard">
              <div class="mb-2">
                <div class="font-24 font-w-600 standard_color">ปกติ</div>
                <div class="font-12 mt-n1">Standard</div>
              </div>
              <v-divider class="" style="background-color: #d3d3d3" />
              <v-row no-gutters align="center" class="flex__box">
                <v-col>
                  <TextInputSetting
                    required
                    inputType="number"
                    :model="form.single_leg_balance.ideal_from"
                    @update-data="form.single_leg_balance.ideal_from = $event"
                  />
                </v-col>
                <v-col cols="auto" class="mx-2">To</v-col>
                <v-col>
                  <TextInputSetting
                    required
                    inputType="number"
                    :model="form.single_leg_balance.ideal_to"
                    @update-data="form.single_leg_balance.ideal_to = $event"
                  />
                </v-col>
              </v-row>
            </box-card>
          </v-flex>

          <!-- Warning -->
          <v-flex xs4>
            <box-card theme="warn">
              <div class="mb-2">
                <div class="font-24 font-w-600 warn_color">เฝ้าระวัง</div>
                <div class="font-12 mt-n1">Warn</div>
              </div>
              <v-divider class="" style="background-color: #d3d3d3" />
              <v-row no-gutters align="center" class="flex__box">
                <v-col>
                  <TextInputSetting
                    required
                    inputType="number"
                    :model="form.single_leg_balance.warn_from"
                    @update-data="form.single_leg_balance.warn_from = $event"
                  />
                </v-col>
                <v-col cols="auto" class="mx-2">To</v-col>
                <v-col>
                  <TextInputSetting
                    required
                    inputType="number"
                    :model="form.single_leg_balance.warn_to"
                    @update-data="form.single_leg_balance.warn_to = $event"
                  />
                </v-col>
              </v-row>
            </box-card>
          </v-flex>

          <!-- Danger -->
          <v-flex xs4>
            <box-card theme="danger">
              <div class="mb-2">
                <div class="font-24 font-w-600 danger_color">อันตราย</div>
                <div class="font-12 mt-n1">Danger</div>
              </div>
              <v-divider class="" style="background-color: #d3d3d3" />
              <v-row no-gutters align="center" class="flex__box">
                <v-col>
                  <TextInputSetting
                    required
                    inputType="number"
                    :model="form.single_leg_balance.danger_from"
                    @update-data="form.single_leg_balance.danger_from = $event"
                  />
                </v-col>
                <v-col cols="auto" class="mx-2">To</v-col>
                <v-col>
                  <TextInputSetting
                    required
                    inputType="number"
                    :model="form.single_leg_balance.danger_to"
                    @update-data="form.single_leg_balance.danger_to = $event"
                  />
                </v-col>
              </v-row>
            </box-card>
          </v-flex>
        </v-layout>

        <!-- ผลการวัด -->
        <box-card class="mt-6 area_input">
          <!-- ผลการวัดวันนี้ -->
          <v-row align="start">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ผลการวัดวันนี้
              </div>
              <div class="font-24 font-w-600">ความเสี่ยง</div>
              <div class="font-18 font-w-600 mt-n1">(อาการทั่วไป)</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <div
                class="font-24 font-w-600 standard_color d-flex align-center"
              >
                A
              </div>
              <div class="font-12 mt-n1 mb-2">standard</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.single_leg_balance.risk_standard_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <div class="font-24 font-w-600 warn_color d-flex align-center">
                B
              </div>
              <div class="font-12 mt-n1 mb-2">warn</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.single_leg_balance.risk_warn_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <div class="font-24 font-w-600 danger_color d-flex align-center">
                C
              </div>
              <div class="font-12 mt-n1 mb-2">Danger</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.single_leg_balance.risk_danger_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>
          </v-row>

          <v-divider class="mx-n4 mb-5" style="background-color: #d3d3d3" />

          <!--  ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา) -->
          <v-row align="start" class="mb-1">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
              </div>
              <div class="font-24 font-w-600">วิธีปรับปรุง</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.single_leg_balance.improve_standard_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="single_leg_balance.standard['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueStandardCheckbox('single_leg_balance', item, $event)
                  "
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.single_leg_balance.improve_warn_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="single_leg_balance.warn['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueWarnCheckbox('single_leg_balance', item, $event)
                  "
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.single_leg_balance.improve_danger_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="single_leg_balance.danger['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueDangerCheckbox('single_leg_balance', item, $event)
                  "
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
        </box-card>
      </div>

      <v-divider class="mt-8 mb-5" style="background-color: #d3d3d3" />

      <!-- *************17 Stand Up Test *************-->
      <div>
        <div class="pl-5 mb-2">
          <div class="font-20 font-w-600" style="color: #c71010">
            17.Stand Up Test
          </div>
          <div class="font-12 font-w-400 mt-n1">Stand Up Test</div>
        </div>

        <v-layout class="input__setting">
          <!-- Standard -->
          <v-flex xs4>
            <box-card theme="standard">
              <div class="mb-2">
                <div class="font-24 font-w-600 standard_color">ปกติ</div>
                <div class="font-12 mt-n1">Standard</div>
              </div>
              <v-divider class="" style="background-color: #d3d3d3" />
              <v-row no-gutters align="center" class="flex__box">
                <v-col>
                  <TextInputSetting
                    required
                    inputType="number"
                    :model="form.stand_up_test.ideal"
                    @update-data="form.stand_up_test.ideal = $event"
                  />
                </v-col>
              </v-row>
            </box-card>
          </v-flex>

          <!-- Warning -->
          <v-flex xs4>
            <box-card theme="warn">
              <div class="mb-2">
                <div class="font-24 font-w-600 warn_color">เฝ้าระวัง</div>
                <div class="font-12 mt-n1">Warn</div>
              </div>
              <v-divider class="" style="background-color: #d3d3d3" />
              <v-row no-gutters align="center" class="flex__box">
                <v-col>
                  <TextInputSetting
                    required
                    inputType="number"
                    :model="form.stand_up_test.warn"
                    @update-data="form.stand_up_test.warn = $event"
                  />
                </v-col>
              </v-row>
            </box-card>
          </v-flex>

          <!-- Danger -->
          <v-flex xs4>
            <box-card theme="danger">
              <div class="mb-2">
                <div class="font-24 font-w-600 danger_color">อันตราย</div>
                <div class="font-12 mt-n1">Danger</div>
              </div>
              <v-divider class="" style="background-color: #d3d3d3" />
              <v-row no-gutters align="center" class="flex__box">
                <v-col>
                  <TextInputSetting
                    required
                    inputType="number"
                    :model="form.stand_up_test.danger"
                    @update-data="form.stand_up_test.danger = $event"
                  />
                </v-col>
              </v-row>
            </box-card>
          </v-flex>
        </v-layout>

        <!-- ผลการวัด -->
        <box-card class="mt-6 area_input">
          <!-- ผลการวัดวันนี้ -->
          <v-row align="start">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ผลการวัดวันนี้
              </div>
              <div class="font-24 font-w-600">ความเสี่ยง</div>
              <div class="font-18 font-w-600 mt-n1">(อาการทั่วไป)</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <div
                class="font-24 font-w-600 standard_color d-flex align-center"
              >
                A
              </div>
              <div class="font-12 mt-n1 mb-2">standard</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.stand_up_test.risk_standard_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <div class="font-24 font-w-600 warn_color d-flex align-center">
                B
              </div>
              <div class="font-12 mt-n1 mb-2">warn</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.stand_up_test.risk_warn_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <div class="font-24 font-w-600 danger_color d-flex align-center">
                C
              </div>
              <div class="font-12 mt-n1 mb-2">Danger</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.stand_up_test.risk_danger_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>
          </v-row>

          <v-divider class="mx-n4 mb-5" style="background-color: #d3d3d3" />

          <!--  ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา) -->
          <v-row align="start" class="mb-1">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
              </div>
              <div class="font-24 font-w-600">วิธีปรับปรุง</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.stand_up_test.improve_standard_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="stand_up_test.standard['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueStandardCheckbox('stand_up_test', item, $event)
                  "
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.stand_up_test.improve_warn_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="stand_up_test.warn['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="setValueWarnCheckbox('stand_up_test', item, $event)"
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.stand_up_test.improve_danger_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="stand_up_test.danger['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueDangerCheckbox('stand_up_test', item, $event)
                  "
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
        </box-card>
      </div>

      <v-divider class="mt-8 mb-5" style="background-color: #d3d3d3" />

      <!-- *************18 Functional Test *************-->
      <div>
        <div class="pl-5 mb-2">
          <div class="font-20 font-w-600" style="color: #c71010">
            18.Functional Test
          </div>
          <div class="font-12 font-w-400 mt-n1">Functional Test</div>
        </div>

        <v-layout class="input__setting">
          <!-- Standard -->
          <v-flex xs4>
            <box-card theme="standard">
              <div class="mb-2">
                <div class="font-24 font-w-600 standard_color">ปกติ</div>
                <div class="font-12 mt-n1">Standard</div>
              </div>
              <v-divider class="" style="background-color: #d3d3d3" />
              <v-row no-gutters align="center" class="flex__box">
                <v-col>
                  <TextInputSetting
                    required
                    inputType="number"
                    :model="form.functional_test.ideal_from"
                    @update-data="form.functional_test.ideal_from = $event"
                  />
                </v-col>
                <v-col cols="auto" class="mx-2">To</v-col>
                <v-col>
                  <TextInputSetting
                    required
                    inputType="number"
                    :model="form.functional_test.ideal_to"
                    @update-data="form.functional_test.ideal_to = $event"
                  />
                </v-col>
              </v-row>
            </box-card>
          </v-flex>

          <!-- Warning -->
          <v-flex xs4>
            <box-card theme="warn">
              <div class="mb-2">
                <div class="font-24 font-w-600 warn_color">เฝ้าระวัง</div>
                <div class="font-12 mt-n1">Warn</div>
              </div>
              <v-divider class="" style="background-color: #d3d3d3" />
              <v-row no-gutters align="center" class="flex__box">
                <v-col>
                  <TextInputSetting
                    required
                    inputType="number"
                    :model="form.functional_test.warn_from"
                    @update-data="form.functional_test.warn_from = $event"
                  />
                </v-col>
                <v-col cols="auto" class="mx-2">To</v-col>
                <v-col>
                  <TextInputSetting
                    required
                    inputType="number"
                    :model="form.functional_test.warn_to"
                    @update-data="form.functional_test.warn_to = $event"
                  />
                </v-col>
              </v-row>
            </box-card>
          </v-flex>

          <!-- Danger -->
          <v-flex xs4>
            <box-card theme="danger">
              <div class="mb-2">
                <div class="font-24 font-w-600 danger_color">อันตราย</div>
                <div class="font-12 mt-n1">Danger</div>
              </div>
              <v-divider class="" style="background-color: #d3d3d3" />
              <v-row no-gutters align="center" class="flex__box">
                <v-col>
                  <TextInputSetting
                    required
                    inputType="number"
                    :model="form.functional_test.danger_from"
                    @update-data="form.functional_test.danger_from = $event"
                  />
                </v-col>
                <v-col cols="auto" class="mx-2">To</v-col>
                <v-col>
                  <TextInputSetting
                    required
                    inputType="number"
                    :model="form.functional_test.danger_to"
                    @update-data="form.functional_test.danger_to = $event"
                  />
                </v-col>
              </v-row>
            </box-card>
          </v-flex>
        </v-layout>

        <!-- ผลการวัด -->
        <box-card class="mt-6 area_input">
          <!-- ผลการวัดวันนี้ -->
          <v-row align="start">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ผลการวัดวันนี้
              </div>
              <div class="font-24 font-w-600">ความเสี่ยง</div>
              <div class="font-18 font-w-600 mt-n1">(อาการทั่วไป)</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <div
                class="font-24 font-w-600 standard_color d-flex align-center"
              >
                A
              </div>
              <div class="font-12 mt-n1 mb-2">standard</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.functional_test.risk_standard_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <div class="font-24 font-w-600 warn_color d-flex align-center">
                B
              </div>
              <div class="font-12 mt-n1 mb-2">warn</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.functional_test.risk_warn_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <div class="font-24 font-w-600 danger_color d-flex align-center">
                C
              </div>
              <div class="font-12 mt-n1 mb-2">Danger</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.functional_test.risk_danger_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>
          </v-row>

          <v-divider class="mx-n4 mb-5" style="background-color: #d3d3d3" />

          <!--  ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา) -->
          <v-row align="start" class="mb-1">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
              </div>
              <div class="font-24 font-w-600">วิธีปรับปรุง</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.functional_test.improve_standard_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="functional_test.standard['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueStandardCheckbox('functional_test', item, $event)
                  "
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.functional_test.improve_warn_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="functional_test.warn['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueWarnCheckbox('functional_test', item, $event)
                  "
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.functional_test.improve_danger_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="functional_test.danger['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueDangerCheckbox('functional_test', item, $event)
                  "
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
        </box-card>
      </div>

      <v-divider class="mt-8 mb-5" style="background-color: #d3d3d3" />

      <!-- *************19 ระดับน้ำในร่างกาย Body water content *************** -->
      <div>
        <div class="pl-5 mb-2">
          <div class="font-20 font-w-600" style="color: #c71010">
            19.ระดับน้ำในร่างกาย
          </div>
          <div class="font-12 font-w-400 mt-n1">Body water content</div>
        </div>

        <v-row no-gutters>
          <v-col>
            <box-card>
              <v-row no-gutters class="pt-3 mb-3">
                <v-col cols="6" class="font-24 font-w-600">Gender</v-col>
                <v-col cols="6" class="font-24 font-w-600">Age</v-col>
              </v-row>

              <v-divider />

              <div
                v-for="(
                  item, body_water_content_index
                ) in form.body_water_content"
                :key="body_water_content_index"
              >
                <div class="flex__box d-flex flex-column justify-center">
                  <v-row
                    align="center"
                    no-gutters
                    :class="
                      form.body_water_content.length ==
                      body_water_content_index + 1
                        ? 'mb-3'
                        : ''
                    "
                  >
                    <v-col cols="6" class="font-16 font-w-600">
                      {{ item.gender === "M" ? "Male" : "Female" }}
                    </v-col>
                    <v-col cols="6" class="font-16 font-w-600">
                      {{ item.age_from }}-{{ item.age_to }}
                    </v-col>
                  </v-row>
                </div>

                <v-divider
                  v-if="
                    form.body_water_content.length !=
                    body_water_content_index + 1
                  "
                />
              </div>
            </box-card>
          </v-col>
          <v-col cols="9">
            <v-layout class="input__setting">
              <!-- Standard -->
              <v-flex shrink style="width: 20%">
                <box-card theme="standard">
                  <div class="mb-2">
                    <div class="font-20 font-w-600 standard_color">ปกติ</div>
                    <div class="font-12 mt-n1">Standard</div>
                  </div>

                  <v-divider />
                  <div
                    v-for="(
                      item, body_water_content_index
                    ) in form.body_water_content"
                    :key="body_water_content_index"
                  >
                    <v-row no-gutters align="center" class="flex__box">
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.ideal_from"
                          @update-data="item.ideal_from = $event"
                        />
                      </v-col>
                      <v-col cols="auto" class="mx-2">To</v-col>
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.ideal_to"
                          @update-data="item.ideal_to = $event"
                        />
                      </v-col>
                    </v-row>

                    <v-divider
                      v-if="
                        form.body_water_content.length !=
                        body_water_content_index + 1
                      "
                    />
                  </div>
                </box-card>
              </v-flex>

              <!-- Warning -->
              <v-flex shrink style="width: 20%">
                <box-card theme="warn">
                  <div class="mb-2">
                    <div class="font-20 font-w-600 warn_color">
                      เฝ้าระวัง <span class="font-12">(ต่ำกว่า)</span>
                    </div>
                    <div class="font-12 mt-n1">Warn (Less than)</div>
                  </div>

                  <v-divider />
                  <div
                    v-for="(
                      item, body_water_content_index
                    ) in form.body_water_content"
                    :key="body_water_content_index"
                  >
                    <v-row no-gutters align="center" class="flex__box">
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.warn_less_from"
                          @update-data="item.warn_less_from = $event"
                        />
                      </v-col>
                      <v-col cols="auto" class="mx-2">To</v-col>
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.warn_less_to"
                          @update-data="item.warn_less_to = $event"
                        />
                      </v-col>
                    </v-row>

                    <v-divider
                      v-if="
                        form.body_water_content.length !=
                        body_water_content_index + 1
                      "
                    />
                  </div>
                </box-card>
              </v-flex>
              <v-flex shrink style="width: 20%">
                <box-card theme="warn">
                  <div class="mb-2">
                    <div class="font-20 font-w-600 warn_color">
                      เฝ้าระวัง <span class="font-12">(มากกว่า)</span>
                    </div>
                    <div class="font-12 mt-n1">Warn (MMore than)</div>
                  </div>

                  <v-divider />
                  <div
                    v-for="(
                      item, body_water_content_index
                    ) in form.body_water_content"
                    :key="body_water_content_index"
                  >
                    <v-row no-gutters align="center" class="flex__box">
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.warn_than_from"
                          @update-data="item.warn_than_from = $event"
                        />
                      </v-col>
                      <v-col cols="auto" class="mx-2">To</v-col>
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.warn_than_to"
                          @update-data="item.warn_than_to = $event"
                        />
                      </v-col>
                    </v-row>

                    <v-divider
                      v-if="
                        form.body_water_content.length !=
                        body_water_content_index + 1
                      "
                    />
                  </div>
                </box-card>
              </v-flex>

              <!-- Danger -->
              <v-flex shrink style="width: 20%">
                <box-card theme="danger">
                  <div class="mb-2">
                    <div
                      class="font-20 font-w-600 danger_color d-flex align-center"
                    >
                      อันตราย <span class="font-12">(ต่ำกว่า)</span>
                    </div>
                    <div class="font-12 mt-n1">Danger(Less than)</div>
                  </div>
                  <v-divider />

                  <div
                    v-for="(
                      item, body_water_content_index
                    ) in form.body_water_content"
                    :key="body_water_content_index"
                  >
                    <v-row no-gutters align="center" class="flex__box">
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.danger_less_from"
                          @update-data="item.danger_less_from = $event"
                        />
                      </v-col>
                      <v-col cols="auto" class="mx-2">To</v-col>
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.danger_less_to"
                          @update-data="item.danger_less_to = $event"
                        />
                      </v-col>
                    </v-row>

                    <v-divider
                      v-if="
                        form.body_water_content.length !=
                        body_water_content_index + 1
                      "
                    />
                  </div>
                </box-card>
              </v-flex>
              <v-flex shrink style="width: 20%">
                <box-card theme="danger">
                  <div class="mb-2">
                    <div
                      class="font-20 font-w-600 danger_color d-flex align-center"
                    >
                      อันตราย <span class="font-12">(มากกว่า)</span>
                    </div>
                    <div class="font-12 mt-n1">Danger(More than)</div>
                  </div>

                  <v-divider />

                  <div
                    v-for="(
                      item, body_water_content_index
                    ) in form.body_water_content"
                    :key="body_water_content_index"
                  >
                    <v-row no-gutters align="center" class="flex__box">
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.danger_than_from"
                          @update-data="item.danger_than_from = $event"
                        />
                      </v-col>
                      <v-col cols="auto" class="mx-2">To</v-col>
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="decimal"
                          :model="item.danger_than_to"
                          @update-data="item.danger_than_to = $event"
                        />
                      </v-col>
                    </v-row>

                    <v-divider
                      v-if="
                        form.body_water_content.length !=
                        body_water_content_index + 1
                      "
                    />
                  </div>
                </box-card>
              </v-flex>
            </v-layout>
          </v-col>
        </v-row>

        <!-- ผลการวัด -->
        <box-card class="mt-6 area_input">
          <!-- ผลการวัดวันนี้ -->
          <v-row align="start">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ผลการวัดวันนี้
              </div>
              <div class="font-24 font-w-600">ความเสี่ยง</div>
              <div class="font-18 font-w-600 mt-n1">(อาการทั่วไป)</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <div
                class="font-24 font-w-600 standard_color d-flex align-center"
              >
                A
              </div>
              <div class="font-12 mt-n1 mb-2">standard</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.body_water_content_text.risk_standard_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <div class="font-24 font-w-600 warn_color d-flex align-center">
                B
              </div>
              <div class="font-12 mt-n1 mb-2">warn</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.body_water_content_text.risk_warn_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <div class="font-24 font-w-600 danger_color d-flex align-center">
                C
              </div>
              <div class="font-12 mt-n1 mb-2">Danger</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.body_water_content_text.risk_danger_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>
          </v-row>

          <v-divider class="mx-n4 mb-5" style="background-color: #d3d3d3" />

          <!--  ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา) -->
          <v-row align="start" class="mb-1">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
              </div>
              <div class="font-24 font-w-600">วิธีปรับปรุง</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.body_water_content_text.improve_standard_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="
                    body_water_content_text.standard['checkbox' + (i + 1)]
                  "
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueStandardCheckbox(
                      'body_water_content_text',
                      item,
                      $event
                    )
                  "
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.body_water_content_text.improve_warn_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="body_water_content_text.warn['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueWarnCheckbox(
                      'body_water_content_text',
                      item,
                      $event
                    )
                  "
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.body_water_content_text.improve_danger_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="body_water_content_text.danger['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueDangerCheckbox(
                      'body_water_content_text',
                      item,
                      $event
                    )
                  "
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
        </box-card>
      </div>

      <v-divider class="mt-8 mb-5" style="background-color: #d3d3d3" />

      <!-- *************20 ความอ่อนตัวของหลัง Flexibility of Waist *************-->
      <div>
        <div class="pl-5 mb-2">
          <div class="font-20 font-w-600" style="color: #c71010">
            20.ความอ่อนตัวของหลัง
          </div>
          <div class="font-12 font-w-400 mt-n1">Flexibility of Back</div>
        </div>

        <v-layout class="input__setting">
          <!-- Standard -->
          <v-flex xs4>
            <box-card theme="standard">
              <div class="mb-2">
                <div class="font-24 font-w-600 standard_color">ปกติ</div>
                <div class="font-12 mt-n1">Standard</div>
              </div>
              <v-divider class="" style="background-color: #d3d3d3" />
              <v-row no-gutters align="center" class="flex__box">
                <v-col>
                  <TextInputSetting
                    required
                    inputType="decimal"
                    :model="form.flexibility_waist.ideal_from"
                    @update-data="form.flexibility_waist.ideal_from = $event"
                  />
                </v-col>
                <v-col cols="auto" class="mx-2">To</v-col>
                <v-col>
                  <TextInputSetting
                    required
                    inputType="decimal"
                    :model="form.flexibility_waist.ideal_to"
                    @update-data="form.flexibility_waist.ideal_to = $event"
                  />
                </v-col>
              </v-row>
            </box-card>
          </v-flex>

          <!-- Warning -->
          <v-flex xs4>
            <box-card theme="warn">
              <div class="mb-2">
                <div class="font-24 font-w-600 warn_color">เฝ้าระวัง</div>
                <div class="font-12 mt-n1">Warn</div>
              </div>
              <v-divider class="" style="background-color: #d3d3d3" />
              <v-row no-gutters align="center" class="flex__box">
                <v-col>
                  <TextInputSetting
                    required
                    inputType="decimal"
                    :model="form.flexibility_waist.warn_from"
                    @update-data="form.flexibility_waist.warn_from = $event"
                  />
                </v-col>
                <v-col cols="auto" class="mx-2">To</v-col>
                <v-col>
                  <TextInputSetting
                    required
                    inputType="decimal"
                    :model="form.flexibility_waist.warn_to"
                    @update-data="form.flexibility_waist.warn_to = $event"
                  />
                </v-col>
              </v-row>
            </box-card>
          </v-flex>

          <!-- Danger -->
          <v-flex xs4>
            <box-card theme="danger">
              <div class="mb-2">
                <div class="font-24 font-w-600 danger_color">อันตราย</div>
                <div class="font-12 mt-n1">Danger</div>
              </div>
              <v-divider class="" style="background-color: #d3d3d3" />
              <v-row no-gutters align="center" class="flex__box">
                <v-col>
                  <TextInputSetting
                    required
                    inputType="decimal"
                    :model="form.flexibility_waist.danger_from"
                    @update-data="form.flexibility_waist.danger_from = $event"
                  />
                </v-col>
                <v-col cols="auto" class="mx-2">To</v-col>
                <v-col>
                  <TextInputSetting
                    required
                    inputType="decimal"
                    :model="form.flexibility_waist.danger_to"
                    @update-data="form.flexibility_waist.danger_to = $event"
                  />
                </v-col>
              </v-row>
            </box-card>
          </v-flex>
        </v-layout>

        <!-- ผลการวัด -->
        <box-card class="mt-6 area_input">
          <!-- ผลการวัดวันนี้ -->
          <v-row align="start">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ผลการวัดวันนี้
              </div>
              <div class="font-24 font-w-600">ความเสี่ยง</div>
              <div class="font-18 font-w-600 mt-n1">(อาการทั่วไป)</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <div
                class="font-24 font-w-600 standard_color d-flex align-center"
              >
                A
              </div>
              <div class="font-12 mt-n1 mb-2">standard</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.flexibility_waist.risk_standard_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <div class="font-24 font-w-600 warn_color d-flex align-center">
                B
              </div>
              <div class="font-12 mt-n1 mb-2">warn</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.flexibility_waist.risk_warn_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <div class="font-24 font-w-600 danger_color d-flex align-center">
                C
              </div>
              <div class="font-12 mt-n1 mb-2">Danger</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.flexibility_waist.risk_danger_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>
          </v-row>

          <v-divider class="mx-n4 mb-5" style="background-color: #d3d3d3" />

          <!--  ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา) -->
          <v-row align="start" class="mb-1">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
              </div>
              <div class="font-24 font-w-600">วิธีปรับปรุง</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.flexibility_waist.improve_standard_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="flexibility_waist.standard['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueStandardCheckbox('flexibility_waist', item, $event)
                  "
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.flexibility_waist.improve_warn_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="flexibility_waist.warn['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueWarnCheckbox('flexibility_waist', item, $event)
                  "
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.flexibility_waist.improve_danger_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="flexibility_waist.danger['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueDangerCheckbox('flexibility_waist', item, $event)
                  "
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
        </box-card>
      </div>

      <v-divider class="mt-8 mb-5" style="background-color: #d3d3d3" />

      <!-- *************21 คะแนนท่าทาง Posture Score *************-->
      <div>
        <div class="pl-5 mb-2">
          <div class="font-20 font-w-600" style="color: #c71010">
            21.คะแนนท่าทาง
          </div>
          <div class="font-12 font-w-400 mt-n1">Posture Score</div>
        </div>

        <v-layout class="input__setting">
          <!-- Standard -->
          <v-flex xs4>
            <box-card theme="standard">
              <div class="mb-2">
                <div class="font-24 font-w-600 standard_color">ปกติ</div>
                <div class="font-12 mt-n1">Standard</div>
              </div>
              <v-divider class="" style="background-color: #d3d3d3" />
              <v-row no-gutters align="center" class="flex__box">
                <v-col>
                  <TextInputSetting
                    required
                    inputType="decimal"
                    :model="form.posture_score.ideal_from"
                    @update-data="form.posture_score.ideal_from = $event"
                  />
                </v-col>
                <v-col cols="auto" class="mx-2">To</v-col>
                <v-col>
                  <TextInputSetting
                    required
                    inputType="decimal"
                    :model="form.posture_score.ideal_to"
                    @update-data="form.posture_score.ideal_to = $event"
                  />
                </v-col>
              </v-row>
            </box-card>
          </v-flex>

          <!-- Warning -->
          <v-flex xs4>
            <box-card theme="warn">
              <div class="mb-2">
                <div class="font-24 font-w-600 warn_color">เฝ้าระวัง</div>
                <div class="font-12 mt-n1">Warn</div>
              </div>
              <v-divider class="" style="background-color: #d3d3d3" />
              <v-row no-gutters align="center" class="flex__box">
                <v-col>
                  <TextInputSetting
                    required
                    inputType="decimal"
                    :model="form.posture_score.warn_from"
                    @update-data="form.posture_score.warn_from = $event"
                  />
                </v-col>
                <v-col cols="auto" class="mx-2">To</v-col>
                <v-col>
                  <TextInputSetting
                    required
                    inputType="decimal"
                    :model="form.posture_score.warn_to"
                    @update-data="form.posture_score.warn_to = $event"
                  />
                </v-col>
              </v-row>
            </box-card>
          </v-flex>

          <!-- Danger -->
          <v-flex xs4>
            <box-card theme="danger">
              <div class="mb-2">
                <div class="font-24 font-w-600 danger_color">อันตราย</div>
                <div class="font-12 mt-n1">Danger</div>
              </div>
              <v-divider class="" style="background-color: #d3d3d3" />
              <v-row no-gutters align="center" class="flex__box">
                <v-col>
                  <TextInputSetting
                    required
                    inputType="decimal"
                    :model="form.posture_score.danger_from"
                    @update-data="form.posture_score.danger_from = $event"
                  />
                </v-col>
                <v-col cols="auto" class="mx-2">To</v-col>
                <v-col>
                  <TextInputSetting
                    required
                    inputType="decimal"
                    :model="form.posture_score.danger_to"
                    @update-data="form.posture_score.danger_to = $event"
                  />
                </v-col>
              </v-row>
            </box-card>
          </v-flex>
        </v-layout>

        <!-- ผลการวัด -->
        <box-card class="mt-6 area_input">
          <!-- ผลการวัดวันนี้ -->
          <v-row align="start">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ผลการวัดวันนี้
              </div>
              <div class="font-24 font-w-600">ความเสี่ยง</div>
              <div class="font-18 font-w-600 mt-n1">(อาการทั่วไป)</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <div
                class="font-24 font-w-600 standard_color d-flex align-center"
              >
                A
              </div>
              <div class="font-12 mt-n1 mb-2">standard</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.posture_score.risk_standard_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <div class="font-24 font-w-600 warn_color d-flex align-center">
                B
              </div>
              <div class="font-12 mt-n1 mb-2">warn</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.posture_score.risk_warn_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <div class="font-24 font-w-600 danger_color d-flex align-center">
                C
              </div>
              <div class="font-12 mt-n1 mb-2">Danger</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.posture_score.risk_danger_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>
          </v-row>

          <v-divider class="mx-n4 mb-5" style="background-color: #d3d3d3" />

          <!--  ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา) -->
          <v-row align="start" class="mb-1">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
              </div>
              <div class="font-24 font-w-600">วิธีปรับปรุง</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.posture_score.improve_standard_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="posture_score.standard['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueStandardCheckbox('posture_score', item, $event)
                  "
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.posture_score.improve_warn_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="posture_score.warn['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="setValueWarnCheckbox('posture_score', item, $event)"
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.posture_score.improve_danger_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="posture_score.danger['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueDangerCheckbox('posture_score', item, $event)
                  "
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
        </box-card>
      </div>

      <v-divider class="mt-8 mb-5" style="background-color: #d3d3d3" />

      <!-- *************22 ปัญหาท่าทาง Posture Problem *************-->
      <div>
        <div class="pl-5 mb-2">
          <div class="font-20 font-w-600" style="color: #c71010">
            22.ปัญหาท่าทาง
          </div>
          <div class="font-12 font-w-400 mt-n1">Posture Problem</div>
        </div>

        <v-layout class="input__setting">
          <!-- Standard -->
          <v-flex xs4>
            <box-card theme="standard">
              <div class="mb-2">
                <div class="font-24 font-w-600 standard_color">ปกติ</div>
                <div class="font-12 mt-n1">Standard</div>
              </div>
              <v-divider class="" style="background-color: #d3d3d3" />
              <v-row no-gutters align="center" class="flex__box">
                <v-col>
                  <TextInputSetting
                    required
                    inputType="decimal"
                    :model="form.posture_type.ideal"
                    @update-data="form.posture_type.ideal = $event"
                  />
                </v-col>
              </v-row>
            </box-card>
          </v-flex>

          <!-- Warning -->
          <v-flex xs4>
            <box-card theme="warn">
              <div class="mb-2">
                <div class="font-24 font-w-600 warn_color">เฝ้าระวัง</div>
                <div class="font-12 mt-n1">Warn</div>
              </div>
              <v-divider class="" style="background-color: #d3d3d3" />
              <v-row no-gutters align="center" class="flex__box">
                <v-col>
                  <TextInputSetting
                    required
                    inputType="decimal"
                    :model="form.posture_type.warn"
                    @update-data="form.posture_type.warn = $event"
                  />
                </v-col>
              </v-row>
            </box-card>
          </v-flex>

          <!-- Danger -->
          <v-flex xs4>
            <box-card theme="danger">
              <div class="mb-2">
                <div class="font-24 font-w-600 danger_color">อันตราย</div>
                <div class="font-12 mt-n1">Danger</div>
              </div>
              <v-divider class="" style="background-color: #d3d3d3" />
              <v-row no-gutters align="center" class="flex__box">
                <v-col>
                  <TextInputSetting
                    required
                    inputType="decimal"
                    :model="form.posture_type.danger_from"
                    @update-data="form.posture_type.danger_from = $event"
                  />
                </v-col>
                <v-col cols="auto" class="mx-2">To</v-col>
                <v-col>
                  <TextInputSetting
                    required
                    inputType="decimal"
                    :model="form.posture_type.danger_to"
                    @update-data="form.posture_type.danger_to = $event"
                  />
                </v-col>
              </v-row>
            </box-card>
          </v-flex>
        </v-layout>

        <!-- ผลการวัด -->
        <box-card class="mt-6 area_input">
          <!-- ผลการวัดวันนี้ -->
          <v-row align="start">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ผลการวัดวันนี้
              </div>
              <div class="font-24 font-w-600">ความเสี่ยง</div>
              <div class="font-18 font-w-600 mt-n1">(อาการทั่วไป)</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <div
                class="font-24 font-w-600 standard_color d-flex align-center"
              >
                A
              </div>
              <div class="font-12 mt-n1 mb-2">standard</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.posture_type.risk_standard_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <div class="font-24 font-w-600 warn_color d-flex align-center">
                B
              </div>
              <div class="font-12 mt-n1 mb-2">warn</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.posture_type.risk_warn_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <div class="font-24 font-w-600 danger_color d-flex align-center">
                C
              </div>
              <div class="font-12 mt-n1 mb-2">Danger</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.posture_type.risk_danger_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>
          </v-row>

          <v-divider class="mx-n4 mb-5" style="background-color: #d3d3d3" />

          <!--  ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา) -->
          <v-row align="start" class="mb-1">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
              </div>
              <div class="font-24 font-w-600">วิธีปรับปรุง</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.posture_type.improve_standard_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="posture_type.standard['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueStandardCheckbox('posture_type', item, $event)
                  "
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.posture_type.improve_warn_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="posture_type.warn['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="setValueWarnCheckbox('posture_type', item, $event)"
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="form.posture_type.improve_danger_text"
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="posture_type.danger['checkbox' + (i + 1)]"
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="setValueDangerCheckbox('posture_type', item, $event)"
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
        </box-card>
      </div>

      <v-divider class="mt-8 mb-5" style="background-color: #d3d3d3" />
      <!-- *************23 Chair Stand Test  *************-->
      <div>
        <div class="pl-5 mb-2">
          <div class="font-20 font-w-600" style="color: #c71010">
            23.ทดสอบลุกจากเก้าอี้
          </div>
          <div class="font-12 font-w-400 mt-n1">Chair Stand Test</div>
        </div>

        <v-row no-gutters>
          <v-col cols="4">
            <box-card>
              <v-row no-gutters class="pt-3 mb-3">
                <v-col cols="6" class="font-24 font-w-600">Gender</v-col>
                <v-col cols="6" class="font-24 font-w-600">Age</v-col>
              </v-row>

              <v-divider />

              <div
                v-for="(
                  item, chair_stand_test_index
                ) in form.chair_stand_test"
                :key="chair_stand_test_index"
              >
                <div class="flex__box d-flex flex-column justify-center">
                  <v-row
                    align="center"
                    no-gutters
                    :class="
                      form.chair_stand_test.length ==
                      chair_stand_test_index + 1
                        ? 'mb-3'
                        : ''
                    "
                  >
                    <v-col cols="6" class="font-16 font-w-600">
                      {{ item.gender === "M" ? "Male" : "Female" }}
                    </v-col>
                    <v-col cols="6" class="font-16 font-w-600">
                      {{ item.age_from }}-{{ item.age_to }}
                    </v-col>
                  </v-row>
                </div>
                <v-divider
                  v-if="
                    form.chair_stand_test.length !=
                    chair_stand_test_index + 1
                  "
                />
              </div>
            </box-card>
          </v-col>
          <v-col cols="8">
            <v-layout class="input__setting">
              <!-- Standard -->
              <v-flex xs4>
                <box-card theme="standard">
                  <div class="mb-2">
                    <div class="font-24 font-w-600 standard_color">ปกติ</div>
                    <div class="font-12 mt-n1">Standard</div>
                  </div>

                  <v-divider />
                  <div
                    v-for="(
                      item, chair_stand_test_index
                    ) in form.chair_stand_test"
                    :key="chair_stand_test_index"
                  >
                    <v-row no-gutters align="center" class="flex__box">
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="number"
                          :model="item.standard_from"
                          @update-data="item.standard_from = $event"
                        />
                      </v-col>
                      <v-col cols="auto" class="mx-2">To</v-col>
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="number"
                          :model="item.standard_to"
                          @update-data="item.standard_to = $event"
                        />
                      </v-col>
                    </v-row>

                    <v-divider
                      v-if="
                        form.chair_stand_test.length !=
                        chair_stand_test_index + 1
                      "
                    />
                  </div>
                </box-card>
              </v-flex>

              <!-- Warning -->
              <v-flex xs4>
                <box-card theme="warn">
                  <div class="mb-2">
                    <div class="font-24 font-w-600 warn_color">เฝ้าระวัง</div>
                    <div class="font-12 mt-n1">Warn</div>
                  </div>

                  <v-divider />
                  <div
                    v-for="(
                      item, chair_stand_test_index
                    ) in form.chair_stand_test"
                    :key="chair_stand_test_index"
                  >
                    <v-row no-gutters align="center" class="flex__box">
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="number"
                          :model="item.warn_from"
                          @update-data="item.warn_from = $event"
                        />
                      </v-col>
                      <v-col cols="auto" class="mx-2">To</v-col>
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="number"
                          :model="item.warn_to"
                          @update-data="item.warn_to = $event"
                        />
                      </v-col>
                    </v-row>

                    <v-divider
                      v-if="
                        form.chair_stand_test.length !=
                        chair_stand_test_index + 1
                      "
                    />
                  </div>
                </box-card>
              </v-flex>

              <!-- Danger -->
              <v-flex xs4>
                <box-card theme="danger">
                  <div class="mb-2">
                    <div class="font-24 font-w-600 danger_color">อันตราย</div>
                    <div class="font-12 mt-n1">Danger</div>
                  </div>

                  <v-divider />

                  <div
                    v-for="(
                      item, chair_stand_test_index
                    ) in form.chair_stand_test"
                    :key="chair_stand_test_index"
                  >
                    <v-row no-gutters align="center" class="flex__box">
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="number"
                          :model="item.danger_from"
                          @update-data="item.danger_from = $event"
                        />
                      </v-col>
                      <v-col cols="auto" class="mx-2">To</v-col>
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="number"
                          :model="item.danger_to"
                          @update-data="item.danger_to = $event"
                        />
                      </v-col>
                    </v-row>

                    <v-divider
                      v-if="
                        form.chair_stand_test.length !=
                        chair_stand_test_index + 1
                      "
                    />
                  </div>
                </box-card>
              </v-flex>
            </v-layout>
          </v-col>
        </v-row>

        <!-- ผลการวัด -->
        <box-card class="mt-6 area_input">
          <!-- ผลการวัดวันนี้ -->
          <v-row align="start">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ผลการวัดวันนี้
              </div>
              <div class="font-24 font-w-600">ความเสี่ยง</div>
              <div class="font-18 font-w-600 mt-n1">(อาการทั่วไป)</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <div
                class="font-24 font-w-600 standard_color d-flex align-center"
              >
                A
              </div>
              <div class="font-12 mt-n1 mb-2">standard</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="
                  form.chair_stand_test_settings_text.risk_standard_text
                "
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <div class="font-24 font-w-600 warn_color d-flex align-center">
                B
              </div>
              <div class="font-12 mt-n1 mb-2">warn</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.chair_stand_test_settings_text.risk_warn_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <div class="font-24 font-w-600 danger_color d-flex align-center">
                C
              </div>
              <div class="font-12 mt-n1 mb-2">Danger</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.chair_stand_test_settings_text.risk_danger_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>
          </v-row>

          <v-divider class="mx-n4 mb-5" style="background-color: #d3d3d3" />

          <!--  ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา) -->
          <v-row align="start" class="mb-1">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
              </div>
              <div class="font-24 font-w-600">วิธีปรับปรุง</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="
                  form.chair_stand_test_settings_text.improve_standard_text
                "
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="
                    chair_stand_test_settings_text.standard[
                      'checkbox' + (i + 1)
                    ]
                  "
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueStandardCheckbox(
                      'chair_stand_test_settings_text',
                      item,
                      $event
                    )
                  "
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="
                  form.chair_stand_test_settings_text.improve_warn_text
                "
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="
                    chair_stand_test_settings_text.warn['checkbox' + (i + 1)]
                  "
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueWarnCheckbox(
                      'chair_stand_test_settings_text',
                      item,
                      $event
                    )
                  "
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="
                  form.chair_stand_test_settings_text.improve_danger_text
                "
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="
                    chair_stand_test_settings_text.danger[
                      'checkbox' + (i + 1)
                    ]
                  "
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueDangerCheckbox(
                      'chair_stand_test_settings_text',
                      item,
                      $event
                    )
                  "
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
        </box-card>
      </div>

      <v-divider class="mt-8 mb-5" style="background-color: #d3d3d3" />
      <!-- *************24 Step Box Test  *************-->
      <div>
        <div class="pl-5 mb-2">
          <div class="font-20 font-w-600" style="color: #c71010">
            24.ทดสอบก้าวขึ้น-ลง
          </div>
          <div class="font-12 font-w-400 mt-n1">Step Box Test</div>
        </div>

        <v-row no-gutters>
          <v-col cols="4">
            <box-card>
              <v-row no-gutters class="pt-3 mb-3">
                <v-col cols="6" class="font-24 font-w-600">Gender</v-col>
                <v-col cols="6" class="font-24 font-w-600">Age</v-col>
              </v-row>

              <v-divider />

              <div
                v-for="(
                  item, step_box_test_index
                ) in form.step_box_test"
                :key="step_box_test_index"
              >
                <div class="flex__box d-flex flex-column justify-center">
                  <v-row
                    align="center"
                    no-gutters
                    :class="
                      form.step_box_test.length ==
                      step_box_test_index + 1
                        ? 'mb-3'
                        : ''
                    "
                  >
                    <v-col cols="6" class="font-16 font-w-600">
                      {{ item.gender === "M" ? "Male" : "Female" }}
                    </v-col>
                    <v-col cols="6" class="font-16 font-w-600">
                      {{ item.age_from }}-{{ item.age_to }}
                    </v-col>
                  </v-row>
                </div>
                <v-divider
                  v-if="
                    form.step_box_test.length !=
                    step_box_test_index + 1
                  "
                />
              </div>
            </box-card>
          </v-col>
          <v-col cols="8">
            <v-layout class="input__setting">
              <!-- Standard -->
              <v-flex xs4>
                <box-card theme="standard">
                  <div class="mb-2">
                    <div class="font-24 font-w-600 standard_color">ปกติ</div>
                    <div class="font-12 mt-n1">Standard</div>
                  </div>

                  <v-divider />
                  <div
                    v-for="(
                      item, step_box_test_index
                    ) in form.step_box_test"
                    :key="step_box_test_index"
                  >
                    <v-row no-gutters align="center" class="flex__box">
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="number"
                          :model="item.standard_from"
                          @update-data="item.standard_from = $event"
                        />
                      </v-col>
                      <v-col cols="auto" class="mx-2">To</v-col>
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="number"
                          :model="item.standard_to"
                          @update-data="item.standard_to = $event"
                        />
                      </v-col>
                    </v-row>

                    <v-divider
                      v-if="
                        form.step_box_test.length !=
                        step_box_test_index + 1
                      "
                    />
                  </div>
                </box-card>
              </v-flex>

              <!-- Warning -->
              <v-flex xs4>
                <box-card theme="warn">
                  <div class="mb-2">
                    <div class="font-24 font-w-600 warn_color">เฝ้าระวัง</div>
                    <div class="font-12 mt-n1">Warn</div>
                  </div>

                  <v-divider />
                  <div
                    v-for="(
                      item, step_box_test_index
                    ) in form.step_box_test"
                    :key="step_box_test_index"
                  >
                    <v-row no-gutters align="center" class="flex__box">
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="number"
                          :model="item.warn_from"
                          @update-data="item.warn_from = $event"
                        />
                      </v-col>
                      <v-col cols="auto" class="mx-2">To</v-col>
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="number"
                          :model="item.warn_to"
                          @update-data="item.warn_to = $event"
                        />
                      </v-col>
                    </v-row>

                    <v-divider
                      v-if="
                        form.step_box_test.length !=
                        step_box_test_index + 1
                      "
                    />
                  </div>
                </box-card>
              </v-flex>

              <!-- Danger -->
              <v-flex xs4>
                <box-card theme="danger">
                  <div class="mb-2">
                    <div class="font-24 font-w-600 danger_color">อันตราย</div>
                    <div class="font-12 mt-n1">Danger</div>
                  </div>

                  <v-divider />

                  <div
                    v-for="(
                      item, step_box_test_index
                    ) in form.step_box_test"
                    :key="step_box_test_index"
                  >
                    <v-row no-gutters align="center" class="flex__box">
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="number"
                          :model="item.danger_from"
                          @update-data="item.danger_from = $event"
                        />
                      </v-col>
                      <v-col cols="auto" class="mx-2">To</v-col>
                      <v-col>
                        <TextInputSetting
                          required
                          inputType="number"
                          :model="item.danger_to"
                          @update-data="item.danger_to = $event"
                        />
                      </v-col>
                    </v-row>

                    <v-divider
                      v-if="
                        form.step_box_test.length !=
                        step_box_test_index + 1
                      "
                    />
                  </div>
                </box-card>
              </v-flex>
            </v-layout>
          </v-col>
        </v-row>

        <!-- ผลการวัด -->
        <box-card class="mt-6 area_input">
          <!-- ผลการวัดวันนี้ -->
          <v-row align="start">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ผลการวัดวันนี้
              </div>
              <div class="font-24 font-w-600">ความเสี่ยง</div>
              <div class="font-18 font-w-600 mt-n1">(อาการทั่วไป)</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <div
                class="font-24 font-w-600 standard_color d-flex align-center"
              >
                A
              </div>
              <div class="font-12 mt-n1 mb-2">standard</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="
                  form.step_box_test_settings_text.risk_standard_text
                "
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <div class="font-24 font-w-600 warn_color d-flex align-center">
                B
              </div>
              <div class="font-12 mt-n1 mb-2">warn</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.step_box_test_settings_text.risk_warn_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <div class="font-24 font-w-600 danger_color d-flex align-center">
                C
              </div>
              <div class="font-12 mt-n1 mb-2">Danger</div>
              <v-textarea
                color="#C71010"
                rows="4"
                solo
                v-model="form.step_box_test_settings_text.risk_danger_text"
                :rules="[(v) => !!v || '']"
              />
            </v-col>
          </v-row>

          <v-divider class="mx-n4 mb-5" style="background-color: #d3d3d3" />

          <!--  ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา) -->
          <v-row align="start" class="mb-1">
            <v-col cols="3">
              <div class="font-22 font-w-600 gray_color align-center mb-2">
                ค่าเป้าหมายการปรับปรุง (3 เดือนต่อมา)
              </div>
              <div class="font-24 font-w-600">วิธีปรับปรุง</div>
            </v-col>

            <!-- Standard -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="
                  form.step_box_test_settings_text.improve_standard_text
                "
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="
                    step_box_test_settings_text.standard[
                      'checkbox' + (i + 1)
                    ]
                  "
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueStandardCheckbox(
                      'step_box_test_settings_text',
                      item,
                      $event
                    )
                  "
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Warn -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="
                  form.step_box_test_settings_text.improve_warn_text
                "
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="
                    step_box_test_settings_text.warn['checkbox' + (i + 1)]
                  "
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueWarnCheckbox(
                      'step_box_test_settings_text',
                      item,
                      $event
                    )
                  "
                ></v-checkbox>
              </div>
            </v-col>

            <!-- Danger -->
            <v-col cols="3">
              <v-textarea
                color="#C71010"
                rows="2"
                solo
                :rules="[(v) => !!v || '']"
                v-model="
                  form.step_box_test_settings_text.improve_danger_text
                "
              />
              <div v-for="(item, i) in listCheckbox" :key="i">
                <v-checkbox
                  v-model="
                    step_box_test_settings_text.danger[
                      'checkbox' + (i + 1)
                    ]
                  "
                  color="#44A04C"
                  hide-details
                  class="pt-0 mt-0"
                  :label="item"
                  :value="item"
                  @change="
                    setValueDangerCheckbox(
                      'step_box_test_settings_text',
                      item,
                      $event
                    )
                  "
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
        </box-card>
      </div>

      <!-- ************* Button Save  *************-->
      <div class="d-flex justify-end px-12 mt-12">
        <v-btn
          width="221"
          height="60"
          style="
            border-radius: 29px;
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
          "
          :dark="valid"
          :disabled="!valid"
          color="#c71010"
          class="text-capitalize"
          @click="validate"
        >
          <span class="font-28 font-w-600 white--text">Save</span>
        </v-btn>
      </div>
    </v-form>

    <Loading color="#C71010" :active.sync="isLoading" :is-full-page="true" />
  </div>
</template>

<script>
import moment from "moment";
import GroupTitle from "@/components/GroupTitle.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import BoxCard from "@/components/BoxCard.vue";
import TextInputSetting from "@/components/TextInputSetting.vue";
import SERVICE from "../services/index";

export default {
  metaInfo: {
    title: "Setting",
    titleTemplate: "%s | Powerpod Fitness Club",
  },
  components: { GroupTitle, Loading, BoxCard, TextInputSetting },
  data() {
    return {
      isLoading: false,
      form: {
        point_concern: {
          id: "",
          point_concern: "",
        },
        blood_pressure: {
          standard_min_from: "",
          standard_min_to: "",
          warn_min_from: "",
          warn_min_to: "",
          danger_min_from: "",
          danger_min_to: "",
          standard_max_from: "",
          standard_max_to: "",
          warn_max_from: "",
          warn_max_to: "",
          danger_max_from: "",
          danger_max_to: "",

          min_risk_standard_text: null,
          min_risk_warn_text: null,
          min_risk_danger_text: null,
          min_improve_standard_text: null,
          min_improve_standard_checked: [],
          min_improve_warn_text: null,
          min_improve_warn_checked: [],
          min_improve_danger_text: null,
          min_improve_danger_checked: [],

          max_risk_standard_text: null,
          max_risk_warn_text: null,
          max_risk_danger_text: null,
          max_improve_standard_text: null,
          max_improve_standard_checked: [],
          max_improve_warn_text: null,
          max_improve_warn_checked: [],
          max_improve_danger_text: null,
          max_improve_danger_checked: [],
        },
        rate: [],
        rank_setting: [],

        bmi: {
          standard_from: "",
          standard_to: "",
          warn_less_from: "",
          warn_less_to: "",
          warn_than_from: "",
          warn_than_to: "",
          danger_from: "",
          danger_to: "",
          risk_standard_text: null,
          risk_warn_text: null,
          risk_danger_text: null,
          improve_standard_text: null,
          improve_standard_checked: [],
          improve_warn_text: null,
          improve_warn_checked: [],
          improve_danger_text: null,
          improve_danger_checked: [],
        },

        visceral_fat_level: {
          standard_from: "",
          standard_to: "",
          warn_from: "",
          warn_to: "",
          danger_from: "",
          danger_to: "",
          risk_standard_text: null,
          risk_warn_text: null,
          risk_danger_text: null,
          improve_standard_text: null,
          improve_standard_checked: [],
          improve_warn_text: null,
          improve_warn_checked: [],
          improve_danger_text: null,
          improve_danger_checked: [],
        },

        stability_criteria_setting: [],
        stability_criteria_setting_text: {
          risk_standard_text: null,
          risk_warn_text: null,
          risk_danger_text: null,
          improve_standard_text: null,
          improve_standard_checked: [],
          improve_warn_text: null,
          improve_warn_checked: [],
          improve_danger_text: null,
          improve_danger_checked: [],
        },

        flexibility_upper_criteria_setting: {
          ideal_from: "",
          ideal_to: "",
          warn_from: "",
          warn_to: "",
          danger_from: "",
          danger_to: "",
          risk_standard_text: null,
          risk_warn_text: null,
          risk_danger_text: null,
          improve_standard_text: null,
          improve_standard_checked: [],
          improve_warn_text: null,
          improve_warn_checked: [],
          improve_danger_text: null,
          improve_danger_checked: [],
        },

        muscle_mass_points: {
          gender: "",
          age_from: "",
          age_to: "",
          standard_from: "",
          standard_to: "",
          warn_from: "",
          warn_to: "",
          danger_from: "",
          danger_to: "",
        },
        muscle_mass_points_settings_text: {
          risk_standard_text: null,
          risk_warn_text: null,
          risk_danger_text: null,
          improve_standard_text: null,
          improve_standard_checked: [],
          improve_warn_text: null,
          improve_warn_checked: [],
          improve_danger_text: null,
          improve_danger_checked: [],
        },

        basal_metabolic_rate: [],
        basal_metabolic_rate_settings_text: {
          risk_standard_text: null,
          risk_warn_text: null,
          risk_danger_text: null,
          improve_standard_text: null,
          improve_standard_checked: [],
          improve_warn_text: null,
          improve_warn_checked: [],
          improve_danger_text: null,
          improve_danger_checked: [],
        },

        grip_strength: [],
        grip_strength_settings_text: {
          risk_standard_text: null,
          risk_warn_text: null,
          risk_danger_text: null,
          improve_standard_text: null,
          improve_standard_checked: [],
          improve_warn_text: null,
          improve_warn_checked: [],
          improve_danger_text: null,
          improve_danger_checked: [],
        },

        body_fat_rate: {
          gender: "",
          age_from: "",
          age_to: "",
          standard_from: "",
          standard_to: "",
          warn_from: "",
          warn_to: "",
          danger_less_from: "",
          danger_less_to: "",
          danger_than_from: "",
          danger_than_to: "",
        },
        body_fat_rate_settings_text: {
          risk_standard_text: null,
          risk_warn_text: null,
          risk_danger_text: null,
          improve_standard_text: null,
          improve_standard_checked: [],
          improve_warn_text: null,
          improve_warn_checked: [],
          improve_danger_text: null,
          improve_danger_checked: [],
        },

        flexibility_lower_criteria_setting: [],
        flexibility_lower_criteria_setting_text: {
          risk_standard_text: null,
          risk_warn_text: null,
          risk_danger_text: null,
          improve_standard_text: null,
          improve_standard_checked: [],
          improve_warn_text: null,
          improve_warn_checked: [],
          improve_danger_text: null,
          improve_danger_checked: [],
        },

        lung_age: {
          standard: null,
          warn: null,
          danger: null,
          risk_standard_text: null,
          risk_warn_text: null,
          risk_danger_text: null,
          improve_standard_text: null,
          improve_standard_checked: [],
          improve_warn_text: null,
          improve_warn_checked: [],
          improve_danger_text: null,
          improve_danger_checked: [],
        },

        skin_age: {
          standard: null,
          warn: null,
          danger: null,
          risk_standard_text: null,
          risk_warn_text: null,
          risk_danger_text: null,
          improve_standard_text: null,
          improve_standard_checked: [],
          improve_warn_text: null,
          improve_warn_checked: [],
          improve_danger_text: null,
          improve_danger_checked: [],
        },

        back_strength: [],
        back_strength_text: {
          standard: null,
          warn: null,
          danger: null,
          risk_standard_text: null,
          risk_warn_text: null,
          risk_danger_text: null,
          improve_standard_text: null,
          improve_standard_checked: [],
          improve_warn_text: null,
          improve_warn_checked: [],
          improve_danger_text: null,
          improve_danger_checked: [],
        },

        single_leg_balance: {
          standard_from: null,
          standard_to: null,
          warn_from: null,
          warn_to: null,
          danger_from: null,
          danger_to: null,
          risk_standard_text: null,
          risk_warn_text: null,
          risk_danger_text: null,
          improve_standard_text: null,
          improve_standard_checked: [],
          improve_warn_text: null,
          improve_warn_checked: [],
          improve_danger_text: null,
          improve_danger_checked: [],
        },

        stand_up_test: {
          standard: null,
          warn: null,
          danger: null,
          risk_standard_text: null,
          risk_warn_text: null,
          risk_danger_text: null,
          improve_standard_text: null,
          improve_standard_checked: [],
          improve_warn_text: null,
          improve_warn_checked: [],
          improve_danger_text: null,
          improve_danger_checked: [],
        },

        functional_test: {
          standard_from: null,
          standard_to: null,
          warn_from: null,
          warn_to: null,
          danger_from: null,
          danger_to: null,
          risk_standard_text: null,
          risk_warn_text: null,
          risk_danger_text: null,
          improve_standard_text: null,
          improve_standard_checked: [],
          improve_warn_text: null,
          improve_warn_checked: [],
          improve_danger_text: null,
          improve_danger_checked: [],
        },

        body_water_content: [],
        body_water_content_text: {
          standard: null,
          warn: null,
          danger: null,
          risk_standard_text: null,
          risk_warn_text: null,
          risk_danger_text: null,
          improve_standard_text: null,
          improve_standard_checked: [],
          improve_warn_text: null,
          improve_warn_checked: [],
          improve_danger_text: null,
          improve_danger_checked: [],
        },

        flexibility_waist: {
          ideal_from: null,
          ideal_to: null,
          warn_from: null,
          warn_to: null,
          danger_from: null,
          danger_to: null,
          risk_standard_text: null,
          risk_warn_text: null,
          risk_danger_text: null,
          improve_standard_text: null,
          improve_standard_checked: [],
          improve_warn_text: null,
          improve_warn_checked: [],
          improve_danger_text: null,
          improve_danger_checked: [],
        },

        posture_score: {
          ideal_from: null,
          ideal_to: null,
          warn_from: null,
          warn_to: null,
          danger_from: null,
          danger_to: null,
          risk_standard_text: null,
          risk_warn_text: null,
          risk_danger_text: null,
          improve_standard_text: null,
          improve_standard_checked: [],
          improve_warn_text: null,
          improve_warn_checked: [],
          improve_danger_text: null,
          improve_danger_checked: [],
        },

        posture_type: {
          ideal_: null,
          ideal: null,
          warn_from: null,
          warn_to: null,
          danger_from: null,
          danger_to: null,
          risk_standard_text: null,
          risk_warn_text: null,
          risk_danger_text: null,
          improve_standard_text: null,
          improve_standard_checked: [],
          improve_warn_text: null,
          improve_warn_checked: [],
          improve_danger_text: null,
          improve_danger_checked: [],
        },
        chair_stand_test: {
          gender: "",
          age_from: "",
          age_to: "",
          standard_from: "",
          standard_to: "",
          warn_from: "",
          warn_to: "",
          danger_from: "",
          danger_to: "",
        },
        chair_stand_test_settings_text: {
          risk_standard_text: null,
          risk_warn_text: null,
          risk_danger_text: null,
          improve_standard_text: null,
          improve_standard_checked: [],
          improve_warn_text: null,
          improve_warn_checked: [],
          improve_danger_text: null,
          improve_danger_checked: [],
        },
        step_box_test: {
          gender: "",
          age_from: "",
          age_to: "",
          standard_from: "",
          standard_to: "",
          warn_from: "",
          warn_to: "",
          danger_from: "",
          danger_to: "",
        },
        step_box_test_settings_text: {
          risk_standard_text: null,
          risk_warn_text: null,
          risk_danger_text: null,
          improve_standard_text: null,
          improve_standard_checked: [],
          improve_warn_text: null,
          improve_warn_checked: [],
          improve_danger_text: null,
          improve_danger_checked: [],
        },
      },

      stability_criteria_setting_text: {
        standard: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        warn: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        danger: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
      },
      flexibility_lower_criteria_setting_text: {
        standard: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        warn: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        danger: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
      },
      muscle_mass_points_settings_text: {
        standard: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        warn: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        danger: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
      },
      chair_stand_test_settings_text: {
        standard: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        warn: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        danger: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
      },
      step_box_test_settings_text: {
        standard: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        warn: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        danger: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
      },
      grip_strength_settings_text: {
        standard: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        warn: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        danger: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
      },
      body_fat_rate_settings_text: {
        standard: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        warn: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        danger: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
      },
      basal_metabolic_rate_settings_text: {
        standard: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        warn: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        danger: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
      },
      bmi: {
        standard: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        warn: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        danger: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
      },
      flexibility_upper_criteria_setting: {
        standard: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        warn: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        danger: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
      },
      visceral_fat_level: {
        standard: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        warn: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        danger: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
      },
      blood_pressure_min: {
        standard: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        warn: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        danger: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
      },
      blood_pressure_max: {
        standard: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        warn: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        danger: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
      },
      lung_age: {
        standard: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        warn: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        danger: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
      },
      skin_age: {
        standard: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        warn: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        danger: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
      },
      back_strength_text: {
        standard: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        warn: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        danger: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
      },
      single_leg_balance: {
        standard: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        warn: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        danger: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
      },
      stand_up_test: {
        standard: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        warn: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        danger: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
      },
      functional_test: {
        standard: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        warn: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        danger: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
      },
      body_water_content_text: {
        standard: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        warn: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        danger: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
      },
      flexibility_waist: {
        standard: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        warn: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        danger: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
      },
      posture_score: {
        standard: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        warn: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        danger: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
      },
      posture_type: {
        standard: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        warn: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
        danger: {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
        },
      },

      listCheckbox: ["ยืดกล้ามเนื้อ", "ฝึกแรงต้าน", "คาร์ดิโอ"],
      valid: true,
    };
  },
  created() {
    this.fetchApi();
  },
  methods: {
    async fetchApi() {
      this.isLoading = true;
      const res = await SERVICE.setting.index();
      if (res.status === 200) {
        this.form.blood_pressure = await res.data.BloodPressure;
        this.blood_pressure_min.standard = await this.setValueCheckbox(
          res.data.BloodPressure.min_improve_standard_checked
        );
        this.blood_pressure_min.warn = await this.setValueCheckbox(
          res.data.BloodPressure.min_improve_warn_checked
        );
        this.blood_pressure_min.danger = await this.setValueCheckbox(
          res.data.BloodPressure.min_improve_danger_checked
        );
        this.blood_pressure_max.standard = await this.setValueCheckbox(
          res.data.BloodPressure.max_improve_standard_checked
        );
        this.blood_pressure_max.warn = await this.setValueCheckbox(
          res.data.BloodPressure.max_improve_warn_checked
        );
        this.blood_pressure_max.danger = await this.setValueCheckbox(
          res.data.BloodPressure.max_improve_danger_checked
        );

        this.form.bmi = await res.data.bmi;
        this.bmi.standard = await this.setValueCheckbox(
          res.data.bmi.improve_standard_checked
        );
        this.bmi.warn = await this.setValueCheckbox(
          res.data.bmi.improve_warn_checked
        );
        this.bmi.danger = await this.setValueCheckbox(
          res.data.bmi.improve_danger_checked
        );

        this.form.basal_metabolic_rate = await res.data.BasalMetabolicRate;
        this.form.basal_metabolic_rate_settings_text = await res.data
          .BasalMetabolicRateText;
        this.basal_metabolic_rate_settings_text.standard =
          await this.setValueCheckbox(
            res.data.BasalMetabolicRateText.improve_standard_checked
          );
        this.basal_metabolic_rate_settings_text.warn =
          await this.setValueCheckbox(
            res.data.BasalMetabolicRateText.improve_warn_checked
          );
        this.basal_metabolic_rate_settings_text.danger =
          await this.setValueCheckbox(
            res.data.BasalMetabolicRateText.improve_danger_checked
          );

        this.form.visceral_fat_level = await res.data.VisceralFatLevel;
        this.visceral_fat_level.standard = await this.setValueCheckbox(
          res.data.VisceralFatLevel.improve_standard_checked
        );
        this.visceral_fat_level.warn = await this.setValueCheckbox(
          res.data.VisceralFatLevel.improve_warn_checked
        );
        this.visceral_fat_level.danger = await this.setValueCheckbox(
          res.data.VisceralFatLevel.improve_danger_checked
        );

        this.form.body_fat_rate = await res.data.BodyFatRate;
        this.form.body_fat_rate_settings_text = await res.data.BodyFatRateText;
        this.body_fat_rate_settings_text.standard = await this.setValueCheckbox(
          res.data.BodyFatRateText.improve_standard_checked
        );
        this.body_fat_rate_settings_text.warn = await this.setValueCheckbox(
          res.data.BodyFatRateText.improve_warn_checked
        );
        this.body_fat_rate_settings_text.danger = await this.setValueCheckbox(
          res.data.BodyFatRateText.improve_danger_checked
        );

        this.form.grip_strength = await res.data.GripStrength;
        this.form.grip_strength_settings_text = await res.data.GripStrengthText;
        this.grip_strength_settings_text.standard = await this.setValueCheckbox(
          res.data.GripStrengthText.improve_standard_checked
        );
        this.grip_strength_settings_text.warn = await this.setValueCheckbox(
          res.data.GripStrengthText.improve_warn_checked
        );
        this.grip_strength_settings_text.danger = await this.setValueCheckbox(
          res.data.GripStrengthText.improve_danger_checked
        );

        this.form.muscle_mass_points = await res.data.MuscleMassPoints;
        this.form.muscle_mass_points_settings_text = await res.data
          .MuscleMassPointsText;
        this.muscle_mass_points_settings_text.standard =
          await this.setValueCheckbox(
            res.data.MuscleMassPointsText.improve_standard_checked
          );
        this.muscle_mass_points_settings_text.warn =
          await this.setValueCheckbox(
            res.data.MuscleMassPointsText.improve_warn_checked
          );
        this.muscle_mass_points_settings_text.danger =
          await this.setValueCheckbox(
            res.data.MuscleMassPointsText.improve_danger_checked
          );

        this.form.point_concern = await res.data.PointOfConcern;

        this.form.stability_criteria_setting = await res.data
          .StabilityCriteriaSetting;
        this.form.stability_criteria_setting_text = await res.data
          .StabilityCriteriaSettingText;
        this.stability_criteria_setting_text.standard =
          await this.setValueCheckbox(
            res.data.StabilityCriteriaSettingText.improve_standard_checked
          );
        this.stability_criteria_setting_text.warn = await this.setValueCheckbox(
          res.data.StabilityCriteriaSettingText.improve_warn_checked
        );
        this.stability_criteria_setting_text.danger =
          await this.setValueCheckbox(
            res.data.StabilityCriteriaSettingText.improve_danger_checked
          );

        this.form.flexibility_upper_criteria_setting = await res.data
          .FlexibilityUpperCriteriaSetting;
        this.flexibility_upper_criteria_setting.standard =
          await this.setValueCheckbox(
            res.data.FlexibilityUpperCriteriaSetting.improve_standard_checked
          );
        this.flexibility_upper_criteria_setting.warn =
          await this.setValueCheckbox(
            res.data.FlexibilityUpperCriteriaSetting.improve_warn_checked
          );
        this.flexibility_upper_criteria_setting.danger =
          await this.setValueCheckbox(
            res.data.FlexibilityUpperCriteriaSetting.improve_danger_checked
          );

        this.form.flexibility_lower_criteria_setting = await res.data
          .FlexibilityLowerCriteriaSetting;
        this.form.flexibility_lower_criteria_setting_text = await res.data
          .FlexibilityLowerCriteriaSettingText;

        this.flexibility_lower_criteria_setting_text.standard =
          await this.setValueCheckbox(
            res.data.FlexibilityLowerCriteriaSettingText
              .improve_standard_checked
          );
        this.flexibility_lower_criteria_setting_text.warn =
          await this.setValueCheckbox(
            res.data.FlexibilityLowerCriteriaSettingText.improve_warn_checked
          );
        this.flexibility_lower_criteria_setting_text.danger =
          await this.setValueCheckbox(
            res.data.FlexibilityLowerCriteriaSettingText.improve_danger_checked
          );

        this.form.rate = await res.data.RankSetting;
        this.form.rank_setting = await res.data.Rank;

        this.form.lung_age = await res.data.lung_age;
        this.lung_age.standard = await this.setValueCheckbox(
          res.data.lung_age.improve_standard_checked
        );
        this.lung_age.warn = await this.setValueCheckbox(
          res.data.lung_age.improve_warn_checked
        );
        this.lung_age.danger = await this.setValueCheckbox(
          res.data.lung_age.improve_danger_checked
        );

        this.form.skin_age = await res.data.skin_age;
        this.skin_age.standard = await this.setValueCheckbox(
          res.data.skin_age.improve_standard_checked
        );
        this.skin_age.warn = await this.setValueCheckbox(
          res.data.skin_age.improve_warn_checked
        );
        this.skin_age.danger = await this.setValueCheckbox(
          res.data.skin_age.improve_danger_checked
        );

        this.form.back_strength = await res.data.back_strength;
        this.form.back_strength_text = await res.data.back_strength_text;
        this.back_strength_text.standard = await this.setValueCheckbox(
          res.data.back_strength_text.improve_standard_checked
        );
        this.back_strength_text.warn = await this.setValueCheckbox(
          res.data.back_strength_text.improve_warn_checked
        );
        this.back_strength_text.danger = await this.setValueCheckbox(
          res.data.back_strength_text.improve_danger_checked
        );

        this.form.single_leg_balance = await res.data.single_leg_balance;
        this.single_leg_balance.standard = await this.setValueCheckbox(
          res.data.single_leg_balance.improve_standard_checked
        );
        this.single_leg_balance.warn = await this.setValueCheckbox(
          res.data.single_leg_balance.improve_warn_checked
        );
        this.single_leg_balance.danger = await this.setValueCheckbox(
          res.data.single_leg_balance.improve_danger_checked
        );

        this.form.stand_up_test = await res.data.stand_up_test;
        this.stand_up_test.standard = await this.setValueCheckbox(
          res.data.stand_up_test.improve_standard_checked
        );
        this.stand_up_test.warn = await this.setValueCheckbox(
          res.data.stand_up_test.improve_warn_checked
        );
        this.stand_up_test.danger = await this.setValueCheckbox(
          res.data.stand_up_test.improve_danger_checked
        );

        this.form.functional_test = await res.data.functional_test;
        this.functional_test.standard = await this.setValueCheckbox(
          res.data.functional_test.improve_standard_checked
        );
        this.functional_test.warn = await this.setValueCheckbox(
          res.data.functional_test.improve_warn_checked
        );
        this.functional_test.danger = await this.setValueCheckbox(
          res.data.functional_test.improve_danger_checked
        );

        this.form.body_water_content = await res.data.body_water_content;
        this.form.body_water_content_text = await res.data
          .body_water_content_text;
        this.body_water_content_text.standard = await this.setValueCheckbox(
          res.data.body_water_content_text.improve_standard_checked
        );
        this.body_water_content_text.warn = await this.setValueCheckbox(
          res.data.body_water_content_text.improve_warn_checked
        );
        this.body_water_content_text.danger = await this.setValueCheckbox(
          res.data.body_water_content_text.improve_danger_checked
        );

        this.form.flexibility_waist = await res.data.flexibility_waist;
        this.flexibility_waist.standard = await this.setValueCheckbox(
          res.data.flexibility_waist.improve_standard_checked
        );
        this.flexibility_waist.warn = await this.setValueCheckbox(
          res.data.flexibility_waist.improve_warn_checked
        );
        this.flexibility_waist.danger = await this.setValueCheckbox(
          res.data.flexibility_waist.improve_danger_checked
        );

        this.form.posture_score = await res.data.posture_score;
        this.posture_score.standard = await this.setValueCheckbox(
          res.data.posture_score.improve_standard_checked
        );
        this.posture_score.warn = await this.setValueCheckbox(
          res.data.posture_score.improve_warn_checked
        );
        this.posture_score.danger = await this.setValueCheckbox(
          res.data.posture_score.improve_danger_checked
        );

        this.form.posture_type = await res.data.posture_type;
        this.posture_type.standard = await this.setValueCheckbox(
          res.data.posture_type.improve_standard_checked
        );
        this.posture_type.warn = await this.setValueCheckbox(
          res.data.posture_type.improve_warn_checked
        );
        this.posture_type.danger = await this.setValueCheckbox(
          res.data.posture_type.improve_danger_checked
        );

        this.form.chair_stand_test = await res.data.cardio_vascular_endurance_settings;
        this.form.step_box_test = await res.data.step_box_test_setting;
        this.form.chair_stand_test_settings_text = await res.data.cardio_vascular_endurance_settings_text;
        this.form.step_box_test_settings_text = await res.data.step_box_test_setting_text;
        this.chair_stand_test_settings_text.standard =
          await this.setValueCheckbox(
            res.data.cardio_vascular_endurance_settings_text.improve_standard_checked
          );
        this.chair_stand_test_settings_text.warn =
          await this.setValueCheckbox(
            res.data.cardio_vascular_endurance_settings_text.improve_warn_checked
          );
        this.chair_stand_test_settings_text.danger =
          await this.setValueCheckbox(
            res.data.cardio_vascular_endurance_settings_text.improve_danger_checked
          );
        this.step_box_test_settings_text.standard =
          await this.setValueCheckbox(
            res.data.step_box_test_setting_text.improve_standard_checked
          );
        this.step_box_test_settings_text.warn =
          await this.setValueCheckbox(
            res.data.step_box_test_setting_text.improve_warn_checked
          );
        this.step_box_test_settings_text.danger =
          await this.setValueCheckbox(
            res.data.step_box_test_setting_text.improve_danger_checked
          );
        

        this.isLoading = await false;
      }
    },
    setValueCheckbox(items) {
      let data = {
        checkbox1: null,
        checkbox2: null,
        checkbox3: null,
      };
      if (items && items.length > 0) {
        items.forEach((item) => {
          if (item === "ยืดกล้ามเนื้อ") {
            data.checkbox1 = "ยืดกล้ามเนื้อ";
          } else if (item === "ฝึกแรงต้าน") {
            data.checkbox2 = "ฝึกแรงต้าน";
          } else if (item === "คาร์ดิโอ") {
            data.checkbox3 = "คาร์ดิโอ";
          }
        });
      }
      return data;
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.save();
      }
    },
    save() {
      this.isLoading = true;
      SERVICE.setting
        .save(this.form)
        .then((res) => {
          if (res.status === 200) {
            this.isLoading = false;
            this.$swal({
              title: res.message,
              icon: "success",
              confirmButtonColor: "#C71010",
            });
          } else {
            this.$swal({
              title: "Update Failed!",
              text: res.message,
              icon: "error",
              confirmButtonColor: "#C71010",
            });
            this.isLoading = false;
          }
        })
        .catch((e) => {
          console.log(e);
          this.$swal({
            title: "Update Failed!",
            text: "Error, Somethings",
            icon: "error",
            confirmButtonColor: "#C71010",
          });
          this.isLoading = false;
        });
    },

    setValueStandardCheckbox(index, key, value) {
      if (value) {
        this.form[index].improve_standard_checked.push(value);
      } else {
        this.form[index].improve_standard_checked = this.form[
          index
        ].improve_standard_checked.filter((item) => item !== key);
      }
    },
    setValueWarnCheckbox(index, key, value) {
      if (value) {
        this.form[index].improve_warn_checked.push(value);
      } else {
        this.form[index].improve_warn_checked = this.form[
          index
        ].improve_warn_checked.filter((item) => item !== key);
      }
    },
    setValueDangerCheckbox(index, key, value) {
      if (value) {
        this.form[index].improve_danger_checked.push(value);
      } else {
        this.form[index].improve_danger_checked = this.form[
          index
        ].improve_danger_checked.filter((item) => item !== key);
      }
    },
    setValueBloodMaxStandardCheckbox(index, key, value) {
      if (value) {
        this.form[index].max_improve_standard_checked.push(value);
      } else {
        this.form[index].max_improve_standard_checked = this.form[
          index
        ].max_improve_standard_checked.filter((item) => item !== key);
      }
    },
    setValueBloodMaxWarnCheckbox(index, key, value) {
      if (value) {
        this.form[index].max_improve_warn_checked.push(value);
      } else {
        this.form[index].max_improve_warn_checked = this.form[
          index
        ].max_improve_warn_checked.filter((item) => item !== key);
      }
    },
    setValueBloodMaxDangerCheckbox(index, key, value) {
      if (value) {
        this.form[index].max_improve_danger_checked.push(value);
      } else {
        this.form[index].max_improve_danger_checked = this.form[
          index
        ].max_improve_danger_checked.filter((item) => item !== key);
      }
    },
    setValueBloodMinStandardCheckbox(index, key, value) {
      if (value) {
        this.form[index].min_improve_standard_checked.push(value);
      } else {
        this.form[index].min_improve_standard_checked = this.form[
          index
        ].min_improve_standard_checked.filter((item) => item !== key);
      }
    },
    setValueBloodMinWarnCheckbox(index, key, value) {
      if (value) {
        this.form[index].min_improve_warn_checked.push(value);
      } else {
        this.form[index].min_improve_warn_checked = this.form[
          index
        ].min_improve_warn_checked.filter((item) => item !== key);
      }
    },
    setValueBloodMinDangerCheckbox(index, key, value) {
      if (value) {
        this.form[index].min_improve_danger_checked.push(value);
      } else {
        this.form[index].min_improve_danger_checked = this.form[
          index
        ].min_improve_danger_checked.filter((item) => item !== key);
      }
    },
  },
};
</script>

<style lang="scss">
.standard_color {
  color: #36a777;
}
.warn_color {
  color: #f5cc5a;
}
.danger_color {
  color: #ff5050;
}
.gray_color {
  color: #a3a3a3;
}
.flex__box {
  height: 55px;
}
</style>
