import AuthService from "../AuthService";

const setting = {
  getCompare(id) {
    return AuthService.get(`/api/compare/${id}`).then((res) => res);
  },
  checkCompare(id) {
    return AuthService.get(`/api/check-compare?customer_id=${id}`).then(
      (res) => res
    );
  },
};

export default setting;
