import Vue from "vue";
import store from "../store";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import Questionnaire from "../views/Questionnaire.vue";
import QuestionnaireDetail from "../views/QuestionnaireDetail.vue";
import QuestionnairePdf from "../views/QuestionnairePdf.vue";
import Login from "../views/Login.vue";
import CustomerForm from "../views/CustomerForm.vue";
import SetingForm from "../views/SetingForm.vue";
import DefaultLayout from "../layouts/DefaultLayout.vue";
import Report from "../views/Report.vue";
import PageNotFound from "../views/PageNotFound.vue";
import ChangePassword from "../views/ChangePassword.vue";
import ComparisonView from "../views/ComparisonView.vue";
import CRM from "../views/CRM.vue";
import ReportPDF from "../views/ReportPDF.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "DefaultLayout",
    component: DefaultLayout,
    children: [
      {
        path: "/",
        name: "home",
        component: HomeView,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/questionnaire",
        name: "questionnaire",
        component: Questionnaire,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/questionnaire/:id",
        name: "QuestionnaireDetail",
        component: QuestionnaireDetail,
        meta: {
          requireAuth: true,
        },
      },

      {
        path: "/questionnaire-pdf/:id",
        name: "questionnaire-pdf",
        component: QuestionnairePdf,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/customer/edit/:slug",
        name: "CustomerForm",
        component: CustomerForm,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/customer/create",
        name: "CustomerCreateForm",
        component: CustomerForm,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/setting",
        name: "SetingForm",
        component: SetingForm,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/report/:slug",
        name: "Report",
        component: Report,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/change-password",
        name: "ChangePassword",
        component: ChangePassword,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/comparison/:id",
        name: "ComparisonView",
        component: ComparisonView,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/crm/:id",
        name: "CRM",
        component: CRM,
        meta: {
          requireAuth: true,
        },
      },
    ],
  },
  {
    path: "*",
    name: "PageNotFound",
    component: PageNotFound,
  },
  {
    path: "/login",
    name: "LoginPage",
    component: Login,
  },
  {
    path: "/report-pdf/:id",
    name: "report-pdf",
    component: ReportPDF,
    meta: {
      requireAuth: true,
    },
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requireAuth)) {
    if (!store.state.auth.access_token) {
      next({
        path: "/login",
      });
    } else {
      next();
    }
  } else {
    if (store.state.auth.access_token && to.path === "/login") {
      window.location.replace("/");
    } else {
      next();
    }
  }
});

export default router;
