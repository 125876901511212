var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg_main"},[_c('Loading',{attrs:{"color":"#C71010","active":_vm.isLoading,"is-full-page":true},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('div',{staticClass:"px-12",class:_vm.$route.name == 'questionnaire-detail' ? 'pt-0' : ''},[_c('v-layout',{class:_vm.$route.name == 'questionnaire' || _vm.$route.name == 'home'
          ? 'custom-header'
          : '',attrs:{"align-center":""}},[(_vm.$route.name !== 'questionnaire-detail')?_c('v-flex',[_c('v-img',{attrs:{"width":"186","src":require("../assets/images/logo.png")}})],1):_c('v-flex',[_c('v-layout',{attrs:{"align-center":""}},[_c('v-flex',{attrs:{"shrink":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v(" mdi-arrow-left ")])],1)],1),_c('v-flex',{staticClass:"pl-3",attrs:{"shrink":""}},[_c('GroupTitle',{attrs:{"onPage":"Questionnaire","txtTh":"แบบสอบถาม","txtEn":"Questionnaire"}})],1)],1)],1),_c('v-spacer'),(_vm.$route.name == 'questionnaire' || _vm.$route.name == 'home')?_c('v-flex',{staticClass:"d-flex align-end pr-6",staticStyle:{"height":"69px"},attrs:{"shrink":""}},[_c('v-layout',{attrs:{"justify-space-between":""}},[_c('v-flex',{staticClass:"tab-menu mx-2 pb-1",class:{ 'active-tap': _vm.$route.path === '/' },attrs:{"shrink":""},on:{"click":function($event){return _vm.$router.push('/')}}},[_vm._v(" Customer List ")]),_c('v-flex',{staticClass:"tab-menu pb-1",class:{ 'active-tap': _vm.$route.path === '/questionnaire' },attrs:{"shrink":"","mx-2":""},on:{"click":function($event){return _vm.$router.push('/questionnaire')}}},[_vm._v(" Questionnaire ")])],1)],1):_vm._e(),_c('v-flex',{staticClass:"d-flex align-end",attrs:{"shrink":""}},[_c('v-layout',{attrs:{"align-center":""}},[_c('v-flex',{attrs:{"shrink":"","mr-6":""}},[_c('v-tooltip',{attrs:{"bottom":"","color":"#6b0000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn_hover",attrs:{"to":"/change-password","icon":"","color":"transparent","small":""}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"width":"36","src":require("../assets/images/Password.png")}})],1)]}}])},[_c('span',{staticClass:"mt-3"},[_vm._v(" Change Password ")])])],1),_c('v-flex',{attrs:{"shrink":"","mr-6":""}},[_c('v-tooltip',{attrs:{"bottom":"","color":"#6b0000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn_hover",attrs:{"to":"/setting","icon":""}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"width":"36","src":require("../assets/images/setting-icon.png")}})],1)]}}])},[_c('span',{staticClass:"mt-3"},[_vm._v(" Setting ")])])],1),_c('v-flex',{attrs:{"shrink":""}},[_c('v-tooltip',{attrs:{"bottom":"","color":"#6b0000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn_hover",attrs:{"icon":""},on:{"click":function($event){return _vm.signout()}}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"width":"36","src":require("../assets/images/logout-icon.png")}})],1)]}}])},[_c('span',{staticClass:"mt-3"},[_vm._v(" Logout ")])])],1)],1)],1)],1)],1),_c('v-main',{staticClass:"px-12"},[_c('transition',{attrs:{"name":"router-animation","mode":"out-in","enter-active-class":"animated fadeIn fast","leave-active-class":"animated fadeOut faster"}},[_c('router-view')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }