<template>
  <div>
    <v-layout align-center justify-space-between>
      <v-flex xs4>
        <v-layout>
          <v-flex shrink>
            <v-btn icon class="mr-6" to="/">
              <v-icon large color="#2E2528">mdi-arrow-left</v-icon>
            </v-btn>
          </v-flex>
          <v-flex>
            <group-title onPage="crm" txtEn="CRM" txtTh="บันทึก" />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-divider />

    <!-- Profile -->
    <v-layout align-center justify-space-between mt-3>
      <v-flex style="margin-left: -6.5rem">
        <v-card class="card__name" block dark color="#000">
          <div class="text-center">
            {{ customer ? customer.full_name : "" }}
          </div>
        </v-card>
      </v-flex>
      <v-flex xs8>
        <box-card height="55" class="py-0 d-flex align-center">
          <v-layout align-center>
            <v-flex>
              <span class="txt__title_th">อายุ</span>
              <span class="txt__title_en mx-2">Age</span>
              <span class="txt_val">{{ customer ? customer.age : "" }}</span>
            </v-flex>
            <v-flex>
              <span class="txt__title_th">เพศ</span>
              <span class="txt__title_en mx-2">gender</span>
              <span class="txt_val" v-if="customer">
                {{ customer.gender === "M" ? "ชาย (Male)" : "หญิง (Female)" }}
              </span>
            </v-flex>
            <v-flex>
              <span class="txt__title_th">ส่วนสูง</span>
              <span class="txt__title_en mx-2">Height</span>
              <span class="txt_val">{{ customer ? customer.height : "" }}</span>
            </v-flex>
            <v-flex>
              <span class="txt__title_th">น้ำหนัก</span>
              <span class="txt__title_en mx-2">Weight</span>
              <span class="txt_val">
                {{ customer ? customer.weight : "" }}
              </span>
            </v-flex>
          </v-layout>
        </box-card>
      </v-flex>
    </v-layout>

    <!-- Form & View -->
    <div class="container mb-6">
      <!-- View -->
      <box-card class="mt-5">
        <div class="crm-view font-24 pa-4">
          <div class="mb-3" v-for="item in data" :key="item.id">
            <v-row>
              <v-col cols="2">
                <div
                  class="event-type mb-3 pa-2 text-center font-14 font-weight-bold"
                >
                  {{ item.event_type }}
                </div>
                <div
                  class="time d-flex justify-center align-center mb-3 text-center font-12"
                >
                  {{ item.created_at | moment("DD/MM/YYYY HH:ss") }}
                </div>
                <div class="d-flex justify-center mb-3">
                  <v-btn class="mx-3" icon outlined @click="editForm(item)">
                    <v-icon> mdi-pencil-outline </v-icon>
                  </v-btn>
                  <v-btn
                    class="mx-3"
                    color="#C71010"
                    icon
                    outlined
                    @click="onDelete(item.id)"
                  >
                    <v-icon> mdi-delete-outline </v-icon>
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="10">
                <div class="text-crm px-3 py-1 mb-2">
                  {{ item.topic }}
                </div>
                <div class="text-crm px-3 py-1 mb-2">
                  <div class="v-html" v-html="item.description"></div>
                </div>
              </v-col>
            </v-row>
            <v-divider />
          </div>
        </div>
      </box-card>

      <!-- Form -->
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-layout align-center justify-space-between mt-5>
          <v-flex xs8>
            <box-card class="pa-0 pt-5 px-5" theme="gray-light">
              <v-layout>
                <v-flex shrink class="mr-3 mt-n1">
                  <div class="font-20 font-weight-bold">ชื่อเทรนเนอร์</div>
                  <div class="font-15 mt-n2">Trainer's Name</div>
                </v-flex>
                <v-flex>
                  <v-text-field
                    :rules="[(v) => !!v || 'Topic is required']"
                    required
                    v-model="form.topic"
                    placeholder="หัวข้อ"
                    outlined
                    dense
                    color="#A3A3A3"
                  />
                </v-flex>
              </v-layout>
            </box-card>
          </v-flex>
          <v-flex xs4>
            <box-card class="pa-0 pt-5 px-5" theme="gray-light">
              <v-layout>
                <v-flex shrink class="mr-3">
                  <div class="font-20 font-weight-bold">ประเภท</div>
                  <div class="font-15 mt-n2">Type</div>
                </v-flex>
                <v-flex>
                  <v-select
                    menu-props="offsetY"
                    v-model="form.event_type"
                    :items="event_type"
                    placeholder="ประเภท"
                    outlined
                    dense
                    color="#A3A3A3"
                    :rules="[(v) => !!v || 'Type is required']"
                    required
                  />
                </v-flex>
              </v-layout>
            </box-card>
          </v-flex>
        </v-layout>

        <box-card class="mt-5 pa-0" theme="gray-light" style="overflow: hidden">
          <vue-editor
            id="editor"
            useCustomImageHandler
            @image-added="handleImageAdded"
            :editorOptions="editorOption"
            v-model="form.description"
          ></vue-editor>
        </box-card>
        <div class="d-flex justify-space-between mt-5">
          <v-btn
            height="44"
            width="200"
            style="
              border-radius: 29px;
              box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
            "
            color="#A3A3A3"
            dark
            depressed
            class="text-capitalize"
            @click="onClear()"
          >
            Clear
          </v-btn>

          <v-btn
            height="44"
            width="200"
            style="
              border-radius: 29px;
              box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
            "
            :dark="valid"
            color="#c71010"
            class="text-capitalize"
            @click="onSubmit()"
            :disabled="!valid"
          >
            บันทึก
          </v-btn>
        </div>
      </v-form>
    </div>

    <Loading color="#C71010" :active.sync="isLoading" :is-full-page="true" />
  </div>
</template>

<script>
import GroupTitle from "@/components/GroupTitle.vue";
import BoxCard from "@/components/BoxCard.vue";
import SERVICES from "../services";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { domain } from "../services/Constants";
//https://www.npmjs.com/package/vue2-editor
import { VueEditor, Quill } from "vue2-editor";
import ImageResize from "quill-image-resize-vue";

Quill.register("modules/imageResize", ImageResize);
import axios from "axios";

var toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  //   ["blockquote", "code-block"],

  [{ header: 1 }, { header: 2 }], // custom button values
  //   [{ list: "ordered" }, { list: "bullet" }],
  //   [{ script: "sub" }, { script: "super" }], // superscript/subscript
  //   [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  //   [{ direction: "rtl" }], // text direction

  //   [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  //   [{ font: [] }],
  [{ align: [] }],
  //   ["clean"], // remove formatting button
  ["image"],

  ["clean"], // remove formatting button
];

export default {
  components: {
    GroupTitle,
    BoxCard,
    Loading,
    VueEditor,
  },
  data() {
    return {
      valid: true,
      form: {
        customer_id: "",
        topic: "",
        description: "",
        event_type: "",
      },
      data: [],
      isLoading: false,
      event_type: ["Training", "Team Review", "Member Status"],
      editorOption: {
        placeholder: "Input your content here...",
        modules: {
          imageResize: {},
          toolbar: toolbarOptions,
        },
      },
      crm_id: "",
      customer: null,
    };
  },
  created() {
    this.getCrm();
    this.form.customer_id = this.$route.params.id;
  },
  computed: {
    // get token from vuex
    token() {
      return this.$store.state.auth.access_token;
    },
  },
  methods: {
    getCrm() {
      this.isLoading = true;
      SERVICES.crm.get(this.$route.params.id).then((res) => {
        this.data = res.data;
        this.customer = res.customer;
        this.isLoading = false;
      });
    },
    onSubmit() {
      if (this.crm_id) {
        this.updateCrm();
      } else {
        this.createCrm();
      }
    },
    onClear() {
      this.$refs.form.reset();
      this.form = {
        customer_id: this.$route.params.id,
        topic: "",
        description: "",
        event_type: "",
      };
      this.crm_id = "";
    },
    onDelete(id) {
      // swal confirm
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          SERVICES.crm.delete(id).then((res) => {
            this.isLoading = false;
            this.$swal("Deleted!", "Your message has been deleted.", "success");
            this.data = this.data.filter((item) => item.id != id);
          });
        }
      });
    },
    createCrm() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        SERVICES.crm
          .post(this.form)
          .then((res) => {
            this.data.unshift(res);
            this.form = {
              customer_id: this.$route.params.id,
              topic: "",
              description: "",
              event_type: "",
            };
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
          });
      }
    },
    updateCrm() {
      this.isLoading = true;
      SERVICES.crm
        .put(this.crm_id, this.form)
        .then((res) => {
          // find index of data by id and replace data with res
          this.data.splice(
            this.data.findIndex((item) => item.id === this.crm_id),
            1,
            res
          );
          this.form = {
            customer_id: this.$route.params.id,
            topic: "",
            description: "",
            event_type: "",
          };
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      this.isLoading = true;
      var formData = new FormData();
      formData.append("image", file);
      formData.append("customer_id", this.$route.params.id);

      axios({
        url: domain + "api/upload-image",
        method: "POST",
        data: formData,
        headers: {
          Authorization: "Bearer " + this.token,
          "Content-Type": "multipart/form-data",
        },
      })
        .then((result) => {
          const url = result.data; // Get url from response
          Editor.insertEmbed(cursorLocation, "image", url);
          this.isLoading = false;
          resetUploader();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editForm(data) {
      this.form = {
        customer_id: data.customer_id,
        topic: data.topic,
        description: data.description,
        event_type: data.event_type,
      };
      this.crm_id = data.id;
    },
  },
};
</script>

<style scoped>
.v-html >>> img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  object-position: top;
  border: 1px solid #a3a3a3;
  border-radius: 10px;
  /* height: auto !important; */
}
.ql-editor >>> img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  object-position: top;
  border: 1px solid #a3a3a3;
  border-radius: 10px;
  /* height: auto !important; */
}
.v-html >>> iframe {
  width: 100% !important;
  height: auto !important;
  aspect-ratio: 1.78341013825;
}
@media only screen and (max-width: 768px) {
  .v-html >>> img {
    width: 100% !important;
    height: auto !important;
  }
  .v-html >>> iframe {
    width: 100% !important;
    height: auto !important;
    aspect-ratio: 1.78341013825;
  }
}
</style>

<style lang="scss">
.crm-view {
  max-height: 300px;
  min-height: 300px;
  overflow: auto;
  //   overflow-x: auto;
  .event-type {
    background: #d4edee;
    border: 1px solid #000000;
    border-radius: 30px;
    width: 100%;
    height: 36px;
  }
  .time {
    background: #f3f3f3;
    border: 1px solid #a3a3a3;
    border-radius: 30px;
    font-weight: 500;
    height: 25px;
  }
  .text-crm {
    background: #ffffff;
    border: 1px solid #a3a3a3;
    border-radius: 10px;
    font-size: 14px;
  }
}
</style>
