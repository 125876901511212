<template>
  <div class="text_input">
    <label :for="label" class="font-20 font-w-500">{{ label }}</label>
    <v-text-field
      class="pa-0"
      :value="model"
      :color="color"
      dense
      solo
      :type="type"
      :placeholder="placeholder"
      :rules="
        required
          ? [
              (v) => !!v || errorMessage + ' is required',
              type === 'email'
                ? (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
                : '',
            ]
          : []
      "
      required
      :hide-details="!required"
      @input="$emit('update-data', $event)"
    />
  </div>
</template>

<script>
export default {
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    errorMessage: String,
    model: String,
    label: String,
    placeholder: String,
    type: {
      typr: String,
      default: "text",
    },
    color: {
      typr: String,
      default: "var(--color-8)",
    },
  },
};
</script>

<style lang="scss">
.text_input {
  .theme--light.v-input input {
    color: var(--color-8);
  }
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
    > .v-input__control
    > .v-input__slot {
    box-shadow: unset;
  }
  .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot,
  .v-text-field.v-text-field--enclosed .v-text-field__details {
    padding: 0px;
  }
}
</style>
