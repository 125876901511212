import AuthService from "../AuthService";

const setting = {
  get(id) {
    return AuthService.get(`/api/crm?customer_id=${id}`).then((res) => res);
  },
  post(payload) {
    return AuthService.post("/api/crm", payload).then((res) => res);
  },
  put(id, payload) {
    return AuthService.put(`/api/crm/${id}`, payload).then((res) => res);
  },
  edit(id) {
    return AuthService.get(`/api/crm/${id}`).then((res) => res);
  },
  delete(id) {
    return AuthService.delete(`/api/crm/${id}`).then((res) => res);
  },
};

export default setting;
