<template>
  <div class="report-pdf-form">
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :filename="filename"
      :paginate-elements-by-height="1600"
      :pdf-quality="2"
      pdf-format="a3"
      pdf-orientation="landscape"
      :manual-pagination="false"
      pdf-content-width="1600px"
      @progress="onProgress($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <!-- ด้านหนัา -->
        <v-layout
          class="section"
          :style="{ backgroundColor: checkColorBg(form.rank) }"
          v-if="form"
        >
          <!-- section left -->
          <v-flex shrink class="section_left">
            <!-- Image model -->
            <img
              class="model-img"
              src="../assets/images/report-pdf/model-a.png"
              v-if="form.rank === 'A'"
            />
            <img
              class="model-img"
              src="../assets/images/report-pdf/model-b.png"
              v-else-if="form.rank === 'B'"
            />
            <img
              class="model-img"
              src="../assets/images/report-pdf/model-c.png"
              v-else-if="form.rank === 'C'"
            />
            <img
              class="model-img"
              src="../assets/images/report-pdf/model-na.png"
              v-else
            />

            <div
              class="center_line"
              :style="{ backgroundColor: checkColorBg(form.rank) }"
            ></div>

            <!-- Section Grade -->
            <div class="section_grade_card">
              <div></div>
            </div>

            <!-- Section Name -->
            <div class="section_name">
              <div>
                <div class="first_name">
                  {{ form.first_name }}
                </div>
                <div class="family_name">
                  {{ form.family_name }}
                </div>
              </div>
            </div>

            <!-- Section info  -->
            <div class="section_info">
              <!-- อายุ -->
              <v-layout class="d-flex align-items-center section_info_item">
                <v-flex xs4 class="section_info_key">
                  อายุ <span class="section_info_val">{{ form.age }}</span>
                </v-flex>
                <v-flex
                  xs8
                  class="d-flex align-items-center section_info_grade"
                >
                  <div
                    class="font-w-600"
                    style="color: #44a04c; margin-right: 5px"
                  >
                    A
                  </div>
                  <div class="font-13">สภาพร่างกายที่มีความสมดุล</div>
                </v-flex>
              </v-layout>

              <!-- เพศ -->
              <v-layout class="d-flex align-items-center section_info_item">
                <v-flex xs4 class="section_info_key">
                  เพศ <span class="section_info_val">{{ form.gender }}</span>
                </v-flex>
                <v-flex
                  xs8
                  class="d-flex align-items-center section_info_grade"
                >
                  <div
                    class="font-w-600"
                    style="color: #ffbb02; margin-right: 5px"
                  >
                    B
                  </div>
                  <div class="font-13">สภาพร่างกายที่กำลังเสียสมดุล</div>
                </v-flex>
              </v-layout>

              <!-- ส่วนสูง -->
              <v-layout class="d-flex align-items-center section_info_item">
                <v-flex xs4 class="section_info_key">
                  ส่วนสูง
                  <span class="section_info_val"
                    >{{ parseInt(form.height) }} cm.</span
                  >
                </v-flex>
                <v-flex
                  xs8
                  class="d-flex align-items-center section_info_grade"
                >
                  <div
                    class="font-w-600"
                    style="color: #c71010; margin-right: 5px"
                  >
                    C
                  </div>
                  <div class="font-13">มีความเสี่ยงสูงที่สุขภาพจะเสีย</div>
                </v-flex>
              </v-layout>

              <!-- น้ำหนัก -->
              <v-layout class="d-flex align-items-center section_info_item">
                <v-flex xs4 class="section_info_key">
                  น้ำหนัก
                  <span class="section_info_val"
                    >{{ parseInt(form.weight) }} Kg.</span
                  >
                </v-flex>
                <v-flex
                  xs8
                  class="d-flex align-items-center section_info_grade"
                >
                </v-flex>
              </v-layout>
            </div>

            <!-- Section body part -->
            <div class="section_body_part">
              <!-- Image position -->
              <div class="body_model">
                <img
                  src="../assets/images/report-pdf/body.png"
                  width="245"
                  height="228"
                  alt=""
                  class="default_position"
                />
                <div
                  class="part_position"
                  v-for="(body_position, i) in body_positions"
                  :key="i"
                >
                  <img
                    v-if="showPositionPart(body_position)"
                    :src="
                      require(`../assets/images/report-pdf/body-position/${body_position.image}.png`)
                    "
                    width="245"
                    height="228"
                    alt=""
                  />
                </div>
              </div>

              <!-- detail -->
              <div class="font-20 font-w-600" style="margin: 15px 0px">
                ตำแหน่งที่มีอาการ/ปัญหาในปัจจุบัน
              </div>
              <div v-for="(position, i) in form.problematic_part" :key="i">
                <v-layout
                  class="body_part_item"
                  v-if="position.position_problem"
                >
                  <v-flex shrink style="width: 10px" class="font-13 pr-2">
                    {{ checkNoBodyPosition(position.position_problem) }}.
                  </v-flex>
                  <v-flex xs3 class="body_part_name">
                    {{ position.position_problem }}
                  </v-flex>
                  <v-flex xs9 class="font-13">
                    {{ position.problem }}
                  </v-flex>
                </v-layout>
              </div>
            </div>
          </v-flex>

          <!-- column right -->
          <v-flex shrink class="section_right">
            <!-- Section Top -->
            <v-layout>
              <v-flex xs9 class="section_right_left">
                <!-- Section Table (อายุร่างกาย, ความสมดุลร่างกาย) -->
                <v-layout>
                  <v-flex xs6 style="padding-right: 10px">
                    <!-- Table อายุร่างกาย -->
                    <div class="section_right_table">
                      <!-- Topic table -->
                      <v-layout class="section_right_title">
                        <v-flex xs6 class="d-flex align-items-center">
                          <div style="margin-right: 10px">
                            <img
                              class="icon"
                              src="../assets/images/report-pdf/age.png"
                              width="25px"
                              height="25px"
                            />
                          </div>
                          <div class="font-w-700">อายุร่างกาย</div>
                        </v-flex>
                        <v-flex xs6 class="d-flex justify-content-end">
                          <div style="margin-right: 10px; padding-top: 5px">
                            <Icon
                              class="font-28"
                              v-if="getStatusBodyAge() === 'A'"
                              :style="{
                                color: checkColor(getStatusBodyAge()),
                              }"
                              icon="fluent-mdl2:emoji"
                            />
                            <Icon
                              class="font-28"
                              v-if="getStatusBodyAge() === 'B'"
                              :style="{
                                color: checkColor(getStatusBodyAge()),
                              }"
                              icon="fluent-mdl2:emoji-neutral"
                            />
                            <Icon
                              class="font-28"
                              v-if="getStatusBodyAge() === 'C'"
                              :style="{
                                color: checkColor(getStatusBodyAge()),
                              }"
                              icon="fluent-mdl2:emoji-disappointed"
                            />
                          </div>
                          <div>
                            <v-btn
                              depressed
                              dark
                              style="border-radius: 38px"
                              :color="checkColor(getStatusBodyAge())"
                              width="60px"
                            >
                              <span class="font-28 font-w-600">
                                {{ form.body_age }}
                              </span>
                            </v-btn>
                          </div>
                        </v-flex>
                      </v-layout>

                      <!-- Table -->
                      <table class="section_table">
                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.vascular_age_criterion
                            ).color,
                            borderTopLeftRadius: '20px',
                            borderTopRightRadius: '20px',
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('อายุหลอดเลือด')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-1.png"
                                width="9"
                                height="12"
                              />
                              <img
                                class="icon_2"
                                src="../assets/images/report-pdf/icon-next-2.png"
                                width="9"
                                height="12"
                              />
                            </div>
                            <div>หลอดเลือด</div>
                          </td>
                          <td>
                            {{ form.vascular_age }}
                          </td>

                          <td>
                            <div
                              class="d-flex justify-content-center align-center"
                            >
                              <div
                                :class="
                                  changeStatusToGrade(
                                    form.vascular_age_criterion
                                  ).grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.vascular_age_criterion
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(
                                    form.vascular_age_criterion
                                  ).grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.body_age_criterion
                            ).color,
                            borderBottomLeftRadius: '20px',
                            borderBottomRightRadius: '20px',
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('อายุองค์ประกอบร่างกาย')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-1.png"
                                width="9"
                                height="12"
                              />
                              <img
                                class="icon_2"
                                src="../assets/images/report-pdf/icon-next-2.png"
                                width="9"
                                height="12"
                              />
                            </div>
                            <div>องค์ประกอบร่างกาย</div>
                          </td>
                          <td>{{ form.body_age }}</td>

                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(form.body_age_criterion)
                                    .grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.body_age_criterion
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(form.body_age_criterion)
                                    .grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.lung_age_criteria
                            ).color,
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('อายุของปอด (ความจุปอด)')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-1.png"
                                width="9"
                                height="12"
                              />
                              <img
                                class="icon_2"
                                src="../assets/images/report-pdf/icon-next-2.png"
                                width="9"
                                height="12"
                              />
                            </div>
                            <div>ความจุปอด</div>
                          </td>
                          <td>{{ form.lung_age }}</td>

                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(form.lung_age_criteria)
                                    .grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.vascular_age_criterion
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(form.lung_age_criteria)
                                    .grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.skin_age_criteria
                            ).color,
                            borderBottomLeftRadius: '20px',
                            borderBottomRightRadius: '20px',
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('อายุผิว')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-1.png"
                                width="9"
                                height="12"
                              />
                              <img
                                class="icon_2"
                                src="../assets/images/report-pdf/icon-next-2.png"
                                width="9"
                                height="12"
                              />
                            </div>
                            <div>ผิว</div>
                          </td>
                          <td>{{ form.skin_age }}</td>

                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(form.skin_age_criteria)
                                    .grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.vascular_age_criterion
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(form.skin_age_criteria)
                                    .grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </v-flex>

                  <v-flex xs6 style="padding-left: 10px">
                    <!-- Table ความสมดุลร่างกาย -->
                    <div class="section_right_table">
                      <!-- Topic table -->
                      <v-layout class="section_right_title">
                        <v-flex xs6 class="d-flex align-items-center">
                          <div style="margin-right: 10px">
                            <img
                              class="icon"
                              src="../assets/images/report-pdf/balance.png"
                              width="15px"
                              height="25px"
                            />
                          </div>
                          <div class="font-w-700">ความสมดุลร่างกาย</div>
                        </v-flex>
                        <v-flex xs6 class="d-flex justify-content-end">
                          <div style="margin-right: 10px; padding-top: 5px">
                            <Icon
                              class="font-28"
                              v-if="getStatusPhysicalBalance().name === 'A'"
                              :style="{
                                color: checkColor(
                                  getStatusPhysicalBalance().name
                                ),
                              }"
                              icon="fluent-mdl2:emoji"
                            />
                            <Icon
                              class="font-28"
                              v-if="getStatusPhysicalBalance().name === 'B'"
                              :style="{
                                color: checkColor(
                                  getStatusPhysicalBalance().name
                                ),
                              }"
                              icon="fluent-mdl2:emoji-neutral"
                            />
                            <Icon
                              class="font-28"
                              v-if="getStatusPhysicalBalance().name === 'C'"
                              :style="{
                                color: checkColor(
                                  getStatusPhysicalBalance().name
                                ),
                              }"
                              icon="fluent-mdl2:emoji-disappointed"
                            />
                          </div>
                          <div>
                            <v-btn
                              depressed
                              dark
                              style="border-radius: 38px"
                              :color="
                                checkColor(getStatusPhysicalBalance().name)
                              "
                              width="60px"
                            >
                              <span class="font-28 font-w-600">
                                {{ getStatusPhysicalBalance().name }}
                              </span>
                            </v-btn>
                          </div>
                        </v-flex>
                      </v-layout>

                      <!-- Table -->
                      <table class="section_table">
                        <tr
                          style="border-top-right-radius: 20px"
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.bmi_criterion
                            ).color,
                            borderTopLeftRadius: '20px',
                            borderTopRightRadius: '20px',
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('ดัชนีมวลกาย')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-1.png"
                                width="9"
                                height="12"
                              />
                              <img
                                class="icon_2"
                                src="../assets/images/report-pdf/icon-next-2.png"
                                width="9"
                                height="12"
                              />
                            </div>
                            <div>BMI</div>
                          </td>
                          <td>{{ form.bmi }}</td>
                          <td>18.5-24.9</td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(form.bmi_criterion)
                                    .grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.bmi_criterion
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(form.bmi_criterion).grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.blood_pressure_criterion
                            ).color,
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="
                                checkSelected('ความดันโลหิตต่ำสุด') ||
                                checkSelected('ความดันโลหิตสูงสุด')
                              "
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-1.png"
                                width="9"
                                height="12"
                              />
                              <img
                                class="icon_2"
                                src="../assets/images/report-pdf/icon-next-2.png"
                                width="9"
                                height="12"
                              />
                            </div>
                            <div>ความดันโลหิต</div>
                          </td>
                          <td>
                            {{ form.blood_pressure_min }}/{{
                              form.blood_pressure_max
                            }}
                          </td>
                          <td>129-101/79-61</td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(
                                    form.blood_pressure_criterion
                                  ).grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.blood_pressure_criterion
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(
                                    form.blood_pressure_criterion
                                  ).grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.visceral_fat_level_criterion
                            ).color,
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('ระดับไขมันในช่องท้อง')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-1.png"
                                width="9"
                                height="12"
                              />
                              <img
                                class="icon_2"
                                src="../assets/images/report-pdf/icon-next-2.png"
                                width="9"
                                height="12"
                              />
                            </div>
                            <div>ไขมันในช่องท้อง</div>
                          </td>
                          <td>{{ form.visceral_fat_level }}</td>
                          <td>9.5/น้อยกว่า</td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(
                                    form.visceral_fat_level_criterion
                                  ).grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.visceral_fat_level_criterion
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(
                                    form.visceral_fat_level_criterion
                                  ).grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.body_fat_rate_criterion
                            ).color,
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('ระดับไขมันในร่างกาย')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-1.png"
                                width="9"
                                height="12"
                              />
                              <img
                                class="icon_2"
                                src="../assets/images/report-pdf/icon-next-2.png"
                                width="9"
                                height="12"
                              />
                            </div>
                            <div>ไขมันในร่างกาย</div>
                          </td>
                          <td>{{ form.body_fat_rate }}</td>
                          <td>18~22</td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(
                                    form.body_fat_rate_criterion
                                  ).grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.body_fat_rate_criterion
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(
                                    form.body_fat_rate_criterion
                                  ).grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.body_water_content_criteria
                            ).color,
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('ระดับน้ำในร่างกาย')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-1.png"
                                width="9"
                                height="12"
                              />
                              <img
                                class="icon_2"
                                src="../assets/images/report-pdf/icon-next-2.png"
                                width="9"
                                height="12"
                              />
                            </div>
                            <div>น้ำในร่างกาย</div>
                          </td>
                          <td>{{ form.body_water_content }}</td>
                          <td>55~65</td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(
                                    form.body_water_content_criteria
                                  ).grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.body_water_content_criteria
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(
                                    form.body_water_content_criteria
                                  ).grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.basal_metabolic_rate_criterion
                            ).color,
                            borderBottomLeftRadius: '20px',
                            borderBottomRightRadius: '20px',
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('อัตราการเผาผลาญ')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-1.png"
                                width="9"
                                height="12"
                              />
                              <img
                                class="icon_2"
                                src="../assets/images/report-pdf/icon-next-2.png"
                                width="9"
                                height="12"
                              />
                            </div>
                            <div>อัตราเผาผลาญ</div>
                          </td>
                          <td>{{ form.basal_metabolic_rate }}</td>
                          <td>1530</td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(
                                    form.basal_metabolic_rate_criterion
                                  ).grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.basal_metabolic_rate_criterion
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(
                                    form.basal_metabolic_rate_criterion
                                  ).grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </v-flex>
                </v-layout>

                <!-- Section Table (การเคลื่อนไหว, ความยืดหยุ่น) -->
                <v-layout
                  style="display: flex; align-items: start; margin-top: 1rem"
                >
                  <v-flex xs6 style="padding-right: 10px">
                    <!-- Table การเคลื่อนไหว -->
                    <div class="section_right_table">
                      <!-- Topic table -->
                      <v-layout class="section_right_title">
                        <v-flex xs6 class="d-flex align-items-center">
                          <div style="margin-right: 10px">
                            <img
                              class="icon"
                              src="../assets/images/report-pdf/movement.png"
                              width="25px"
                              height="25px"
                            />
                          </div>
                          <div class="font-w-700">การเคลื่อนไหว</div>
                        </v-flex>
                        <v-flex xs6 class="d-flex justify-content-end">
                          <div style="margin-right: 10px; padding-top: 5px">
                            <Icon
                              class="font-28"
                              v-if="getStatusMovement().name === 'A'"
                              :style="{
                                color: checkColor(getStatusMovement().name),
                              }"
                              icon="fluent-mdl2:emoji"
                            />
                            <Icon
                              class="font-28"
                              v-if="getStatusMovement().name === 'B'"
                              :style="{
                                color: checkColor(getStatusMovement().name),
                              }"
                              icon="fluent-mdl2:emoji-neutral"
                            />
                            <Icon
                              class="font-28"
                              v-if="getStatusMovement().name === 'C'"
                              :style="{
                                color: checkColor(getStatusMovement().name),
                              }"
                              icon="fluent-mdl2:emoji-disappointed"
                            />
                          </div>
                          <div>
                            <v-btn
                              depressed
                              dark
                              style="border-radius: 38px"
                              :color="checkColor(getStatusMovement().name)"
                              width="60px"
                            >
                              <span class="font-28 font-w-600">
                                {{ getStatusMovement().name }}
                              </span>
                            </v-btn>
                          </div>
                        </v-flex>
                      </v-layout>

                      <!-- Table -->
                      <table class="section_table">
                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.muscle_mass_points_criterion
                            ).color,
                            borderTopLeftRadius: '20px',
                            borderTopRightRadius: '20px',
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('มวลกล้ามเนื้อ')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-1.png"
                                width="9"
                                height="12"
                              />
                              <img
                                class="icon_2"
                                src="../assets/images/report-pdf/icon-next-2.png"
                                width="9"
                                height="12"
                              />
                            </div>
                            <div>มวลกล้ามเนื้อ</div>
                          </td>
                          <td>{{ form.muscle_mass }}</td>
                          <td>{{ ">48" }}</td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(
                                    form.muscle_mass_points_criterion
                                  ).grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.muscle_mass_points_criterion
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(
                                    form.muscle_mass_points_criterion
                                  ).grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.grip_strength_right_criterion
                            ).color,
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('แรงบีบมือข้างขวา')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-1.png"
                                width="9"
                                height="12"
                              />
                              <img
                                class="icon_2"
                                src="../assets/images/report-pdf/icon-next-2.png"
                                width="9"
                                height="12"
                              />
                            </div>
                            <div>แรงบีบมือ (ขวา)</div>
                          </td>
                          <td>{{ form.grip_strength_right }}</td>
                          <td>30</td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(
                                    form.grip_strength_right_criterion
                                  ).grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.grip_strength_right_criterion
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(
                                    form.grip_strength_right_criterion
                                  ).grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.grip_strength_left_criterion
                            ).color,
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('แรงบีบมือข้างซ้าย')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-1.png"
                                width="9"
                                height="12"
                              />
                              <img
                                class="icon_2"
                                src="../assets/images/report-pdf/icon-next-2.png"
                                width="9"
                                height="12"
                              />
                            </div>
                            <div>แรงบีบมือ (ซ้าย)</div>
                          </td>
                          <td>{{ form.grip_strength_left }}</td>
                          <td>30</td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(
                                    form.grip_strength_left_criterion
                                  ).grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.grip_strength_left_criterion
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(
                                    form.grip_strength_left_criterion
                                  ).grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.back_strength_criteria
                            ).color,
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('ความแข็งแรงกล้ามเนื้อหลัง')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-1.png"
                                width="9"
                                height="12"
                              />
                              <img
                                class="icon_2"
                                src="../assets/images/report-pdf/icon-next-2.png"
                                width="9"
                                height="12"
                              />
                            </div>
                            <div>กล้ามเนื้อหลัง</div>
                          </td>
                          <td>{{ form.back_strength }}</td>
                          <td>130</td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(
                                    form.back_strength_criteria
                                  ).grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.back_strength_criteria
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(
                                    form.back_strength_criteria
                                  ).grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.single_leg_balance_criteria
                            ).color,
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('การทรงตัวยืนขาเดียว')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-1.png"
                                width="9"
                                height="12"
                              />
                              <img
                                class="icon_2"
                                src="../assets/images/report-pdf/icon-next-2.png"
                                width="9"
                                height="12"
                              />
                            </div>
                            <div>ยืนขาเดียว</div>
                          </td>
                          <td>{{ form.single_leg_balance }}</td>
                          <td>60</td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(
                                    form.single_leg_balance_criteria
                                  ).grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.single_leg_balance_criteria
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(
                                    form.single_leg_balance_criteria
                                  ).grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.stand_up_test_criteria
                            ).color,
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('Stand Up Test')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-1.png"
                                width="9"
                                height="12"
                              />
                              <img
                                class="icon_2"
                                src="../assets/images/report-pdf/icon-next-2.png"
                                width="9"
                                height="12"
                              />
                            </div>
                            <div>Stand Up Test</div>
                          </td>
                          <td>{{ form.stand_up_test }}</td>
                          <td>4</td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(
                                    form.stand_up_test_criteria
                                  ).grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.stand_up_test_criteria
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(
                                    form.stand_up_test_criteria
                                  ).grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.functional_test_criteria
                            ).color,
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('Functional Test')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-1.png"
                                width="9"
                                height="12"
                              />
                              <img
                                class="icon_2"
                                src="../assets/images/report-pdf/icon-next-2.png"
                                width="9"
                                height="12"
                              />
                            </div>
                            <div>Functional Test</div>
                          </td>
                          <td>{{ form.functional_test }}</td>
                          <td>30</td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(
                                    form.functional_test_criteria
                                  ).grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.functional_test_criteria
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(
                                    form.functional_test_criteria
                                  ).grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.stability_criteria
                            ).color,
                            borderBottomLeftRadius: '20px',
                            borderBottomRightRadius: '20px',
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('2 Step Test')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-1.png"
                                width="9"
                                height="12"
                              />
                              <img
                                class="icon_2"
                                src="../assets/images/report-pdf/icon-next-2.png"
                                width="9"
                                height="12"
                              />
                            </div>
                            <div>2 Step Test</div>
                          </td>
                          <td>{{ form.stability }}</td>
                          <td>1.54</td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(form.stability_criteria)
                                    .grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.stability_criteria
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(form.stability_criteria)
                                    .grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </v-flex>

                  <v-flex xs6 style="padding-left: 10px">
                    <!-- Table ความยืดหยุ่น -->
                    <div class="section_right_table">
                      <!-- Topic table -->
                      <v-layout class="section_right_title">
                        <v-flex xs6 class="d-flex align-items-center">
                          <div style="margin-right: 10px">
                            <img
                              class="icon"
                              src="../assets/images/report-pdf/flexibility.png"
                              width="25px"
                              height="25px"
                            />
                          </div>
                          <div class="font-w-700">ความยืดหยุ่น</div>
                        </v-flex>
                        <v-flex xs6 class="d-flex justify-content-end">
                          <div style="margin-right: 10px; padding-top: 5px">
                            <Icon
                              class="font-28"
                              v-if="getStatusFlexibility().name === 'A'"
                              :style="{
                                color: checkColor(getStatusFlexibility().name),
                              }"
                              icon="fluent-mdl2:emoji"
                            />
                            <Icon
                              class="font-28"
                              v-if="getStatusFlexibility().name === 'B'"
                              :style="{
                                color: checkColor(getStatusFlexibility().name),
                              }"
                              icon="fluent-mdl2:emoji-neutral"
                            />
                            <Icon
                              class="font-28"
                              v-if="getStatusFlexibility().name === 'C'"
                              :style="{
                                color: checkColor(getStatusFlexibility().name),
                              }"
                              icon="fluent-mdl2:emoji-disappointed"
                            />
                          </div>
                          <div>
                            <v-btn
                              depressed
                              dark
                              style="border-radius: 38px"
                              :color="checkColor(getStatusFlexibility().name)"
                              width="60px"
                            >
                              <span class="font-28 font-w-600">
                                {{ getStatusFlexibility().name }}
                              </span>
                            </v-btn>
                          </div>
                        </v-flex>
                      </v-layout>

                      <!-- Table -->
                      <table class="section_table">
                        <!-- todo this ตอนนี้ยังไม่มีเก็บความยืดหยุ่นของหัวไหล่แยกกัน -->
                        <!-- <tr
                      :style="{
                        backgroundColor: changeStatusToGrade(
                          form.flexibility_upper_criteria
                        ).color,
                      }"
                    >
                      <td style="position: relative">
                        <div
                          v-if="checkSelected('ความอ่อนตัวหัวไหล่')"
                          class="con_icon"
                          style="position: absolute; top: -2px; left: -2px"
                        >
                          <img
                            class="icon_1"
                            src="../assets/images/report-pdf/icon-next-4.png"
                            width="11"
                            height="13"
                          />
                        </div>
                        <div>ความยืดหยุ่นของหัวไหล่(ขวา)</div>
                      </td>
                      <td>{{ form.flexibility_upper }}</td>
                      <td>0以下</td>
                      <td>
                        <div
                          class="column_grade"
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.flexibility_upper_criteria
                            ).background,
                          }"
                        >
                          {{
                            changeStatusToGrade(form.flexibility_upper_criteria)
                              .grade
                          }}
                        </div>
                      </td>
                    </tr> -->

                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.flexibility_upper_criteria
                            ).color,
                            borderTopLeftRadius: '20px',
                            borderTopRightRadius: '20px',
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('ความอ่อนตัวหัวไหล่')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-1.png"
                                width="9"
                                height="12"
                              />
                              <img
                                class="icon_2"
                                src="../assets/images/report-pdf/icon-next-2.png"
                                width="9"
                                height="12"
                              />
                            </div>
                            <div>หัวไหล่</div>
                          </td>
                          <td>{{ form.flexibility_upper }}</td>
                          <td>0</td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(
                                    form.flexibility_upper_criteria
                                  ).grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.flexibility_upper_criteria
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(
                                    form.flexibility_upper_criteria
                                  ).grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.flexibility_lower_criteria
                            ).color,
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('ความอ่อนตัวหัวเข่า')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-1.png"
                                width="9"
                                height="12"
                              />
                              <img
                                class="icon_2"
                                src="../assets/images/report-pdf/icon-next-2.png"
                                width="9"
                                height="12"
                              />
                            </div>
                            <div>ข้อเข่า</div>
                          </td>
                          <td>{{ form.flexibility_lower }}</td>
                          <td>20</td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(
                                    form.flexibility_lower_criteria
                                  ).grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.flexibility_lower_criteria
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(
                                    form.flexibility_lower_criteria
                                  ).grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.flexibility_waist_criteria
                            ).color,
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('ความอ่อนตัวของหลัง')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-1.png"
                                width="9"
                                height="12"
                              />
                              <img
                                class="icon_2"
                                src="../assets/images/report-pdf/icon-next-2.png"
                                width="9"
                                height="12"
                              />
                            </div>
                            <div>สะโพก</div>
                          </td>
                          <td>{{ form.flexibility_waist }}</td>
                          <td>90</td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(
                                    form.flexibility_waist_criteria
                                  ).grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.flexibility_waist_criteria
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(
                                    form.flexibility_waist_criteria
                                  ).grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.posture_score_criteria
                            ).color,
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('คะแนนท่าทาง')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-1.png"
                                width="9"
                                height="12"
                              />
                              <img
                                class="icon_2"
                                src="../assets/images/report-pdf/icon-next-2.png"
                                width="9"
                                height="12"
                              />
                            </div>
                            <div>คะแนนท่าทาง</div>
                          </td>
                          <td>{{ form.posture_score }}</td>
                          <td>50</td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(
                                    form.posture_score_criteria
                                  ).grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.posture_score_criteria
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(
                                    form.posture_score_criteria
                                  ).grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.posture_type_criteria
                            ).color,
                            borderBottomLeftRadius: '20px',
                            borderBottomRightRadius: '20px',
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('ปัญหาท่าทาง')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-1.png"
                                width="9"
                                height="12"
                              />
                              <img
                                class="icon_2"
                                src="../assets/images/report-pdf/icon-next-2.png"
                                width="9"
                                height="12"
                              />
                            </div>
                            <div>ปัญหาท่าทาง</div>
                          </td>
                          <td>{{ form.posture_type }}</td>
                          <td>ปกติ</td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(
                                    form.posture_type_criteria
                                  ).grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.posture_type_criteria
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(
                                    form.posture_type_criteria
                                  ).grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </v-flex>
                </v-layout>
              </v-flex>

              <!-- ความคิดเห็นของคุณ -->
              <v-flex xs3 class="section_right_right">
                <div
                  class="d-flex align-items-center"
                  style="margin-bottom: 5px"
                >
                  <div class="font-18 font-w-600">ความคิดเห็นของคุณ</div>
                  <Icon
                    style="color: #55c5c8; margin-left: 5px"
                    class="font-20"
                    icon="bxs:message-square-detail"
                  />
                </div>

                <div class="box_message font-14">
                  <p v-if="form.comment">{{ form.comment }}</p>
                </div>

                <div
                  class="d-flex align-items-center"
                  style="margin-bottom: 5px; margin-top: 3.5rem"
                >
                  <div class="font-18 font-w-600">ข้อความจาก</div>
                  <img
                    style="margin-left: 5px"
                    src="../assets/images/logo.png"
                    width="76px"
                    height="29px"
                  />
                </div>

                <div style="height: 265px" class="box_message font-14">
                  <p v-if="form.message_pwp">{{ form.message_pwp }}</p>
                </div>
              </v-flex>
            </v-layout>

            <!-- Section Buttom -->
            <v-layout row wrap class="section_right_buttom">
              <!-- ผลลัพท์ความเสี่ยงสูง -->
              <v-flex xs3>
                <div style="margin-bottom: 32px" class="font-18 font-w-600">
                  ผลลัพท์ความเสี่ยงสูง
                </div>
                <div
                  v-for="(item, i) in form.measurements"
                  :key="i"
                  class="d-flex"
                >
                  <div class="con_box">
                    <div class="box_1 d-flex align-items-center">
                      <v-layout class="d-flex align-items-center">
                        <v-flex shrink style="margin-right: 10px">
                          <img
                            src="../assets/images/report-pdf/age.png"
                            width="40"
                            height="40"
                            v-if="
                              checkGroupMeasurement(item.measurement_name) ===
                              'bodyAge'
                            "
                          />
                          <img
                            src="../assets/images/report-pdf/balance.png"
                            width="25px"
                            height="40px"
                            v-if="
                              checkGroupMeasurement(item.measurement_name) ===
                              'physicalBalance'
                            "
                          />
                          <img
                            src="../assets/images/report-pdf/movement.png"
                            width="30"
                            height="30"
                            v-if="
                              checkGroupMeasurement(item.measurement_name) ===
                              'movement'
                            "
                          />
                          <img
                            src="../assets/images/report-pdf/flexibility.png"
                            width="40"
                            height="40"
                            v-if="
                              checkGroupMeasurement(item.measurement_name) ===
                              'flexibility'
                            "
                          />
                        </v-flex>
                        <v-flex>
                          {{ item.measurement_name }}
                        </v-flex>
                      </v-layout>
                    </div>
                    <div class="box_2"></div>
                  </div>
                  <div class="con_icon">
                    <img
                      class="icon_1"
                      src="../assets/images/report-pdf/icon-next-1.png"
                      width="33"
                      height="40"
                    />
                    <img
                      class="icon_2"
                      src="../assets/images/report-pdf/icon-next-2.png"
                      width="33"
                      height="40"
                    />
                  </div>
                </div>
              </v-flex>

              <!-- ผลการวัดวันนี้และความเสี่ยง -->
              <v-flex xs5 style="padding-left: 1rem">
                <div style="margin-bottom: 5px" class="font-18 font-w-600">
                  ผลการวัดวันนี้และความเสี่ยง
                  <div style="color: #a3a3a3">(อาการทั่วไป)</div>
                </div>

                <div
                  v-for="(item, i) in form.measurements"
                  :key="i"
                  class="d-flex"
                >
                  <div class="con_box" style="width: 84%">
                    <div class="box_1 d-flex align-items-center">
                      <v-layout>
                        <v-flex shrink>
                          <div
                            class="d-flex align-items-center justify-content-center"
                          >
                            <div
                              class="font-20 font-w-600 d-flex align-items-center justify-content-center"
                              :style="{
                                width: '26px',
                                height: '26px',
                                borderRadius: '100px',
                                color: '#fff',
                                backgroundColor: checkColor(
                                  item.grade_result_today
                                ),
                              }"
                            >
                              {{ item.grade_result_today }}
                            </div>
                            <span
                              class="font-24 font-w-600"
                              :style="{
                                color: checkColor(item.grade_result_today),
                                padding: '0px 5px',
                              }"
                            >
                              {{
                                item.result_today
                                  ? checkDecimal(item.result_today)
                                  : ""
                              }}
                            </span>
                          </div>
                        </v-flex>
                        <v-flex class="font-22" style="padding-left: 2px">
                          {{
                            getMeasurementMessage(
                              item.measurement_name,
                              item.grade_result_today,
                              "today"
                            ).msg
                          }}
                        </v-flex>
                      </v-layout>
                    </div>

                    <div class="box_2"></div>
                  </div>
                  <div class="con_icon">
                    <img
                      class="icon_1"
                      src="../assets/images/report-pdf/icon-next-1.png"
                      width="33"
                      height="40"
                    />
                    <img
                      class="icon_2"
                      src="../assets/images/report-pdf/icon-next-2.png"
                      width="33"
                      height="40"
                    />
                  </div>
                </div>
              </v-flex>

              <!-- ค่าเป้าหมายการปรับปรุงและวิธีปรับปรุง -->
              <v-flex xs4>
                <div style="margin-bottom: 5px" class="font-18 font-w-600">
                  <v-layout class="d-flex align-items-center">
                    <v-flex>
                      <img
                        width="37"
                        height="37"
                        src="../assets/images/report-pdf/goal.png"
                        alt=""
                      />
                    </v-flex>
                    <v-flex style="margin-right: -1rem">
                      ค่าเป้าหมายการปรับปรุงและวิธีปรับปรุง
                      <div style="color: #a3a3a3">(3 เดือนต่อมา)</div>
                    </v-flex>
                  </v-layout>
                </div>

                <div
                  v-for="(item, i) in form.measurements"
                  :key="i"
                  class="d-flex"
                >
                  <div class="con_box" style="width: 100%">
                    <div
                      class="box_1 d-flex"
                      style="
                        flex-direction: column;
                        align-items: start;
                        justify-content: center;
                      "
                    >
                      <divz
                        class="d-flex align-items-center justify-content-center"
                      >
                        <div
                          class="font-20 font-w-600 d-flex align-items-center justify-content-center"
                          :style="{
                            width: '26px',
                            height: '26px',
                            borderRadius: '100px',
                            color: '#fff',
                            backgroundColor: checkColor(
                              item.grade_improve_target
                            ),
                          }"
                        >
                          {{ item.grade_improve_target }}
                        </div>

                        <div
                          class="font-24 font-w-600"
                          :style="{
                            color: checkColor(item.grade_improve_target),
                            padding: '0px 5px',
                          }"
                        >
                          {{
                            item.improve_target
                              ? checkDecimal(item.improve_target)
                              : ""
                          }}
                        </div>

                        <div
                          v-if="
                            getMeasurementMessage(
                              item.measurement_name,
                              item.grade_improve_target,
                              'target'
                            ).checked.length > 0
                          "
                        >
                          <v-layout class="d-flex">
                            <v-flex
                              shrink
                              v-for="(msg, m) in getMeasurementMessage(
                                item.measurement_name,
                                item.grade_improve_target,
                                'target'
                              ).checked"
                              :key="m"
                            >
                              <span
                                :style="{
                                  backgroundColor: checkColor(
                                    item.grade_improve_target
                                  ),
                                  color: '#fff',
                                  marginRight: '5px',
                                  padding: '0px 6px',
                                  borderRadius: '5px',
                                }"
                                class="font-13 font-w-400"
                              >
                                {{ msg == "คาร์ดิโอ" ? "คาร์ดิโอ" : msg }}
                              </span>
                            </v-flex>
                          </v-layout>
                        </div>
                      </divz>
                      <div class="font-22 one_line">
                        {{
                          getMeasurementMessage(
                            item.measurement_name,
                            item.grade_improve_target,
                            "target"
                          ).msg
                        }}
                      </div>
                    </div>
                    <div class="box_2"></div>
                  </div>
                </div>
              </v-flex>

              <v-flex xs12 style="display: flex; justify-content: end">
                <span class="font-w-600" style="padding-right: 2rem">
                  {{ form.trainer_name }}
                </span>
                <span class="font-w-400">
                  {{ dateNow }}
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <!-- ด้านหลัง -->
        <div class="section" style="background-color: #fff" v-if="form">
          <v-layout>
            <!-- Section Left -->
            <v-flex xs8 class="back_left">
              <div
                style="font-size: 64px; margin-bottom: 1.5rem"
                class="font-w-600"
              >
                รายการตรวจวัด
              </div>

              <!-- Group 1 อายุร่างกาย -->
              <v-layout style="margin-bottom: 2.5rem">
                <v-flex xs3>
                  <div
                    class="font-18 font-w-600"
                    :style="{
                      color: checkColor(form.rank),
                      marginBottom: '10px',
                    }"
                  >
                    อายุร่างกาย
                  </div>
                  <div>หลอดเลือด</div>
                  <div>องค์ประกอบของร่างกาย</div>
                  <div>ความจุปอด</div>
                  <div>ผิว</div>
                </v-flex>

                <v-flex xs4>
                  <div
                    class="font-18 font-w-600"
                    :style="{
                      color: checkColor(form.rank),
                      marginBottom: '10px',
                    }"
                  >
                    ค่ามาตราฐาน
                  </div>
                  <div>น้อยกว่าอายุจริง</div>
                  <div>น้อยกว่าอายุจริง</div>
                  <div>น้อยกว่าอายุจริง</div>
                  <div>น้อยกว่าอายุจริง</div>
                </v-flex>

                <v-flex xs5 style="margin-left: -2.5rem">
                  <div
                    class="font-18 font-w-600"
                    :style="{
                      color: checkColor(form.rank),
                      marginBottom: '10px',
                    }"
                  >
                    คำอธิบาย
                  </div>
                  <div style="margin-right: -10rem">
                    อายุของหลอดเลือดคำนวณจากความยืดหยุ่นของหลอดเลือด และอื่น ๆ
                  </div>
                  <div style="margin-right: -10rem">
                    อายุองค์ประกอบร่างกายคำนวณจากความสมดุลขององค์ประกอบในร่างกาย
                  </div>
                  <div style="margin-right: -10rem">
                    อายุที่คำนวณจากแรงในการหายใจออก
                  </div>
                  <div style="margin-right: -10rem">
                    อายุผิวคำนวณจากความยืดหยุ่นของผิว และอื่น ๆ
                  </div>
                </v-flex>
              </v-layout>

              <!-- Group 2 การเคลื่อนไหว -->
              <v-layout style="margin-bottom: 2.5rem">
                <v-flex xs3>
                  <div
                    class="font-18 font-w-600"
                    :style="{
                      color: checkColor(form.rank),
                      marginBottom: '10px',
                    }"
                  >
                    การเคลื่อนไหว
                  </div>
                  <div>มวลกล้ามเนื้อ</div>
                  <div>แรงบีบมือ</div>
                  <div>กล้ามเนื้อหลัง</div>
                  <div>ยืนขาเดียว</div>
                  <div>Stand Up Test</div>
                  <div>Functional</div>
                  <div>2 Step Test</div>
                </v-flex>

                <v-flex xs4>
                  <div
                    class="font-18 font-w-600"
                    :style="{
                      color: checkColor(form.rank),
                      marginBottom: '10px',
                    }"
                  >
                    ค่ามาตราฐาน
                  </div>
                  <v-layout>
                    <v-flex xs7> > 48</v-flex>
                    <v-flex xs5>pt.</v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs7> ≧ 30</v-flex>
                    <v-flex xs5>Kgw.</v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs7> ≧ 130</v-flex>
                    <v-flex xs5>Kg.</v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs7>60</v-flex>
                    <v-flex xs5>Sec.</v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs7>4</v-flex>
                    <v-flex xs5>Cm.</v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs7> ≧ 30</v-flex>
                    <v-flex xs5>Kg.</v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs7> ≧ 1.54</v-flex>
                    <v-flex xs5>Points</v-flex>
                  </v-layout>
                </v-flex>

                <v-flex xs5 style="margin-left: -2.5rem">
                  <div
                    class="font-18 font-w-600"
                    :style="{
                      color: checkColor(form.rank),
                      marginBottom: '10px',
                    }"
                  >
                    คำอธิบาย
                  </div>
                  <div style="margin-right: -10rem">
                    ค่าตัวเลขคะแนนคุณภาพของกล้ามเนื้อ
                  </div>
                  <div style="margin-right: -10rem">
                    ตัวเลขแสดงความแข็งแรงทั้งหมดของกล้ามเนื้อร่างกายส่วนบน
                  </div>
                  <div style="margin-right: -10rem">
                    ตัวเลขแสดงความแข็งแรงโดยรวมของกล้ามเนื้อหลัง
                  </div>
                  <div style="margin-right: -10rem">
                    การประเมินความสามารถในการเดินอย่างครอบคลุม
                  </div>
                  <div style="margin-right: -10rem">
                    การประเมินความสามารถในการยืนอย่างครอบคลุม
                  </div>
                  <div style="margin-right: -10rem">
                    ตัวเลขแสดงความมั่นคงในขณะที่เคลื่อนไหว
                  </div>
                  <div style="margin-right: -10rem">
                    ตัวเลขแสดงความมั่นคงในขณะที่หยุดนิ่ง
                  </div>
                </v-flex>
              </v-layout>

              <!-- Group 3 -->
              <v-layout style="margin-bottom: 2.5rem">
                <v-flex xs3>
                  <div
                    class="font-18 font-w-600"
                    :style="{
                      color: checkColor(form.rank),
                      marginBottom: '10px',
                    }"
                  >
                    ความสมดุลร่างกาย
                  </div>
                  <div>BMI</div>
                  <div>ความดันโลหิต</div>
                  <div>ระดับไขมันในช่องท้อง</div>
                  <div>ระดับไขมันในร่างกาย</div>
                  <div>ปริมาณน้ำในร่างกาย</div>
                  <div>อัตราเผาผลาญ</div>
                </v-flex>

                <v-flex xs4>
                  <div
                    class="font-18 font-w-600"
                    :style="{
                      color: checkColor(form.rank),
                      marginBottom: '10px',
                    }"
                  >
                    ค่ามาตราฐาน
                  </div>
                  <v-layout>
                    <v-flex xs7>18.5-24.9</v-flex>
                    <v-flex xs5>Kg./m2</v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs7>129-101/79-61</v-flex>
                    <v-flex xs5>mmHg.</v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs7>9.5 หรือน้อยกว่า</v-flex>
                    <v-flex xs5>LV.</v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs7>18-22</v-flex>
                    <v-flex xs5>%.</v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs7>55-65</v-flex>
                    <v-flex xs5>%.</v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs7> ≧ 1530</v-flex>
                    <v-flex xs5>kcal</v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs5 style="margin-left: -2.5rem">
                  <div
                    class="font-18 font-w-600"
                    :style="{
                      color: checkColor(form.rank),
                      marginBottom: '10px',
                    }"
                  >
                    คำอธิบาย
                  </div>
                  <div style="margin-right: -10rem">
                    ดัชนีที่แสดงถึงความสมดุลระหว่างความสูงและน้ำหนัก
                  </div>
                  <div style="margin-right: -10rem">
                    แรงดันของกระแสเลือดที่ส่งมาจากหัวใจกระทบกับผนังหลอดเลือดแดง
                  </div>
                  <div style="margin-right: -10rem">
                    ไขมันที่เกาะตามอวัยวะภายในช่องท้อง
                  </div>
                  <div style="margin-right: -10rem">ไขมันที่สะสมในร่างกาย</div>
                  <div style="margin-right: -10rem">
                    เปอร์เซ็นต์ของน้ำในร่างกายต่อน้ำหนักตัว
                  </div>
                  <div style="margin-right: -10rem">
                    ปริมาณพลังงานที่ถูกเผาผลาญโดยไม่ต้องทำอะไร
                  </div>
                </v-flex>
              </v-layout>

              <!-- Group 4 -->
              <v-layout>
                <v-flex xs3>
                  <div
                    class="font-18 font-w-600"
                    :style="{
                      color: checkColor(form.rank),
                      marginBottom: '10px',
                    }"
                  >
                    ความยืดหยุ่น
                  </div>
                  <div>ความยืดหยุ่นของหัวไหล่</div>
                  <div>ความยืดหยุ่นของข้อเข่า</div>
                  <div>ความยืดหยุ่นของสะโพก</div>
                  <div>คะแนนท่าทาง</div>
                  <div>ปัญหาท่าทาง</div>
                </v-flex>

                <v-flex xs4>
                  <div
                    class="font-18 font-w-600"
                    :style="{
                      color: checkColor(form.rank),
                      marginBottom: '10px',
                    }"
                  >
                    ค่ามาตราฐาน
                  </div>
                  <v-layout>
                    <v-flex xs7>0 หรือน้อยกว่า</v-flex>
                    <v-flex xs5>Cm.</v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs7>≧ 40</v-flex>
                    <v-flex xs5>Cm.</v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs7>≧ 90</v-flex>
                    <v-flex xs5>°</v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs7>50</v-flex>
                    <v-flex xs5>pt.</v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs7>ปกติ</v-flex>
                    <v-flex xs5></v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs5 style="margin-left: -2.5rem">
                  <div
                    class="font-18 font-w-600"
                    :style="{
                      color: checkColor(form.rank),
                      marginBottom: '10px',
                    }"
                  >
                    คำอธิบาย
                  </div>
                  <div>ตัวเลขแสดงช่วงการเคลื่อนที่ของกล้ามเนื้อไหล่</div>
                  <div>ตัวเลขแสดงช่วงการเคลื่อนไหวของกล้ามเนื้อรอบข้อเข่า</div>
                  <div>ตัวเลขแสดงช่วงการเคลื่อนไหวของกล้ามเนื้อรอบสะโพก</div>
                  <div>การประเมินท่าทางโดยรวมออกมาเป็นคะแนนการตัดสิน</div>
                  <div
                    style="margin-right: -6rem; position: relative; z-index: 2"
                  >
                    ท่าทางตามประเภท (ปกติ, หลังค่อม, หลังแบน, หลังแอ่น,
                    หลังค่อม+หลังแอ่น)
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>

            <!-- Section Right -->
            <v-flex xs4 class="back_right">
              <!-- Image model -->
              <img
                class="model-img"
                src="../assets/images/report-pdf/model-standard-back.png"
                v-if="form.rank === 'A'"
              />
              <img
                class="model-img"
                src="../assets/images/report-pdf/model-warn-back.png"
                v-else-if="form.rank === 'B'"
              />
              <img
                class="model-img"
                src="../assets/images/report-pdf/model-danger-back.png"
                v-else-if="form.rank === 'C'"
              />
              <img
                class="model-img"
                src="../assets/images/report-pdf/model-na-back.png"
                v-else
              />

              <v-layout class="back_detail">
                <v-flex>
                  <div class="map">
                    <img
                      v-if="form.rank === 'A'"
                      width="327"
                      height="189"
                      src="../assets/images/report-pdf/map-standard.png"
                      alt=""
                    />
                    <img
                      v-else-if="form.rank === 'B'"
                      width="327"
                      height="189"
                      src="../assets/images/report-pdf/map-warn.png"
                      alt=""
                    />
                    <img
                      v-else-if="form.rank === 'C'"
                      width="327"
                      height="189"
                      src="../assets/images/report-pdf/map-danger.png"
                      alt=""
                    />
                    <img
                      v-else
                      width="327"
                      height="189"
                      src="../assets/images/report-pdf/map-na.png"
                      alt=""
                    />
                  </div>

                  <div class="social">
                    <div class="line d-flex">
                      <img
                        width="30"
                        height="30"
                        src="../assets/images/report-pdf/line.png"
                        alt=""
                      />
                      <div class="font-14 font-w-500">@Powerpod</div>
                    </div>
                    <div class="facebook d-flex">
                      <img
                        width="30"
                        height="30"
                        src="../assets/images/report-pdf/facebook.png"
                        alt=""
                      />
                      <div class="font-14 font-w-500">Powerpod Fitness</div>
                    </div>
                    <div class="twitter d-flex">
                      <img
                        width="30"
                        height="30"
                        src="../assets/images/report-pdf/twitter.png"
                        alt=""
                      />
                      <div class="font-14 font-w-500">Powerpod.Fitness</div>
                    </div>
                    <div class="instagram d-flex">
                      <img
                        width="30"
                        height="30"
                        src="../assets/images/report-pdf/instagram.png"
                        alt=""
                      />
                      <div class="font-14 font-w-500">PWP_Powerpod</div>
                    </div>
                  </div>

                  <div class="address">
                    <div class="d-flex" style="padding-left: 10px">
                      <Icon
                        :style="{ color: checkColor(form.rank) }"
                        icon="ph:phone-call-fill"
                      />
                      <div>092 -741 2239</div>
                    </div>
                    <div class="d-flex" style="padding-left: 10px">
                      <Icon
                        :style="{
                          color: checkColor(form.rank),
                          fontSize: '30px',
                        }"
                        icon="mdi:map-marker"
                      />
                      <div>
                        39 ถ. สุขุมวิท 71 แขวง พระโขนงเหนือ เขตวัฒนา
                        กรุงเทพมหานคร 10120
                      </div>
                    </div>
                    <div class="d-flex" style="padding-left: 20px">
                      <Icon
                        :style="{ color: checkColor(form.rank) }"
                        icon="material-symbols:train"
                      />
                      <div>BTS พระโขนง ทางออกที่ 3 (เดินประมาณ 7 นาที)</div>
                    </div>
                    <div class="d-flex" style="padding-left: 25px">
                      <Icon
                        :style="{ color: checkColor(form.rank) }"
                        icon="ic:baseline-access-time-filled"
                      />
                      <div>จันทร์-อาทิตย์ 8:00-22:00 น.</div>
                    </div>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </div>
      </section>
    </vue-html2pdf>

    <v-btn
      style="position: fixed; bottom: 0; right: 0; z-index: 10"
      @click="downloadPdf()"
    >
      downloadPdf
    </v-btn>

    <!-- ด้านหนัา -->
        <v-layout
          class="section"
          :style="{ backgroundColor: checkColorBg(form.rank) }"
          v-if="form"
        >
          <!-- section left -->
          <v-flex shrink class="section_left">
            <!-- Image model -->
            <img
              class="model-img"
              src="../assets/images/report-pdf/model-a.png"
              v-if="form.rank === 'A'"
            />
            <img
              class="model-img"
              src="../assets/images/report-pdf/model-b.png"
              v-else-if="form.rank === 'B'"
            />
            <img
              class="model-img"
              src="../assets/images/report-pdf/model-c.png"
              v-else-if="form.rank === 'C'"
            />
            <img
              class="model-img"
              src="../assets/images/report-pdf/model-na.png"
              v-else
            />

            <div
              class="center_line"
              :style="{ backgroundColor: checkColorBg(form.rank) }"
            ></div>

            <!-- Section Grade -->
            <div class="section_grade_card">
              <div></div>
            </div>

            <!-- Section Name -->
            <div class="section_name">
              <div>
                <div class="first_name">
                  {{ form.first_name }}
                </div>
                <div class="family_name">
                  {{ form.family_name }}
                </div>
              </div>
            </div>

            <!-- Section info  -->
            <div class="section_info">
              <!-- อายุ -->
              <v-layout class="d-flex align-items-center section_info_item">
                <v-flex xs4 class="section_info_key">
                  อายุ <span class="section_info_val">{{ form.age }}</span>
                </v-flex>
                <v-flex
                  xs8
                  class="d-flex align-items-center section_info_grade"
                >
                  <div
                    class="font-w-600"
                    style="color: #44a04c; margin-right: 5px"
                  >
                    A
                  </div>
                  <div class="font-13">สภาพร่างกายที่มีความสมดุล</div>
                </v-flex>
              </v-layout>

              <!-- เพศ -->
              <v-layout class="d-flex align-items-center section_info_item">
                <v-flex xs4 class="section_info_key">
                  เพศ <span class="section_info_val">{{ form.gender }}</span>
                </v-flex>
                <v-flex
                  xs8
                  class="d-flex align-items-center section_info_grade"
                >
                  <div
                    class="font-w-600"
                    style="color: #ffbb02; margin-right: 5px"
                  >
                    B
                  </div>
                  <div class="font-13">สภาพร่างกายที่กำลังเสียสมดุล</div>
                </v-flex>
              </v-layout>

              <!-- ส่วนสูง -->
              <v-layout class="d-flex align-items-center section_info_item">
                <v-flex xs4 class="section_info_key">
                  ส่วนสูง
                  <span class="section_info_val"
                    >{{ parseInt(form.height) }} cm.</span
                  >
                </v-flex>
                <v-flex
                  xs8
                  class="d-flex align-items-center section_info_grade"
                >
                  <div
                    class="font-w-600"
                    style="color: #c71010; margin-right: 5px"
                  >
                    C
                  </div>
                  <div class="font-13">มีความเสี่ยงสูงที่สุขภาพจะเสีย</div>
                </v-flex>
              </v-layout>

              <!-- น้ำหนัก -->
              <v-layout class="d-flex align-items-center section_info_item">
                <v-flex xs4 class="section_info_key">
                  น้ำหนัก
                  <span class="section_info_val"
                    >{{ parseInt(form.weight) }} Kg.</span
                  >
                </v-flex>
                <v-flex
                  xs8
                  class="d-flex align-items-center section_info_grade"
                >
                </v-flex>
              </v-layout>
            </div>

            <!-- Section body part -->
            <div class="section_body_part">
              <!-- Image position -->
              <div class="body_model">
                <img
                  src="../assets/images/report-pdf/body.png"
                  width="245"
                  height="228"
                  alt=""
                  class="default_position"
                />
                <div
                  class="part_position"
                  v-for="(body_position, i) in body_positions"
                  :key="i"
                >
                  <img
                    v-if="showPositionPart(body_position)"
                    :src="
                      require(`../assets/images/report-pdf/body-position/${body_position.image}.png`)
                    "
                    width="245"
                    height="228"
                    alt=""
                  />
                </div>
              </div>

              <!-- detail -->
              <div class="font-20 font-w-600" style="margin: 15px 0px">
                ตำแหน่งที่มีอาการ/ปัญหาในปัจจุบัน
              </div>
              <div v-for="(position, i) in form.problematic_part" :key="i">
                <v-layout
                  class="body_part_item"
                  v-if="position.position_problem"
                >
                  <v-flex shrink style="width: 10px" class="font-13 pr-2">
                    {{ checkNoBodyPosition(position.position_problem) }}.
                  </v-flex>
                  <v-flex xs3 class="body_part_name">
                    {{ position.position_problem }}
                  </v-flex>
                  <v-flex xs9 class="font-13">
                    {{ position.problem }}
                  </v-flex>
                </v-layout>
              </div>
            </div>
          </v-flex>

          <!-- column right -->
          <v-flex shrink class="section_right">
            <!-- Section Top -->
            <v-layout>
              <v-flex xs9 class="section_right_left">
                <!-- Section Table (อายุร่างกาย, ความสมดุลร่างกาย) -->
                <v-layout>
                  <v-flex xs6 style="padding-right: 10px">
                    <!-- Table อายุร่างกาย -->
                    <div class="section_right_table">
                      <!-- Topic table -->
                      <v-layout class="section_right_title">
                        <v-flex xs6 class="d-flex align-items-center">
                          <div style="margin-right: 10px">
                            <img
                              class="icon"
                              src="../assets/images/report-pdf/age.png"
                              width="25px"
                              height="25px"
                            />
                          </div>
                          <div class="font-w-700">อายุร่างกาย</div>
                        </v-flex>
                        <v-flex xs6 class="d-flex justify-content-end">
                          <div style="margin-right: 10px; padding-top: 5px">
                            <Icon
                              class="font-28"
                              v-if="getStatusBodyAge() === 'A'"
                              :style="{
                                color: checkColor(getStatusBodyAge()),
                              }"
                              icon="fluent-mdl2:emoji"
                            />
                            <Icon
                              class="font-28"
                              v-if="getStatusBodyAge() === 'B'"
                              :style="{
                                color: checkColor(getStatusBodyAge()),
                              }"
                              icon="fluent-mdl2:emoji-neutral"
                            />
                            <Icon
                              class="font-28"
                              v-if="getStatusBodyAge() === 'C'"
                              :style="{
                                color: checkColor(getStatusBodyAge()),
                              }"
                              icon="fluent-mdl2:emoji-disappointed"
                            />
                          </div>
                          <div>
                            <v-btn
                              depressed
                              dark
                              style="border-radius: 38px"
                              :color="checkColor(getStatusBodyAge())"
                              width="60px"
                            >
                              <span class="font-28 font-w-600">
                                {{ getStatusBodyAge() }}
                              </span>
                            </v-btn>
                          </div>
                        </v-flex>
                      </v-layout>

                      <!-- Table -->
                      <table class="section_table">
                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.vascular_age_criterion
                            ).color,
                            borderTopLeftRadius: '20px',
                            borderTopRightRadius: '20px',
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('อายุหลอดเลือด')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-4.png"
                                width="11"
                                height="13"
                              />
                            </div>
                            <div>หลอดเลือด</div>
                          </td>
                          <td>
                            {{ form.vascular_age }}
                          </td>

                          <td>
                            <div
                              class="d-flex justify-content-center align-center"
                            >
                              <div
                                :class="
                                  changeStatusToGrade(
                                    form.vascular_age_criterion
                                  ).grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.vascular_age_criterion
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(
                                    form.vascular_age_criterion
                                  ).grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.body_age_criterion
                            ).color,
                            borderBottomLeftRadius: '20px',
                            borderBottomRightRadius: '20px',
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('อายุองค์ประกอบร่างกาย')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-4.png"
                                width="11"
                                height="13"
                              />
                            </div>
                            <div>องค์ประกอบร่างกาย</div>
                          </td>
                          <td>{{ form.body_age }}</td>

                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(form.body_age_criterion)
                                    .grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.body_age_criterion
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(form.body_age_criterion)
                                    .grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </v-flex>
                  <v-flex xs6 style="padding-right: 10px">
                    <!-- Table การเคลื่อนไหว -->
                    <div class="section_right_table">
                      <!-- Topic table -->
                      <v-layout class="section_right_title">
                        <v-flex xs6 class="d-flex align-items-center">
                          <div style="margin-right: 10px">
                            <img
                              class="icon"
                              src="../assets/images/report-pdf/movement.png"
                              width="25px"
                              height="25px"
                            />
                          </div>
                          <div class="font-w-700">การเคลื่อนไหว</div>
                        </v-flex>
                        <v-flex xs6 class="d-flex justify-content-end">
                          <div style="margin-right: 10px; padding-top: 5px">
                            <Icon
                              class="font-28"
                              v-if="getStatusMovement().name === 'A'"
                              :style="{
                                color: checkColor(getStatusMovement().name),
                              }"
                              icon="fluent-mdl2:emoji"
                            />
                            <Icon
                              class="font-28"
                              v-if="getStatusMovement().name === 'B'"
                              :style="{
                                color: checkColor(getStatusMovement().name),
                              }"
                              icon="fluent-mdl2:emoji-neutral"
                            />
                            <Icon
                              class="font-28"
                              v-if="getStatusMovement().name === 'C'"
                              :style="{
                                color: checkColor(getStatusMovement().name),
                              }"
                              icon="fluent-mdl2:emoji-disappointed"
                            />
                          </div>
                          <div>
                            <v-btn
                              depressed
                              dark
                              style="border-radius: 38px"
                              :color="checkColor(getStatusMovement().name)"
                              width="60px"
                            >
                              <span class="font-28 font-w-600">
                                {{ getStatusMovement().name }}
                              </span>
                            </v-btn>
                          </div>
                        </v-flex>
                      </v-layout>

                      <!-- Table -->
                      <table class="section_table">
                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.muscle_mass_points_criterion
                            ).color,
                            borderTopLeftRadius: '20px',
                            borderTopRightRadius: '20px',
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('มวลกล้ามเนื้อ')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-4.png"
                                width="11"
                                height="13"
                              />
                            </div>
                            <div>มวลกล้ามเนื้อ</div>
                          </td>
                          <td>{{ form.muscle_mass }}</td>
                          <td>{{ ">48" }}</td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(
                                    form.muscle_mass_points_criterion
                                  ).grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.muscle_mass_points_criterion
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(
                                    form.muscle_mass_points_criterion
                                  ).grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.grip_strength_right_criterion
                            ).color,
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('แรงบีบมือข้างขวา')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-4.png"
                                width="11"
                                height="13"
                              />
                            </div>
                            <div>แรงบีบมือ (ขวา)</div>
                          </td>
                          <td>{{ form.grip_strength_right }}</td>
                          <td>30</td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(
                                    form.grip_strength_right_criterion
                                  ).grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.grip_strength_right_criterion
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(
                                    form.grip_strength_right_criterion
                                  ).grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.grip_strength_left_criterion
                            ).color,
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('แรงบีบมือข้างซ้าย')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-4.png"
                                width="11"
                                height="13"
                              />
                            </div>
                            <div>แรงบีบมือ (ซ้าย)</div>
                          </td>
                          <td>{{ form.grip_strength_left }}</td>
                          <td>30</td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(
                                    form.grip_strength_left_criterion
                                  ).grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.grip_strength_left_criterion
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(
                                    form.grip_strength_left_criterion
                                  ).grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.single_leg_balance_criteria
                            ).color,
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('การทรงตัวยืนขาเดียว')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-4.png"
                                width="11"
                                height="13"
                              />
                            </div>
                            <div>ยืนขาเดียว</div>
                          </td>
                          <td>{{ form.single_leg_balance }}</td>
                          <td>60</td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(
                                    form.single_leg_balance_criteria
                                  ).grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.single_leg_balance_criteria
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(
                                    form.single_leg_balance_criteria
                                  ).grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.stand_up_test_criteria
                            ).color,
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('Stand Up Test')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-4.png"
                                width="11"
                                height="13"
                              />
                            </div>
                            <div>Stand Up Test</div>
                          </td>
                          <td>{{ form.stand_up_test }}</td>
                          <td>4</td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(
                                    form.stand_up_test_criteria
                                  ).grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.stand_up_test_criteria
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(
                                    form.stand_up_test_criteria
                                  ).grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.functional_test_criteria
                            ).color,
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('Functional Test')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-4.png"
                                width="11"
                                height="13"
                              />
                            </div>
                            <div>Functional Test</div>
                          </td>
                          <td>{{ form.functional_test }}</td>
                          <td>30</td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(
                                    form.functional_test_criteria
                                  ).grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.functional_test_criteria
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(
                                    form.functional_test_criteria
                                  ).grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.stability_criteria
                            ).color,
                            borderBottomLeftRadius: '20px',
                            borderBottomRightRadius: '20px',
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('2 Step Test')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-4.png"
                                width="11"
                                height="13"
                              />
                            </div>
                            <div>2 Step Test</div>
                          </td>
                          <td>{{ form.stability }}</td>
                          <td>1.54</td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(form.stability_criteria)
                                    .grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.stability_criteria
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(form.stability_criteria)
                                    .grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </v-flex>
                </v-layout>

                <!-- Section Table (การเคลื่อนไหว, ความยืดหยุ่น) -->
                <v-layout
                  style="display: flex; align-items: start; margin-top: 1rem"
                >
                  <v-flex xs6 style="margin-top: -165px; margin-right: 21px">
                    <!-- Table ความสมดุลร่างกาย -->
                    <div class="section_right_table">
                      <!-- Topic table -->
                      <v-layout class="section_right_title">
                        <v-flex xs6 class="d-flex align-items-center">
                          <div style="margin-right: 10px">
                            <img
                              class="icon"
                              src="../assets/images/report-pdf/balance.png"
                              width="15px"
                              height="25px"
                            />
                          </div>
                          <div class="font-w-700">ความสมดุลร่างกาย</div>
                        </v-flex>
                        <v-flex xs6 class="d-flex justify-content-end">
                          <div style="margin-right: 10px; padding-top: 5px">
                            <Icon
                              class="font-28"
                              v-if="getStatusPhysicalBalance().name === 'A'"
                              :style="{
                                color: checkColor(
                                  getStatusPhysicalBalance().name
                                ),
                              }"
                              icon="fluent-mdl2:emoji"
                            />
                            <Icon
                              class="font-28"
                              v-if="getStatusPhysicalBalance().name === 'B'"
                              :style="{
                                color: checkColor(
                                  getStatusPhysicalBalance().name
                                ),
                              }"
                              icon="fluent-mdl2:emoji-neutral"
                            />
                            <Icon
                              class="font-28"
                              v-if="getStatusPhysicalBalance().name === 'C'"
                              :style="{
                                color: checkColor(
                                  getStatusPhysicalBalance().name
                                ),
                              }"
                              icon="fluent-mdl2:emoji-disappointed"
                            />
                          </div>
                          <div>
                            <v-btn
                              depressed
                              dark
                              style="border-radius: 38px"
                              :color="
                                checkColor(getStatusPhysicalBalance().name)
                              "
                              width="60px"
                            >
                              <span class="font-28 font-w-600">
                                {{ getStatusPhysicalBalance().name }}
                              </span>
                            </v-btn>
                          </div>
                        </v-flex>
                      </v-layout>

                      <!-- Table -->
                      <table class="section_table">
                        <tr
                          style="border-top-right-radius: 20px"
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.bmi_criterion
                            ).color,
                            borderTopLeftRadius: '20px',
                            borderTopRightRadius: '20px',
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('ดัชนีมวลกาย')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-4.png"
                                width="11"
                                height="13"
                              />
                            </div>
                            <div>BMI</div>
                          </td>
                          <td>{{ form.bmi }}</td>
                          <td>18.5-24.9</td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(form.bmi_criterion)
                                    .grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.bmi_criterion
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(form.bmi_criterion).grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.blood_pressure_criterion
                            ).color,
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="
                                checkSelected('ความดันโลหิตต่ำสุด') ||
                                checkSelected('ความดันโลหิตสูงสุด')
                              "
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-4.png"
                                width="11"
                                height="13"
                              />
                            </div>
                            <div>ความดันโลหิต</div>
                          </td>
                          <td>
                            {{ form.blood_pressure_min }}/{{
                              form.blood_pressure_max
                            }}
                          </td>
                          <td>129-101/79-61</td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(
                                    form.blood_pressure_criterion
                                  ).grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.blood_pressure_criterion
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(
                                    form.blood_pressure_criterion
                                  ).grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.visceral_fat_level_criterion
                            ).color,
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('ระดับไขมันในช่องท้อง')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-4.png"
                                width="11"
                                height="13"
                              />
                            </div>
                            <div>ไขมันในช่องท้อง</div>
                          </td>
                          <td>{{ form.visceral_fat_level }}</td>
                          <td>9.5/น้อยกว่า</td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(
                                    form.visceral_fat_level_criterion
                                  ).grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.visceral_fat_level_criterion
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(
                                    form.visceral_fat_level_criterion
                                  ).grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.body_fat_rate_criterion
                            ).color,
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('ระดับไขมันในร่างกาย')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-4.png"
                                width="11"
                                height="13"
                              />
                            </div>
                            <div>ไขมันในร่างกาย</div>
                          </td>
                          <td>{{ form.body_fat_rate }}</td>
                          <td>18~22</td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(
                                    form.body_fat_rate_criterion
                                  ).grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.body_fat_rate_criterion
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(
                                    form.body_fat_rate_criterion
                                  ).grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.body_water_content_criteria
                            ).color,
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('ระดับน้ำในร่างกาย')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-4.png"
                                width="11"
                                height="13"
                              />
                            </div>
                            <div>น้ำในร่างกาย</div>
                          </td>
                          <td>{{ form.body_water_content }}</td>
                          <td>55~65</td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(
                                    form.body_water_content_criteria
                                  ).grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.body_water_content_criteria
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(
                                    form.body_water_content_criteria
                                  ).grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.basal_metabolic_rate_criterion
                            ).color,
                            borderBottomLeftRadius: '20px',
                            borderBottomRightRadius: '20px',
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('อัตราการเผาผลาญ')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-4.png"
                                width="11"
                                height="13"
                              />
                            </div>
                            <div>อัตราเผาผลาญ</div>
                          </td>
                          <td>{{ form.basal_metabolic_rate }}</td>
                          <td>1530</td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(
                                    form.basal_metabolic_rate_criterion
                                  ).grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.basal_metabolic_rate_criterion
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(
                                    form.basal_metabolic_rate_criterion
                                  ).grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </v-flex>
                  <v-flex xs6 style="padding-right: 10px">
                    <!-- Table ความยืดหยุ่น -->
                    <div class="section_right_table">
                      <!-- Topic table -->
                      <v-layout class="section_right_title">
                        <v-flex xs6 class="d-flex align-items-center">
                          <div style="margin-right: 10px">
                            <img
                              class="icon"
                              src="../assets/images/report-pdf/flexibility.png"
                              width="25px"
                              height="25px"
                            />
                          </div>
                          <div class="font-w-700">ความยืดหยุ่น</div>
                        </v-flex>
                        <v-flex xs6 class="d-flex justify-content-end">
                          <div style="margin-right: 10px; padding-top: 5px">
                            <Icon
                              class="font-28"
                              v-if="getStatusFlexibility().name === 'A'"
                              :style="{
                                color: checkColor(getStatusFlexibility().name),
                              }"
                              icon="fluent-mdl2:emoji"
                            />
                            <Icon
                              class="font-28"
                              v-if="getStatusFlexibility().name === 'B'"
                              :style="{
                                color: checkColor(getStatusFlexibility().name),
                              }"
                              icon="fluent-mdl2:emoji-neutral"
                            />
                            <Icon
                              class="font-28"
                              v-if="getStatusFlexibility().name === 'C'"
                              :style="{
                                color: checkColor(getStatusFlexibility().name),
                              }"
                              icon="fluent-mdl2:emoji-disappointed"
                            />
                          </div>
                          <div>
                            <v-btn
                              depressed
                              dark
                              style="border-radius: 38px"
                              :color="checkColor(getStatusFlexibility().name)"
                              width="60px"
                            >
                              <span class="font-28 font-w-600">
                                {{ getStatusFlexibility().name }}
                              </span>
                            </v-btn>
                          </div>
                        </v-flex>
                      </v-layout>

                      <!-- Table -->
                      <table class="section_table">
                        <!-- todo this ตอนนี้ยังไม่มีเก็บความยืดหยุ่นของหัวไหล่แยกกัน -->
                        <!-- <tr
                      :style="{
                        backgroundColor: changeStatusToGrade(
                          form.flexibility_upper_criteria
                        ).color,
                      }"
                    >
                      <td style="position: relative">
                        <div
                          v-if="checkSelected('ความอ่อนตัวหัวไหล่')"
                          class="con_icon"
                          style="position: absolute; top: -2px; left: -2px"
                        >
                          <img
                            class="icon_1"
                            src="../assets/images/report-pdf/icon-next-4.png"
                            width="11"
                            height="13"
                          />
                        </div>
                        <div>ความยืดหยุ่นของหัวไหล่(ขวา)</div>
                      </td>
                      <td>{{ form.flexibility_upper }}</td>
                      <td>0以下</td>
                      <td>
                        <div
                          class="column_grade"
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.flexibility_upper_criteria
                            ).background,
                          }"
                        >
                          {{
                            changeStatusToGrade(form.flexibility_upper_criteria)
                              .grade
                          }}
                        </div>
                      </td>
                    </tr> -->

                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.flexibility_upper_criteria
                            ).color,
                            borderTopLeftRadius: '20px',
                            borderTopRightRadius: '20px',
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('ความอ่อนตัวหัวไหล่')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-4.png"
                                width="11"
                                height="13"
                              />
                            </div>
                            <div>หัวไหล่</div>
                          </td>
                          <td>{{ form.flexibility_upper }}</td>
                          <td>0</td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(
                                    form.flexibility_upper_criteria
                                  ).grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.flexibility_upper_criteria
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(
                                    form.flexibility_upper_criteria
                                  ).grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.flexibility_lower_left_criteria
                            ).color,
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('ความอ่อนตัวขาข้างซ้าย')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-4.png"
                                width="11"
                                height="13"
                              />
                            </div>
                            <div>ขา (ซ้าย)</div>
                          </td>
                          <td>{{ form.flexibility_lower_left }}</td>
                          <td>20</td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(
                                    form.flexibility_lower_left_criteria
                                  ).grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.flexibility_lower_left_criteria
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(
                                    form.flexibility_lower_left_criteria
                                  ).grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.flexibility_lower_right_criteria
                            ).color,
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('ความอ่อนตัวขาข้างขวา')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-4.png"
                                width="11"
                                height="13"
                              />
                            </div>
                            <div>ขา (ขวา)</div>
                          </td>
                          <td>{{ form.flexibility_lower_right }}</td>
                          <td>20</td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(
                                    form.flexibility_lower_right_criteria
                                  ).grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.flexibility_lower_right_criteria
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(
                                    form.flexibility_lower_right_criteria
                                  ).grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.flexibility_waist_criteria
                            ).color,
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('ความอ่อนตัวของหลัง')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-4.png"
                                width="11"
                                height="13"
                              />
                            </div>
                            <div>หลัง</div>
                          </td>
                          <td>{{ form.flexibility_waist }}</td>
                          <td>90</td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(
                                    form.flexibility_waist_criteria
                                  ).grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.flexibility_waist_criteria
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(
                                    form.flexibility_waist_criteria
                                  ).grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.posture_score_criteria
                            ).color,
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('คะแนนท่าทาง')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-4.png"
                                width="11"
                                height="13"
                              />
                            </div>
                            <div>คะแนนท่าทาง</div>
                          </td>
                          <td>{{ form.posture_score }}</td>
                          <td>50</td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(
                                    form.posture_score_criteria
                                  ).grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.posture_score_criteria
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(
                                    form.posture_score_criteria
                                  ).grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.posture_type_criteria
                            ).color,
                            borderBottomLeftRadius: '20px',
                            borderBottomRightRadius: '20px',
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('ปัญหาท่าทาง')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-4.png"
                                width="11"
                                height="13"
                              />
                            </div>
                            <div>ปัญหาท่าทาง</div>
                          </td>
                          <td>{{ form.posture_type }}</td>
                          <td>ปกติ</td>
                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(
                                    form.posture_type_criteria
                                  ).grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.posture_type_criteria
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(
                                    form.posture_type_criteria
                                  ).grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </v-flex>
                </v-layout>
                <!-- Section Table ระบบไหลเวียนโลหิตและหายใจ  -->
                <v-layout
                  style="display: flex; align-items: start; margin-top: 1rem"
                >
                  <v-flex xs6 style="margin-top: -165px; margin-right: 21px">
                    <!-- ระบบไหลเวียนโลหิตและหายใจ -->
                    <div class="section_right_table">
                      <!-- Topic table -->
                      <v-layout class="section_right_title">
                        <v-flex xs6 class="d-flex align-items-center">
                          <div style="margin-right: 10px">
                            <img
                              class="icon"
                              src="../assets/images/report-pdf/cardioVascularEndurance.png"
                              width="30px"
                              height="30px"
                            />
                          </div>
                          <div class="font-w-700">
                            ระบบไหลเวียนโลหิตและหายใจ
                          </div>
                        </v-flex>
                        <v-flex xs6 class="d-flex justify-content-end">
                          <div style="margin-right: 10px; padding-top: 5px">
                            <Icon
                              class="font-28"
                              v-if="getCardioVascularEndurance().name === 'A'"
                              :style="{
                                color: checkColor(
                                  getCardioVascularEndurance().name
                                ),
                              }"
                              icon="fluent-mdl2:emoji"
                            />
                            <Icon
                              class="font-28"
                              v-if="getCardioVascularEndurance().name === 'B'"
                              :style="{
                                color: checkColor(
                                  getCardioVascularEndurance().name
                                ),
                              }"
                              icon="fluent-mdl2:emoji-neutral"
                            />
                            <Icon
                              class="font-28"
                              v-if="getCardioVascularEndurance().name === 'C'"
                              :style="{
                                color: checkColor(
                                  getCardioVascularEndurance().name
                                ),
                              }"
                              icon="fluent-mdl2:emoji-disappointed"
                            />
                          </div>
                          <div>
                            <v-btn
                              depressed
                              dark
                              style="border-radius: 38px"
                              :color="
                                checkColor(getCardioVascularEndurance().name)
                              "
                              width="60px"
                            >
                              <span class="font-28 font-w-600">
                                {{ getCardioVascularEndurance().name }}
                              </span>
                            </v-btn>
                          </div>
                        </v-flex>
                      </v-layout>
                      <!-- Table -->
                      <table class="section_table">
                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.chair_stand_test_criterion
                            ).color,
                            borderTopLeftRadius: '20px',
                            borderTopRightRadius: '20px',
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('ทดสอบลุกจากเก้าอี้')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-4.png"
                                width="11"
                                height="13"
                              />
                            </div>
                            <div>ทดสอบลุกจากเก้าอี้</div>
                          </td>
                          <td>
                            {{ form.chair_stand_test }}
                          </td>

                          <td>
                            <div
                              class="d-flex justify-content-center align-center"
                            >
                              <div
                                :class="
                                  changeStatusToGrade(
                                    form.chair_stand_test_criterion
                                  ).grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.chair_stand_test_criterion
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(
                                    form.chair_stand_test_criterion
                                  ).grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr
                          :style="{
                            backgroundColor: changeStatusToGrade(
                              form.step_box_test_criterion
                            ).color,
                            borderBottomLeftRadius: '20px',
                            borderBottomRightRadius: '20px',
                          }"
                        >
                          <td style="position: relative">
                            <div
                              v-if="checkSelected('ทดสอบก้าวขึ้น-ลง')"
                              class="con_icon"
                              style="position: absolute; top: -2px; left: -2px"
                            >
                              <img
                                class="icon_1"
                                src="../assets/images/report-pdf/icon-next-4.png"
                                width="11"
                                height="13"
                              />
                            </div>
                            <div>ทดสอบก้าวขึ้น-ลง</div>
                          </td>
                          <td>{{ form.step_box_test }}</td>

                          <td>
                            <div class="d-flex justify-content-center">
                              <div
                                :class="
                                  changeStatusToGrade(
                                    form.step_box_test_criterion
                                  ).grade != 'N/A'
                                    ? 'column_grade'
                                    : 'column_grade_na'
                                "
                                :style="{
                                  backgroundColor: changeStatusToGrade(
                                    form.step_box_test_criterion
                                  ).background,
                                }"
                              >
                                {{
                                  changeStatusToGrade(
                                    form.step_box_test_criterion
                                  ).grade
                                }}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </v-flex>
                </v-layout>
              </v-flex>

              <!-- ความคิดเห็นของคุณ -->
              <v-flex xs3 class="section_right_right">
                <div
                  class="d-flex align-items-center"
                  style="margin-bottom: 5px"
                >
                  <div class="font-18 font-w-600">ความคาดหวังของคุณ</div>
                  <Icon
                    style="color: #55c5c8; margin-left: 5px"
                    class="font-20"
                    icon="bxs:message-square-detail"
                  />
                </div>

                <div class="box_message font-14">
                  <p v-if="form.comment">{{ form.comment }}</p>
                </div>

                <div
                  class="d-flex align-items-center"
                  style="margin-bottom: 5px; margin-top: 3.5rem"
                >
                  <div class="font-18 font-w-600">ข้อความจาก</div>
                  <img
                    style="margin-left: 5px"
                    src="../assets/images/logo.png"
                    width="76px"
                    height="29px"
                  />
                </div>

                <div style="height: 265px" class="box_message font-14">
                  <p v-if="form.message_pwp">{{ form.message_pwp }}</p>
                </div>
              </v-flex>
            </v-layout>

            <!-- Section Buttom -->
            <v-layout row wrap class="section_right_buttom">
              <!-- ผลลัพท์ความเสี่ยงสูง -->
              <v-flex xs3>
                <div style="margin-bottom: 32px" class="font-18 font-w-600">
                  ผลลัพท์ความเสี่ยงสูง
                </div>
                <div
                  v-for="(item, i) in form.measurements"
                  :key="i"
                  class="d-flex"
                >
                  <div class="con_box">
                    <div class="box_1 d-flex align-items-center">
                      <v-layout class="d-flex align-items-center">
                        <v-flex shrink style="margin-right: 10px">
                          <img
                            src="../assets/images/report-pdf/age.png"
                            width="40"
                            height="40"
                            v-if="
                              checkGroupMeasurement(item.measurement_name) ===
                              'bodyAge'
                            "
                          />
                          <img
                            src="../assets/images/report-pdf/balance.png"
                            width="25px"
                            height="40px"
                            v-if="
                              checkGroupMeasurement(item.measurement_name) ===
                              'physicalBalance'
                            "
                          />
                          <img
                            src="../assets/images/report-pdf/movement.png"
                            width="30"
                            height="30"
                            v-if="
                              checkGroupMeasurement(item.measurement_name) ===
                              'movement'
                            "
                          />
                          <img
                            src="../assets/images/report-pdf/flexibility.png"
                            width="40"
                            height="40"
                            v-if="
                              checkGroupMeasurement(item.measurement_name) ===
                              'flexibility'
                            "
                          />
                          <img
                            src="../assets/images/report-pdf/cardioVascularEndurance.png"
                            width="40"
                            height="40"
                            v-if="
                              checkGroupMeasurement(item.measurement_name) ===
                              'cardio'
                            "
                          />
                        </v-flex>
                        <v-flex>
                          {{ item.measurement_name }}
                        </v-flex>
                      </v-layout>
                    </div>
                    <div class="box_2"></div>
                  </div>
                  <div class="con_icon">
                    <img
                      class="icon_1"
                      src="../assets/images/report-pdf/icon-next-1.png"
                      width="33"
                      height="40"
                    />
                    <img
                      class="icon_2"
                      src="../assets/images/report-pdf/icon-next-2.png"
                      width="33"
                      height="40"
                    />
                  </div>
                </div>
              </v-flex>

              <!-- ผลการวัดวันนี้และความเสี่ยง -->
              <v-flex xs5 style="padding-left: 1rem">
                <div style="margin-bottom: 5px" class="font-18 font-w-600">
                  ผลการวัดวันนี้และความเสี่ยง
                  <div style="color: #a3a3a3">(อาการทั่วไป)</div>
                </div>

                <div
                  v-for="(item, i) in form.measurements"
                  :key="i"
                  class="d-flex"
                >
                  <div class="con_box" style="width: 84%">
                    <div class="box_1 d-flex align-items-center">
                      <v-layout>
                        <v-flex shrink>
                          <div
                            class="d-flex align-items-center justify-content-center"
                          >
                            <div
                              class="font-20 font-w-600 d-flex align-items-center justify-content-center"
                              :style="{
                                width: '26px',
                                height: '26px',
                                borderRadius: '100px',
                                color: '#fff',
                                backgroundColor: checkColor(
                                  item.grade_result_today
                                ),
                              }"
                            >
                              {{ item.grade_result_today }}
                            </div>
                            <span
                              class="font-24 font-w-600"
                              :style="{
                                color: checkColor(item.grade_result_today),
                                padding: '0px 5px',
                              }"
                            >
                              {{
                                item.result_today
                                  ? checkDecimal(item.result_today)
                                  : ""
                              }}
                            </span>
                          </div>
                        </v-flex>
                        <v-flex class="font-22" style="padding-left: 2px">
                          {{
                            getMeasurementMessage(
                              item.measurement_name,
                              item.grade_result_today,
                              "today"
                            ).msg
                          }}
                        </v-flex>
                      </v-layout>
                    </div>

                    <div class="box_2"></div>
                  </div>
                  <div class="con_icon">
                    <img
                      class="icon_1"
                      src="../assets/images/report-pdf/icon-next-1.png"
                      width="33"
                      height="40"
                    />
                    <img
                      class="icon_2"
                      src="../assets/images/report-pdf/icon-next-2.png"
                      width="33"
                      height="40"
                    />
                  </div>
                </div>
              </v-flex>

              <!-- ค่าเป้าหมายการปรับปรุงและวิธีปรับปรุง -->
              <v-flex xs4>
                <div style="margin-bottom: 5px" class="font-18 font-w-600">
                  <v-layout class="d-flex align-items-center">
                    <v-flex>
                      <img
                        width="37"
                        height="37"
                        src="../assets/images/report-pdf/goal.png"
                        alt=""
                      />
                    </v-flex>
                    <v-flex style="margin-right: -1rem">
                      ค่าเป้าหมายการปรับปรุงและวิธีปรับปรุง
                      <div style="color: #a3a3a3">(3 เดือนต่อมา)</div>
                    </v-flex>
                  </v-layout>
                </div>

                <div
                  v-for="(item, i) in form.measurements"
                  :key="i"
                  class="d-flex"
                >
                  <div class="con_box" style="width: 100%">
                    <div
                      class="box_1 d-flex"
                      style="
                        flex-direction: column;
                        align-items: start;
                        justify-content: center;
                      "
                    >
                      <div
                        class="d-flex align-items-center justify-content-center"
                      >
                        <div
                          class="font-20 font-w-600 d-flex align-items-center justify-content-center"
                          :style="{
                            width: '26px',
                            height: '26px',
                            borderRadius: '100px',
                            color: '#fff',
                            backgroundColor: checkColor(
                              item.grade_improve_target
                            ),
                          }"
                        >
                          {{ item.grade_improve_target }}
                        </div>

                        <div
                          class="font-24 font-w-600"
                          :style="{
                            color: checkColor(item.grade_improve_target),
                            padding: '0px 5px',
                          }"
                        >
                          {{
                            item.improve_target
                              ? checkDecimal(item.improve_target)
                              : ""
                          }}
                        </div>

                        <div
                          v-if="
                            getMeasurementMessage(
                              item.measurement_name,
                              item.grade_improve_target,
                              'target'
                            ).checked.length > 0
                          "
                        >
                          <v-layout class="d-flex">
                            <v-flex
                              shrink
                              v-for="(msg, m) in getMeasurementMessage(
                                item.measurement_name,
                                item.grade_improve_target,
                                'target'
                              ).checked"
                              :key="m"
                            >
                              <span
                                :style="{
                                  backgroundColor: checkColor(
                                    item.grade_improve_target
                                  ),
                                  color: '#fff',
                                  marginRight: '5px',
                                  padding: '0px 6px',
                                  borderRadius: '5px',
                                }"
                                class="font-13 font-w-400"
                              >
                                {{ msg == "คาร์ดิโอ" ? "คาร์ดิโอ" : msg }}
                              </span>
                            </v-flex>
                          </v-layout>
                        </div>
                      </div>
                      <div class="font-22 one_line">
                        {{
                          getMeasurementMessage(
                            item.measurement_name,
                            item.grade_improve_target,
                            "target"
                          ).msg
                        }}
                      </div>
                    </div>
                    <div class="box_2"></div>
                  </div>
                </div>
              </v-flex>

              <v-flex xs12 style="display: flex; justify-content: end">
                <span class="font-w-600" style="padding-right: 2rem">
                  {{ form.trainer_name }}
                </span>
                <span class="font-w-400">
                  {{ dateFormat(form.date) }}
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <!-- ด้านหลัง -->
        <div class="section" style="background-color: #fff" v-if="form">
          <v-layout>
            <!-- Section Left -->
            <v-flex xs8 class="back_left">
              <div
                style="font-size: 64px; margin-bottom: 1.5rem"
                class="font-w-600"
              >
                รายการตรวจวัด
              </div>

              <!-- Group 1 อายุร่างกาย -->
              <v-layout style="margin-bottom: 2.5rem">
                <v-flex xs3>
                  <div
                    class="font-18 font-w-600"
                    :style="{
                      color: checkColor(form.rank),
                      marginBottom: '10px',
                    }"
                  >
                    อายุร่างกาย
                  </div>
                  <div>หลอดเลือด</div>
                  <div>องค์ประกอบของร่างกาย</div>
                </v-flex>

                <v-flex xs4>
                  <div
                    class="font-18 font-w-600"
                    :style="{
                      color: checkColor(form.rank),
                      marginBottom: '10px',
                    }"
                  >
                    ค่ามาตราฐาน
                  </div>
                  <div>น้อยกว่าอายุจริง</div>
                  <div>น้อยกว่าอายุจริง</div>
                </v-flex>

                <v-flex xs5 style="margin-left: -2.5rem">
                  <div
                    class="font-18 font-w-600"
                    :style="{
                      color: checkColor(form.rank),
                      marginBottom: '10px',
                    }"
                  >
                    คำอธิบาย
                  </div>
                  <div style="margin-right: -10rem">
                    อายุของหลอดเลือดคำนวณจากความยืดหยุ่นของหลอดเลือด และอื่น ๆ
                  </div>
                  <div style="margin-right: -10rem; display: contents">
                    อายุองค์ประกอบร่างกายคำนวณจากความสมดุลขององค์ประกอบในร่างกาย
                  </div>
                </v-flex>
              </v-layout>

              <!-- Group 2 การเคลื่อนไหว -->
              <v-layout style="margin-bottom: 2.5rem">
                <v-flex xs3>
                  <div
                    class="font-18 font-w-600"
                    :style="{
                      color: checkColor(form.rank),
                      marginBottom: '10px',
                    }"
                  >
                    การเคลื่อนไหว
                  </div>
                  <div>มวลกล้ามเนื้อ</div>
                  <div>แรงบีบมือ</div>
                  <div>ยืนขาเดียว</div>
                  <div>Stand Up Test</div>
                  <div>Functional</div>
                  <div>2 Step Test</div>
                </v-flex>

                <v-flex xs4>
                  <div
                    class="font-18 font-w-600"
                    :style="{
                      color: checkColor(form.rank),
                      marginBottom: '10px',
                    }"
                  >
                    ค่ามาตราฐาน
                  </div>
                  <v-layout>
                    <v-flex xs7> > 48</v-flex>
                    <v-flex xs5>Kg.</v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs7> ≧ 30</v-flex>
                    <v-flex xs5>Kgw.</v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs7>60</v-flex>
                    <v-flex xs5>Sec.</v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs7>4</v-flex>
                    <v-flex xs5>Lv.</v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs7> ≧ 30</v-flex>
                    <v-flex xs5>Cm.</v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs7> ≧ 1.54</v-flex>
                    <v-flex xs5>Points</v-flex>
                  </v-layout>
                </v-flex>

                <v-flex xs5 style="margin-left: -2.5rem">
                  <div
                    class="font-18 font-w-600"
                    :style="{
                      color: checkColor(form.rank),
                      marginBottom: '10px',
                    }"
                  >
                    คำอธิบาย
                  </div>
                  <div style="margin-right: -10rem">
                    ค่าตัวเลขคะแนนคุณภาพของกล้ามเนื้อ
                  </div>
                  <div style="margin-right: -10rem">
                    ตัวเลขแสดงความแข็งแรงทั้งหมดของกล้ามเนื้อร่างกายส่วนบน
                  </div>
                  <div style="margin-right: -10rem">
                    ทดสอบความแข็งแรงกล้ามเนื้อส่วนล่างและการทรงตัว
                  </div>
                  <div style="margin-right: -10rem">
                    ทดสอบความแข็งแรงกล้ามเนื้อส่วนล่าง
                  </div>
                  <div style="margin-right: -10rem">
                    ตัวเลขแสดงความมั่นคงในขณะที่เคลื่อนไหว
                  </div>
                  <div style="margin-right: -10rem">
                    ทดสอบความแข็งแรงกล้ามเนื้อส่วนล่างและความยืดหยุ่นของหลัง
                  </div>
                </v-flex>
              </v-layout>

              <!-- Group 3 -->
              <v-layout style="margin-bottom: 2.5rem">
                <v-flex xs3>
                  <div
                    class="font-18 font-w-600"
                    :style="{
                      color: checkColor(form.rank),
                      marginBottom: '10px',
                    }"
                  >
                    ความสมดุลร่างกาย
                  </div>
                  <div>BMI</div>
                  <div>ความดันโลหิต</div>
                  <div>ระดับไขมันในช่องท้อง</div>
                  <div>ระดับไขมันในร่างกาย</div>
                  <div>ปริมาณน้ำในร่างกาย</div>
                  <div>อัตราเผาผลาญ</div>
                </v-flex>

                <v-flex xs4>
                  <div
                    class="font-18 font-w-600"
                    :style="{
                      color: checkColor(form.rank),
                      marginBottom: '10px',
                    }"
                  >
                    ค่ามาตราฐาน
                  </div>
                  <v-layout>
                    <v-flex xs7>18.5-24.9</v-flex>
                    <v-flex xs5>Kg./m2</v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs7>129-101/79-61</v-flex>
                    <v-flex xs5>mmHg.</v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs7>9.5 หรือน้อยกว่า</v-flex>
                    <v-flex xs5>LV.</v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs7>18-22</v-flex>
                    <v-flex xs5>%.</v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs7>55-65</v-flex>
                    <v-flex xs5>%.</v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs7> ≧ 1530</v-flex>
                    <v-flex xs5>kcal</v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs5 style="margin-left: -2.5rem">
                  <div
                    class="font-18 font-w-600"
                    :style="{
                      color: checkColor(form.rank),
                      marginBottom: '10px',
                    }"
                  >
                    คำอธิบาย
                  </div>
                  <div style="margin-right: -10rem">
                    ดัชนีที่แสดงถึงความสมดุลระหว่างความสูงและน้ำหนัก
                  </div>
                  <div style="margin-right: -10rem">
                    แรงดันของกระแสเลือดที่ส่งมาจากหัวใจกระทบกับผนังหลอดเลือดแดง
                  </div>
                  <div style="margin-right: -10rem">
                    ไขมันที่เกาะตามอวัยวะภายในช่องท้อง
                  </div>
                  <div style="margin-right: -10rem">ไขมันที่สะสมในร่างกาย</div>
                  <div style="margin-right: -10rem">
                    เปอร์เซ็นต์ของน้ำในร่างกายต่อน้ำหนักตัว
                  </div>
                  <div style="margin-right: -10rem">
                    ปริมาณพลังงานที่ถูกเผาผลาญโดยไม่ต้องทำอะไร
                  </div>
                </v-flex>
              </v-layout>

              <!-- Group 4 -->
              <v-layout style="margin-bottom: 2.5rem">
                <v-flex xs3>
                  <div
                    class="font-18 font-w-600"
                    :style="{
                      color: checkColor(form.rank),
                      marginBottom: '10px',
                    }"
                  >
                    ความยืดหยุ่น
                  </div>
                  <div>ความยืดหยุ่นของหัวไหล่</div>
                  <div>ความยืดหยุ่นของขา</div>
                  <div>ความยืดหยุ่นของหลัง</div>
                  <div>คะแนนท่าทาง</div>
                  <div>ปัญหาท่าทาง</div>
                </v-flex>

                <v-flex xs4>
                  <div
                    class="font-18 font-w-600"
                    :style="{
                      color: checkColor(form.rank),
                      marginBottom: '10px',
                    }"
                  >
                    ค่ามาตราฐาน
                  </div>
                  <v-layout>
                    <v-flex xs7>0 หรือน้อยกว่า</v-flex>
                    <v-flex xs5>Cm.</v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs7>≧ 0</v-flex>
                    <v-flex xs5>Cm.</v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs7>≧ 15</v-flex>
                    <v-flex xs5>°</v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs7>50</v-flex>
                    <v-flex xs5>pt.</v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs7>ปกติ</v-flex>
                    <v-flex xs5></v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs5 style="margin-left: -2.5rem">
                  <div
                    class="font-18 font-w-600"
                    :style="{
                      color: checkColor(form.rank),
                      marginBottom: '10px',
                    }"
                  >
                    คำอธิบาย
                  </div>
                  <div>ประเมินความยืดหยุ่นของกล้ามเนื้อหัวไหล่</div>
                  <div>ประเมินความยืดหยุ่นของกล้ามเนื้อส่วนล่าง</div>
                  <div>ประเมินความยืดหยุ่นและความอ่อนตัวของหลัง</div>
                  <div>การประเมินท่าทางโดยรวมออกมาเป็นคะแนนการตัดสิน</div>
                  <div
                    style="margin-right: -6rem; position: relative; z-index: 2"
                  >
                    ท่าทางตามประเภท (ปกติ, หลังค่อม, หลังแบน, หลังแอ่น,
                    หลังค่อม+หลังแอ่น)
                  </div>
                </v-flex>
              </v-layout>

              <!-- Group 5 -->
              <v-layout style="margin-bottom: 2.5rem">
                <v-flex xs3>
                  <div
                    class="font-18 font-w-600"
                    :style="{
                      color: checkColor(form.rank),
                      marginBottom: '10px',
                    }"
                  >
                    ระบบไหลเวียนโลหิตและหายใจ
                  </div>
                  <div>ทดสอบลุกจากเก้าอี้</div>
                  <div>ทดสอบก้าวขึ้น-ลง</div>
                </v-flex>

                <v-flex xs4>
                  <div
                    class="font-18 font-w-600"
                    :style="{
                      color: checkColor(form.rank),
                      marginBottom: '10px',
                    }"
                  >
                    ค่ามาตราฐาน
                  </div>
                  <v-layout>
                    <v-flex xs7>25</v-flex>
                    <v-flex xs5>rep</v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs7>92</v-flex>
                    <v-flex xs5>HR</v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs5 style="margin-left: -2.5rem">
                  <div
                    class="font-18 font-w-600"
                    :style="{
                      color: checkColor(form.rank),
                      marginBottom: '10px',
                    }"
                  >
                    คำอธิบาย
                  </div>
                  <div style="margin-right: -10rem">
                    ทดสอบความแข็งแรงกล้ามเนื้อส่วนล่างและความทนทานของปอดและหัวใจ
                  </div>
                  <div style="margin-right: -10rem">
                    ทดสอบการทรงตัวและความทนทานของปอดและหัวใจ
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>

            <!-- Section Right -->
            <v-flex xs4 class="back_right">
              <!-- Image model -->
              <img
                class="model-img"
                src="../assets/images/report-pdf/model-standard-back.png"
                v-if="form.rank === 'A'"
              />
              <img
                class="model-img"
                src="../assets/images/report-pdf/model-warn-back.png"
                v-else-if="form.rank === 'B'"
              />
              <img
                class="model-img"
                src="../assets/images/report-pdf/model-danger-back.png"
                v-else-if="form.rank === 'C'"
              />
              <img
                class="model-img"
                src="../assets/images/report-pdf/model-na-back.png"
                v-else
              />

              <v-layout class="back_detail">
                <v-flex>
                  <div class="map">
                    <img
                      v-if="form.rank === 'A'"
                      width="327"
                      height="189"
                      src="../assets/images/report-pdf/map-standard.png"
                      alt=""
                    />
                    <img
                      v-else-if="form.rank === 'B'"
                      width="327"
                      height="189"
                      src="../assets/images/report-pdf/map-warn.png"
                      alt=""
                    />
                    <img
                      v-else-if="form.rank === 'C'"
                      width="327"
                      height="189"
                      src="../assets/images/report-pdf/map-danger.png"
                      alt=""
                    />
                    <img
                      v-else
                      width="327"
                      height="189"
                      src="../assets/images/report-pdf/map-na.png"
                      alt=""
                    />
                  </div>

                  <div class="social">
                    <div class="line d-flex">
                      <img
                        width="30"
                        height="30"
                        src="../assets/images/report-pdf/line.png"
                        alt=""
                      />
                      <div class="font-14 font-w-500">@Powerpod</div>
                    </div>
                    <div class="facebook d-flex">
                      <img
                        width="30"
                        height="30"
                        src="../assets/images/report-pdf/facebook.png"
                        alt=""
                      />
                      <div class="font-14 font-w-500">
                        Powerpod Fitness Club ฟิตเนสฟื้นฟูสุขภาพ
                      </div>
                    </div>
                    <div class="twitter d-flex">
                      <img
                        width="30"
                        height="30"
                        src="../assets/images/report-pdf/twitter.png"
                        alt=""
                      />
                      <div class="font-14 font-w-500">Powerpod.Fitness</div>
                    </div>
                    <div class="instagram d-flex">
                      <img
                        width="30"
                        height="30"
                        src="../assets/images/report-pdf/instagram.png"
                        alt=""
                      />
                      <div class="font-14 font-w-500">Powerpod_fitness</div>
                    </div>
                  </div>

                  <div class="address">
                    <div class="d-flex" style="padding-left: 10px">
                      <Icon
                        :style="{ color: checkColor(form.rank) }"
                        icon="ph:phone-call-fill"
                      />
                      <div>092-7412239</div>
                    </div>
                    <div class="d-flex" style="padding-left: 10px">
                      <Icon
                        :style="{
                          color: checkColor(form.rank),
                          fontSize: '130px',
                          marginTop: '-29px',
                        }"
                        icon="mdi:map-marker"
                      />
                      <div>
                        สาขาศรีนครินทร์: สนามแบดมินตัน Bestminton ซ.
                        สวนนครินทร์หมู่บ้าน 2 ตรงข้ามหมู่บ้านอัญชัน แขวงหนองบอน
                        เขตประเวศ กรุงเทพมหานคร 10250
                        <br /><br />
                        สาขาพระโขนง: 39 ถ.สุขุมวิท 71 แขวง พระโขนงเหนือ เขตวัฒนา
                        10120
                      </div>
                    </div>
                    <div class="d-flex" style="padding-left: 20px">
                      <Icon
                        :style="{ color: checkColor(form.rank) }"
                        icon="material-symbols:train"
                      />
                      <div>BTS พระโขนง ทางออกที่ 3 (เดินประมาณ 7 นาที)</div>
                    </div>
                    <div class="d-flex" style="padding-left: 25px">
                      <Icon
                        :style="{ color: checkColor(form.rank) }"
                        icon="ic:baseline-access-time-filled"
                      />
                      <div>อังคาร-ศุกร์ 10:00-20:00 น.</div>
                    </div>
                    <div style="padding-left: 51px">
                      เสาร์-อาทิตย์ 10:00-19:00 น.
                    </div>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </div>

    <!-- <pre>{{ form }}</pre> -->

    <Loading color="#C71010" :active.sync="isLoading" :is-full-page="true" />
  </div>
</template>

<script>
import { Icon } from "@iconify/vue2";
import SERVICE from "../services/index";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import { body_positions } from "../services/Constants";
import { getMeasurementMessage } from "../plugins/global-func";

import VueHtml2pdf from "vue-html2pdf";

export default {
  components: {
    Icon,
    VueHtml2pdf,
    Loading,
  },
  data() {
    return {
      form: null,
      filename: "",
      body_positions,
      settings: null,
      isLoading: false,
    };
  },
  created() {
    this.isLoading = true;
    Promise.all([this.fetchSettings(), this.fetchData()])
      .then((res) => {
        console.log(res);
        this.isLoading = false;
      })
      .catch((err) => {
        this.isLoading = false;
      });
  },
  computed: {
    dateNow() {
      return moment().format("DD/MM/YYYY");
    },
  },
  methods: {
    TranslateTraget(msg) {
      const translations = {
        "ยืดกล้ามเนื้อ": "Stretching",
        "ฝึกแรงต้าน":	"Resistance",
        "คาร์ดิโอ":	"Cardio",
      };
      return translations[msg] || msg;
    },
    TranslateEngPosition(thaiName) {
    const translations = {
        "ศรีษะ": "Head",
        "สะบัก": "Scapula",
        "ไหล่": "Shoulder",
        "หลังส่วนบน": "Upper Back",
        "หลังส่วนล่าง": "Lower Back",
        "เอว": "Waist",
        "สะโพก": "Hip",
        "แขน": "Arm",
        "นิ้วมือ": "Fingers",
        "ข้อพับ": "Elbow",
        "ข้อมือ": "Wrist",
        "หน้าท้อง": "Abdomen",
        "หน้าอก": "Chest",
        "หลังขา": "Hamstring",
        "ต้นขา": "Thigh",
        "หัวเข่า": "Knee",
        "หน้าแข้ง": "Shin",
        "น่อง": "Calf",
        "ข้อเท้า": "Ankle",
        "นิ้วเท้า": "Toes"
    };

    return translations[thaiName] || thaiName;
},
    TranslateToEng(word){
      switch (word) {
        case "ความดันโลหิตต่ำสุด":
          return "Lowest Blood Pressure";
        case "ความดันโลหิตสูงสุด":
          return "Highest Blood Pressure";
        case "ดัชนีมวลกาย":
          return "BMI";
        case "อัตราการเผาผลาญ":
          return "Basal Metabolic Rate";
        case "ระดับไขมันในร่างกาย":
          return "Body Fat Rate";
        case "ระดับไขมันในช่องท้อง":
          return "Visceral Fat Level";
        case "แรงบีบมือข้างซ้าย":
          return "Grip Strength (Left)";
        case "แรงบีบมือข้างขวา":
          return "Grip Strength (Right)";
        case "มวลกล้ามเนื้อ":
          return "Muscle Mass";
        case "2 Step Test":
          return "2 Step Test";
          case "ความอ่อนตัวหัวไหล่":
          return "Flexibility of Shoulder";
        case "ความอ่อนตัวขาข้างซ้าย":
          return "Flexibility of Leg (Left)";
        case "ความอ่อนตัวขาข้างขวา":
          return "Flexibility of Leg (Right)";
        case "การทรงตัวยืนขาเดียว":
          return "Single Leg Balance";
        case "Stand Up Test":
          return "Stand Up Test";
        case "Functional Test":
          return "Functional Test";
        case "ความอ่อนตัวของหลัง":
          return "Flexibility of Back";
        case "ระดับน้ำในร่างกาย":
          return "Body Water Content";
        case "คะแนนท่าทาง":
          return "Posture Score";
        case "ทดสอบลุกจากเก้าอี้":
          return "Chair Stand Test";
        case "ทดสอบก้าวขึ้น-ลง":
          return "Step Box Test";
        case "ปัญหาท่าทาง":
          return "Posture Problem";
        default:
          return word;
      }
    },
    dateFormat(date){
      const newFormat = new Date(date);
      return moment(newFormat).format("DD/MM/YYYY");
    },
    async downloadPdf() {
      console.log("downloadPdf");
      this.$refs.html2Pdf.generatePdf();
    },
    onProgress(e) {
      if (e === 100) {
        this.$emit("onDownloadComplete");
      }
    },

    async fetchData() {
      const res = await SERVICE.customer.show(this.$route.params.id);
      if (res.status == 200) {
        this.form = (await res.history.length) > 0 ? res.history[0] : res.data;
      }

      return res;
    },
    async fetchSettings() {
      const { data } = await SERVICE.setting.index();
      this.settings = data;
      return data;
    },

    formatDate(date) {
      return date
        ? new Date(date).toLocaleDateString("th-TH", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })
        : "";
    },

    formatGender(gender) {
      if (gender) {
        if (gender === "M") {
          return "ชาย";
        } else {
          return "หญิง";
        }
      }
    },

    checkShowImage(positions, key) {
      let isShow = false;
      positions.forEach((e) => {
        if (e.question_7_position == key) {
          isShow = true;
        }
      });
      return isShow;
    },

    checkSymptom(item) {
      if (item) {
        switch (item.question_9_select_name) {
          case "เดือนที่แล้ว":
            return item.question_9_last_month + " เดือนที่แล้ว";
          case "สัปดาห์ที่ผ่านมา":
            return item.question_9_last_week + " สัปดาห์ที่ผ่านมา";
          default:
            return item.question_9_select_name;
        }
      }
    },

    checkExercise(item) {
      console.log(item);
      if (item) {
        if (item.question_12_often_exercise === "ทำประจำ ประมาณ") {
          return item.question_12_often_exercise_week + " วัน/สัปดาห์";
        } else {
          return item.question_12_often_exercise_month + " วัน/เดือน";
        }
      }
    },

    checkSmoke(item) {
      if (item) {
        if (item.question_13_smoke === "สูบ ประมาณ") {
          return item.question_13_smoke_day + " มวน/วัน";
        } else {
          return item.question_13_smoke_year + " ปีที่แล้ว";
        }
      }
    },

    checkDrink(item) {
      if (item) {
        if (item.question_14_alcohol === "ดื่ม ประมาณ") {
          return item.question_14_alcohol_week + " วัน/สัปดาห์";
        } else {
          return item.question_14_alcohol_month + " วัน/เดือน";
        }
      }
    },

    checkColor(grade) {
      if (grade) {
        switch (grade) {
          case "A":
            return "#44A04C";
          case "B":
            return "#FFBB02";
          case "C":
            return "#C71010";
          default:
            return "#000000";
        }
      }
    },

    checkColorBg(grade) {
      if (grade) {
        switch (grade) {
          case "A":
            return "#E0F3E8";
          case "B":
            return "#FFFBDF";
          case "C":
            return "#FFE3E3";
          default:
            return "#D2D2D2";
        }
      }
    },

    getMaxNameStatus(array) {
      // หาว่ามีค่าเป็น standard กี่ตัว และเก็บไว้ในตัวแปร countStandard
      let countStandard = {
        name: "A",
        count: 0,
        percent: 0,
      };
      countStandard.count = array.filter((e) => e === "standard").length;

      // หาว่ามีค่าเป็น warn กี่ตัว และเก็บไว้ในตัวแปร countWarn
      let countWarn = {
        name: "B",
        count: 0,
        percent: 0,
      };
      countWarn.count = array.filter((e) => e === "warn").length;

      // หาว่ามีค่าเป็น danger กี่ตัว และเก็บไว้ในตัวแปร countDanger
      let countDanger = {
        name: "C",
        count: 0,
        percent: 0,
      };
      countDanger.count = array.filter((e) => e === "danger").length;

      // เอาค่า countStandard มาเทียบกับ ค่า countWarn และ countDanger ถ้าอันมีค่ามากที่สุด ให้เก็บค่าและชื่อไว้ในตัวแปร max
      let max = countStandard;
      if (countWarn.count > max.count) {
        max = countWarn;
      }
      if (countDanger.count > max.count) {
        max = countDanger;
      }

      return {
        name: max.name,
        count: max.count,
        percent: ((max.count / array.length) * 100).toFixed(1),
      };
    },

    checkGrade(grade) {
      if (grade) {
        switch (grade) {
          case "standard":
            return "A";
          case "warn":
            return "B";
          case "danger":
            return "C";
          default:
            return "N/A";
        }
      }
    },

    getStatusBodyAge() {
      // // กำหนดตัวแปร = [vascular_age_criterion, body_age_criterion, lung_age_criteria, skin_age_criterion]
      // let bodyAge = [
      //   this.form.vascular_age,
      //   this.form.body_age,
      //   // this.form.lung_age,
      //   // this.form.skin_age,
      // ];

      // let age = this.form.age;

      // // หา avarage ของ bodyAge
      // let sum = 0;
      // for (let i = 0; i < bodyAge.length; i++) {
      //   sum += parseInt(bodyAge[i], 10); //don't forget to add the base
      // }
      // let avarage = sum / bodyAge.length;

      // // ถ้า avarage <= age ให้ return ค่า A
      // if (avarage <= age) {
      //   return "A";
      // }
      // // ถ้า avarage มากกว่า 1-5 ของ age ให้ return ค่า B
      // else if (avarage > age && avarage <= age + 5) {
      //   return "B";
      // }
      // // ถ้า avarage มากกว่า 5 ของ age ให้ return ค่า C
      // else if (avarage > age + 5) {
      //   return "C";
      // }
      let bodyAge = [this.form.vascular_age_criterion, this.form.body_age_criterion];
      let sum = 0;
      // standard = 3, warn = 2, danger = 1
      for (let i = 0; i < bodyAge.length; i++) {
        if (bodyAge[i] === "standard") {
          sum += 3;
        } else if (bodyAge[i] === "warn") {
          sum += 2;
        } else if (bodyAge[i] === "danger") {
          sum += 1;
        }
      }
      if (sum == 6) {
        return "A";
      }
      else if (sum >= 4 && sum <= 5) {
        return "B";
      }
      else if (sum < 4) {
        return "C";
      } else {
        return {
          name: "N/A",
        };
      }
    },

    getStatusPhysicalBalance() {
      // กำหนดตัวแปร = [bmi_criterion, blood_pressure_criterion, visceral_fat_level_criterion, body_fat_rate_criterion, body_water_content_criteria, basal_metabolic_rate_criterion]
      let physicalBalance = [
        this.form.bmi_criterion,
        this.form.blood_pressure_criterion,
        this.form.visceral_fat_level_criterion,
        this.form.body_fat_rate_criterion,
        this.form.body_water_content_criteria,
        this.form.basal_metabolic_rate_criterion,
      ];

      let sum = 0;
      // standard = 3, warn = 2, danger = 1
      for (let i = 0; i < physicalBalance.length; i++) {
        if (physicalBalance[i] === "standard") {
          sum += 3;
        } else if (physicalBalance[i] === "warn") {
          sum += 2;
        } else if (physicalBalance[i] === "danger") {
          sum += 1;
        }
      }
      // ถ้า sum >= 16 และ <= 18 ให้ return ค่า A
      if (sum >= 16 && sum <= 18) {
        return {
          name: "A",
        };
      }
      // ถ้า sum >= 12 และ <= 15 ให้ return ค่า B
      else if (sum >= 12 && sum <= 15) {
        return {
          name: "B",
        };
      }
      // ถ้า sum < 12 ให้ return ค่า C
      else if (sum < 12) {
        return {
          name: "C",
        };
      } else {
        return {
          name: "N/A",
        };
      }
    },

    getStatusMovement() {
      // กำหนดตัวแปร = [muscle_mass_points_criterion, grip_strength_right_criterion, grip_strength_left_criterion, back_strength_criteria, single_leg_balance_criteria, stand_up_test_criteria, functional_test_criteria, stability_criteria]
      let movement = [
        this.form.muscle_mass_points_criterion,
        this.form.grip_strength_right_criterion,
        this.form.grip_strength_left_criterion,
        this.form.back_strength_criteria,
        this.form.single_leg_balance_criteria,
        this.form.stand_up_test_criteria,
        this.form.functional_test_criteria,
        this.form.stability_criteria,
      ];

      let sum = 0;
      // standard = 3, warn = 2, danger = 1
      for (let i = 0; i < movement.length; i++) {
        if (movement[i] === "standard") {
          sum += 3;
        } else if (movement[i] === "warn") {
          sum += 2;
        } else if (movement[i] === "danger") {
          sum += 1;
        }
      }

      // ถ้า sum >= 22 และ <= 24 ให้ return ค่า A
      if (sum >= 22 && sum <= 24) {
        return {
          name: "A",
        };
      }
      // ถ้า sum >= 16 และ <= 21 ให้ return ค่า B
      else if (sum >= 16 && sum <= 21) {
        return {
          name: "B",
        };
      }
      // ถ้า sum < 16 ให้ return ค่า C
      else if (sum < 16) {
        return {
          name: "C",
        };
      } else {
        return {
          name: "N/A",
        };
      }
    },

    getStatusFlexibility() {
      // กำหนดตัวแปร = [flexibility_lower_criteria, flexibility_upper_criteria, flexibility_waist_criteria, posture_score_criteria, posture_type_criteria]
      let flexibility = [
        this.form.flexibility_lower_criteria,
        this.form.flexibility_upper_criteria,
        this.form.flexibility_waist_criteria,
        this.form.posture_score_criteria,
        this.form.posture_type_criteria,
      ];

      let sum = 0;
      // standard = 3, warn = 2, danger = 1
      for (let i = 0; i < flexibility.length; i++) {
        if (flexibility[i] === "standard") {
          sum += 3;
        } else if (flexibility[i] === "warn") {
          sum += 2;
        } else if (flexibility[i] === "danger") {
          sum += 1;
        }
      }

      // ถ้า sum >= 15 และ <= 18 ให้ return ค่า A
      if (sum >= 15 && sum <= 18) {
        return {
          name: "A",
        };
      }
      // ถ้า sum >= 12 และ <= 15 ให้ return ค่า B
      else if (sum >= 12 && sum <= 14) {
        return {
          name: "B",
        };
      }
      // ถ้า sum < 12 ให้ return ค่า C
      else if (sum < 12) {
        return {
          name: "C",
        };
      } else {
        return {
          name: "N/A",
        };
      }
    },
    getCardioVascularEndurance() {
      let cardio = [
        this.form.step_box_test_criterion,
        this.form.chair_stand_test_criterion,
      ];

      let sum = 0;
      // standard = 3, warn = 2, danger = 1
      for (let i = 0; i < cardio.length; i++) {
        if (cardio[i] === "standard") {
          sum += 3;
        } else if (cardio[i] === "warn") {
          sum += 2;
        } else if (cardio[i] === "danger") {
          sum += 1;
        }
      }
      // ถ้า sum >= 16 และ <= 18 ให้ return ค่า A
      if (sum >= 5 && sum <= 6) {
        return {
          name: "A",
        };
      }
      // ถ้า sum >= 12 และ <= 15 ให้ return ค่า B
      else if (sum >= 3 && sum <= 4) {
        return {
          name: "B",
        };
      }
      // ถ้า sum < 12 ให้ return ค่า C
      else if (sum < 3) {
        return {
          name: "C",
        };
      } else {
        return {
          name: "N/A",
        };
      }
    },

    showPositionPart(item) {
      let status = false;

      if (this.form.problematic_part === null) {
        return status;
      }
      this.form.problematic_part.forEach((e) => {
        if (e.position_problem === item.name) {
          status = true;
        }
      });

      return status;
    },

    changeStatusToGrade(status) {
      // standard = A , warn = B , danger = C
      let reault = {
        grade: "",
        color: "",
        background: "",
      };
      if (status === "standard") {
        reault.grade = "A";
        reault.color = "#D1EEDE";
        reault.background = "#44A04C";
      } else if (status === "warn") {
        reault.grade = "B";
        reault.color = "#FDF6BF";
        reault.background = "#FFBB02";
      } else if (status === "danger") {
        reault.grade = "C";
        reault.color = "#FFDBDB";
        reault.background = "#C71010";
      } else {
        reault.grade = "N/A";
        reault.color = "#E5E5E5";
        reault.background = "#868686";
      }

      return reault;
    },

    checkDecimal(value) {
      return parseInt(value);
    },

    getMeasurementMessage(key, grade, type) {
      return getMeasurementMessage(this.settings, key, grade, type);
    },

    checkGroupMeasurement(key) {
      // จัดกลุ่มตัวชี้วัด
      const bodyAge = [
        "อายุหลอดเลือด",
        "อายุองค์ประกอบของร่างกาย",
        "อายุของปอด (ความจุปอด)",
        "อายุผิว",
      ];
      const physicalBalance = [
        "ดัชนีมวลกาย",
        "ความดันโลหิตต่ำสุด",
        "ความดันโลหิตสูงสุด",
        "ระดับไขมันในช่องท้อง",
        "ระดับไขมันในร่างกาย",
        "ระดับน้ำในร่างกาย",
        "อัตราการเผาผลาญ",
      ];
      const movement = [
        "มวลกล้ามเนื้อ",
        "แรงบีบมือข้างซ้าย",
        "แรงบีบมือข้างขวา",
        "ความแข็งแรงกล้ามเนื้อหลัง",
        "การทรงตัวยืนขาเดียว",
        "Stand Up Test",
        "Functional Test",
        "2 Step Test",
      ];
      const flexibility = [
        "ความอ่อนตัวหัวไหล่",
        "ความอ่อนตัวหัวเข่า",
        "ความอ่อนตัวของหลัง",
        "คะแนนท่าทาง",
        "ปัญหาท่าทาง",
        "ความอ่อนตัวขาข้างซ้าย",
        "ความอ่อนตัวขาข้างขวา",
      ];
      const cardio = [
        "ทดสอบลุกจากเก้าอี้",
        "ทดสอบก้าวขึ้น-ลง",
      ];

      if (bodyAge.includes(key)) {
        return "bodyAge";
      } else if (physicalBalance.includes(key)) {
        return "physicalBalance";
      } else if (movement.includes(key)) {
        return "movement";
      } else if (flexibility.includes(key)) {
        return "flexibility";
      } else if (cardio.includes(key)) {
        return "cardio";
      } else {
        return "other";
      }
    },

    checkSelected(key) {
      // ตรวจสอบว่ามีการเลือกตัวชี้วัดหรือไม่
      let status = false;
      if (this.form.measurements === null) {
        return status;
      }
      this.form.measurements.forEach((e) => {
        if (e.measurement_name === key) {
          status = true;
        }
      });

      return status;
    },

    checkNoBodyPosition(position) {
      // find position in body_positions array
      const body = this.body_positions.find((e) => e.name === position);

      return body ? body.no : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.section {
  // position: relative;
  height: 1120px;
  width: 100%;
  // height: 792px;
  // border-bottom: 1px solid rgb(204, 204, 204);
  .section_left {
    padding: 0px 1.8rem;
    position: relative;
    z-index: 1;
    // width: 424.5px;
    display: flex;
    width: 850px;
    justify-content: start;
    flex-direction: column;
    background-color: #fff;

    .model-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1120px;
      z-index: 0;
    }
    .center_line {
      position: absolute;
      top: 610px;
      left: 0;
      height: 5px;
      width: 100%;
      z-index: -1;
      background: #e5e5e5;
    }
    .section_grade_card {
      margin-top: 3rem;
      display: flex;
      // justify-content: end;
      height: 212px;
      padding-left: 13rem;
      .section_grade_item {
        position: relative;
        z-index: 2;
        display: flex;
        justify-content: start;
        align-items: center;
        margin-bottom: 18px;
        height: 35px;
        .__img {
          margin-right: 10px;
          width: 25px;
          height: 25px;
          img {
            width: 25px;
            height: 25px;
          }
        }
        .box__card {
          width: 177px;
          margin-right: 2px;
          .__card {
            width: 177px;
            height: 40px;
            border-radius: 10px;
            padding: 0px 7px;
            color: #fff;
            font-weight: 700;
            font-size: 13px;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }

        .__emoji {
          padding-top: 10px;
          font-size: 38px;
          width: 38px;
          margin: 0px 5px;
        }
        .__grade {
          font-size: 45px;
          font-weight: 700;
        }
        .text-standard {
          color: #00bfa5;
          font-weight: 700;
          font-size: 45px;
        }
        .text-warn {
          color: #ffc107;
          font-weight: 700;
          font-size: 45px;
        }
        .text-danger {
          color: #ff9800;
          font-weight: 700;
          font-size: 45px;
        }
      }
    }

    .section_name {
      position: relative;
      display: flex;
      // justify-content: end;
      // width: 440px;
      z-index: 3;
      margin-top: 7.6rem;
      padding-left: 11rem;
      .first_name {
        font-size: 40px;
        font-weight: 700;
        color: #000;
        z-index: 3;
      }
      .family_name {
        font-size: 40px;
        font-weight: 700;
        color: #000;
        margin-top: -1rem;
        z-index: 3;
      }
    }

    .section_info {
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: start;
      flex-direction: column;
      width: 440px;
      padding-left: 1rem;
      margin-top: 1rem;
      .section_info_item {
        margin-bottom: 5px;
        .section_info_key {
          font-size: 13px;
        }
        .section_info_val {
          font-size: 13px;
          font-weight: 700;
          margin-left: 5px;
        }
        .section_info_grade {
          margin-left: -12px;
        }
      }
    }

    .section_body_part {
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: start;
      padding-left: 1rem;
      flex-direction: column;
      margin-top: 10px;
      .body_model {
        position: relative;
        margin-top: 20px;
        padding-left: 10px;
        .part_position {
          position: absolute;
          top: 0;
          left: 10px;
          z-index: 1;
        }
      }

      .body_part_item {
        margin-bottom: 12px;
        .body_part_name {
          border: 1px solid #c71010;
          border-radius: 5px;
          font-size: 13px;
          padding-left: 3px;
          margin: 0px 5px;
        }
      }
    }
  }

  .section_right {
    position: relative;
    z-index: 1;
    width: 100%;
    min-width: 1000px;
    // width: 424.5px;
    margin-left: -1rem;
    padding-right: 10px;

    .section_right_left {
      padding: 2rem 1rem 1rem 0px;
      background: #fff;
      border-bottom-right-radius: 20px;
    }
    .section_right_right {
      padding: 2rem 0.5rem 0px 0.5rem;

      .box_message {
        background: #ffffff;
        border: 1px solid #a3a3a3;
        border-radius: 20px;
        height: 190px;
        overflow: auto;
        padding: 10px;
      }
    }

    .section_right_table {
      .section_right_title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 35px;
      }
      .section_table {
        width: 100%;
        border-radius: 20px;
        margin-top: 5px;
        // overflow: hidden;
        border-collapse: separate;
        border-spacing: 0;

        table {
          border-collapse: separate;
          border-spacing: 0;
        }

        td {
          border: solid 0px #000;
          border-style: none solid solid none;
          padding: 10px;
          font-size: 14px !important;
        }

        tr:first-child td:first-child {
          border-top-left-radius: 10px !important;
        }
        tr:first-child td:last-child {
          border-top-right-radius: 10px !important;
        }

        tr:last-child td:first-child {
          border-bottom-left-radius: 10px !important;
        }
        tr:last-child td:last-child {
          border-bottom-right-radius: 10px !important;
        }

        tr:first-child td {
          border-top-style: solid;
        }
        tr td:first-child {
          border-left-style: solid;
        }

        tr td {
          font-size: 12px;
          padding: 6px 10px;

          .column_grade {
            width: 18px;
            height: 18px;
            border-radius: 100%;
            text-align: center;
            color: #fff;
            font-weight: 600;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .column_grade_na {
            width: 34px;
            height: 18px;
            border-radius: 20px;
            text-align: center;
            color: #fff;
            font-weight: 600;
          }
        }
        .con_icon {
          position: absolute;
          top: -2px;
          left: -3px;
          .icon_1 {
            position: absolute;
            top: 10px;
            left: -2px;
            z-index: 1;
          }
          .icon_2 {
            position: absolute;
            top: 11px;
            left: 0px;
            z-index: 0;
          }
        }
      }
    }

    .section_right_buttom {
      background-color: #fff;
      margin-top: 5px;
      padding: 1rem 1.5rem 1rem 1rem;
      height: 506px;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      .con_box {
        position: relative;
        width: 198px;
        height: 81px;
        margin-bottom: 15px;
        .box_1 {
          position: absolute;
          background: #ffffff;
          border: 1.5px solid #000000;
          border-radius: 10px;
          z-index: 1;
          width: 100%;
          height: 100%;
          padding: 0px 2px 0px 10px;
          font-weight: 600;
          font-size: 20px;
        }
        .box_2 {
          position: absolute;
          background: #000000;
          border: 1.5px solid #000000;
          border-radius: 10px;
          z-index: 0;
          width: 100%;
          height: 100%;
          top: 5px;
          left: 5px;
        }
      }
      .con_icon {
        position: relative;
        padding-top: 1rem;
        .icon_1 {
          position: absolute;
          top: 20px;
          left: 17px;
          z-index: 1;
        }
        .icon_2 {
          position: absolute;
          top: 25px;
          left: 21px;
          z-index: 0;
        }
      }
    }
  }

  .back_left {
    position: relative;
    z-index: 10;
    padding: 2rem 0rem 2rem 3.6rem;
    .layout {
      // margin-bottom: 3.6rem;
    }
  }
  .back_right {
    padding: 0px 1.8rem;
    position: relative;
    z-index: 1;
    // width: 424.5px;
    display: flex;
    // width: 600px;
    // height: 1120px;
    justify-content: start;
    flex-direction: column;
    background-color: #fff;

    .model-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1120px;
      z-index: 0;
    }

    .back_detail {
      position: relative;
      height: 1120px;
      z-index: 10;
      display: flex;
      align-items: end;
      padding-bottom: 1.5rem;
      .social {
        margin-top: 1.8rem;
        padding-left: 1.5rem;
        .line {
          padding-left: 3.6rem;
          margin-bottom: 1rem;
          align-items: center;
          img {
            margin-right: 5px;
          }
        }
        .facebook {
          padding-left: 3.8rem;
          margin-bottom: 1rem;
          align-items: center;
          img {
            margin-right: 5px;
          }
        }
        .twitter {
          padding-left: 4rem;
          margin-bottom: 1rem;
          align-items: center;
          img {
            margin-right: 5px;
          }
        }
        .instagram {
          padding-left: 4.2rem;
          margin-bottom: 1rem;
          align-items: center;
          img {
            margin-right: 5px;
          }
        }
      }
      .map {
      }
      .address {
        margin-top: 2rem;
        margin-bottom: 5rem;
        padding-left: 6rem;
        font-size: 13px;
        font-weight: 500;
        .d-flex {
          margin-bottom: 15px;
        }
        svg {
          font-size: 18px;
          margin-right: 8px;
        }
      }
    }
  }

  .one_line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .d-flex {
    display: flex;
  }
  .justify-content-center {
    justify-content: center;
  }
  .justify-content-end {
    justify-content: flex-end;
  }
  .justify-content-start {
    justify-content: flex-start;
  }
  .align-items-center {
    align-items: center;
  }
  .flex-column {
    flex-direction: column;
  }
  .flex-wrap {
    flex-wrap: wrap;
  }
  .flex-shrink-0 {
    flex-shrink: 0;
  }
  .flex-grow-1 {
    flex-grow: 1;
  }
  .flex-grow-0 {
    flex-grow: 0;
  }
  .flex-fill {
    flex: 1 1 auto;
  }
  .mb7 {
    margin-bottom: 7px;
  }
}

// for pdf content
.pdf-content {
  width: 100%;
  background: #fff;
  padding: 30px;

  .box-header {
    width: 100%;
    .card-box-header {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: #55c5c8;
      border: 1px solid #000000;
      border-radius: 20px;
      color: #fff;
      margin-bottom: 1rem;
    }
  }
  .card-info {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .box-info {
      width: 60%;
      display: flex;
      justify-content: space-between;
    }
  }
  .section-card {
    border: 1px solid #000;
    border-radius: 30px;
    padding: 1rem 0px;
    margin-bottom: 1rem;
  }

  .d-flex {
    display: flex;
  }
  .justify-content-center {
    justify-content: center;
  }
  .align-items-center {
    align-items: center;
  }
  .flex-column {
    flex-direction: column;
  }
  .flex-wrap {
    flex-wrap: wrap;
  }
  .flex-shrink-0 {
    flex-shrink: 0;
  }
  .flex-grow-1 {
    flex-grow: 1;
  }
  .flex-grow-0 {
    flex-grow: 0;
  }
  .flex-fill {
    flex: 1 1 auto;
  }
  .mb7 {
    margin-bottom: 7px;
  }
}
.width-100 {
  width: 100%;
}
</style>
