<template>
  <div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :filename="filename"
      :paginate-elements-by-height="1500"
      :pdf-quality="2"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      :manual-pagination="false"
      @progress="onProgress($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content" v-if="form">
        <!-- information -->
        <div style="height: 1120px; position: relative">
          <div class="page_number">1/{{ pageTotal }}</div>
          <div class="pdf-content">
            <div class="box-header">
              <div class="shadow_border"></div>
              <div class="card-box-header">
                <div class="font-24">ส่งแบบสอบถาม</div>
                <div class="font-36 font-w-600">เรียบร้อย!</div>
              </div>
            </div>

            <div class="section_card">
              <div class="shadow_border"></div>

              <div class="main_card">
                <!-- info -->
                <div style="padding: 1.5rem 4rem">
                  <v-layout justify-space-between>
                    <v-flex xs9>
                      <v-layout wrap>
                        <v-flex xs4 class="mb7">
                          <div class="font-20 font-w-600">ชื่อ</div>
                        </v-flex>
                        <v-flex xs8 class="mb7">
                          <div class="font-20 font-w-400">
                            {{ form.first_name }}
                          </div>
                        </v-flex>

                        <v-flex xs4 class="mb7">
                          <div class="font-20 font-w-600">นามสกุล</div>
                        </v-flex>
                        <v-flex xs8 class="mb7">
                          <div class="font-20 font-w-400">
                            {{ form.last_name }}
                          </div>
                        </v-flex>

                        <v-flex xs4 class="mb7">
                          <div class="font-20 font-w-600">ชื่อเล่น</div>
                        </v-flex>
                        <v-flex xs8 class="mb7">
                          <div class="font-20 font-w-400">
                            {{ form.nickname }}
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs3 style="padding-left: 2rem">
                      <img
                        class="image"
                        src="../assets/images/step1-info.png"
                        width="60"
                      />
                    </v-flex>
                  </v-layout>
                </div>

                <v-divider />

                <!-- วันเกิด -->
                <div style="padding: 1.5rem 4rem">
                  <v-layout>
                    <v-flex xs9>
                      <div class="font-20 font-w-600 mb7">วันเกิด</div>
                      <div class="font-20 font-w-400">
                        {{ formatDate(form.birthdate) }}
                      </div>
                    </v-flex>
                    <v-flex xs3 style="padding-left: 2rem">
                      <img
                        src="../assets/images/step2-birthdate.png"
                        width="60"
                      />
                    </v-flex>
                  </v-layout>
                </div>

                <v-divider />

                <!-- เพศ -->
                <div style="padding: 1.5rem 4rem">
                  <v-layout>
                    <v-flex xs9>
                      <div class="font-20 font-w-600 mb7">เพศ</div>
                      <div class="font-20 font-w-400">
                        {{ formatGender(form.gender) }}
                      </div>
                    </v-flex>
                    <v-flex xs3 style="padding-left: 2rem">
                      <Icon
                        v-if="form.gender == 'F'"
                        style="font-size: 3rem"
                        icon="ph:gender-female-bold"
                      />
                      <Icon
                        v-else
                        style="font-size: 3rem"
                        icon="ph:gender-male-bold"
                      />
                    </v-flex>
                  </v-layout>
                </div>
                <v-divider />

                <!-- พื้นที่อยู่อาศัย -->
                <div style="padding: 1.5rem 4rem">
                  <v-layout>
                    <v-flex xs9>
                      <div class="font-20 font-w-600 mb7">พื้นที่อยู่อาศัย</div>
                      <div class="font-20 font-w-400">
                        {{ form.postcode }} {{ form.province_name }},
                        {{ form.district_name }}
                      </div>
                    </v-flex>
                    <v-flex xs3 style="padding-left: 2rem">
                      <img src="../assets/images/step4-home.png" width="60" />
                    </v-flex>
                  </v-layout>
                </div>
                <v-divider />

                <!-- สาเหตุที่คิดจะ "ตรวจอายุ ร่างกาย" ในครั้งนี้คืออะไร  -->
                <div
                  style="padding: 1.5rem 4rem; position: relative; z-index: 999"
                >
                  <v-layout justify-center>
                    <v-flex xs9>
                      <div class="font-20 font-w-600 mb7">
                        สาเหตุที่คิดจะ "ตรวจอายุ ร่างกาย" ในครั้งนี้คืออะไร?
                      </div>
                      <ul>
                        <li
                          v-for="(item, i) in form.question_5_select_name"
                          :key="i"
                          class="font-20 font-w-400"
                        >
                          {{ item }}
                        </li>
                      </ul>
                    </v-flex>
                    <v-flex xs3 style="padding-left: 2rem">
                      <img src="../assets/images/step5.png" width="60" />
                    </v-flex>
                  </v-layout>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- สาเหตุที่คิดจะ "ตรวจอายุ ร่างกาย" ในครั้งนี้คืออะไร? -->
        <div
          v-for="(item, i) in form.body_problem_concerns"
          :key="i"
          style="height: 1120px; position: relative"
        >
          <div class="page_number">
            {{ i + (pageTotal - 2) }}/{{ pageTotal }}
          </div>
          <div class="pdf-content">
            <div class="section_card">
              <div class="shadow_border"></div>

              <div class="main_card">
                <!-- สาเหตุที่คิดจะ "ตรวจอายุร่างกาย" ในครั้งนี้คืออะไร? -->
                <div style="padding: 15px 4rem">
                  <v-layout>
                    <v-flex xs9>
                      <div class="font-20 font-w-600">
                        สาเหตุที่คิดจะ "ตรวจอายุ ร่างกาย" ในครั้งนี้คืออะไร?
                      </div>

                      <!-- หัวข้อปัญหา -->
                      <div style="margin: 1rem 0px">
                        <v-btn
                          color="#C71010"
                          style="border-radius: 35px"
                          height="40px"
                          depressed
                          dark
                        >
                          <div class="font-20 font-w-400">
                            {{ item.question_6_problem_name }}
                            {{
                              item.question_6_problem_name == "อื่นๆ"
                                ? `(${item.question_6_problem_other})`
                                : ""
                            }}
                          </div>
                        </v-btn>
                      </div>

                      <!-- ถ้าเลือกปัญหาข้อ 1-4 ให้แสดง problem position (ตำแหน่งที่มีอาการ/ปัญหาคือบริเวณใด?)  -->
                      <v-layout>
                        <v-flex xs10>
                          <div class="font-20 font-w-600 mb7">
                            ตำแหน่งที่มีอาการ /ปัญหาคือบริเวณใด?
                          </div>
                          <ul>
                            <li
                              v-for="(
                                text, d
                              ) in item.body_problem_concern_position"
                              :key="d"
                              class="font-20 font-w-400"
                            >
                              {{ text.question_7_position }}
                            </li>
                          </ul>
                        </v-flex>
                        <v-flex xs2 style="position: relative">
                          <div class="body_model">
                            <img
                              style="padding-left: 2rem"
                              src="../assets/images/report-pdf/body.png"
                              widht="132.45px"
                              height="123.94px"
                              contain
                            />

                            <div
                              class="part_position"
                              style="padding-left: 2rem"
                              v-for="(body_parts, i) in body_parts"
                              :key="i"
                            >
                              <img
                                v-if="
                                  checkShowImage(
                                    item.body_problem_concern_position,
                                    body_parts.name
                                  )
                                "
                                :src="
                                  require(`../assets/images/report-pdf/body-part/${body_parts.image}.png`)
                                "
                                widht="132.45px"
                                height="123.94px"
                                alt=""
                              />
                            </div>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs3 style="padding-left: 2rem">
                      <img
                        src="../assets/images/step6-problem.png"
                        width="60"
                      />
                    </v-flex>
                  </v-layout>
                </div>
                <v-divider />

                <!-- คุณพอจะทราบสาเหตุที่ทำให้เกิดอาการ/ปัญหาออกหรือไม่? -->
                <div style="padding: 15px 4rem">
                  <div class="font-20 font-w-600 mb7">
                    คุณพอจะทราบสาเหตุที่ทำให้เกิดอาการ /ปัญหาออกหรือไม่?
                  </div>
                  <div class="font-20 font-w-400">
                    {{
                      item.body_problem_concern_questionnaire
                        ? item.body_problem_concern_questionnaire
                            .question_8_is_know
                        : ""
                    }}
                  </div>
                  <div
                    class="font-20 font-w-400"
                    v-if="
                      item.body_problem_concern_questionnaire &&
                      item.body_problem_concern_questionnaire
                        .question_8_is_know === 'ทราบ'
                    "
                  >
                    {{
                      item.body_problem_concern_questionnaire
                        .question_8_case_question
                    }}
                  </div>
                </div>
                <v-divider />

                <!-- คุณเริ่มรู้สึกถึงอาการ/ปัญหา ตั้งแต่เมื่อใด? -->
                <div style="padding: 15px 4rem">
                  <v-layout justify-center>
                    <v-flex xs9>
                      <div class="font-20 font-w-600 mb7">
                        คุณเริ่มรู้สึกถึงอาการ /ปัญหา ตั้งแต่เมื่อใด?
                      </div>
                      <div class="font-20 font-w-400">
                        {{
                          item.body_problem_concern_questionnaire
                            ? checkSymptom(
                                item.body_problem_concern_questionnaire
                              )
                            : ""
                        }}
                      </div>
                    </v-flex>
                    <v-flex xs3 style="padding-left: 2rem">
                      <img src="../assets/images/calendar.png" width="60" />
                    </v-flex>
                  </v-layout>
                </div>
                <v-divider />

                <!-- อาการ/ปัญหาเกิดขึ้นในเวลาใด? -->
                <div style="padding: 15px 4rem">
                  <div class="font-20 font-w-600 mb7">
                    อาการ/ปัญหาเกิดขึ้นในเวลาใด?
                  </div>
                  <ul
                    class="font-18 font-w-400"
                    v-if="item.body_problem_concern_questionnaire"
                  >
                    <li>
                      ตอนที่ตื่นนอน ({{
                        item.body_problem_concern_questionnaire
                          .question_10_wakeup
                      }})
                    </li>
                    <li>
                      ตอนที่ยืนอยู่({{
                        item.body_problem_concern_questionnaire
                          .question_10_standing
                      }})
                    </li>
                    <li>
                      ตอนที่เดินอยู่({{
                        item.body_problem_concern_questionnaire
                          .question_10_walking
                      }})
                    </li>
                    <li>
                      ตอนที่นั่งอยู่({{
                        item.body_problem_concern_questionnaire
                          .question_10_sitting
                      }})
                    </li>
                    <li>
                      ตอนที่เคลื่อนไหวบางอิริยาบท({{
                        item.body_problem_concern_questionnaire
                          .question_10_moving
                      }})
                    </li>
                    <li>
                      ตอนที่นอนอยู่({{
                        item.body_problem_concern_questionnaire
                          .question_10_sleeping
                      }})
                    </li>
                  </ul>
                </div>
                <v-divider />

                <!-- ที่ผ่านมาคุณทำอะไรไปบ้าง เพื่อแก้ไขอาการ/ปัญหา?  -->
                <div style="padding: 15px 4rem">
                  <div class="font-20 font-w-600 mb7">
                    สาเหตุที่คิดจะ "ตรวจอายุ ร่างกาย" ในครั้งนี้คืออะไร?
                  </div>
                  <ul
                    class="font-18 font-w-400"
                    v-if="item.body_problem_concern_questionnaire"
                  >
                    <li>
                      ตรวจที่โรงพยาบาล/คลินิก({{
                        item.body_problem_concern_questionnaire
                          .question_11_hospital
                      }})
                    </li>
                    <li>
                      นวด({{
                        item.body_problem_concern_questionnaire
                          .question_11_massage
                      }})
                    </li>
                    <li>
                      เข้าคลาสโยคะ/พิลาทิส({{
                        item.body_problem_concern_questionnaire
                          .question_11_yoga
                      }})
                    </li>
                    <li>
                      ไปฟิตเนส({{
                        item.body_problem_concern_questionnaire
                          .question_11_fitness
                      }})
                    </li>
                    <li>
                      ออกกำลังกายด้วยตัวเอง({{
                        item.body_problem_concern_questionnaire
                          .question_11_exercise
                      }})
                    </li>
                    <li
                      v-if="
                        item.body_problem_concern_questionnaire
                          .question_11_exercise_other
                      "
                    >
                      อื่นๆ({{
                        item.body_problem_concern_questionnaire
                          .question_11_exercise_other
                      }})
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style="height: 1120px; position: relative">
          <div class="page_number">{{ pageTotal }}/{{ pageTotal }}</div>

          <div class="pdf-content">
            <!-- card 3 -->
            <div class="section_card">
              <div class="shadow_border"></div>

              <div class="main_card">
                <!-- คุณมักจะออกกำลังกายหรือไม่?  -->
                <div style="padding: 15px 4rem">
                  <v-layout>
                    <v-flex xs9>
                      <div class="font-20 font-w-600 mb7">
                        คุณมักจะออกกำลังกายหรือไม่?
                      </div>
                      <div class="font-20">
                        {{ form ? form.question_12_often_exercise : "" }}
                      </div>
                      <div
                        class="font-20"
                        v-if="
                          form.question_12_often_exercise !==
                          'ไม่ได้ออกกำลังกาย'
                        "
                      >
                        {{ checkExercise(form) }}
                      </div>
                    </v-flex>
                    <v-flex xs3 style="padding-left: 2rem">
                      <img src="../assets/images/step-12.png" width="60" />
                    </v-flex>
                  </v-layout>
                </div>
                <v-divider />

                <!-- คุณสูบบุหรี่หรือไม่??  -->
                <div style="padding: 15px 4rem">
                  <v-layout>
                    <v-flex xs9>
                      <div class="font-20 font-w-600">
                        คุณสูบบุหรี่หรือไม่??
                      </div>
                      <div class="font-20">
                        {{ form.question_13_smoke }}
                      </div>
                      <div
                        class="font-20"
                        v-if="form.question_13_smoke !== 'ไม่สูบ'"
                      >
                        {{ checkSmoke(form) }}
                      </div>
                    </v-flex>
                    <v-flex xs3 style="padding-left: 2rem">
                      <img src="../assets/images/step-13.png" width="60" />
                    </v-flex>
                  </v-layout>
                </div>
                <v-divider />

                <!-- คุณดื่มเครื่องดื่มผสมแอลกอฮอล์หรือไม่?  -->
                <div style="padding: 15px 4rem">
                  <v-layout>
                    <v-flex xs9>
                      <div class="font-20 font-w-600 mb7">
                        คุณดื่มเครื่องดื่มผสมแอลกอฮอล์หรือไม่?
                      </div>
                      <div class="font-20">
                        {{ form ? form.question_14_alcohol : "" }}
                      </div>
                      <div
                        class="font-20"
                        v-if="form.question_14_alcohol !== 'ไม่ดื่ม'"
                      >
                        {{ checkDrink(form) }}
                      </div>
                    </v-flex>
                    <v-flex xs3 style="padding-left: 2rem">
                      <img src="../assets/images/step-14.png" width="60" />
                    </v-flex>
                  </v-layout>
                </div>
                <v-divider />

                <!-- คุณรู้จัก Powerpod จากช่องทางใด?  -->
                <div style="padding: 15px 4rem">
                  <div class="font-20 font-w-600 mb7">
                    คุณรู้จัก Powerpod จากช่องทางใด?
                  </div>
                  <div
                    class="font-20"
                    v-for="(
                      question_15_know_powerpod, q
                    ) in form.question_15_know_powerpod"
                    :key="q"
                  >
                    {{ question_15_know_powerpod }}
                    <div
                      v-if="
                        question_15_know_powerpod == 'สมาชิก Powerpod แนะนำ'
                      "
                    >
                      {{ form.question_15_member_recommend }}
                    </div>
                    <div v-if="question_15_know_powerpod == 'อื่นๆ'">
                      {{ form.question_15_other }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue2";
import SERVICE from "../services/index";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";

import VueHtml2pdf from "vue-html2pdf";

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    onDownload: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Icon,
    VueHtml2pdf,
  },

  watch: {
    onDownload: function (val) {
      if (val) {
        this.downloadPdf();
      }
    },
    data: function (val) {
      this.form = val;
      const date = moment().format("YYYYMMDDHHmmss");
      this.filename = `Questionnaire_${date}`;
    },
  },
  created() {
    // this.getQuestionnaireDetail();
  },

  data() {
    return {
      form: null,
      filename: "",
    };
  },

  methods: {
    async downloadPdf() {
      this.$refs.html2Pdf.generatePdf();
    },
    onProgress(e) {
      if (e === 100) {
        this.$emit("onDownloadComplete");
      }
    },

    getQuestionnaireDetail() {
      this.isLoading = true;
      SERVICE.questionnaire.show(this.$route.params.id).then((res) => {
        this.isLoading = false;
        if (res.status == 200) {
          this.form = res.data.questionnaire;
        } else {
          this.$swal({
            title: "Error!",
            text: "ไม่พบข้อมูลแบบสอบถาม",
            icon: "error",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push("/questionnaire");
            }
          });
        }
      });
    },

    formatDate(date) {
      return date
        ? new Date(date).toLocaleDateString("th-TH", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })
        : "";
    },

    formatGender(gender) {
      if (gender) {
        if (gender === "M") {
          return "ชาย";
        } else {
          return "หญิง";
        }
      }
    },

    checkShowImage(positions, key) {
      let isShow = false;
      positions.forEach((e) => {
        if (e.question_7_position == key) {
          isShow = true;
        }
      });
      return isShow;
    },

    checkSymptom(item) {
      if (item) {
        switch (item.question_9_select_name) {
          case "เดือนที่แล้ว":
            return item.question_9_last_month + " เดือนที่แล้ว";
          case "สัปดาห์ที่ผ่านมา":
            return item.question_9_last_week + " สัปดาห์ที่ผ่านมา";
          default:
            return item.question_9_select_name;
        }
      }
    },

    checkExercise(item) {
      console.log(item);
      if (item) {
        if (item.question_12_often_exercise === "ทำประจำ ประมาณ") {
          return item.question_12_often_exercise_week + " วัน/สัปดาห์";
        } else {
          return item.question_12_often_exercise_month + " วัน/เดือน";
        }
      }
    },

    checkSmoke(item) {
      if (item) {
        if (item.question_13_smoke === "สูบ ประมาณ") {
          return item.question_13_smoke_day + " มวน/วัน";
        } else {
          return item.question_13_smoke_year + " ปีที่แล้ว";
        }
      }
    },

    checkDrink(item) {
      if (item) {
        if (item.question_14_alcohol === "ดื่ม ประมาณ") {
          return item.question_14_alcohol_week + " วัน/สัปดาห์";
        } else {
          return item.question_14_alcohol_month + " วัน/เดือน";
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pdf-content {
  position: relative;
  width: 100%;
  background: #fff;
  padding: 30px;

  .page_number {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 18px;
    color: #000;
    z-index: 99999;
  }

  .box-header {
    width: 100%;
    .card-box-header {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: #55c5c8;
      border: 1px solid #000000;
      border-radius: 20px;
      color: #fff;
      margin-bottom: 1rem;
    }
  }
  .card-info {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .box-info {
      width: 60%;
      display: flex;
      justify-content: space-between;
    }
  }
  .section-card {
    border: 1px solid #000;
    border-radius: 30px;
    padding: 1rem 0px;
    margin-bottom: 1rem;
  }
}
.width-100 {
  width: 100%;
}

.d-flex {
  display: flex;
}
.justify-content-center {
  justify-content: center;
}
.align-items-center {
  align-items: center;
}
.flex-column {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
.flex-grow-1 {
  flex-grow: 1;
}
.flex-grow-0 {
  flex-grow: 0;
}
.flex-fill {
  flex: 1 1 auto;
}
.mb7 {
  margin-bottom: 7px;
}
</style>
