<template>
  <v-card
    :height="height"
    :width="width"
    class="mx-3"
    :class="checkTheme"
    :style="theme === 'light' ? { background: backgroundColor } : ''"
  >
    <slot></slot>
  </v-card>
</template>

<script>
export default {
  props: {
    theme: {
      type: String,
      default: "dark",
    },
    backgroundColor: {
      type: String,
      default: "#ffcfcf",
    },
    height: {
      type: String,
      default: "auto",
    },
    width: {
      type: String,
      default: "auto",
    },
  },
  computed: {
    checkTheme() {
      switch (this.theme) {
        case "warn":
          return "box__shadow__warn";
        case "danger":
          return "box__shadow__danger";
        case "standard":
          return "box__shadow__standard";
        case "light":
          return "box__shadow__light";
        case "danger-dark":
          return "box__shadow__danger_dark";
        case "yellow-dark":
          return "box__shadow__yellow__dark";
        case "red-dark":
          return "box__shadow__red__dark";
        case "green-dark":
          return "box__shadow__green__dark";
        case "gray-light":
          return "box__shadow__gray_light";
        case "gray":
          return "box__shadow__gray";
        case "gray-black":
          return "box__shadow__gray__black";
        case "white-bluelight":
          return "box__shadow__white__bluelight";
        default:
          return "box__shadow__dark";
      }
    },
  },
};
</script>

<style>
.box__shadow__dark {
  -webkit-box-shadow: 7px 7px 0px 1px #000000 !important;
  box-shadow: 7px 7px 0px 1px #000000 !important;
  border: 1px solid !important;
  border-radius: 30px !important;
  padding: 0.7rem 1.5rem;
}
.box__shadow__light {
  background: #ffcfcf !important;
  -webkit-box-shadow: 7px 7px 0px 0px #fff, 7px 7px 0px 1px black !important;
  box-shadow: 7px 7px 0px 0px #fff, 7px 7px 0px 1px black !important;
  border: 1px solid !important;
  border-radius: 30px !important;
  padding: 0.7rem 1.5rem;
}
.box__shadow__standard {
  -webkit-box-shadow: 7px 7px 0px 0px #cce8d8, 7px 7px 0px 1px black !important;
  box-shadow: 7px 7px 0px 0px #cce8d8, 7px 7px 0px 1px black !important;
  border: 1px solid !important;
  border-radius: 30px !important;
  padding: 0.7rem 1.5rem;
}
.box__shadow__warn {
  -webkit-box-shadow: 7px 7px 0px 0px #faf5c1, 7px 7px 0px 1px black !important;
  box-shadow: 7px 7px 0px 0px #faf5c1, 7px 7px 0px 1px black !important;
  border: 1px solid !important;
  border-radius: 30px !important;
  padding: 0.7rem 1.5rem;
}
.box__shadow__yellow__dark {
  background: #fdf6bf !important;
  -webkit-box-shadow: 7px 7px 0px 0px #000000, 7px 7px 0px 1px black !important;
  box-shadow: 7px 7px 0px 0px #000000, 7px 7px 0px 1px black !important;
  border: 1px solid !important;
  border-radius: 30px !important;
  padding: 0.7rem 1.5rem;
}
.box__shadow__red__dark {
  background: #ffdbdb !important;
  -webkit-box-shadow: 7px 7px 0px 0px #000000, 7px 7px 0px 1px black !important;
  box-shadow: 7px 7px 0px 0px #000000, 7px 7px 0px 1px black !important;
  border: 1px solid !important;
  border-radius: 30px !important;
  padding: 0.7rem 1.5rem;
}
.box__shadow__green__dark {
  background: #ddffdb !important;
  -webkit-box-shadow: 7px 7px 0px 0px #000000, 7px 7px 0px 1px black !important;
  box-shadow: 7px 7px 0px 0px #000000, 7px 7px 0px 1px black !important;
  border: 1px solid !important;
  border-radius: 30px !important;
  padding: 0.7rem 1.5rem;
}
.box__shadow__danger {
  -webkit-box-shadow: 7px 7px 0px 0px #ffcfcf, 7px 7px 0px 1px black !important;
  box-shadow: 7px 7px 0px 0px #ffcfcf, 7px 7px 0px 1px black !important;
  border: 1px solid !important;
  border-radius: 30px !important;
  padding: 0.7rem 1.5rem;
}
.box__shadow__danger_dark {
  background: #c71010 !important;
  -webkit-box-shadow: 7px 7px 0px 0px #000000, 7px 7px 0px 1px black !important;
  box-shadow: 7px 7px 0px 0px #000000, 7px 7px 0px 1px black !important;
  border: 1px solid !important;
  border-radius: 30px !important;
  padding: 0.7rem 1.5rem;
}
.box__shadow__gray_light {
  background: #fff !important;
  -webkit-box-shadow: 7px 7px 0px 0px #a3a3a3, 7px 7px 0px 1px #a3a3a3 !important;
  box-shadow: 7px 7px 0px 0px #a3a3a3, 7px 7px 0px 1px #a3a3a3 !important;
  border: 1px solid #a3a3a3 !important;
  border-radius: 30px !important;
  padding: 0.7rem 1.5rem;
}
.box__shadow__gray {
  background: #f3f3f3 !important;
  -webkit-box-shadow: 7px 7px 0px 0px #f3f3f3, 7px 7px 0px 1px #000 !important;
  box-shadow: 7px 7px 0px 0px #f3f3f3, 7px 7px 0px 1px #000 !important;
  border: 1px solid #000 !important;
  border-radius: 30px !important;
  padding: 0.7rem 1.5rem;
}
.box__shadow__gray__black {
  background: #f3f3f3 !important;
  -webkit-box-shadow: 7px 7px 0px 0px #000, 7px 7px 0px 1px #000 !important;
  box-shadow: 7px 7px 0px 0px #000, 7px 7px 0px 1px #000 !important;
  border: 1px solid #000 !important;
  border-radius: 30px !important;
  padding: 0.7rem 1.5rem;
}
.box__shadow__white__bluelight {
  background: #fff !important;
  -webkit-box-shadow: 7px 7px 0px 0px #d4edee, 7px 7px 0px 1px #000 !important;
  box-shadow: 7px 7px 0px 0px #d4edee, 7px 7px 0px 1px #000 !important;
  border: 1px solid #000 !important;
  border-radius: 30px !important;
  padding: 0.7rem 1.5rem;
}
</style>
