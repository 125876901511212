<template>
  <div>
    <v-layout align-center>
      <v-flex shrink mr-3>
        <v-img
          v-if="onPage === 'customerList'"
          width="40"
          height="40"
          contain
          src="../assets/images/customer-list-icon.png"
        ></v-img>
        <v-img
          v-if="onPage === 'Questionnaire'"
          width="40"
          height="40"
          contain
          src="../assets/images/Note.png"
        ></v-img>
        <v-img
          v-if="onPage === 'customerForm'"
          width="40"
          height="40"
          contain
          src="../assets/images/Badge.png"
        ></v-img>
        <v-img
          v-if="onPage === 'setting'"
          width="40"
          height="40"
          contain
          src="../assets/images/Settings.png"
        ></v-img>
        <v-img
          v-if="onPage === 'report'"
          width="40"
          height="40"
          contain
          src="../assets/images/Report.png"
        ></v-img>
        <v-img
          v-if="onPage === 'password'"
          width="40"
          height="40"
          contain
          src="../assets/images/Password.png"
        ></v-img>
        <v-img
          v-if="onPage === 'crm'"
          width="40"
          height="40"
          contain
          src="../assets/images/Notification.png"
        ></v-img>
        <v-img
          v-if="onPage === 'compare'"
          width="40"
          height="40"
          contain
          src="../assets/images/Chart.png"
        ></v-img>
      </v-flex>
      <v-flex shrink>
        <span class="txt__title">{{ txtEn }}</span>
      </v-flex>
      <v-flex shrink>
        <span class="font-24 font-w-400 ml-3">{{ txtTh }}</span>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: {
    onPage: String,
    txtTh: {
      type: String,
      default: "",
    },
    txtEn: {
      type: String,
      default: "",
    },
  },
};
</script>

<style></style>
