import auth from "./api/Auth.Service";
import customer from "./api/Customer.Service";
import setting from "./api/Setting.Service";
import crm from "./api/Crm.Service";
import compare from "./api/Compare.Service";
import questionnaire from "./api/Questionnaire.Service";

export default {
  auth,
  customer,
  setting,
  crm,
  compare,
  questionnaire,
};
